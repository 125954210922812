import {
  AccessTime,
  AccountBalance,
  AccountBalanceWallet,
  AcUnit,
  AddReaction,
  AirlineSeatFlat,
  AlarmOn,
  AutoStories,
  AvTimer,
  BackHand,
  BakeryDining,
  Bathtub,
  Bed,
  Bedtime,
  BusinessCenter,
  Cake,
  Call,
  Camera,
  CameraAlt,
  CameraRoll,
  CarRental,
  Celebration,
  Chair,
  Chalet,
  Church,
  Code,
  Comment,
  Commute,
  Computer,
  Cookie,
  Coronavirus,
  Countertops,
  Description,
  DesignServices,
  DinnerDining,
  DirectionsBike,
  DirectionsBoat,
  DirectionsBus,
  DirectionsCar,
  DirectionsRun,
  DirectionsTransit,
  DirectionsWalk,
  Diversity3,
  DownhillSkiing,
  Draw,
  EditNote,
  Emergency,
  EmojiNature,
  EmojiPeople,
  Error,
  Face2,
  FaceRetouchingNatural,
  FamilyRestroom,
  Fastfood,
  Favorite,
  FavoriteBorder,
  FitnessCenter,
  Flatware,
  Flight,
  FormatPaint,
  Groups,
  Hiking,
  Home,
  Hotel,
  HourglassEmpty,
  IceSkating,
  ImportContacts,
  Iron,
  Lightbulb,
  LocalActivity,
  LocalBar,
  LocalCafe,
  LocalDining,
  LocalFireDepartment,
  LocalGasStation,
  LocalGroceryStore,
  LocalHospital,
  LocalLaundryService,
  LocalLibrary,
  MediaBluetoothOn,
  Medication,
  Movie,
  Museum,
  Newspaper,
  NoteAlt,
  Park,
  PeopleOutline,
  Pets,
  PhoneEnabled,
  Place,
  PlayCircle,
  Pool,
  Restaurant,
  RollerSkating,
  Sailing,
  SelfImprovement,
  ShapeLine,
  ShoppingBag,
  Shower,
  Skateboarding,
  Snowboarding,
  Spa,
  Sports,
  SportsEsports,
  SportsGymnastics,
  Subway,
  Terminal,
  TheaterComedy,
  Tour,
  Train,
  Translate,
  Tv,
  TwoWheeler,
  ViewCarousel,
  Weekend,
  Work,
  Yard,
  Healing,
  Brush,
  MusicNote,
  RecordVoiceOver,
  Wc,
  Air,
  Agriculture,
  AirplaneTicket,
  Album,
  Architecture,
  BeachAccess,
  Biotech,
  Cabin,
  Cloud,
  ElectricScooter,
  Festival,
  Fingerprint,
  Gavel,
  Handyman,
  Keyboard,
  MonitorHeart,
  Mouse,
  NaturePeople,
  Poll,
  QuestionAnswer,
  RamenDining,
  RocketLaunch,
  Savings,
  School,
  Science,
  ScubaDiving,
  SportsMotorsports,
  Storm,
  Straighten,
  Surfing,
  Traffic,
  Umbrella,
  Vaccines,
  VolunteerActivism,
  WavingHand,
  Whatshot,
  WineBar,
  Window,
} from "@mui/icons-material";

export const ICONS: Record<string, typeof AccountBalance> = {
  // Media and Entertainment
  tv: Tv,
  movie: Movie,
  theater_comedy: TheaterComedy,
  game_controller: SportsEsports,
  play_circle: PlayCircle,
  newspaper: Newspaper,
  carousel: ViewCarousel,
  album: Album,

  // Education and Learning
  book: ImportContacts,
  book_flip: AutoStories,
  library: LocalLibrary,
  translate: Translate,
  school: School,
  science: Science,

  // Personal Care and Health
  bathtub: Bathtub,
  shower: Shower,
  spa: Spa,
  fitness_center: FitnessCenter,
  medication: Medication,
  red_cross: LocalHospital,
  beauty_face: FaceRetouchingNatural,
  meditation: SelfImprovement,
  healing: Healing,
  toilet: Wc,
  monitor_heart: MonitorHeart,
  vaccines: Vaccines,

  // Home and Household
  home: Home,
  bed: Bed,
  bedtime: Bedtime,
  chair: Chair,
  chalet: Chalet,
  countertops: Countertops,
  iron: Iron,
  laundry: LocalLaundryService,
  yard: Yard,
  cabin: Cabin,
  window: Window,
  handyman: Handyman,

  // Work and Business
  work: Work,
  briefcase: BusinessCenter,
  computer: Computer,
  code: Code,
  terminal: Terminal,
  description: Description,
  note: NoteAlt,
  edit_note: EditNote,
  keyboard: Keyboard,
  mouse: Mouse,
  poll: Poll,
  straighten: Straighten,

  // Social and Communication
  add_reaction: AddReaction,
  favorite: Favorite,
  favourite_border: FavoriteBorder,
  face: Face2,
  family_restroom: FamilyRestroom,
  people: PeopleOutline,
  diversity: Diversity3,
  groups: Groups,
  comment: Comment,
  call: Call,
  phone_enabled: PhoneEnabled,
  celebration: Celebration,
  person: EmojiPeople,
  backhand: BackHand,
  question_answer: QuestionAnswer,
  waving_hand: WavingHand,
  volunteer_activism: VolunteerActivism,
  fingerprint: Fingerprint,

  // Sports and Activities
  sports: Sports,
  gymnastics: SportsGymnastics,
  hiking: Hiking,
  ski: DownhillSkiing,
  skateboarding: Skateboarding,
  iceskate: IceSkating,
  rollerskate: RollerSkating,
  snowboarding: Snowboarding,
  run: DirectionsRun,
  bike: DirectionsBike,
  sailing: Sailing,
  pool: Pool,
  scuba_diving: ScubaDiving,
  surfing: Surfing,
  sports_motorsports: SportsMotorsports,

  // Transportation
  car_rental: CarRental,
  motorcycle: TwoWheeler,
  flight: Flight,
  subway: Subway,
  train: Train,
  commute: Commute,
  boat: DirectionsBoat,
  car: DirectionsCar,
  transit: DirectionsTransit,
  bus: DirectionsBus,
  walk: DirectionsWalk,
  airplane_ticket: AirplaneTicket,
  electric_scooter: ElectricScooter,
  traffic: Traffic,

  // Shopping and Services
  shopping: ShoppingBag,
  grocery: LocalGroceryStore,
  activity: LocalActivity,
  cafe: LocalCafe,
  bar: LocalBar,

  // Food and Dining
  restaurant: Restaurant,
  dinner: DinnerDining,
  dining: LocalDining,
  croissant: BakeryDining,
  cookie: Cookie,
  cake: Cake,
  burger: Fastfood,
  utensils: Flatware,
  ramen_dining: RamenDining,
  wine_bar: WineBar,

  // Art and Creativity
  draw: Draw,
  design: DesignServices,
  shape_line: ShapeLine,
  paint_roller: FormatPaint,
  paint: Brush,
  music: MusicNote,
  architecture: Architecture,

  // Nature and Outdoors
  park: Park,
  nature: EmojiNature,
  pets: Pets,
  snowflake: AcUnit,
  air: Air,
  beach_access: BeachAccess,
  cloud: Cloud,
  nature_people: NaturePeople,
  storm: Storm,
  umbrella: Umbrella,

  // Places and Tourism
  museum: Museum,
  place: Place,
  hotel: Hotel,
  tour: Tour,
  festival: Festival,

  // Time and Scheduling
  alarm: AlarmOn,
  time: AccessTime,
  hourglass_empty: HourglassEmpty,
  timer: AvTimer,

  // Finance
  bank: AccountBalance,
  wallet: AccountBalanceWallet,
  gas: LocalGasStation,
  savings: Savings,

  // Emergency and Safety
  fire: LocalFireDepartment,
  astriks: Emergency,
  error: Error,
  coronavirus: Coronavirus,
  whatshot: Whatshot,

  // Photography
  film: CameraRoll,
  apeture: Camera,
  camera: CameraAlt,

  // Miscellaneous
  lightbulb: Lightbulb,
  weekend: Weekend,
  church: Church,
  voice_over: RecordVoiceOver,
  media_bluetooth: MediaBluetoothOn,
  liedown: AirlineSeatFlat,
  biotech: Biotech,
  rocket_launch: RocketLaunch,
  gavel: Gavel,
  agriculture: Agriculture,
};

export const ICONS_SEARCH_TERMS: Record<string, string[]> = {
  // Media and Entertainment
  tv: ["television", "show", "screen", "broadcast"],
  movie: ["cinema", "theatre", "film", "video"],
  theater_comedy: ["comedy", "drama", "stage", "performance"],
  game_controller: ["gaming", "video game", "console", "play"],
  play_circle: ["play", "start", "video", "media"],
  newspaper: ["news", "article", "press", "reading"],
  carousel: ["slideshow", "gallery", "images", "rotation"],
  album: ["record", "cd", "disk", "vinyl", "music album"],

  // Education and Learning
  book: ["reading", "literature", "textbook", "novel"],
  book_flip: ["reading", "story", "pages", "novel"],
  library: ["books", "study", "archive", "collection"],
  translate: ["language", "interpretation", "dictionary", "foreign"],
  school: ["education", "graduation", "university", "academic", "college"],
  science: ["laboratory", "research", "experiment", "chemistry", "flask"],

  // Personal Care and Health
  bathtub: ["bath", "soak", "washroom", "bathroom"],
  shower: ["wash", "bathroom", "clean", "water"],
  spa: ["wellness", "massage", "relaxation", "treatment"],
  fitness_center: ["gym", "workout", "exercise", "training"],
  medication: ["medicine", "pills", "pharmacy", "treatment", "doctor"],
  red_cross: ["hospital", "emergency", "medical", "healthcare", "doctor"],
  beauty_face: ["skincare", "beauty", "cosmetics", "facial"],
  meditation: ["mindfulness", "yoga", "calm", "zen", "self improvement"],
  healing: ["recovery", "therapy", "wellness", "health"],
  toilet: ["bathroom", "restroom", "lavatory", "loo"],
  monitor_heart: [
    "cardiogram",
    "heartbeat",
    "pulse",
    "medical",
    "health monitoring",
  ],
  vaccines: [
    "syringe",
    "injection",
    "medicine",
    "immunisation",
    "vaccination",
    "doctor",
    "healthcare",
  ],

  // Home and Household
  home: ["house", "residence", "apartment", "dwelling"],
  bed: ["sleep", "bedroom", "rest", "mattress"],
  bedtime: ["sleep", "night", "rest", "bedroom"],
  chair: ["seat", "furniture", "sitting", "stool"],
  chalet: ["cabin", "cottage", "lodge", "mountain house"],
  countertops: ["kitchen", "counter", "surface", "worktop"],
  iron: ["ironing", "clothes", "laundry", "pressing"],
  laundry: ["washing", "clothes", "cleaning", "detergent"],
  yard: ["garden", "lawn", "outdoors", "backyard"],
  cabin: ["loghouse", "cottage", "wooden house", "lodge", "rural home"],
  window: ["glass", "view", "pane", "opening", "frame"],
  handyman: ["tools", "repair", "hammer", "screwdriver", "diy"],

  // Work and Business
  work: ["job", "career", "office", "profession"],
  briefcase: ["business", "work", "office", "professional"],
  computer: ["laptop", "desktop", "pc", "technology"],
  code: ["programming", "development", "coding", "software"],
  terminal: ["command line", "console", "shell", "programming"],
  description: ["document", "text", "file", "information"],
  note: ["memo", "reminder", "writing", "notes"],
  edit_note: ["writing", "document", "edit", "modify"],
  keyboard: ["typing", "computer", "input device", "keys", "tech"],
  mouse: ["computer mouse", "pointer", "click", "input device", "cursor"],
  poll: ["graph", "chart", "statistics", "survey", "data"],
  straighten: ["ruler", "measure", "length", "straight line", "precision"],

  // Social and Communication
  add_reaction: ["emoji", "reaction", "emotion", "response"],
  favorite: ["like", "love", "heart", "preference"],
  favourite_border: ["like", "love", "heart", "outline"],
  face: ["person", "head", "portrait", "profile"],
  family_restroom: ["family", "bathroom", "parents", "children"],
  people: ["persons", "group", "humans", "crowd"],
  diversity: ["inclusion", "variety", "different", "multicultural"],
  groups: ["team", "crowd", "gathering", "community"],
  comment: ["message", "feedback", "chat", "communication"],
  call: ["phone", "telephone", "contact", "dial"],
  phone_enabled: ["telephone", "mobile", "contact", "call"],
  celebration: ["party", "event", "festivity", "celebration"],
  person: ["human", "individual", "people", "body"],
  backhand: ["hand", "palm", "wave", "gesture"],
  question_answer: [
    "chat",
    "conversation",
    "dialogue",
    "messaging",
    "discussion",
  ],
  waving_hand: ["hello", "greeting", "wave", "welcome", "goodbye"],
  volunteer_activism: [
    "charity",
    "helping",
    "donation",
    "community service",
    "heart",
  ],
  fingerprint: [
    "identity",
    "biometric",
    "security",
    "unique",
    "authentication",
  ],

  // Sports and Activities
  sports: ["athletics", "games", "physical activity", "competition"],
  gymnastics: ["acrobatics", "tumbling", "athletics", "exercise"],
  hiking: ["trekking", "walking", "outdoors", "trail"],
  ski: ["skiing", "snow", "winter sport", "mountain"],
  skateboarding: ["skate", "board", "trick", "ramp"],
  iceskate: ["ice skating", "winter", "rink", "blades"],
  rollerskate: ["roller skating", "wheels", "rink", "skates"],
  snowboarding: ["snow", "board", "winter sport", "mountain"],
  run: ["running", "jog", "sprint", "exercise"],
  bike: ["bicycle", "cycling", "ride", "pedal"],
  sailing: ["boat", "sail", "water", "nautical"],
  pool: ["swimming", "water", "swim", "aquatic"],
  scuba_diving: ["diving", "underwater", "snorkel", "ocean", "aquatic sport"],
  surfing: ["surf", "waves", "beach", "board", "water sport"],
  sports_motorsports: [
    "racing",
    "motorcycle helmet",
    "speed",
    "motor racing",
    "protection",
  ],

  // Transportation
  car_rental: ["hire car", "rental", "vehicle", "automobile"],
  motorcycle: ["bike", "motorbike", "scooter", "vehicle"],
  flight: ["airplane", "travel", "air", "plane"],
  subway: ["underground", "metro", "tube", "transit"],
  train: ["railway", "locomotive", "rail", "transport"],
  commute: ["travel", "journey", "transport", "trip"],
  boat: ["ship", "vessel", "watercraft", "sailing"],
  car: ["auto", "vehicle", "automobile", "transport"],
  transit: ["transport", "travel", "commute", "public transport"],
  bus: ["coach", "transport", "vehicle", "public transport"],
  walk: ["walking", "stroll", "hike", "pedestrian"],
  airplane_ticket: [
    "flight ticket",
    "boarding pass",
    "travel document",
    "air travel",
    "reservation",
  ],
  electric_scooter: [
    "e-scooter",
    "mobility",
    "electric vehicle",
    "urban transport",
    "rental",
  ],
  traffic: [
    "traffic lights",
    "road signals",
    "intersection",
    "street lights",
    "road control",
  ],

  // Shopping and Services
  shopping: ["shop", "purchase", "retail", "buying"],
  grocery: ["supermarket", "food shop", "store", "provisions"],
  activity: ["event", "entertainment", "recreation", "leisure"],
  cafe: ["coffee shop", "bistro", "coffeehouse", "espresso"],
  bar: ["pub", "tavern", "drinks", "nightlife"],

  // Food and Dining
  restaurant: ["dining", "eatery", "food", "meal"],
  dinner: ["supper", "evening meal", "dining", "food"],
  dining: ["eating", "meal", "food", "restaurant"],
  croissant: ["pastry", "bakery", "breakfast", "french"],
  cookie: ["biscuit", "sweet", "treat", "baked"],
  cake: ["dessert", "birthday", "sweet", "baked"],
  burger: ["hamburger", "fast food", "sandwich", "takeaway"],
  utensils: ["cutlery", "silverware", "fork", "knife"],
  ramen_dining: ["noodles", "soup", "chopsticks", "japanese food", "bowl"],
  wine_bar: ["wine glass", "alcohol", "drink", "beverage", "bar"],

  // Art and Creativity
  draw: ["drawing", "sketch", "art", "illustration"],
  design: ["graphic", "creative", "artwork", "layout"],
  shape_line: ["geometry", "form", "outline", "drawing"],
  paint_roller: ["painting", "decorating", "wall", "renovation"],
  paint: ["brush", "art", "painting", "colour"],
  music: ["song", "audio", "sound", "melody"],
  architecture: ["design", "building", "compass", "blueprint", "construction"],

  // Nature and Outdoors
  park: ["garden", "outdoors", "recreation", "green space"],
  nature: ["environment", "outdoors", "plants", "wildlife"],
  pets: ["animals", "companions", "dogs", "cats"],
  snowflake: ["snow", "winter", "cold", "ice"],
  air: ["wind", "breeze", "atmosphere", "airflow", "weather"],
  beach_access: ["beach", "umbrella", "seaside", "shore", "sand"],
  cloud: ["sky", "weather", "fluffy", "storage", "atmosphere"],
  nature_people: ["park", "outdoors", "tree", "person", "environment"],
  storm: ["cyclone", "hurricane", "weather", "tornado", "wind"],
  umbrella: ["rain", "shelter", "protection", "weather", "parasol"],

  // Places and Tourism
  museum: ["gallery", "exhibition", "artifacts", "history"],
  place: ["location", "spot", "destination", "site"],
  hotel: ["accommodation", "lodging", "stay", "resort"],
  tour: ["tourism", "sightseeing", "guide", "excursion"],
  festival: ["event", "celebration", "tent", "music festival", "gathering"],

  // Time and Scheduling
  alarm: ["alert", "reminder", "clock", "wake up"],
  time: ["clock", "hour", "minute", "schedule"],
  hourglass_empty: ["timer", "waiting", "countdown", "sand"],
  timer: ["stopwatch", "countdown", "timing", "clock"],

  // Finance
  bank: ["financial", "money", "banking", "institution"],
  wallet: ["money", "purse", "payment", "billfold"],
  gas: ["petrol", "fuel", "station", "gasoline"],
  savings: ["piggy bank", "money", "budget", "investment", "financial"],

  // Emergency and Safety
  fire: ["flame", "emergency", "burning", "firefighter"],
  astriks: ["emergency", "urgent", "important", "critical"],
  error: ["mistake", "problem", "warning", "fault"],
  coronavirus: ["covid", "virus", "pandemic", "disease"],
  whatshot: ["flame", "fire", "hot", "trending", "popular"],

  // Photography
  film: ["movie", "cinema", "reel", "photography"],
  apeture: ["lens", "camera", "photography", "opening"],
  camera: ["photo", "picture", "snapshot", "photography"],

  // Miscellaneous
  lightbulb: ["idea", "light", "inspiration", "bright"],
  weekend: ["relaxation", "time off", "saturday", "sunday"],
  church: ["religion", "worship", "chapel", "faith"],
  voice_over: ["narration", "speaking", "audio", "voiceover"],
  media_bluetooth: ["wireless", "connection", "audio", "pairing"],
  liedown: ["recline", "rest", "horizontal", "relax"],
  biotech: ["microscope", "biology", "research", "laboratory", "science"],
  rocket_launch: ["spaceship", "launch", "space", "mission", "blast off"],
  gavel: ["judge", "court", "legal", "justice", "hammer"],
  agriculture: ["farm", "farming", "tractor", "harvest"],
};

// Create reverse search map where each search term points to an array of matching icon keys
export const ICON_SEARCH: Record<string, string[]> = {};

// Populate the reverse search map
Object.entries(ICONS_SEARCH_TERMS).forEach(([iconKey, searchTerms]) => {
  // Add the icon key itself as a search term
  if (!ICON_SEARCH[iconKey]) {
    ICON_SEARCH[iconKey] = [];
  }
  ICON_SEARCH[iconKey].push(iconKey);

  // Add all search terms for this icon
  searchTerms.forEach((term) => {
    const lowerTerm = term.toLowerCase();
    if (!ICON_SEARCH[lowerTerm]) {
      ICON_SEARCH[lowerTerm] = [];
    }
    ICON_SEARCH[lowerTerm].push(iconKey);
  });
});

export const ICON_ARRAY: { name: string; icon: typeof AccountBalance }[] =
  Object.keys(ICONS).map((key) => ({
    name: key,
    icon: ICONS[key],
  }));
