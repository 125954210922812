import styled from "@emotion/styled";
import {
  Box,
  Stack,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { CategorySegment } from "../../../utils/stats";
import theme from "../../../theme/theme";
import { NoteAlt, Book, BarChart } from "@mui/icons-material";
import { css } from "@emotion/react";
const StackedBar = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 25px);
  width: 100%;
  gap: 1px;
  margin-top: 25px;
`;

const BarRow = styled(Stack)<{ height: number }>`
  height: ${(props) => props.height}%;
  width: 100%;
  gap: 1px;
`;

const BarSegment = styled(Box)<{
  color: string;
  width: number;
}>`
  height: 100%;
  width: ${(props) => props.width}%;
  background-color: ${(props) => props.color};
`;

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme: _ }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 400,
    fontSize: 12,
    padding: "10px",
  },
}));

const IconsContainer = styled(Stack)`
  position: absolute;
  top: 7px;
  right: 7px;
  z-index: 10;
`;

const StyledNoteIcon = styled(NoteAlt)<{ disabled?: boolean }>`
  font-size: 16px;
  color: ${(props) => (props.disabled ? "#ddd" : "#666")};
  cursor: pointer;

  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;

  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover {
        color: #000;
        background-color: #ddd;
      }
    `}
`;

const StyledJournalIcon = styled(Book)<{ disabled?: boolean }>`
  font-size: 16px;
  color: ${(props) => (props.disabled ? "#ddd" : "#666")};
  cursor: pointer;

  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;

  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover {
        color: #000;
        background-color: #ddd;
      }
    `}
`;

const StyledStatsIcon = styled(BarChart)`
  font-size: 16px;
  color: #666;
  cursor: pointer;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;

  &:hover {
    color: #000;
  }
`;

const TooltipWrapper = styled.div`
  max-width: 500px;
  max-height: 500px;
  min-width: 300px;
  white-space: pre-line;
  overflow: auto;
`;

interface StatsCalendarCellProps {
  segments: CategorySegment[];
  percentUnknown: number;
  hasNotes?: boolean;
  dayNotes?: string[];
  hasJournal?: boolean;
  journalEntry?: string;
  dateKey: string;
  onJournalClick: (dateKey: string, event: React.MouseEvent) => void;
}

const StatsCalendarCell: React.FC<StatsCalendarCellProps> = ({
  segments,
  percentUnknown,
  hasNotes = false,
  dayNotes = [],
  hasJournal = false,
  journalEntry = "",
  dateKey,
  onJournalClick,
}) => {
  segments.sort((a, b) => b.percentage - a.percentage);
  const totalPercentage =
    segments.reduce((sum, segment) => sum + segment.percentage, 0) +
    percentUnknown;
  const halfTotalPercentage = totalPercentage / 2;

  const firstRow: CategorySegment[] = [];
  const secondRow: CategorySegment[] = [];

  let firstRowPercentage = 0;
  let secondRowPercentage = 0;

  segments.forEach((segment) => {
    if (firstRowPercentage < halfTotalPercentage) {
      firstRow.push(segment);
      firstRowPercentage += segment.percentage;
    } else {
      secondRow.push(segment);
      secondRowPercentage += segment.percentage;
    }
  });

  const percentageToTime = (percentage: number) => {
    const percentNormalised = percentage / 100;
    const hours = Math.floor(percentNormalised * 24);
    const minutes = Math.floor((percentNormalised * 24 - hours) * 60);
    return `${hours}h ${minutes}m`;
  };
  const isXS = useMediaQuery(theme.breakpoints.down("sm"));

  const renderRow = (
    rowSegments: CategorySegment[],
    rowTotalPercentage: number
  ) => (
    <BarRow
      height={(rowTotalPercentage / totalPercentage) * 100}
      direction={{ xs: "column", sm: "row" }}>
      {rowSegments.map(({ category, percentage }) => (
        <BarSegment
          key={category?.id}
          color={category?.colour || "#d9d9d9"}
          width={isXS ? 100 : (percentage / rowTotalPercentage) * 100}
        />
      ))}
    </BarRow>
  );

  return (
    <>
      <IconsContainer direction="row" spacing={0}>
        {hasNotes && (
          <HtmlTooltip
            title={
              <TooltipWrapper>
                <Typography variant="body2">Notes</Typography>
                {dayNotes.join("\n")}
              </TooltipWrapper>
            }
            arrow
            placement="top">
            <StyledNoteIcon onClick={(e) => onJournalClick(dateKey, e)} />
          </HtmlTooltip>
        )}

        {hasJournal ? (
          <HtmlTooltip
            title={
              <TooltipWrapper
                style={{
                  whiteSpace: "pre-line",
                  maxWidth: "300px",
                  maxHeight: "200px",
                  overflow: "auto",
                }}>
                <Typography variant="body2">Journal</Typography>
                {journalEntry.length > 300
                  ? journalEntry.substring(0, 300) + "..."
                  : journalEntry}
              </TooltipWrapper>
            }
            arrow
            placement="top">
            <StyledJournalIcon onClick={(e) => onJournalClick(dateKey, e)} />
          </HtmlTooltip>
        ) : (
          <HtmlTooltip
            title={
              <Typography variant="body2">
                No journal entry
                <br />
                Click to create one
              </Typography>
            }>
            <StyledJournalIcon
              disabled={true}
              onClick={(e) => onJournalClick(dateKey, e)}
            />
          </HtmlTooltip>
        )}

        <HtmlTooltip
          title={
            <Stack direction={"column"} gap={0.5} padding={0}>
              {segments.map(({ category, percentage }) => (
                <Stack
                  direction={"row"}
                  key={category?.id}
                  justifyContent={"space-between"}
                  gap={2}>
                  <Stack direction={"row"} width={"100%"} gap={1}>
                    <Box
                      sx={{
                        width: "20px",
                        height: "20px",
                        borderRadius: "5px",
                        border: "1px solid #d9d9d9",
                        boxSizing: "border-box",
                        backgroundColor: category?.colour || "#d9d9d9",
                      }}></Box>
                    <Typography variant="body2" fontWeight={"bold"}>
                      {category?.name}
                    </Typography>
                  </Stack>
                  <Stack direction={"row"} gap={1}>
                    <Typography
                      variant="body2"
                      fontWeight={"bold"}
                      sx={{
                        whiteSpace: "nowrap",
                      }}>
                      {percentageToTime(percentage)}
                    </Typography>
                    <Typography variant="body2" fontWeight={"bold"}>
                      {percentage.toFixed(1)}%
                    </Typography>
                  </Stack>
                </Stack>
              ))}
              {percentUnknown > 0 ? (
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  gap={2}
                  padding={1}>
                  <Stack direction={"row"} width={"100%"} gap={1}>
                    <Box
                      sx={{
                        width: "20px",
                        height: "20px",
                        borderRadius: "5px",
                        border: "1px solid #d9d9d9",
                        boxSizing: "border-box",
                        backgroundColor: "transparent",
                      }}></Box>
                    <Typography variant="body2" fontWeight={"bold"}>
                      Unknown
                    </Typography>
                  </Stack>
                  <Stack direction={"row"} gap={1}>
                    <Typography
                      variant="body2"
                      fontWeight={"bold"}
                      sx={{
                        whiteSpace: "nowrap",
                      }}>
                      {percentageToTime(percentUnknown)}
                    </Typography>
                    <Typography variant="body2" fontWeight={"bold"}>
                      {percentUnknown.toFixed(1)}%
                    </Typography>
                  </Stack>
                </Stack>
              ) : null}
            </Stack>
          }
          style={{ backgroundColor: "white" }}>
          <StyledStatsIcon />
        </HtmlTooltip>
      </IconsContainer>

      <StackedBar>
        {renderRow(firstRow, firstRowPercentage)}
        {renderRow(secondRow, secondRowPercentage)}
        <BarRow height={percentUnknown} direction={{ xs: "column", sm: "row" }}>
          <BarSegment color="#d9d9d9" width={100} />
        </BarRow>
      </StackedBar>
    </>
  );
};

export default StatsCalendarCell;
