import styled from "@emotion/styled";
import { format } from "date-fns";
import React from "react";
import { Timeslot } from "../../../../database/db";
import { ActivityJoinCategory } from "../../../../database/helpers";
import { Box, Stack, SxProps, Theme, Typography, Tooltip } from "@mui/material";
import { QuestionMark } from "@mui/icons-material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import NotesIcon from "@mui/icons-material/NoteAlt";
import { ICONS } from "../../../../const/icons";
import { heightFromMinutes, timeDisplay } from "../../../../utils/time";
import { OUTLINE_COLORS } from "../../../../const/colours";

const TimeslotContainer = styled(Box)<{
  isselected: string;
  isediting: string;
  isconflicting: string;
  isdraggingthis: string;
}>`
  padding: 4px 12px;
  margin: 0;

  border: ${(props) =>
    props.isconflicting === "true"
      ? `2px solid ${OUTLINE_COLORS.conflicting}`
      : props.isediting === "true"
      ? `2px solid ${OUTLINE_COLORS.editing}`
      : props.isselected === "true"
      ? `1px solid ${OUTLINE_COLORS.selected}`
      : `1px solid #e0e0e0`};

  opacity: ${(props) => (props.isdraggingthis === "true" ? 0.0 : 1)};
  cursor: pointer;
  transition: height 0.2s ease, top 0.2s ease, background-color 0.2s ease;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
`;

export const TimeDisplay = styled(Typography)`
  font-size: 14px;
  margin-top: -16px;
  background-color: #ffffffcc;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  padding: 2px 6px;
  border-radius: 4px;
  font-weight: bold;
`;

interface TimeslotComponentProps {
  timeslot: Timeslot;
  activityCategoryMap: Record<string, ActivityJoinCategory>;
  isSelected: boolean;
  isConflicting: boolean;
  isEditing: boolean;
  isDraggingThis: boolean;
  isDraggingOther: boolean;
  isReduced?: boolean;
  onClick: () => void;
  onDoubleClick: () => void;
}

export const TimeslotComponent: React.FC<TimeslotComponentProps> = ({
  timeslot,
  activityCategoryMap,
  isSelected,
  isConflicting,
  isEditing,
  isDraggingThis,
  isDraggingOther,
  isReduced = false,
  onClick,
  onDoubleClick,
}) => {
  const activity = activityCategoryMap[timeslot.activityId];
  const Icon = activity ? ICONS[activity.icon] : QuestionMark;
  const colour = activity ? activity.colour : "#999999";
  const name = activity ? activity.name : "Unknown Activity";
  const startTime = format(new Date(timeslot.startTimestampMills), "HH:mm");
  const minutes =
    (timeslot.endTimestampMills - timeslot.startTimestampMills) / 1000 / 60;
  const timezone = timeslot.timezone;
  const verticalHeight = heightFromMinutes(minutes);
  const hasNotes = !!timeslot.notes && timeslot.notes.trim() !== "";

  let sx: SxProps<Theme> = {
    backgroundColor: isSelected ? `${colour}CC` : `${colour}33`,
    height: verticalHeight + "px",
  };
  if (!isDraggingOther) {
    sx = {
      ...sx,
      "&:hover": {
        backgroundColor: `${colour}55`,
      },
    };
  }

  // Render reduced version when requested
  if (isReduced) {
    return (
      <TimeslotContainer
        isselected={isSelected.toString()}
        isediting={isEditing.toString()}
        isconflicting={isConflicting.toString()}
        isdraggingthis={isDraggingThis.toString()}
        onClick={onClick}
        onDoubleClick={onDoubleClick}
        sx={sx}>
        <Stack direction="row" alignItems="flex-start" spacing={1}>
          <Stack direction="column" alignItems="center">
            <TimeDisplay>{startTime}</TimeDisplay>
          </Stack>

          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            sx={{ flexGrow: 1 }}>
            <Icon />
            <Typography
              variant="subtitle1"
              fontWeight={500}
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}>
              {name}
            </Typography>
          </Stack>
        </Stack>
      </TimeslotContainer>
    );
  }

  // Render full version
  return (
    <TimeslotContainer
      isselected={isSelected.toString()}
      isediting={isEditing.toString()}
      isconflicting={isConflicting.toString()}
      isdraggingthis={isDraggingThis.toString()}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      sx={sx}>
      <Stack direction="row" alignItems="flex-start" spacing={2}>
        <Stack direction="column" alignItems="center">
          <TimeDisplay>{startTime}</TimeDisplay>
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          sx={{ flexGrow: 1 }}>
          <Stack
            sx={{ flexGrow: 1 }}
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            height="100%"
            gap={2}>
            <Icon />
            <Typography variant="subtitle1" fontWeight={500}>
              {name}
            </Typography>
          </Stack>
          {timeslot.placeId && <LocationOnIcon />}
          {hasNotes && (
            <Tooltip title={timeslot.notes} arrow placement="top">
              <NotesIcon fontSize="small" />
            </Tooltip>
          )}
          <Typography sx={{ fontSize: "12px", color: "grey" }}>
            {timezone}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ width: "70px", textAlign: "right" }}>
            {timeDisplay(minutes)}
          </Typography>
        </Stack>
      </Stack>
    </TimeslotContainer>
  );
};
