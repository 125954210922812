import { ThemeProvider } from "@mui/material/styles";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import App from "./App.tsx";
import { ModalProvider } from "./hooks/ModalProvider";
import { SettingsProvider } from "./hooks/SettingsProvider.tsx";
import { SnackbarProvider } from "./hooks/SnackbarProvider.tsx";
import { UndoRedoProvider } from "./hooks/UndoRedoProvider.tsx";
import { About } from "./page/About.tsx";
import { Activity } from "./page/activity/Activity.tsx";
import { Analytics } from "./page/analytics/Analytics.tsx";
import { Data } from "./page/data/Data.tsx";
import { Home } from "./page/home/Home.tsx";
import { ImportExport } from "./page/importexport/ImportExport.tsx";
import { Instructions } from "./page/Instructions.tsx";
import { Journal } from "./page/journal/Journal";
import { Settings } from "./page/Settings.tsx";
import { CloudSettings } from "./page/CloudSettings.tsx";
import { DataCleanup } from "./page/DataCleanup.tsx";
import { TimezoneAnalysis } from "./page/TimezoneAnalysis.tsx";
import DataIntegrityCheck from "./page/settings/DataIntegrityCheck.tsx";
import theme from "./theme/theme.ts";
import { JournalSettings } from "./page/journal/components/JournalSettings.tsx";

const router = createBrowserRouter([
  {
    element: <App />,
    children: [
      {
        path: "/",
        element: <Navigate to="/home/today/timeline" replace />,
      },
      {
        path: "/home/today",
        element: <Navigate to="/home/today/timeline" replace />,
      },
      {
        path: "/home/today/timeline",
        element: <Home />,
      },
      {
        path: "/home/today/timeslot",
        element: <Home />,
      },
      {
        path: "/home/:date/timeline",
        element: <Home />,
      },
      {
        path: "/home/:date/timeslot",
        element: <Home />,
      },
      {
        path: "/timeline",
        element: <Navigate to="/home/today/timeline" replace />,
      },
      {
        path: "/about",
        element: <About />,
      },
      {
        path: "/settings",
        element: <Settings />,
      },
      {
        path: "/settings/cloud",
        element: <CloudSettings />,
      },
      {
        path: "/datacleanup",
        element: <DataCleanup />,
      },
      {
        path: "/timezoneanalysis",
        element: <TimezoneAnalysis />,
      },
      {
        path: "/dataintegrity",
        element: <DataIntegrityCheck />,
      },
      {
        path: "/importexport",
        element: <ImportExport />,
      },
      {
        path: "/analytics/:page?",
        element: <Analytics />,
      },
      {
        path: "/data",
        element: <Data />,
      },
      {
        path: "/instructions",
        element: <Instructions />,
      },
      {
        path: "/settings/journal",
        element: <JournalSettings />,
      },
      {
        path: "/journal/table/:date",
        element: <Journal />,
      },
      {
        path: "/journal/summary/:date",
        element: <Journal />,
      },
      {
        path: "/journal/:date",
        element: <Journal />,
      },
      {
        path: "/journal",
        element: <Journal />,
      },
      {
        path: "/activity/:activityId",
        element: <Activity />,
      },
      {
        path: "*",
        element: <div>Not Found</div>,
      },
    ],
  },
]);

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <ThemeProvider theme={theme}>
      <SettingsProvider>
        <UndoRedoProvider>
          <SnackbarProvider>
            <ModalProvider>
              <RouterProvider router={router} />
            </ModalProvider>
          </SnackbarProvider>
        </UndoRedoProvider>
      </SettingsProvider>
    </ThemeProvider>
  </StrictMode>
);
