import styled from "@emotion/styled";
import {
  ChevronLeft,
  ChevronRight,
  Today,
  ViewDay,
  ViewList,
  Summarize,
} from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Paper,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { addDays, format, isSameDay, parse, parseISO, subDays } from "date-fns";
import { useLiveQuery } from "dexie-react-hooks";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import timezone_icon from "../../assets/icons/timezone.svg";
import { KeyIcon } from "../../components/KeyIcon";
import { TimezoneSelectorModal } from "../../components/TimezoneSelectorModal";
import { db } from "../../database/db";
import { useCloudSyncedDB } from "../../hooks/useCloudSyncedDb";
import { useSettings } from "../../hooks/useSettings";
import { useSnackbar } from "../../hooks/useSnackbar";
import {
  dateStringToDayBoundsMills,
  detectTimezone,
  getComputerTimezone,
} from "../../utils/time";
import { DatePickerCalendar } from "../shared/DatePickerCalendar";
import { JournalDayView } from "./components/JournalDayView";
import { JournalTableView } from "./components/JournalTableView";
import { JournalSummaryView } from "./components/JournalSummaryView";

const ControlPanel = styled(Paper)`
  position: sticky;
  top: 0;
  z-index: 1100;
  padding: 10px;
  background-color: white;
`;

export const Journal = () => {
  const navigate = useNavigate();
  const params = useParams<{ date?: string; view?: string }>();
  const { showSnackbar } = useSnackbar();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const cloudSyncedDB = useCloudSyncedDB();

  // State for date and view mode
  const [date, setDate] = useState(() => {
    // First check if there's a date in the URL params
    if (params.date && /^\d{4}-\d{2}-\d{2}$/.test(params.date)) {
      return params.date;
    }

    // If not in URL, check sessionStorage
    const storedDate = sessionStorage.getItem("selectedDate");
    if (storedDate && /^\d{4}-\d{2}-\d{2}$/.test(storedDate)) {
      return storedDate;
    }

    // Default to today
    return format(new Date(), "yyyy-MM-dd");
  });

  const { dateFormat } = useSettings();

  // Format date with day of week
  const formattedDate = useMemo(() => {
    const dateObj = parse(date, "yyyy-MM-dd", new Date());
    return format(dateObj, dateFormat);
  }, [date, dateFormat]);

  const isToday = useMemo(() => {
    return isSameDay(new Date(), parse(date, "yyyy-MM-dd", new Date()));
  }, [date]);

  const [viewMode, setViewMode] = useState<"day" | "table" | "summary">(() => {
    return params.view === "table"
      ? "table"
      : params.view === "summary"
      ? "summary"
      : "day";
  });

  // State for timezone
  const [currentTimezone, setCurrentTimezone] = useState(getComputerTimezone());
  const [timezoneSelectorOpen, setTimezoneSelectorOpen] = useState(false);
  const computerTimezone = useMemo(() => getComputerTimezone(), []);
  const [detectedTimezone, setDetectedTimezone] = useState(
    getComputerTimezone()
  );

  // State for calendar
  const [calendarOpen, setCalendarOpen] = useState(false);

  // Get day bounds for the selected date
  const dayBounds = useMemo(() => {
    return dateStringToDayBoundsMills(date, currentTimezone, computerTimezone);
  }, [date, currentTimezone, computerTimezone]);

  // Get timeslots for the selected date
  const timeslots = useLiveQuery(async () => {
    if (!dayBounds) return [];
    const [startMs, endMs] = dayBounds;
    return await db.timeslots
      .where("startTimestampMills")
      .between(startMs, endMs)
      .or("endTimestampMills")
      .between(startMs, endMs)
      .toArray();
  }, [dayBounds]);

  // Get journal entries for the table view (last 30 days)
  const journalEntries = useLiveQuery(async () => {
    if (viewMode !== "table") return [];

    // Calculate date range (30 days before selected date to selected date)
    const endDate = parseISO(date);
    const startDate = subDays(endDate, 30);
    const startDateStr = format(startDate, "yyyy-MM-dd");

    return await db.journals
      .where("date")
      .between(startDateStr, date, true, true)
      .reverse()
      .toArray();
  }, [date, viewMode]);

  // Update URL when date or view mode changes
  useEffect(() => {
    if (viewMode === "day") {
      navigate(`/journal/${date}`);
    } else if (viewMode === "summary") {
      navigate(`/journal/summary/${date}`);
    } else {
      navigate(`/journal/table/${date}`);
    }
  }, [date, viewMode, navigate]);

  // Detect timezone based on timeslots
  useEffect(() => {
    if (timeslots && timeslots.length > 0) {
      const detected = detectTimezone(timeslots);
      setDetectedTimezone(detected);
    }
  }, [timeslots]);

  // Handle date change
  const handleDateChange = useCallback((value: Date | null) => {
    if (value instanceof Date) {
      const newDate = format(value, "yyyy-MM-dd");
      setDate(newDate);
      setCalendarOpen(false);
    }
  }, []);

  // Handle previous/next day navigation
  const goToPreviousDay = useCallback(() => {
    const prevDate = subDays(parseISO(date), 1);
    const newDate = format(prevDate, "yyyy-MM-dd");
    setDate(newDate);
  }, [date]);

  const goToNextDay = useCallback(() => {
    const nextDate = addDays(parseISO(date), 1);
    const newDate = format(nextDate, "yyyy-MM-dd");
    setDate(newDate);
  }, [date]);

  // Go to today
  const goToToday = useCallback(() => {
    const today = format(new Date(), "yyyy-MM-dd");
    setDate(today);
    setCalendarOpen(false);
  }, []);

  // Handle view mode toggle
  const toggleViewMode = useCallback(() => {
    setViewMode((prev) => {
      if (prev === "day") return "summary";
      if (prev === "summary") return "table";
      return "day";
    });
  }, []);

  // Handle keyboard shortcuts
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      // Check if any text input or textarea is focused
      const activeElement = document.activeElement;
      const isInputFocused =
        activeElement instanceof HTMLInputElement ||
        activeElement instanceof HTMLTextAreaElement ||
        activeElement?.getAttribute("contenteditable") === "true";

      // Don't handle shortcuts if an input is focused
      if (isInputFocused) return;

      if (event.key === "ArrowLeft") {
        goToPreviousDay();
      } else if (event.key === "ArrowRight") {
        goToNextDay();
      } else if (event.key === "t") {
        goToToday();
      } else if (event.key === "d") {
        // Change display mode
        setViewMode((prev) => {
          if (prev === "summary") return "day";
          if (prev === "day") return "table";
          return "summary";
        });
      } else if (event.key === "q") {
        setViewMode("summary");
      } else if (event.key === "w") {
        setViewMode("day");
      } else if (event.key === "e") {
        setViewMode("table");
      } else if (event.key === "Escape") {
        if (calendarOpen) setCalendarOpen(false);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [goToPreviousDay, goToNextDay, goToToday, calendarOpen, toggleViewMode]);

  return (
    <Container maxWidth="lg" sx={{ pb: 10 }}>
      <ControlPanel elevation={1}>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="space-between"
          flexWrap="wrap"
          sx={{ mb: 1 }}>
          <Stack direction="row" spacing={1} alignItems="center">
            <ToggleButtonGroup
              value={viewMode}
              exclusive
              onChange={(_event, newValue) => {
                if (newValue) {
                  setViewMode(newValue);
                }
              }}
              aria-label="view mode">
              <ToggleButton
                value="summary"
                aria-label="summary view"
                color="primary">
                <Tooltip
                  title={
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography fontSize={12}>
                        Switch to Summary View
                      </Typography>
                      <KeyIcon keyName="W" />
                    </Stack>
                  }>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Summarize />
                  </Stack>
                </Tooltip>
              </ToggleButton>
              <ToggleButton value="day" aria-label="day view" color="primary">
                <Tooltip
                  title={
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography fontSize={12}>Switch to Day View</Typography>
                      <KeyIcon keyName="Q" />
                    </Stack>
                  }>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <ViewDay />
                  </Stack>
                </Tooltip>
              </ToggleButton>
              <ToggleButton
                value="table"
                aria-label="table view"
                color="primary">
                <Tooltip
                  title={
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography fontSize={12}>
                        Switch to Table View
                      </Typography>
                      <KeyIcon keyName="E" />
                    </Stack>
                  }>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <ViewList />
                  </Stack>
                </Tooltip>
              </ToggleButton>
            </ToggleButtonGroup>
            <ButtonGroup variant="outlined">
              <Tooltip
                title={
                  <Stack direction="row" gap={1}>
                    Go to previous day
                    <KeyIcon keyName="ArrowLeft" />
                  </Stack>
                }>
                <Button
                  variant="outlined"
                  onClick={goToPreviousDay}
                  sx={{ padding: 0, width: "10px" }}>
                  <ChevronLeft />
                </Button>
              </Tooltip>
              <Tooltip
                title={
                  <Stack direction="row" gap={1}>
                    Go to today
                    <KeyIcon keyName="T" />
                  </Stack>
                }>
                <Button onClick={goToToday} disabled={isToday}>
                  <Today />
                </Button>
              </Tooltip>
              <Button onClick={() => setCalendarOpen(!calendarOpen)}>
                {formattedDate}
              </Button>
              <Tooltip
                title={
                  <Stack direction="row" gap={1}>
                    Go to next day
                    <KeyIcon keyName="ArrowRight" />
                  </Stack>
                }>
                <Button
                  variant="outlined"
                  onClick={goToNextDay}
                  sx={{ padding: 0, width: "10px" }}>
                  <ChevronRight />
                </Button>
              </Tooltip>
            </ButtonGroup>
          </Stack>

          <Stack direction="row" spacing={1} alignItems="center">
            <Tooltip title="Change timezone">
              <Button
                variant="outlined"
                onClick={() => setTimezoneSelectorOpen(true)}>
                <Box
                  component="img"
                  src={timezone_icon}
                  alt="Timezone"
                  sx={{ width: 24, height: 24, mr: 1 }}
                />
                {currentTimezone}
              </Button>
            </Tooltip>
          </Stack>
        </Stack>
      </ControlPanel>

      {calendarOpen && (
        <Box sx={{ mt: 2, mb: 2 }}>
          <DatePickerCalendar
            onChange={(value, _event) => {
              if (value instanceof Date) {
                handleDateChange(value);
              }
            }}
            value={parseISO(date)}
            $isSmallScreen={isSmallScreen}
          />
        </Box>
      )}

      {viewMode === "day" ? (
        <Box sx={{ mt: 2 }}>
          <JournalDayView
            date={date}
            timeslots={timeslots || []}
            currentTimezone={currentTimezone}
            dayBounds={dayBounds}
          />
        </Box>
      ) : viewMode === "summary" ? (
        <JournalSummaryView
          date={date}
          timeslots={timeslots || []}
          currentTimezone={currentTimezone}
          handleEditJournal={() => {
            // Change view mode to day
            setViewMode("day");
          }}
        />
      ) : (
        <JournalTableView
          entries={journalEntries || []}
          selectedDate={date}
          onDateSelect={(date) => {
            if (date) {
              setDate(format(date, "yyyy-MM-dd"));
              setViewMode("day");
            }
          }}
          onSave={async (id: string, changes: { entry: string }) => {
            if (changes.entry.trim() === "") {
              try {
                await cloudSyncedDB.deleteJournalEntry(id);
                showSnackbar("Journal entry deleted", "success");
                return;
              } catch (error) {
                console.error("Error deleting journal entry:", error);
                showSnackbar("Failed to delete journal entry", "error");
                return;
              }
            }
            try {
              await cloudSyncedDB.updateJournalEntry(id, changes);
              showSnackbar("Journal entry updated", "success");
            } catch (error) {
              console.error("Error updating journal entry:", error);
              showSnackbar("Failed to update journal entry", "error");
            }
          }}
        />
      )}

      <TimezoneSelectorModal
        open={timezoneSelectorOpen}
        onClose={() => setTimezoneSelectorOpen(false)}
        currentTimezone={currentTimezone}
        detectedTimezone={detectedTimezone}
        computerTimezone={computerTimezone}
        onTimezoneSelect={setCurrentTimezone}
      />
    </Container>
  );
};
