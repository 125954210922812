import { Cancel, Check } from "@mui/icons-material";
import { Button, Stack } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { ActivitySelector } from "../../components/ActivitySelector";
import { useActivityJoinCategory } from "../../hooks/useActivityJoinCategory";

interface ActivityOption {
  id: string;
  label: string;
  category?: string;
  colour?: string;
}

const ChangeTimeslot = ({
  activityId,
  changedTimeslot,
  cancelled,
}: {
  activityId: string;
  changedTimeslot?: (activityId: string) => void;
  cancelled?: () => void;
}) => {
  const { activityCategoryArray } = useActivityJoinCategory();
  const [isLoading, setIsLoading] = useState(true);
  const inputRef = useRef<HTMLInputElement>(null);

  const activityOptions: ActivityOption[] = useMemo(() => {
    if (!activityCategoryArray) return [];
    return activityCategoryArray.map((a) => ({
      id: a.id,
      label: a.name,
      category: a.category,
      colour: a.colour,
    }));
  }, [activityCategoryArray]);

  const [selectedActivity, setSelectedActivity] =
    useState<ActivityOption | null>(null);

  useEffect(() => {
    if (activityOptions.length > 0) {
      setIsLoading(false);
      const activity = activityOptions.find((a) => a.id === activityId) || null;
      setSelectedActivity(activity);
    }
  }, [activityOptions, activityId]);

  // Focus and select input text when component mounts
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, []);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Delete" || event.key === "Backspace") {
        event.preventDefault();
        event.stopPropagation();
      }
      if (event.key === "Enter") {
        if (changedTimeslot && selectedActivity?.id) {
          changedTimeslot(selectedActivity.id);
        }
      }
      if (event.key === "Escape") {
        if (cancelled) cancelled();
      }
      event.stopPropagation();
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [cancelled, changedTimeslot, selectedActivity]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.addEventListener("keydown", (e) => {
        e.stopPropagation();
      });
    }
  }, []);

  if (selectedActivity === null || isLoading) return null;

  return (
    <Stack direction={"row"} alignItems="center" justifyContent="center">
      <ActivitySelector
        preselectedActivityId={selectedActivity?.id}
        onChange={(activityId) => {
          const newActivity = activityOptions.find((a) => a.id === activityId);
          if (newActivity) {
            setSelectedActivity(newActivity);
          }
        }}
      />

      <Button
        sx={{ minWidth: 0 }}
        onClick={() => {
          if (changedTimeslot && selectedActivity) {
            changedTimeslot(selectedActivity.id);
          }
        }}>
        <Check />
      </Button>
      <Button
        sx={{ minWidth: 0 }}
        onClick={() => {
          if (cancelled) cancelled();
        }}
        color="error">
        <Cancel />
      </Button>
    </Stack>
  );
};

export default ChangeTimeslot;
