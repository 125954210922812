/**
 * Creates a deterministic hash of an object by sorting keys and stringifying
 * @param data The data to hash
 * @returns A SHA-256 hash of the sorted JSON string
 */
export async function createContentHash(
  data: Record<string, unknown> | string | null
): Promise<string> {
  if (!data) return "";

  try {
    // Parse the JSON if it's a string
    const obj = typeof data === "string" ? JSON.parse(data) : data;

    // Function to sort an object's keys recursively
    const sortObject = (obj: unknown): unknown => {
      if (obj === null || typeof obj !== "object") {
        return obj;
      }

      if (Array.isArray(obj)) {
        return obj.map(sortObject);
      }

      return Object.keys(obj as Record<string, unknown>)
        .sort()
        .reduce((result: Record<string, unknown>, key) => {
          result[key] = sortObject((obj as Record<string, unknown>)[key]);
          return result;
        }, {});
    };

    // Sort the object and stringify it
    const sortedObj = sortObject(obj);
    const sortedJson = JSON.stringify(sortedObj);

    // Use the browser's Web Crypto API
    const msgUint8 = new TextEncoder().encode(sortedJson);
    const hashBuffer = await crypto.subtle.digest("SHA-256", msgUint8);

    // Convert the hash to a hex string
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray
      .map((b) => b.toString(16).padStart(2, "0"))
      .join("");

    return hashHex;
  } catch (error) {
    console.error("Error creating content hash:", error);
    return "";
  }
}
