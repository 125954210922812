import { Box, TextField, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { Timeslot } from "../../../database/db";
import { useCloudSyncedDB } from "../../../hooks/useCloudSyncedDb";

const NotesContainer = styled(Box)<{ isEditing: boolean }>`
  position: ${(props) => (props.top !== undefined ? "absolute" : "relative")};
  top: ${(props) => (props.top !== undefined ? `${props.top}px` : undefined)};
  height: ${(props) => `${props.height}px`};
  width: 100%;
  padding: 4px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  z-index: ${(props) => (props.isEditing ? 1000 : 100)};
  outline: 1px solid #f0f0f0;
  background-color: #fff;
  &:hover {
    background-color: #fcfcfc;
  }
`;

const NotesText = styled(Typography)<{ hasNotes: boolean }>(({ hasNotes }) => ({
  width: "100%",
  height: "100%",
  textAlign: "left",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "pre-wrap",
  fontSize: "0.75rem",
  color: hasNotes ? "text.primary" : "#d0d0d0",
  fontStyle: hasNotes ? "normal" : "italic",
  display: "-webkit-box",
  WebkitLineClamp: 4,
  WebkitBoxOrient: "vertical",
}));

const NotesTextField = styled(TextField)`
  height: 100%;
  padding: 0;
  min-height: 100px;
  position: relative;
  z-index: 1000;
  font-size: 12px;

  & .MuiInputBase-root {
    height: 100%;
    display: flex;
    align-items: flex-start;
    padding: 4px;
    font-size: 0.75rem;
  }

  & .MuiInputBase-input {
    height: 100% !important;
    overflow: auto;
    padding: 2px !important;
    font-size: 0.75rem !important;
  }

  & .MuiOutlinedInput-notchedOutline {
    border-width: 1px;
  }
`;

const PortalContainer = styled(Box)`
  position: fixed;
  z-index: 9999;
  background-color: white;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 4px;
`;

export const TimeslotNotes = ({
  timeslot,
  height,
  top,
}: {
  timeslot: Timeslot;
  height: number;
  top?: number;
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [notes, setNotes] = useState(timeslot.notes || "");
  const { updateTimeslot } = useCloudSyncedDB();
  const textFieldRef = useRef<HTMLTextAreaElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [portalPosition, setPortalPosition] = useState({
    top: 0,
    left: 0,
    width: 0,
    height: 0,
  });

  useEffect(() => {
    setNotes(timeslot.notes || "");
  }, [timeslot.notes]);

  useEffect(() => {
    if (isEditing && containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      setPortalPosition({
        top: rect.top,
        left: rect.left,
        width: rect.width,
        height: Math.max(rect.height, 150), // Ensure minimum height for editing
      });
    }
  }, [isEditing]);

  const handleSave = useCallback(() => {
    if (notes !== timeslot.notes) {
      updateTimeslot(timeslot.id, {
        ...timeslot,
        notes,
      });
    }
    setIsEditing(false);
  }, [notes, timeslot, updateTimeslot]);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      // Prevent keyboard events from bubbling up when editing
      e.stopPropagation();

      if (e.key === "Escape") {
        setNotes(timeslot.notes || "");
        setIsEditing(false);
      } else if (e.key === "Enter" && (e.ctrlKey || e.metaKey)) {
        handleSave();
      }
    },
    [timeslot.notes, handleSave]
  );

  const renderPortalContent = () => {
    return ReactDOM.createPortal(
      <PortalContainer
        style={{
          top: portalPosition.top,
          left: portalPosition.left,
          width: portalPosition.width,
          height: portalPosition.height,
        }}>
        <NotesTextField
          inputRef={textFieldRef}
          multiline
          fullWidth
          variant="outlined"
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          onBlur={handleSave}
          onKeyDown={handleKeyDown}
          onClick={(e) => e.stopPropagation()}
          autoFocus
        />
      </PortalContainer>,
      document.body
    );
  };

  return (
    <NotesContainer
      ref={containerRef}
      height={height}
      top={top}
      isEditing={isEditing}
      onClick={() => {
        if (!isEditing) {
          setIsEditing(true);
          // Focus the text field after a short delay to ensure it's rendered
          setTimeout(() => {
            textFieldRef.current?.focus();
          }, 10);
        }
      }}>
      {isEditing ? (
        renderPortalContent()
      ) : (
        <NotesText
          variant="body2"
          hasNotes={!!notes}
          onClick={() => setIsEditing(true)}>
          {notes || "Add notes..."}
        </NotesText>
      )}
    </NotesContainer>
  );
};
