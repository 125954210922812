import {
  Alert,
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import Papa from "papaparse";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { db, JournalFieldDefinition } from "../../../database/db";
import { useCloudSyncedDB } from "../../../hooks/useCloudSyncedDb";
import { useSnackbar } from "../../../hooks/useSnackbar";
import { parse, isValid } from "date-fns";
import { useNavigate } from "react-router-dom";

type ConflictResolution = "override" | "append" | "skip";

interface JournalRow {
  date: string;
  journal: string;
  [key: string]: string; // Allow for dynamic custom fields
}

interface ValidationStats {
  missingDate: number;
  missingJournal: number;
  invalidDateFormat: number;
  incorrectHeaders: boolean;
  invalidCustomFields: Record<string, number>; // Track invalid values for each custom field
}

const JournalImport: React.FC = () => {
  const [csvFile, setCsvFile] = useState<File | null>(null);
  const [isImporting, setIsImporting] = useState(false);
  const [isAnalysing, setIsAnalysing] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [validRows, setValidRows] = useState(0);
  const [conflictRows, setConflictRows] = useState(0);
  const [importProgress, setImportProgress] = useState(0);
  const [conflictResolution, setConflictResolution] =
    useState<ConflictResolution>("skip");
  const [analysisComplete, setAnalysisComplete] = useState(false);
  const [parsedData, setParsedData] = useState<JournalRow[]>([]);
  const [validationStats, setValidationStats] = useState<ValidationStats>({
    missingDate: 0,
    missingJournal: 0,
    invalidDateFormat: 0,
    incorrectHeaders: false,
    invalidCustomFields: {},
  });
  const [fieldDefinitions, setFieldDefinitions] = useState<
    JournalFieldDefinition[]
  >([]);

  const cloudDb = useCloudSyncedDB();
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  // Fetch field definitions when component mounts
  useEffect(() => {
    const fetchFieldDefinitions = async () => {
      try {
        const fields = await db.journalFieldDefinitions
          .where("archivedAt")
          .equals(0)
          .toArray();
        setFieldDefinitions(fields);
      } catch (error) {
        console.error("Error fetching field definitions:", error);
        showSnackbar("Error loading custom fields", "error");
      }
    };

    fetchFieldDefinitions();
  }, [showSnackbar]);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setCsvFile(acceptedFiles[0]);
    setAnalysisComplete(false);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { "text/csv": [".csv"] },
    multiple: false,
  });

  // Parse CSV file
  const parseCSV = (
    file: File
  ): Promise<{ data: JournalRow[]; meta: Papa.ParseMeta }> => {
    return new Promise((resolve, reject) => {
      Papa.parse(file, {
        complete: (results) => {
          resolve({
            data: results.data as JournalRow[],
            meta: results.meta,
          });
        },
        header: true,
        skipEmptyLines: true, // Skip empty lines to avoid counting them as errors
        error: (error) => {
          reject(error);
        },
      });
    });
  };

  // Validate custom field value based on field type
  const validateCustomFieldValue = (
    value: string,
    fieldType: string
  ): { isValid: boolean; parsedValue: string | number | boolean | null } => {
    // Empty values are always valid (treated as null)
    if (value === undefined || value === null || value.trim() === "") {
      return { isValid: true, parsedValue: null };
    }

    if (fieldType === "checkbox") {
      // For checkbox, only "true" or "false" are valid
      const lowerValue = value.toLowerCase().trim();
      if (lowerValue === "true" || lowerValue === "false") {
        return { isValid: true, parsedValue: lowerValue === "true" };
      }
      return { isValid: false, parsedValue: null };
    } else if (fieldType === "number") {
      // For number, try to parse as float
      const parsedNum = parseFloat(value);
      if (!isNaN(parsedNum) && isFinite(parsedNum)) {
        return { isValid: true, parsedValue: parsedNum };
      }
      return { isValid: false, parsedValue: null };
    }

    // For text fields, any value is valid
    return { isValid: true, parsedValue: value };
  };

  // Validate a single row
  const validateRow = (row: JournalRow): boolean => {
    // Skip empty rows or rows that are just empty objects
    if (!row || Object.keys(row).length === 0) return false;

    // Check if date and journal fields exist and are not empty
    if (!row.date) return false;
    if (!row.journal) return false;

    // Check if date is in YYYY-MM-DD format
    const dateObj = parse(row.date, "yyyy-MM-dd", new Date());
    if (!isValid(dateObj)) return false;

    // Validate custom fields
    for (const field of fieldDefinitions) {
      const value = row[field.identifier];
      if (value !== undefined && value !== null && value.trim() !== "") {
        const { isValid } = validateCustomFieldValue(value, field.type);
        if (!isValid) return false;
      }
    }

    return true;
  };

  // Analyse the CSV file
  const analyseFile = async () => {
    if (!csvFile) return;

    try {
      setIsAnalysing(true);
      const { data, meta } = await parseCSV(csvFile);

      // Filter out any empty rows that might have been parsed
      const filteredData = data.filter(
        (row) => row && Object.keys(row).length > 0
      );
      setParsedData(filteredData);

      // Check if headers are correct
      const requiredHeaders = ["date", "journal"];
      const headers = meta.fields || [];

      // Debug log for header detection
      console.log("CSV Headers detected:", headers);
      console.log("Required headers:", requiredHeaders);

      const hasRequiredHeaders = requiredHeaders.every((header) =>
        headers
          .map((h) => h.toLowerCase().trim())
          .includes(header.toLowerCase().trim())
      );

      console.log("Required headers present:", hasRequiredHeaders);

      // Count total rows (excluding empty rows)
      const total = filteredData.length;
      setTotalRows(total);

      // Track validation issues
      const stats: ValidationStats = {
        missingDate: 0,
        missingJournal: 0,
        invalidDateFormat: 0,
        incorrectHeaders: !hasRequiredHeaders,
        invalidCustomFields: {},
      };

      // Count valid rows and track validation issues
      let valid = 0;

      // Only validate rows if headers are correct
      if (hasRequiredHeaders) {
        for (const row of filteredData) {
          // Skip empty rows
          if (!row || Object.keys(row).length === 0) continue;

          let rowValid = true;

          if (!row.date) {
            stats.missingDate++;
            rowValid = false;
          } else {
            const dateObj = parse(row.date, "yyyy-MM-dd", new Date());
            if (!isValid(dateObj)) {
              stats.invalidDateFormat++;
              rowValid = false;
            }
          }

          if (!row.journal) {
            stats.missingJournal++;
            rowValid = false;
          }

          // Validate custom fields
          for (const field of fieldDefinitions) {
            const value = row[field.identifier];
            if (value !== undefined && value !== null && value.trim() !== "") {
              const { isValid } = validateCustomFieldValue(value, field.type);
              if (!isValid) {
                stats.invalidCustomFields[field.identifier] =
                  (stats.invalidCustomFields[field.identifier] || 0) + 1;
                rowValid = false;
              }
            }
          }

          if (rowValid) {
            valid++;
          }
        }
      }

      setValidRows(valid);
      setValidationStats(stats);

      // Check for conflicts (existing journal entries for the same dates)
      const validDates = hasRequiredHeaders
        ? filteredData.filter(validateRow).map((row) => row.date)
        : [];

      const existingEntries =
        validDates.length > 0
          ? await db.journals.where("date").anyOf(validDates).count()
          : 0;

      setConflictRows(existingEntries);
      setAnalysisComplete(true);
    } catch (error) {
      console.error("Error analysing CSV:", error);
      showSnackbar("Error analysing CSV file", "error");
    } finally {
      setIsAnalysing(false);
    }
  };

  // Import the data
  const importData = async () => {
    if (!csvFile || !analysisComplete) return;

    // Don't proceed if headers are incorrect
    if (validationStats.incorrectHeaders) {
      showSnackbar("Cannot import with incorrect headers", "error");
      return;
    }

    try {
      setIsImporting(true);
      setImportProgress(0);

      // Filter valid rows
      const validData = parsedData.filter(validateRow);

      if (validData.length === 0) {
        showSnackbar("No valid data to import", "error");
        setIsImporting(false);
        return;
      }

      // Process each row
      let processed = 0;

      for (const row of validData) {
        // Check if there's an existing entry for this date
        const existingEntry = await db.journals
          .where("date")
          .equals(row.date)
          .first();

        // Prepare userData object with custom fields
        const userData: Record<string, string | number | boolean | null> = {};
        for (const field of fieldDefinitions) {
          const value = row[field.identifier];
          if (value !== undefined && value !== null && value.trim() !== "") {
            const { parsedValue } = validateCustomFieldValue(value, field.type);
            userData[field.identifier] = parsedValue;
          }
        }

        if (existingEntry) {
          // Handle conflict based on selected resolution
          if (conflictResolution === "skip") {
            // Skip this entry
          } else if (conflictResolution === "override") {
            // Override existing entry
            let existingUserData = {};
            if (existingEntry.userData) {
              try {
                existingUserData = JSON.parse(existingEntry.userData);
              } catch (error) {
                console.error("Error parsing existing userData:", error);
              }
            }

            await cloudDb.updateJournalEntry(existingEntry.id, {
              entry: row.journal,
              userData: JSON.stringify({
                ...existingUserData,
                ...userData,
              }),
            });
          } else if (conflictResolution === "append") {
            // Append to existing entry
            let existingUserData = {};
            if (existingEntry.userData) {
              try {
                existingUserData = JSON.parse(existingEntry.userData);
              } catch (error) {
                console.error("Error parsing existing userData:", error);
              }
            }

            await cloudDb.updateJournalEntry(existingEntry.id, {
              entry: existingEntry.entry + "\n\n" + row.journal,
              userData: JSON.stringify({
                ...existingUserData,
                ...userData,
              }),
            });
          }
        } else {
          // No conflict, add new entry
          const dateObj = parse(row.date, "yyyy-MM-dd", new Date());
          const startMs = dateObj.getTime();
          const endMs = startMs + 86400000; // Add one day in milliseconds

          await cloudDb.addJournalEntry({
            date: row.date,
            entry: row.journal,
            startMs,
            endMs,
            userData:
              Object.keys(userData).length > 0
                ? JSON.stringify(userData)
                : undefined,
          });
        }

        processed++;
        setImportProgress(Math.round((processed / validData.length) * 100));

        // Allow UI to update
        await new Promise((resolve) => setTimeout(resolve, 0));
      }

      showSnackbar("Journal import completed successfully", "success");
      setCsvFile(null);
      setAnalysisComplete(false);
    } catch (error) {
      console.error("Error importing journals:", error);
      showSnackbar("Error importing journals", "error");
    } finally {
      setIsImporting(false);
    }
  };

  // Generate sample data with custom fields
  const generateSampleData = useCallback((): JournalRow[] => {
    // Create sample data with custom fields
    const sampleData: JournalRow[] = [
      {
        date: "2023-01-01",
        journal:
          "This is a sample journal entry for January 1st. It can include commas, periods, and other punctuation.",
      },
      {
        date: "2023-01-02",
        journal: "Another sample entry for January 2nd with some text.",
      },
      {
        date: "2023-01-03",
        journal: "Third sample entry showing how multiple entries work.",
      },
      {
        date: "2023-01-04",
        journal:
          "Fourth entry with all custom fields left blank to show optional nature.",
      },
    ];

    // Add custom fields to sample data
    fieldDefinitions.forEach((field) => {
      sampleData.forEach((row, index) => {
        if (index === 3) {
          // Fourth row - leave all custom fields blank
          row[field.identifier] = "";
        } else if (field.type === "checkbox") {
          // Alternate between true, false, and empty for checkbox fields
          if (index === 0) row[field.identifier] = "true";
          else if (index === 1) row[field.identifier] = "false";
          // Leave the third row empty for this field
        } else if (field.type === "number") {
          // Use different number formats for number fields
          if (index === 0) row[field.identifier] = "42";
          else if (index === 1) row[field.identifier] = "3.14";
          // Leave the third row empty for this field
        } else {
          // Text fields
          if (index === 0)
            row[field.identifier] = `Sample text for ${field.identifier}`;
          else if (index === 1)
            row[field.identifier] = `Another example for ${field.identifier}`;
          // Leave the third row empty for this field
        }
      });
    });

    return sampleData;
  }, [fieldDefinitions]);

  // Generate and download sample CSV
  const downloadSampleCSV = () => {
    const sampleData = generateSampleData();

    // Create columns array with required fields first, then custom fields
    const columns = [
      "date",
      "journal",
      ...fieldDefinitions.map((f) => f.identifier),
    ];

    // Generate CSV
    const csv = Papa.unparse(sampleData, {
      header: true,
      columns: columns,
    });

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "journal_sample.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Generate custom field format explanation
  const getCustomFieldsExplanation = () => {
    if (fieldDefinitions.length === 0) {
      return null;
    }

    return (
      <>
        <Typography variant="body2" sx={{ mt: 2 }}>
          <strong>Custom Fields:</strong> Your CSV can also include these custom
          fields:
        </Typography>
        <Box sx={{ ml: 2 }}>
          {fieldDefinitions.map((field) => (
            <Typography key={field.id} variant="body2" component="div">
              <code>{field.identifier}</code> ({field.type}):
              {field.type === "checkbox" &&
                " Use 'true' or 'false'. Leave blank for null."}
              {field.type === "number" &&
                " Use numeric values (e.g., 42, 3.14). Leave blank for null."}
              {field.type === "text" &&
                " Any text value. Leave blank for null."}
            </Typography>
          ))}
        </Box>
      </>
    );
  };

  // Generate custom field format for the sample display
  const getCustomFieldsSample = useCallback(() => {
    if (fieldDefinitions.length === 0) {
      return null;
    }
    let sampleString = "";
    fieldDefinitions.forEach((field) => {
      sampleString += `,${field.identifier}`;
    });
    return sampleString;
  }, [fieldDefinitions]);

  const sampleData = useMemo(() => {
    const data = generateSampleData();
    let sampleString = "date,journal" + getCustomFieldsSample();
    data.forEach((row) => {
      sampleString += "\n";
      const values = Object.values(row);
      sampleString += `"${values.join('","')}"`;
    });
    return sampleString;
  }, [generateSampleData, getCustomFieldsSample]);

  return (
    <Paper elevation={2} sx={{ padding: 3, marginTop: 4 }}>
      <Typography variant="h5" gutterBottom>
        Journal Import
      </Typography>

      <Stack spacing={2}>
        <Typography variant="body1">
          Import your journal entries from a CSV file. The CSV must have the
          following format:
        </Typography>

        <pre
          style={{
            maxWidth: "100%",
            overflowX: "auto",
            backgroundColor: "#f5f5f5",
            padding: "8px",
            borderRadius: "4px",
            fontFamily: "monospace",
          }}
        >
          {sampleData}
        </pre>

        <Typography variant="body2">
          <strong>Important:</strong>
          <ul>
            <li>
              The first row must contain the headers <code>date</code> and{" "}
              <code>journal</code>
            </li>
            <li>The date must be in YYYY-MM-DD format (e.g., 2023-01-01)</li>
            <li>The journal column contains the text of your journal entry</li>
            <li>
              If your journal text contains commas, ensure it's enclosed in
              quotes
            </li>
          </ul>
        </Typography>

        {getCustomFieldsExplanation()}

        <Button
          variant="outlined"
          onClick={() => navigate("/settings/journal")}
        >
          Edit Custom Fields
        </Button>

        <Button
          variant="outlined"
          onClick={downloadSampleCSV}
          disabled={isImporting || isAnalysing}
        >
          Download Sample CSV
        </Button>

        <Box
          {...getRootProps()}
          sx={{
            border: "1px dashed grey",
            p: 2,
            my: 2,
            cursor: "pointer",
            backgroundColor: csvFile ? "#f0f7ff" : "inherit",
          }}
        >
          <input {...getInputProps()} />
          <Typography>
            {csvFile
              ? `File selected: ${csvFile.name}`
              : "Drop your journal CSV file here or click to select"}
          </Typography>
        </Box>

        {csvFile && !analysisComplete && !isAnalysing && (
          <Button
            variant="contained"
            onClick={analyseFile}
            disabled={isImporting}
          >
            Analyse File
          </Button>
        )}

        {isAnalysing && (
          <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
            <CircularProgress />
          </Box>
        )}

        {analysisComplete && (
          <>
            <Alert
              severity={validationStats.incorrectHeaders ? "error" : "info"}
            >
              <Typography variant="body2">
                Total rows: {totalRows}
                <br />
                Valid rows: {validRows}
                <br />
                Invalid rows: {totalRows - validRows}{" "}
                {totalRows - validRows > 0 &&
                  "(rows with wrong date format or missing data)"}
                <br />
                Conflicts (entries with same date): {conflictRows}
              </Typography>
            </Alert>

            {validationStats.incorrectHeaders && (
              <Alert severity="error" sx={{ mt: 1 }}>
                <Typography variant="body2">
                  <strong>Incorrect CSV headers detected.</strong> Your CSV file
                  must have the following headers as the first row:
                  <Box
                    component="code"
                    sx={{ display: "block", my: 1, p: 1, bgcolor: "#f5f5f5" }}
                  >
                    date,journal
                  </Box>
                  Please correct your headers and try again. Make sure there are
                  no spaces before or after the header names.
                </Typography>
              </Alert>
            )}

            {!validationStats.incorrectHeaders && totalRows - validRows > 0 && (
              <Alert severity="warning" sx={{ mt: 1 }}>
                <Typography variant="body2">
                  Some rows have invalid data:
                  {validationStats.missingDate > 0 && (
                    <li>Missing date: {validationStats.missingDate} rows</li>
                  )}
                  {validationStats.missingJournal > 0 && (
                    <li>
                      Missing journal text: {validationStats.missingJournal}{" "}
                      rows
                    </li>
                  )}
                  {validationStats.invalidDateFormat > 0 && (
                    <li>
                      Invalid date format: {validationStats.invalidDateFormat}{" "}
                      rows
                    </li>
                  )}
                  {Object.entries(validationStats.invalidCustomFields).map(
                    ([field, count]) => (
                      <li key={field}>
                        Invalid {field} values: {count} rows
                      </li>
                    )
                  )}
                  <br />
                  Please ensure all dates are in YYYY-MM-DD format, all required
                  fields are filled, and custom fields have valid values.
                </Typography>
              </Alert>
            )}

            {conflictRows > 0 && !validationStats.incorrectHeaders && (
              <FormControl fullWidth>
                <InputLabel id="conflict-resolution-label">
                  Conflict Resolution
                </InputLabel>
                <Select
                  labelId="conflict-resolution-label"
                  value={conflictResolution}
                  label="Conflict Resolution"
                  onChange={(e) =>
                    setConflictResolution(e.target.value as ConflictResolution)
                  }
                  disabled={isImporting}
                >
                  <MenuItem value="skip">
                    Skip (don't import conflicting entries)
                  </MenuItem>
                  <MenuItem value="override">
                    Override (replace existing entries)
                  </MenuItem>
                  <MenuItem value="append">
                    Append (add to existing entries)
                  </MenuItem>
                </Select>
              </FormControl>
            )}

            <Button
              variant="contained"
              onClick={importData}
              disabled={
                isImporting ||
                validRows === 0 ||
                validationStats.incorrectHeaders
              }
              color="primary"
            >
              {isImporting ? "Importing..." : "Import Journal Entries"}
            </Button>
          </>
        )}

        {isImporting && (
          <Box sx={{ width: "100%", mt: 2 }}>
            <LinearProgress
              variant="determinate"
              value={importProgress}
              sx={{ height: 10, borderRadius: 5 }}
            />
            <Typography variant="body2" sx={{ mt: 1, textAlign: "center" }}>
              {importProgress}% Complete
            </Typography>
          </Box>
        )}
      </Stack>
    </Paper>
  );
};

export default JournalImport;
