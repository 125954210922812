import { Edit } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { useLiveQuery } from "dexie-react-hooks";
import { useMemo } from "react";
import { db, Timeslot } from "../../../database/db";
import { DayTimeslotNotesSummary } from "./DayTimeslotNotesSummary";
import { DayTimeslotsSummary } from "./DayTimeslotsSummary";

interface JournalSummaryViewProps {
  date: string;
  timeslots: Timeslot[];
  currentTimezone: string;
  handleEditJournal: () => void;
}

export const JournalSummaryView = ({
  date,
  timeslots,
  currentTimezone,
  handleEditJournal,
}: JournalSummaryViewProps) => {
  // Get the journal entry for the day
  const journalEntry = useLiveQuery(async () => {
    return await db.journals.where("date").equals(date).first();
  }, [date]);

  // Get journal field definitions
  const fieldDefinitions = useLiveQuery(
    () =>
      db.journalFieldDefinitions.where("archivedAt").equals(0).sortBy("order"),
    []
  );

  // Parse userData from journal entry
  const customFieldValues = useMemo(() => {
    if (journalEntry?.userData) {
      try {
        return JSON.parse(journalEntry.userData);
      } catch (error) {
        console.error("Error parsing userData:", error);
        return {};
      }
    }
    return {};
  }, [journalEntry]);

  // Format identifier to title case for display
  const formatIdentifier = (identifier: string) => {
    return identifier
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  // Sort timeslots by start time
  const sortedTimeslots = useMemo(() => {
    return [...timeslots].sort(
      (a, b) => a.startTimestampMills - b.startTimestampMills
    );
  }, [timeslots]);

  return (
    <Stack spacing={2} sx={{ mt: 2 }}>
      <Paper sx={{ p: 3 }}>
        <Stack direction="row" justifyContent="space-between" marginBottom={3}>
          <Typography variant="h5" gutterBottom>
            Day Summary for {date} ({currentTimezone})
          </Typography>
          <Button startIcon={<Edit />} size="small" onClick={handleEditJournal}>
            Edit
          </Button>
        </Stack>

        {/* Journal Entry and Notes Section */}
        <Stack
          direction={{ xs: "column", md: "row" }}
          spacing={3}
          justifyContent="flex-start"
          alignItems="flex-start">
          <Stack direction="column" spacing={2} flexGrow={1}>
            <Typography variant="h6" textAlign="left">
              Journal Entry
            </Typography>

            {journalEntry && journalEntry.entry ? (
              <Typography
                variant="body1"
                sx={{ whiteSpace: "pre-wrap", textAlign: "left" }}
                component="div"
                onDoubleClick={() => {
                  handleEditJournal();
                }}>
                {journalEntry.entry}
              </Typography>
            ) : (
              <Typography
                variant="body2"
                color="text.secondary"
                fontStyle="italic">
                No journal entry for this day.
              </Typography>
            )}
          </Stack>

          {/* Custom Fields Section */}
          {fieldDefinitions && fieldDefinitions.length > 0 && (
            <Stack
              direction="column"
              spacing={2}
              flexGrow={1}
              alignItems="flex-start">
              <TableContainer
                component={Box}
                sx={{ bgcolor: "background.default" }}>
                <Table size="small">
                  <TableBody>
                    {fieldDefinitions.map((field) => (
                      <TableRow key={field.id}>
                        <TableCell
                          sx={{
                            verticalAlign: "top",
                            border: "none",
                            p: 1,
                            pr: 2,
                            width: "30%",
                            fontWeight: "bold",
                          }}>
                          {formatIdentifier(field.identifier)}
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            border: "none",
                            p: 1,
                          }}>
                          {field.type === "checkbox" ? (
                            customFieldValues[field.identifier] ? (
                              "Yes"
                            ) : (
                              "No"
                            )
                          ) : customFieldValues[field.identifier] !==
                            undefined ? (
                            <Typography variant="body2">
                              {customFieldValues[field.identifier]}
                            </Typography>
                          ) : (
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              fontStyle="italic">
                              No data
                            </Typography>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
          )}
        </Stack>
        <Divider sx={{ my: 2 }} />
        {/* Day Timeslots Summary Section */}
        <Stack
          direction={{
            xs: "column",
            md: "row",
          }}
          spacing={2}>
          <DayTimeslotsSummary
            date={date}
            currentTimezone={currentTimezone}
            sortedTimeslots={sortedTimeslots}
          />
          <DayTimeslotNotesSummary sortedTimeslots={sortedTimeslots} />
        </Stack>
      </Paper>
    </Stack>
  );
};
