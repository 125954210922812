import {
  Box,
  Button,
  Paper,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { ICON_ARRAY, ICON_SEARCH, ICONS } from "../../../const/icons";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

export const IconSelector: React.FC<{
  selectedIcon: string;
  onSelectIcon: (iconName: string) => void;
  color?: string;
}> = ({ selectedIcon, onSelectIcon, color }) => {
  const selectedColor = color || "primary";
  const [searchTerm, setSearchTerm] = useState("");

  // Filter icons based on search term
  const filteredIcons =
    searchTerm.trim() === ""
      ? ICON_ARRAY
      : ICON_ARRAY.filter((icon) => {
          // Check if the icon name contains the search term
          if (icon.name.toLowerCase().includes(searchTerm.toLowerCase())) {
            return true;
          }

          // Check if any search terms match
          const searchTerm_lower = searchTerm.toLowerCase();
          return Object.keys(ICON_SEARCH).some((term) => {
            if (term.includes(searchTerm_lower)) {
              return ICON_SEARCH[term].includes(icon.name);
            }
            return false;
          });
        });

  // Get the selected icon component
  const SelectedIconComponent = selectedIcon
    ? ICONS[selectedIcon]
    : HelpOutlineIcon;
  const displayName = selectedIcon
    ? selectedIcon.replace(/_/g, " ")
    : "No icon selected";

  return (
    <Stack direction="column" spacing={2}>
      <Stack direction="row" spacing={2} paddingX={2} alignItems="center">
        <Paper sx={{ padding: 1, borderRadius: 1, width: "50%" }}>
          <Stack direction="row" spacing={2} alignItems="center">
            <SelectedIconComponent sx={{ color: selectedColor }} />
            <Typography variant="body1" textAlign={"left"} sx={{ mt: 0.5 }}>
              {displayName}
            </Typography>
          </Stack>
        </Paper>

        <Typography
          variant="body2"
          sx={{ color: "text.secondary", whiteSpace: "nowrap" }}>
          {filteredIcons.length} / {ICON_ARRAY.length}
        </Typography>

        <TextField
          variant="standard"
          size="small"
          placeholder="Search icons..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </Stack>

      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          maxHeight: "300px",
          overflowY: "auto",
          mt: 1,
        }}>
        {filteredIcons.map((i) => {
          const IconComponent = i.icon;

          // Find matching search terms if we're searching
          let matchingTerms = "";
          if (searchTerm.trim() !== "") {
            const searchTerm_lower = searchTerm.toLowerCase();

            // Check if any search terms match
            Object.keys(ICON_SEARCH).forEach((categoryTerm) => {
              if (ICON_SEARCH[categoryTerm].includes(i.name)) {
                // Check if the search term matches any of the keywords
                const matchingKeywords = ICON_SEARCH[categoryTerm].filter(
                  (keyword) => keyword.toLowerCase().includes(searchTerm_lower)
                );

                if (matchingKeywords.length > 0) {
                  matchingTerms = matchingKeywords.join(", ");
                } else if (categoryTerm.includes(searchTerm_lower)) {
                  // If no keywords match but the category matches
                  matchingTerms = categoryTerm;
                }
              }
            });
          }

          const tooltipTitle = (
            <>
              <Typography variant="caption" display="block">
                {i.name.replace(/_/g, " ")}
              </Typography>
              {matchingTerms && (
                <Typography
                  variant="caption"
                  sx={{ fontStyle: "italic", fontSize: "10px" }}
                  display="block">
                  (matches: {matchingTerms})
                </Typography>
              )}
            </>
          );

          return (
            <Tooltip key={i.name} title={tooltipTitle}>
              <Button
                onClick={() => onSelectIcon(i.name)}
                sx={{
                  color: selectedColor,
                  borderColor: selectedColor,
                  m: 0.5,
                }}
                variant={selectedIcon === i.name ? "outlined" : "text"}
                aria-label={i.name}>
                <IconComponent />
              </Button>
            </Tooltip>
          );
        })}
      </Box>
    </Stack>
  );
};
