import { NoteAlt, QuestionMark } from "@mui/icons-material";
import { Box, Stack, styled, Tooltip, Typography } from "@mui/material";
import { format } from "date-fns";
import { ICONS } from "../../../const/icons";
import { Timeslot } from "../../../database/db";

const TimeslotPill = styled(Box)<{
  width?: number;
  hasNotes?: string;
  isSelected?: string;
  bgcolor?: string;
}>`
  display: flex;
  align-items: center;
  justify-content: ${({ width }) =>
    width && width > 36 ? "flex-start" : "center"};
  width: ${({ width }) => (width ? `${width}px` : "36px")};
  height: 36px;
  border-radius: 5px;
  background-color: ${({ bgcolor }) => bgcolor || "#ccc"};
  cursor: pointer;
  box-shadow: 1;
  position: relative;
  transition: all 0.2s ease-in-out;
  padding-left: ${({ width }) => (width && width > 36 ? "12px" : 0)};
  border: ${({ hasNotes }) =>
    hasNotes && hasNotes === "true" ? "2px dashed black" : "none"};
  opacity: ${({ isSelected }) =>
    isSelected && isSelected === "true" ? 1 : 0.8};
  ${({ isSelected }) =>
    isSelected === "true" &&
    `
    box-shadow: inset 0 0 10px rgba(255,255,255,0.5);
    outline: 2px solid rgba(25, 118, 210, 0.8);
  `}
  &:hover {
    box-shadow: 3;
    transform: scale(1.05);
    opacity: 1;
  }
`;

interface DayTimeslotsRowProps {
  rowTimeslots: Timeslot[];
  label: React.ReactNode;
  activityCategoryMap: Record<string, any>;
  onSelectTimeslot?: (timeslot: Timeslot | null) => void;
  selectedTimeslotId?: string;
}

export const DayTimeslotsRow = ({
  rowTimeslots,
  label,
  activityCategoryMap,
  onSelectTimeslot,
  selectedTimeslotId,
}: DayTimeslotsRowProps) => {
  // Function to calculate width based on duration
  const getWidthFromDuration = (startMs: number, endMs: number) => {
    const durationHours = (endMs - startMs) / (1000 * 60 * 60);

    if (durationHours <= 0.5) {
      return 28; // Circle for 0.5 hour or less
    } else if (durationHours <= 1) {
      return 36; // Circle for 1 hour or less
    } else if (durationHours <= 2) {
      return 72; // Short pill for 1-2 hours
    } else if (durationHours <= 4) {
      return 108; // Medium pill for 2-4 hours
    } else if (durationHours <= 6) {
      return 144; // Long pill for 4-6 hours
    } else {
      return 180; // Long pill for 6+ hours
    }
  };

  return (
    <Stack direction="row" alignItems="center" gap={2} sx={{ mt: 2 }}>
      {label}
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5, mt: 1 }}>
        {rowTimeslots.map((timeslot) => {
          const activityWithCategory = activityCategoryMap[timeslot.activityId];
          if (!activityWithCategory) return null;

          // Format time
          const startTime = format(
            new Date(timeslot.startTimestampMills),
            "HH:mm"
          );
          const endTime = format(new Date(timeslot.endTimestampMills), "HH:mm");
          const timeRange = `${startTime}-${endTime}`;

          // Calculate duration in hours for display
          const durationHours =
            (timeslot.endTimestampMills - timeslot.startTimestampMills) /
            (1000 * 60 * 60);
          const durationText =
            durationHours < 1
              ? `${Math.round(durationHours * 60)} min`
              : `${durationHours.toFixed(1)} h`;

          // Activity name and tooltip text
          const activityName = activityWithCategory.name || "Unknown";
          const tooltipText = (
            <Typography variant="body2">
              {activityName}{" "}
              {timeslot.notes ? (
                <NoteAlt sx={{ ml: 1, fontSize: "1rem" }} />
              ) : (
                ""
              )}
              <br />({timeRange}, {durationText})
            </Typography>
          );

          // Get the icon component
          const Icon = activityWithCategory.icon
            ? ICONS[activityWithCategory.icon]
            : QuestionMark;

          // Calculate width based on duration
          const width = getWidthFromDuration(
            timeslot.startTimestampMills,
            timeslot.endTimestampMills
          );

          // Check if this timeslot is selected
          const isSelected = selectedTimeslotId === timeslot.id;

          // Check if this timeslot has notes
          const hasNotes = !!timeslot.notes && timeslot.notes.trim() !== "";

          return (
            <Tooltip key={timeslot.id} title={tooltipText} arrow>
              <TimeslotPill
                width={width}
                onClick={() => onSelectTimeslot?.(timeslot)}
                bgcolor={activityWithCategory.colour || "#ccc"}
                isSelected={isSelected ? "true" : "false"}
                hasNotes={hasNotes ? "true" : "false"}>
                <Icon
                  sx={{
                    fontSize: "1.2rem",
                    color: "white",
                    filter: "drop-shadow(0px 0px 1px rgba(0,0,0,0.5))",
                  }}
                />

                {width > 72 && (
                  <Typography
                    variant="caption"
                    sx={{
                      color: "white",
                      ml: 1,
                      fontWeight: "bold",
                      textShadow: "0px 0px 2px rgba(0,0,0,0.5)",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: width - 50, // Leave space for the icon
                    }}>
                    {durationText}
                  </Typography>
                )}
              </TimeslotPill>
            </Tooltip>
          );
        })}
      </Box>
    </Stack>
  );
};
