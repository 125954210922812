import { useCallback } from "react";
import { useSettings } from "./useSettings";
import { roundToSnap } from "../utils/time";

/**
 * Hook that provides a function to round timestamps to the nearest snap interval from settings
 */
export const useRoundToSnap = () => {
  const { snapToMills } = useSettings();

  return useCallback(
    (mills: number) => roundToSnap(mills, snapToMills),
    [snapToMills]
  );
};
