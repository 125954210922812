import styled from "@emotion/styled";
import { DragHandle, WarningAmber } from "@mui/icons-material";
import { Button, Stack, Typography, Box } from "@mui/material";
import { format } from "date-fns";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { PRIMARY_COLOUR } from "../../../../const/colours";
import { Timeslot } from "../../../../database/db";
import { ActivityJoinCategory } from "../../../../database/helpers";
import { useCloudSyncedDB } from "../../../../hooks/useCloudSyncedDb";
import { useUndoRedo } from "../../../../hooks/useUndoRedo";
import {
  applyTimeslotChanges,
  getConflictingTimeslots,
  getTimeslotAdjustments,
  TimeslotChange,
  TimeslotLite,
} from "../../../../utils/conflict_management";
import {
  heightFromMinutes,
  hourLabelBackgroundColor,
  timeOfDay,
} from "../../../../utils/time";
import { BlankTimeslotComponent } from "./BlankTimeslotComponent";
import { DraggingTimeslotComponent } from "./DraggingTimeslotComponent";
import { TimeDisplay, TimeslotComponent } from "./TimeslotComponent";
import {
  TimeBlock,
  TimeslotModificationContext,
  updateTimeslotModification,
} from "./utils";
import { TimeslotNotes } from "../TimeslotNotes";

const ListContainer = styled(Stack)`
  padding: 16px 16px 0;
  margin-top: 30px;
  margin-bottom: 50px;
`;

const TimeblockComponent = styled.div<{ height: number; color: string }>`
  height: ${(props) => props.height}px;
  background-color: ${(props) => props.color};
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  align-items: start;
  justify-content: center;
  transition: height 0.2s ease-out;

  div {
    font-size: 12px;
    color: #666;
    font-weight: bold;
    margin-top: -10px;
    padding: 0 4px;
    opacity: 0.6;
    filter: drop-shadow(1px 1px 1px #fff) drop-shadow(-1px -1px 1px #fff);
    transition: opacity 0.1s linear;
  }

  &:hover {
    div {
      opacity: 1;
    }
  }
`;

const ScaleButton = styled(Button)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  height: 10px;
  width: 200px;
  padding: 0;
  min-width: unset;
  cursor: ns-resize;
  background-color: #ffffffaa;
  opacity: 0.5;
  transition: opacity 0.1s linear;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  border: 1px solid ${PRIMARY_COLOUR}00;

  display: flex;
  align-items: center;
  justify-content: center;

  .handleIcon {
    font-size: 15px;
    color: ${PRIMARY_COLOUR};
  }

  &:hover {
    border: 1px solid ${PRIMARY_COLOUR}22;
    opacity: 1;
  }
`;

interface TimeslotListViewProps {
  dayBounds: [number, number];
  timeslots: Timeslot[];
  currentTimezone: string;
  computerTimezone: string;
  activityCategoryMap: Record<string, ActivityJoinCategory>;
  selectedTimeslot: Timeslot | null;
  editingTimeslot: string | null;
  onTimeslotClick: (timeslot: Timeslot) => void;
  onBlankClick: (startMs: number, endMs: number) => void;
  setEditingTimeslot: (timeslotId: string | null) => void;
  revertTimezone: () => void;
  revertDisplayMode: () => void;
  showNotesMode?: boolean;
}

export const TimeslotListView: React.FC<TimeslotListViewProps> = ({
  dayBounds,
  timeslots,
  currentTimezone,
  computerTimezone,
  activityCategoryMap,
  selectedTimeslot,
  editingTimeslot,
  onTimeslotClick,
  onBlankClick,
  setEditingTimeslot,
  revertTimezone,
  revertDisplayMode,
  showNotesMode = false,
}) => {
  const [mouseYPosition, setMouseYPosition] = useState<number | null>(null);
  const { pushUndoSteps } = useUndoRedo();
  const cloudSyncedDB = useCloudSyncedDB();

  const containerRef = useRef<HTMLDivElement>(null);
  // State for highlighting conflicting timeslots, list of ids
  const [conflictingTimeslots, setConflictingTimeslots] = useState<string[]>(
    []
  );

  // State for scaling timeslots, if null means not scaling
  const [modificationContext, setModificationContext] =
    useState<TimeslotModificationContext | null>(null);

  const isModifyingTimeslot = useMemo(() => {
    return modificationContext !== null;
  }, [modificationContext]);

  const isDifferentTimezone = useMemo(
    () => currentTimezone !== computerTimezone,
    [currentTimezone, computerTimezone]
  );

  const timeBlocks = useMemo(() => {
    const blocks: Array<TimeBlock> = [];
    let currentTime = dayBounds[0];
    let heightSoFar = 0;

    // Sort timeslots by start time
    const sortedTimeslots = [...timeslots].sort(
      (a, b) => a.startTimestampMills - b.startTimestampMills
    );

    sortedTimeslots.forEach((timeslot) => {
      // Add blank block if there's a gap
      if (timeslot.startTimestampMills > currentTime) {
        const blankMinutes =
          (timeslot.startTimestampMills - currentTime) / 1000 / 60;
        const blankHeight = heightFromMinutes(blankMinutes);
        blocks.push({
          type: "blank",
          start: currentTime,
          end: timeslot.startTimestampMills,
          startPx: heightSoFar,
          endPx: heightSoFar + blankHeight,
        });
        heightSoFar += blankHeight;
      }

      // Add timeslot block
      const timeslotMinutes =
        (timeslot.endTimestampMills - timeslot.startTimestampMills) / 1000 / 60;
      const timeslotHeight = heightFromMinutes(timeslotMinutes);
      blocks.push({
        type: "timeslot",
        start: timeslot.startTimestampMills,
        end: timeslot.endTimestampMills,
        startPx: heightSoFar,
        endPx: heightSoFar + timeslotHeight,
        timeslot,
      });
      heightSoFar += timeslotHeight;

      currentTime = timeslot.endTimestampMills;
    });

    // Add final blank block if needed
    if (currentTime < dayBounds[1]) {
      const finalMinutes = (dayBounds[1] - currentTime) / 1000 / 60;
      const finalHeight = heightFromMinutes(finalMinutes);
      blocks.push({
        type: "blank",
        start: currentTime,
        end: dayBounds[1],
        startPx: heightSoFar,
        endPx: heightSoFar + finalHeight,
      });
    }

    return blocks;
  }, [timeslots, dayBounds]);

  const totalHeight = useMemo(() => {
    return timeBlocks[timeBlocks.length - 1].endPx;
  }, [timeBlocks]);

  const modificationHandlePositions = useMemo(() => {
    const points: number[] = [0]; // Start of day

    timeBlocks.forEach((block) => {
      points.push(block.startPx);
      points.push(block.endPx);
    });

    // Remove duplicates
    return [...new Set(points)];
  }, [timeBlocks]);

  // Position of the handle closest to the user's cursor
  const modificationHandleYPosition: number | null = useMemo(() => {
    if (mouseYPosition === null) return null;
    if (isModifyingTimeslot) {
      // If dragging, return the cursor position
      return mouseYPosition;
    }

    // Otherwise, return the closest point
    const closestPoint = modificationHandlePositions.reduce(
      (closest, point) => {
        const currentDistance = Math.abs(point - mouseYPosition);
        const closestDistance = Math.abs(closest - mouseYPosition);
        return currentDistance < closestDistance ? point : closest;
      },
      modificationHandlePositions[0]
    );
    return closestPoint;
  }, [mouseYPosition, isModifyingTimeslot, modificationHandlePositions]);

  const hourBlocks = useMemo(() => {
    const blocks: Array<{ hour: number; height: number; color: string }> = [];
    const dayStart = new Date(dayBounds[0]);

    // Calculate height for each hour based on the timeBlocks that intersect with it
    for (let hour = 0; hour < 24; hour++) {
      const hourStart = new Date(dayStart).setHours(hour, 0, 0, 0);
      const hourEnd = new Date(dayStart).setHours(hour + 1, 0, 0, 0);

      // Calculate total height of timeBlocks that intersect with this hour
      let hourHeight = 0;

      timeBlocks.forEach((block) => {
        const blockStart = Math.max(block.start, hourStart);
        const blockEnd = Math.min(block.end, hourEnd);

        if (blockEnd > blockStart) {
          const blockDuration = blockEnd - blockStart;
          const totalBlockDuration = block.end - block.start;
          const blockHeight = heightFromMinutes(
            (block.end - block.start) / 1000 / 60
          );

          hourHeight += (blockDuration / totalBlockDuration) * blockHeight;
        }
      });

      // Determine color based on time of day
      const color = hourLabelBackgroundColor(hour);

      blocks.push({
        hour,
        height: hourHeight,
        color,
      });
    }

    return blocks;
  }, [timeBlocks, dayBounds]);

  const beginTimeslotModification = useCallback(
    (yPos: number) => {
      if (isDifferentTimezone) return;
      if (modificationHandleYPosition === null || mouseYPosition === null)
        return;

      let topTimeblock = timeBlocks.find(
        (block) => block.endPx === modificationHandleYPosition
      );
      let bottomTimeblock = timeBlocks.find(
        (block) => block.startPx === modificationHandleYPosition
      );

      // If there is no top timeblock, it means we are dragging from the top, create a fake one
      if (!topTimeblock) {
        topTimeblock = {
          type: "blank",
          start: dayBounds[0],
          end: dayBounds[0],
          startPx: 0,
          endPx: 0,
        };
      }

      // If there is no bottom timeblock, it means we are dragging to the bottom, create a fake one
      if (!bottomTimeblock) {
        bottomTimeblock = {
          type: "blank",
          start: dayBounds[1],
          end: dayBounds[1],
          startPx: totalHeight,
          endPx: totalHeight,
        };
      }

      const newDraggingTimeslots = {
        above: topTimeblock,
        below: bottomTimeblock,
        currentlyDragging: null,
        dragDirection: null,
        startDragY: yPos,
        currentTime: null,
        currentStartMs: null,
        currentEndMs: null,
      };

      setModificationContext(newDraggingTimeslots);
    },
    [
      isDifferentTimezone,
      modificationHandleYPosition,
      mouseYPosition,
      timeBlocks,
      dayBounds,
      totalHeight,
    ]
  );

  const handleMouseMovement = useCallback(
    (yPos: number) => {
      setMouseYPosition(yPos);

      if (modificationContext !== null) {
        const updatedModificationContext = updateTimeslotModification(
          yPos,
          timeBlocks,
          dayBounds,
          modificationContext
        );

        setModificationContext(updatedModificationContext);
        const updatedTimeslot: TimeslotLite = {
          id: updatedModificationContext.currentlyDragging?.timeslot?.id || "",
          startTimestampMills: updatedModificationContext.currentStartMs ?? 0,
          endTimestampMills: updatedModificationContext.currentEndMs ?? 0,
        };

        // Check for conflicts
        const conflicts = getConflictingTimeslots(timeslots, updatedTimeslot);
        if (conflicts.length > 0) {
          // Highlight conflicting timeslots
          setConflictingTimeslots(conflicts.map((t) => t.id));
        } else {
          // No conflicts, clear any previously highlighted conflicts
          setConflictingTimeslots([]);
        }
      }
    },
    [modificationContext, timeBlocks, dayBounds, timeslots]
  );

  const finishTimeslotModification = useCallback(async () => {
    if (modificationContext === null) return;

    if (
      modificationContext?.currentStartMs === null ||
      modificationContext?.currentEndMs === null ||
      modificationContext?.currentTime === null
    ) {
      setModificationContext(null);
      return;
    }

    // Calculate new start and end times
    const compareTimeslot = {
      id:
        modificationContext?.currentlyDragging?.type === "timeslot"
          ? modificationContext?.currentlyDragging?.timeslot?.id || ""
          : "",
      startTimestampMills: modificationContext.currentStartMs,
      endTimestampMills: modificationContext.currentEndMs,
    };

    // Get and apply timeslot adjustments
    const timeslotChanges = await getTimeslotAdjustments(
      timeslots,
      compareTimeslot
    );

    let undoSteps: TimeslotChange[] = [];

    if (modificationContext.currentlyDragging?.type !== "timeslot") {
      // No timeslot to update, just apply the changes
      undoSteps = await applyTimeslotChanges(
        cloudSyncedDB,
        [...timeslotChanges],
        true
      );
    } else {
      const updatedTimeslot = {
        ...modificationContext.currentlyDragging.timeslot,
        startTimestampMills: modificationContext.currentStartMs,
        endTimestampMills: modificationContext.currentEndMs,
      };
      undoSteps = await applyTimeslotChanges(
        cloudSyncedDB,
        [...timeslotChanges, { action: "update", timeslot: updatedTimeslot }],
        true
      );
    }

    // Push undo steps after a short delay
    setTimeout(() => {
      pushUndoSteps(undoSteps, true);
    }, 20);

    setModificationContext(null);
  }, [modificationContext, timeslots, cloudSyncedDB, pushUndoSteps]);

  useEffect(() => {
    const containerRefCurrent = containerRef.current;
    const handleTouchMove = (e: TouchEvent) => {
      if (isModifyingTimeslot) {
        e.preventDefault();
      }
    };
    if (containerRefCurrent) {
      // Prevent touch scrolling when dragging
      containerRefCurrent.addEventListener("touchmove", handleTouchMove, {
        passive: false,
      });
    }
    window.addEventListener("mouseup", finishTimeslotModification);
    window.addEventListener("touchend", finishTimeslotModification);
    return () => {
      window.removeEventListener("mouseup", finishTimeslotModification);
      window.removeEventListener("touchend", finishTimeslotModification);
      containerRefCurrent?.removeEventListener("touchmove", handleTouchMove);
    };
  }, [isModifyingTimeslot, handleMouseMovement, finishTimeslotModification]);

  return (
    <>
      <ListContainer
        direction="row"
        spacing={0}
        ref={containerRef}
        onMouseMove={(e) => {
          handleMouseMovement(
            e.clientY - containerRef.current!.getBoundingClientRect().top - 15
          );
        }}
        onTouchMove={(e) => {
          const touch = e.touches[0];
          handleMouseMovement(
            touch.clientY -
              containerRef.current!.getBoundingClientRect().top -
              15
          );
        }}
        onMouseLeave={() => {
          setMouseYPosition(null);
        }}
        onTouchEnd={() => {
          setMouseYPosition(null);
        }}>
        {/* Timeline */}
        <Stack
          direction="column"
          height={totalHeight + "px"}
          width="50px"
          sx={{
            backgroundColor: "#eeeeee88",
            borderRadius: 1,
          }}>
          {hourBlocks.map((block) => (
            <TimeblockComponent
              key={block.hour}
              height={block.height}
              color={block.color}>
              {block.hour > 0 && (
                <div>{format(new Date().setHours(block.hour), "HH:00")}</div>
              )}
            </TimeblockComponent>
          ))}
        </Stack>
        {/* Padding */}
        <Box width="15px"></Box>
        {/* Timeslots */}
        <Stack direction="column" flexGrow={1} position="relative" width="100%">
          {timeBlocks.map((block, index) => (
            <React.Fragment key={index}>
              {block.type === "timeslot" && block.timeslot ? (
                <TimeslotComponent
                  timeslot={block.timeslot}
                  activityCategoryMap={activityCategoryMap}
                  isSelected={selectedTimeslot?.id === block.timeslot.id}
                  isConflicting={conflictingTimeslots.includes(
                    block.timeslot.id
                  )}
                  isEditing={editingTimeslot === block.timeslot.id}
                  isDraggingThis={
                    modificationContext?.currentlyDragging === block
                  }
                  isDraggingOther={isModifyingTimeslot}
                  isReduced={showNotesMode}
                  onClick={() => {
                    if (isDifferentTimezone) return;
                    onTimeslotClick(block.timeslot!);
                  }}
                  onDoubleClick={() => {
                    if (isDifferentTimezone) return;
                    if (
                      editingTimeslot !== block.timeslot?.id &&
                      block.timeslot
                    ) {
                      setEditingTimeslot(block.timeslot.id);
                    } else {
                      setEditingTimeslot(null);
                    }
                  }}
                />
              ) : (
                <BlankTimeslotComponent
                  startMs={block.start}
                  endMs={block.end}
                  isDraggingOther={isModifyingTimeslot}
                  isReduced={showNotesMode}
                  onClick={() => {
                    if (isDifferentTimezone) return;
                    onBlankClick(block.start, block.end);
                  }}
                />
              )}
            </React.Fragment>
          ))}

          {modificationContext?.currentlyDragging && (
            <DraggingTimeslotComponent
              modificationContext={modificationContext}
              activityCategoryMap={activityCategoryMap}
              currentYPos={mouseYPosition ?? 0}
            />
          )}

          {modificationContext?.currentTime && (
            <TimeDisplay
              sx={{
                position: "absolute",
                top: (mouseYPosition || 0) + "px",
                left: "15px",
              }}
              variant="subtitle1">
              {timeOfDay(modificationContext.currentTime)}
            </TimeDisplay>
          )}

          {modificationHandleYPosition !== null && (
            <ScaleButton
              variant="contained"
              sx={{
                top: modificationHandleYPosition + "px",
              }}
              onMouseDown={(e) => {
                if (!containerRef.current) return;
                beginTimeslotModification(
                  e.clientY -
                    containerRef.current.getBoundingClientRect().top -
                    15
                );
              }}
              onTouchStart={(e) => {
                if (!containerRef.current) return;
                const touch = e.touches[0];
                beginTimeslotModification(
                  touch.clientY -
                    containerRef.current.getBoundingClientRect().top -
                    15
                );
              }}>
              <DragHandle className="handleIcon" />
            </ScaleButton>
          )}
        </Stack>

        {showNotesMode && (
          <Stack
            direction="column"
            width="100%"
            sx={{
              borderLeft: "1px solid #eee",
              position: "relative",
              backgroundColor: "#eee",
            }}>
            {timeBlocks.map((block) => (
              <React.Fragment key={`note-${block.type}-${block.start}`}>
                {block.type === "timeslot" && block.timeslot ? (
                  <TimeslotNotes
                    timeslot={block.timeslot}
                    height={block.endPx - block.startPx}
                  />
                ) : (
                  <Box
                    sx={{
                      height: `${block.endPx - block.startPx}px`,
                      width: "100%",
                    }}
                  />
                )}
              </React.Fragment>
            ))}
          </Stack>
        )}
      </ListContainer>
      {isDifferentTimezone && (
        <Stack
          position="absolute"
          top="50%"
          left="50%"
          sx={{
            transform: "translate(-50%, -50%)",
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            padding: 5,
            borderRadius: 2,
            boxShadow: 3,
            backdropFilter: "blur(8px)",
            textAlign: "center",
          }}>
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent="center"
            mb={2}>
            <WarningAmber sx={{ color: "warning.main", fontSize: 30 }} />
            <Stack direction="column">
              <Typography mb={1}>
                The schedule view does not support viewing timeslots in
                alternate timezones yet.
              </Typography>
              <Typography mb={3} fontSize={14}>
                Please switch to the timeline view to see timeslots in{" "}
                {currentTimezone}.
              </Typography>
            </Stack>
          </Stack>
          <Stack direction="row" spacing={2} justifyContent="center">
            <Button
              variant="contained"
              onClick={() => {
                revertDisplayMode();
              }}>
              Switch to timeline view
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                revertTimezone();
              }}>
              Switch to {computerTimezone}
            </Button>
          </Stack>
        </Stack>
      )}
    </>
  );
};
