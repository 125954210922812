import styled from "@emotion/styled";
import { QuestionMark } from "@mui/icons-material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { ICONS } from "../../../../const/icons";
import { ActivityJoinCategory } from "../../../../database/helpers";
import { timeDisplay, timeOfDay } from "../../../../utils/time";
import { TimeDisplay } from "./TimeslotComponent";
import { TimeslotModificationContext } from "./utils";

const DraggingContainer = styled(Box)`
  padding: 4px 12px;
  margin: 0;
  border: 1px solid #e0e0e0;
  position: absolute;
  pointer-events: none;
  width: calc(100%);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
`;

interface DraggingTimeslotComponentProps {
  modificationContext: TimeslotModificationContext;
  activityCategoryMap: Record<string, ActivityJoinCategory>;
  currentYPos: number;
}

export const DraggingTimeslotComponent: React.FC<
  DraggingTimeslotComponentProps
> = ({ modificationContext, activityCategoryMap, currentYPos }) => {
  const timeblock = modificationContext?.currentlyDragging;
  if (!timeblock) return null;

  const timeslot = timeblock.type === "timeslot" ? timeblock.timeslot : null;

  const activity = timeslot ? activityCategoryMap[timeslot.activityId] : null;
  const Icon = activity ? ICONS[activity.icon] : QuestionMark;
  const colour = activity ? activity.colour : "#fefefe";
  const name = activity ? activity.name : "No activity";

  let top = 0;
  let height = 0;
  let startTime: string | null = timeOfDay(timeblock.start);
  const currentTime = modificationContext.currentTime
    ? modificationContext.currentTime
    : 0;

  let minutes = 0;

  if (modificationContext.dragDirection === "up") {
    top = currentYPos;
    height = timeblock.endPx - top;
    startTime = null;
    minutes = (timeblock.end - currentTime) / 1000 / 60;
  } else if (modificationContext.dragDirection === "down") {
    top = timeblock.startPx;
    height = currentYPos - top;
    minutes = (currentTime - timeblock.start) / 1000 / 60;
  } else {
    return null;
  }

  return (
    <DraggingContainer
      sx={{
        backgroundColor: `${colour}CC`,
        top: `${top}px`,
        height: `${height}px`,
      }}>
      <Stack direction="row" alignItems="flex-start" spacing={2}>
        {startTime && (
          <Stack direction="column" alignItems="center">
            <TimeDisplay>{startTime}</TimeDisplay>
          </Stack>
        )}

        <Stack
          sx={{ flexGrow: 1 }}
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          height="100%"
          gap={2}>
          <Icon />
          <Typography variant="subtitle1" fontWeight={500}>
            {name}
          </Typography>
        </Stack>
        {timeslot?.placeId && <LocationOnIcon />}

        <Typography variant="body2" color="text.secondary">
          {timeDisplay(minutes)}
        </Typography>
      </Stack>
    </DraggingContainer>
  );
};
