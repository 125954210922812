import {
  Box,
  Container,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  Stack,
} from "@mui/material";
import { useEffect } from "react";
import "react-calendar/dist/Calendar.css";
import { useNavigate, useParams } from "react-router-dom";
import { useSettings } from "../../hooks/useSettings";
import OverviewSection from "./components/OverviewSection";
import StatsCalendar from "./components/StatsCalendar";
import Trends from "./components/Trends";
import { KeyIcon, ValidKey } from "../../components/KeyIcon";

const tabRoutes = ["recents", "trends", "calendar"];
const shortcuts = {
  recents: "Q",
  trends: "W",
  calendar: "E",
};

export const Analytics = () => {
  const { page } = useParams();
  const navigate = useNavigate();

  const { startAnalyticsScreen } = useSettings();
  const currentTab = tabRoutes.indexOf(page || "");

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    navigate(`/analytics/${tabRoutes[newValue]}`);
  };

  useEffect(() => {
    if (!page) {
      navigate(`/analytics/${startAnalyticsScreen}`);
    }
  }, [page, startAnalyticsScreen, navigate]);

  // Handle keyboard shortcuts
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const key = event.key.toUpperCase();

      if (key === shortcuts.recents) {
        navigate(`/analytics/recents`);
      } else if (key === shortcuts.trends) {
        navigate(`/analytics/trends`);
      } else if (key === shortcuts.calendar) {
        navigate(`/analytics/calendar`);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [navigate]);

  const renderTabContent = () => {
    switch (page) {
      case "recents":
        return <OverviewSection />;
      case "trends":
        return <Trends />;
      case "calendar":
        return <StatsCalendar />;
    }
  };

  return (
    <Container>
      <Tabs
        value={currentTab}
        onChange={handleTabChange}
        centered
        sx={{ width: "100%" }}>
        <Tooltip
          title={
            <Stack direction="row" spacing={1}>
              <Typography>Recent Activity</Typography>
              <KeyIcon keyName={shortcuts.recents as ValidKey} />
            </Stack>
          }>
          <Tab label="Recents" />
        </Tooltip>
        <Tooltip
          title={
            <Stack direction="row" spacing={1}>
              <Typography>Trends</Typography>
              <KeyIcon keyName={shortcuts.trends as ValidKey} />
            </Stack>
          }>
          <Tab label="Trends" />
        </Tooltip>
        <Tooltip
          title={
            <Stack direction="row" spacing={1}>
              <Typography>Calendar</Typography>
              <KeyIcon keyName={shortcuts.calendar as ValidKey} />
            </Stack>
          }>
          <Tab label="Calendar" />
        </Tooltip>
      </Tabs>
      <Box mt={5}>{renderTabContent()}</Box>
    </Container>
  );
};
