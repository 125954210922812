import { Timeslot } from "../../../../database/db";

export const SNAP_THRESHOLD_PX = 10; // Pixels within which to snap to a boundary

export type TimeBlock = {
  start: number;
  end: number;
  startPx: number;
  endPx: number;
} & (
  | { type: "blank"; timeslot?: { id?: undefined } } // Doing this allows us to access timeblock.timeslot.id
  | { type: "timeslot"; timeslot: Timeslot }
);

// Represents the current state of a timeslot being resized/dragged
export type TimeslotDragState = {
  currentlyDragging: TimeBlock | null;
  dragDirection: "up" | "down" | null;
  currentTime: number | null;
  currentStartMs: number | null;
  currentEndMs: number | null;
};

// Contains all context needed for timeslot resize operations
export type TimeslotModificationContext = {
  above: TimeBlock | null;
  below: TimeBlock | null;
  startDragY: number;
} & TimeslotDragState;

/**
 * Converts a Y coordinate position to a timestamp within the day
 * @param yPos - The Y coordinate relative to the container
 * @param timeBlocks - Array of TimeBlocks representing the day's schedule
 * @param dayBounds - Tuple of [startTime, endTime] timestamps for the day
 * @returns The timestamp corresponding to the Y position
 */
export const convertYPositionToTimestamp = (
  yPos: number,
  timeBlocks: TimeBlock[],
  dayBounds: [number, number]
): number => {
  // Clamp yPos to valid range
  const maxY = timeBlocks[timeBlocks.length - 1].endPx;
  const clampedY = Math.max(0, Math.min(yPos, maxY));

  // Find the containing block
  const containingBlock = timeBlocks.find(
    (block) => clampedY >= block.startPx && clampedY <= block.endPx
  );

  if (!containingBlock) {
    return dayBounds[0]; // fallback to start of day
  }

  // Calculate the percentage through the block
  const blockProgress =
    (clampedY - containingBlock.startPx) /
    (containingBlock.endPx - containingBlock.startPx);

  // Interpolate the time
  const timeRange = containingBlock.end - containingBlock.start;
  const time = containingBlock.start + timeRange * blockProgress;

  // Clamp to day bounds
  return Math.max(dayBounds[0], Math.min(dayBounds[1], time));
};

/**
 * Updates the resize state based on the current drag position
 * @param yPos - The current Y coordinate of the drag operation
 * @param currentTimeAtY - The timestamp corresponding to the current Y position
 * @param modificationContext - The current modification context
 * @returns Updated drag state for the timeslot being resized
 */
export const updateTimeslotModification = (
  yPos: number,
  timeBlocks: TimeBlock[],
  dayBounds: [number, number],
  modificationContext: TimeslotModificationContext
): TimeslotModificationContext => {
  const currentTimeAtY = convertYPositionToTimestamp(
    yPos,
    timeBlocks,
    dayBounds
  );
  // If dragging up, set the currently dragging to the bottom timeblock
  if (yPos < modificationContext.startDragY) {
    return {
      ...modificationContext,
      currentlyDragging: modificationContext.below,
      dragDirection: "up",
      currentTime: currentTimeAtY,
      currentStartMs: currentTimeAtY,
      currentEndMs: modificationContext.below?.end ?? null,
    };
  } else if (yPos > modificationContext.startDragY) {
    // If dragging down, set the currently dragging to the top timeblock
    return {
      ...modificationContext,
      currentlyDragging: modificationContext.above,
      dragDirection: "down",
      currentTime: currentTimeAtY,
      currentStartMs: modificationContext.above?.start ?? null,
      currentEndMs: currentTimeAtY,
    };
  } else {
    // If not dragging, set the currently dragging to null
    return {
      ...modificationContext,
      currentlyDragging: null,
      dragDirection: null,
      currentTime: null,
      currentStartMs: null,
      currentEndMs: null,
    };
  }
};
