import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Category } from "../../../database/db";

import { Box, Button, Stack, Switch, Typography } from "@mui/material";
import { useState } from "react";
import { timeDisplay } from "../../../utils/time";

ChartJS.register(ArcElement, Tooltip, Legend);

interface CategoryData {
  category: Category;
  minutes: number;
  totalTime: string;
}

interface PercentageGraphProps {
  data: CategoryData[];
}

const PercentageGraph: React.FC<PercentageGraphProps> = ({ data }) => {
  const [hiddenCategories, setHiddenCategories] = useState<Set<string>>(
    new Set()
  );
  const [showAllCategories, setShowAllCategories] = useState(false);

  // Sort data by minutes in descending order
  const sortedData = [...data].sort((a, b) => b.minutes - a.minutes);

  // Calculate total minutes
  const totalMinutes = sortedData.reduce((sum, item) => sum + item.minutes, 0);

  // Function to calculate percentage
  const calculatePercentage = (minutes: number): string => {
    return ((minutes / totalMinutes) * 100).toFixed(1) + "%";
  };

  const toggleCategory = (categoryName: string) => {
    setHiddenCategories((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(categoryName)) {
        newSet.delete(categoryName);
      } else {
        newSet.add(categoryName);
      }
      return newSet;
    });
  };

  const filteredData = sortedData.filter(
    (item) => !hiddenCategories.has(item.category.name)
  );

  const chartData = {
    labels: filteredData.map((item) => item.category.name),
    datasets: [
      {
        data: filteredData.map((item) => item.minutes),
        backgroundColor: filteredData.map((item) => item.category.colour),
        borderColor: filteredData.map((item) => item.category.colour),
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: "Time Spent per Category",
      },
      tooltip: {
        callbacks: {
          label: (context: { dataIndex: number }) => {
            const item = filteredData[context.dataIndex];
            return `${item.category.name}: ${timeDisplay(
              item.minutes
            )} (${calculatePercentage(item.minutes)})`;
          },
        },
      },
    },
  };

  const displayedCategories = showAllCategories
    ? sortedData
    : sortedData.slice(0, 6);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          mb: 2,
        }}>
        {displayedCategories.map((item) => (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            key={item.category.name}
            sx={{
              mb: 1,
              cursor: "pointer",
            }}
            onClick={() => toggleCategory(item.category.name)}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
              spacing={2}>
              <Box
                sx={{
                  width: 20,
                  height: 20,
                  backgroundColor: item.category.colour,
                  mr: 1,
                }}
              />
              <Typography
                variant="body2"
                width="100%"
                sx={{ textAlign: "left" }}>
                {item.category.name}
              </Typography>
              <Typography variant="body2">
                {timeDisplay(item.minutes)} ({calculatePercentage(item.minutes)}
                )
              </Typography>
            </Stack>
            <Switch
              checked={!hiddenCategories.has(item.category.name)}
              onChange={() => toggleCategory(item.category.name)}
              color="primary"
              onClick={(e) => e.stopPropagation()}
            />
          </Stack>
        ))}
      </Box>
      {sortedData.length > 6 && (
        <Button onClick={() => setShowAllCategories(!showAllCategories)}>
          {showAllCategories ? "Show Less" : "Show All Categories"}
        </Button>
      )}
      <Box sx={{ height: "400px", width: "100%" }}>
        <Doughnut data={chartData} options={options} />
      </Box>
    </Box>
  );
};

export default PercentageGraph;
