import styled from "@emotion/styled";
import { QuestionMark } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { ICONS } from "../../../../const/icons";
import { ActivityJoinCategory } from "../../../../database/helpers";
import { timeDisplay } from "../../../../utils/time";
import {
  MID_TIMESLOT_HEIGHT,
  SHORT_TIMESLOT_HEIGHT,
} from "./TimeslotComponent";
import { TimeslotModificationContext } from "./utils";

const TimeslotRow = styled(Box)`
  position: absolute;
  width: calc(100% - 120px);
  z-index: 1;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #aaaaaa;
  cursor: pointer;

  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
`;

interface DraggingTimeslotComponentProps {
  modificationContext: TimeslotModificationContext;
  activityCategoryMap: Record<string, ActivityJoinCategory>;
  currentYPos: number;
  pxPerMs: number;
  dayBounds: number[];
}

const DraggingTimeslotComponent: React.FC<DraggingTimeslotComponentProps> = ({
  modificationContext,
  activityCategoryMap,
  currentYPos,
  pxPerMs,
  dayBounds,
}) => {
  const timeblock = modificationContext?.currentlyDragging;
  if (!timeblock) return null;

  const timeslot = timeblock.type === "timeslot" ? timeblock.timeslot : null;

  const activity = timeslot ? activityCategoryMap[timeslot.activityId] : null;
  const Icon = activity ? ICONS[activity.icon] : QuestionMark;
  const colour = activity ? activity.colour : "#fefefe";
  const name = activity ? activity.name : "No activity";

  let top = 0;
  let height = 0;
  if (modificationContext.modificationType === "drag") {
    const startPx =
      ((modificationContext.currentStartMs ?? 0) - dayBounds[0]) * pxPerMs;
    const endPx =
      ((modificationContext.currentEndMs ?? 0) - dayBounds[0]) * pxPerMs;
    top = startPx;
    height = endPx - startPx;
  } else if (modificationContext.dragDirection === "up") {
    top = currentYPos;
    height = timeblock.endPx - top;
  } else if (modificationContext.dragDirection === "down") {
    top = timeblock.startPx;
    height = currentYPos - top;
  } else {
    return null;
  }

  const minutes = Math.round(height / pxPerMs / 1000 / 60);

  return (
    <TimeslotRow
      key={timeslot?.startTimestampMills}
      sx={{
        top: `${top}px`,
        height: `${height}px`,
        backgroundColor: `${colour}66`,
      }}>
      {height >= MID_TIMESLOT_HEIGHT ? (
        <Stack direction="row" padding={1} gap={1}>
          <Icon />
          <Typography textAlign="left" sx={{ flexGrow: 1 }}>
            {name}
          </Typography>
          <Typography>{timeDisplay(minutes)}</Typography>
        </Stack>
      ) : height >= SHORT_TIMESLOT_HEIGHT ? (
        <Stack direction="row" alignItems="center" paddingX={1} gap={1}>
          <Icon sx={{ fontSize: "12px" }} />
          <Typography textAlign="left" sx={{ flexGrow: 1, fontSize: "12px" }}>
            {name}
          </Typography>
          <Typography sx={{ fontSize: "12px" }}>
            {timeDisplay(minutes)}
          </Typography>
        </Stack>
      ) : null}
    </TimeslotRow>
  );
};

export default DraggingTimeslotComponent;
