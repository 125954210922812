import styled from "@emotion/styled";
import {
  ChevronLeft,
  ChevronRight,
  NoteAlt,
  Redo,
  Today,
  Undo,
  ZoomIn,
} from "@mui/icons-material";

import TimeslotIcon from "@mui/icons-material/DnsOutlined";
import TimelineIcon from "@mui/icons-material/ViewListOutlined";
import {
  Button,
  ButtonGroup,
  Container,
  Paper,
  Slider,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { addDays, format, isToday as isDateToday } from "date-fns";
import { useLiveQuery } from "dexie-react-hooks";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import "react-calendar/dist/Calendar.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import LocationHistoryIcon from "../../assets/icons/location_history.svg?react";
import timezone_icon from "../../assets/icons/timezone.svg";
import { KeyIcon } from "../../components/KeyIcon";
import { TimezoneSelectorModal } from "../../components/TimezoneSelectorModal";
import { db, dbuuid, Place, Timeslot } from "../../database/db";
import { useActivityJoinCategory } from "../../hooks/useActivityJoinCategory";
import { useCloudSyncedDB } from "../../hooks/useCloudSyncedDb";
import useSessionBackedState from "../../hooks/useSessionBackedState";
import { useSettings } from "../../hooks/useSettings";
import { useSnackbar } from "../../hooks/useSnackbar";
import { useUndoRedo } from "../../hooks/useUndoRedo";
import {
  applyTimeslotChanges,
  getTimeslotAdjustments,
} from "../../utils/conflict_management";
import { LocationTimeBlock } from "../../utils/location_history_processing";
import {
  dateStringToDayBoundsMills,
  detectTimezone,
  getComputerTimezone,
} from "../../utils/time";
import { DatePickerCalendar } from "../shared/DatePickerCalendar";
import {
  NewTimeslotInfo,
  NewTimeslotParams,
  SuggestedTimeslotInfo,
} from "./components/AddTimeslotComponents";
import SelectedTimeslotInfo from "./components/SelectedTimeslotInfo";
import { TimelineView } from "./components/timeline/TimelineView";
import { TimeslotListView } from "./components/timeslots/TimeslotListView";
export const ControlPanel = styled(Paper)`
  position: sticky;
  top: 0;
  z-index: 1100;
  padding: 10px;
  background-color: white;
`;

const ONE_HOUR_MS = 60 * 60 * 1000;

export const Home = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { showSnackbar } = useSnackbar();
  const params = useParams<{ date?: string }>();

  const cloudSyncedDB = useCloudSyncedDB();

  const [date, setDate] = useState(() => {
    // First check if there's a date in the URL params
    if (
      params.date &&
      params.date !== "today" &&
      /^\d{4}-\d{2}-\d{2}$/.test(params.date)
    ) {
      return params.date;
    }

    // If not in URL, check sessionStorage
    const storedDate = sessionStorage.getItem("selectedDate");
    if (storedDate && /^\d{4}-\d{2}-\d{2}$/.test(storedDate)) {
      return storedDate;
    }

    // Default to today
    return format(new Date(), "yyyy-MM-dd");
  });

  // Determine display mode from URL
  const [displayMode, setDisplayMode] = useState<"timeline" | "timeslot">(
    () => {
      const path = location.pathname.split("/");
      return path[path.length - 1] === "timeslot" ? "timeslot" : "timeline";
    }
  );

  // Update URL when date or display mode changes
  useEffect(() => {
    const dateParam =
      date === format(new Date(), "yyyy-MM-dd") ? "today" : date;
    navigate(`/home/${dateParam}/${displayMode}`, { replace: true });
  }, [date, displayMode, navigate]);

  const {
    popUndoSteps,
    pushUndoSteps,
    pushRedoSteps,
    popRedoSteps,
    totalUndoSteps,
    totalRedoSteps,
  } = useUndoRedo();
  const { defaultZoom, dateFormat } = useSettings();
  const [pxPerHr, setPxPerHr] = useSessionBackedState(
    defaultZoom,
    "home-pxPerHr"
  );
  const pxPerMs = useMemo(() => {
    return pxPerHr / (60 * 60 * 1000);
  }, [pxPerHr]);

  const nextAllowedZoom = useRef(0);

  const { activityCategoryMap } = useActivityJoinCategory();

  const [currentTimezone, setCurrentTimezone] = useState(getComputerTimezone());
  const [timezoneSelectorOpen, setTimezoneSelectorOpen] = useState(false);
  const computerTimezone = useMemo(() => getComputerTimezone(), []);
  const [detectedTimezone, setDetectedTimezone] = useState(
    getComputerTimezone()
  );

  const currentScrollMs = useRef(0);

  useEffect(() => {
    setSelectedTimeslot(null);
    setEditingTimeslot(null);
    setNewTimeslotParams(null);
  }, [displayMode]);

  const toggleDisplayMode = useCallback(() => {
    setDisplayMode((prev) => (prev === "timeline" ? "timeslot" : "timeline"));
  }, [setDisplayMode]);

  const [showLocationHistory, setShowLocationHistory] = useState(true);

  const [editingTimeslot, setEditingTimeslot] = useState<string | null>(null);
  const [selectedTimeslot, setSelectedTimeslot] = useState<Timeslot | null>(
    null
  );

  const [newTimeslotParams, setNewTimeslotParams] =
    useState<NewTimeslotParams | null>(null);

  const [currentTimeMills, setCurrentTimeMills] = useState<number>(0);

  const [showCalendar, setShowCalendar] = useState(false);

  const dayBounds = useMemo(() => {
    return dateStringToDayBoundsMills(date, currentTimezone, computerTimezone);
  }, [date, currentTimezone, computerTimezone]);

  const timeslots = useLiveQuery(async () => {
    if (!dayBounds) return [];
    const [startMs, endMs] = dayBounds;
    const timeslots = await db.timeslots
      .where("startTimestampMills")
      .between(startMs, endMs)
      .or("endTimestampMills")
      .between(startMs, endMs)
      .toArray();

    // Timeslots that exceed the day bounds should be clamped
    return timeslots
      .map((timeslot) => {
        return {
          ...timeslot,
          startTimestampMills: Math.max(
            dayBounds[0],
            Math.min(dayBounds[1], timeslot.startTimestampMills)
          ),
          endTimestampMills: Math.max(
            dayBounds[0],
            Math.min(dayBounds[1], timeslot.endTimestampMills)
          ),
        };
      })
      .filter((timeslot) => {
        // Remove timeslots that are <= 0 milliseconds long
        return timeslot.startTimestampMills < timeslot.endTimestampMills;
      });
  }, [dayBounds]);

  // Get the location history for the day
  const locationHistory = useLiveQuery(() => {
    if (!dayBounds) return [];

    return db.locationHistories
      .where("timestampMills")
      .between(dayBounds[0] - ONE_HOUR_MS, dayBounds[1] + ONE_HOUR_MS)
      .toArray();
  }, [dayBounds]);

  // Detect the timezone based on the timeslots and the location history
  useEffect(() => {
    const detectedTimezone = detectTimezone(timeslots || []);
    setDetectedTimezone(detectedTimezone);
  }, [timeslots]);

  const selectedTimeslotLotLon = useMemo(() => {
    if (!selectedTimeslot) return null;
    if (!locationHistory) return null;
    // Find the latlon of the timeslot based on the location history
    const closestLocation = locationHistory.find((location) => {
      return (
        location.timestampMills >= selectedTimeslot.startTimestampMills &&
        location.timestampMills <= selectedTimeslot.endTimestampMills
      );
    });
    if (!closestLocation) return null;
    return {
      latitude: closestLocation.latitude,
      longitude: closestLocation.longitude,
    };
  }, [selectedTimeslot, locationHistory]);

  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.up("md"));

  const changeTimeslotActivityAndTime = useCallback(
    async (
      timeslot: Timeslot,
      activityId: string,
      startMs: number,
      endMs: number,
      timezone: string
    ) => {
      if (!activityId) return;
      const updatedTimeslot = {
        ...timeslot,
        activityId: activityId,
        startTimestampMills: startMs,
        endTimestampMills: endMs,
        timezone: timezone,
      };
      const undoSteps = await applyTimeslotChanges(
        cloudSyncedDB,
        [
          {
            action: "update",
            timeslot: updatedTimeslot,
          },
        ],
        true
      );
      pushUndoSteps(undoSteps, true);
      setEditingTimeslot(null);
    },
    [cloudSyncedDB, pushUndoSteps]
  );

  const changeTimeslotPlace = useCallback(
    async (timeslot: Timeslot, placeId: string | null) => {
      const updatedTimeslot = {
        ...timeslot,
        placeId: placeId || undefined,
      };
      const undoSteps = await applyTimeslotChanges(
        cloudSyncedDB,
        [
          {
            action: "update",
            timeslot: updatedTimeslot,
          },
        ],
        true
      );
      pushUndoSteps(undoSteps, true);
    },
    [cloudSyncedDB, pushUndoSteps]
  );
  const addTimeslot = useCallback(
    async (timeslot: {
      startMs: number;
      endMs: number;
      activityId: string;
    }) => {
      const { startMs, endMs, activityId } = timeslot;

      const newTimeslot = {
        id: dbuuid(),
        startTimestampMills: startMs,
        endTimestampMills: endMs,
        activityId,
        timezone: currentTimezone,
      };

      const timeslotChanges = await getTimeslotAdjustments(timeslots || [], {
        id: dbuuid(),
        startTimestampMills: startMs,
        endTimestampMills: endMs,
      });

      const undoSteps = await applyTimeslotChanges(
        cloudSyncedDB,
        [...timeslotChanges, { action: "add", timeslot: newTimeslot }],
        true
      );

      pushUndoSteps(undoSteps, true);
    },
    [currentTimezone, timeslots, cloudSyncedDB, pushUndoSteps]
  );

  const deleteTimeslot = useCallback(
    async (timeslotId: string) => {
      const undoSteps = await applyTimeslotChanges(
        cloudSyncedDB,
        [{ action: "delete", timeslot: { id: timeslotId } }],
        true
      );
      pushUndoSteps(undoSteps, true);
    },
    [cloudSyncedDB, pushUndoSteps]
  );

  const deselectAll = useCallback(() => {
    if (selectedTimeslot) setSelectedTimeslot(null);
    if (editingTimeslot) setEditingTimeslot(null);
    if (newTimeslotParams) setNewTimeslotParams(null);
  }, [selectedTimeslot, editingTimeslot, newTimeslotParams]);

  const openAddTimeslot = useCallback(
    (ms: number, length: number = 60 * 60 * 1000) => {
      // setShowAddTimeslot(true);
      // setLastSelectedHour(hour);
      deselectAll();
      setNewTimeslotParams({
        startMs: ms,
        endMs: ms + length,
        activityId: "",
      });
    },
    [deselectAll]
  );

  const applyPlaceToTimeslots = useCallback(
    async (locationTimeBlock: LocationTimeBlock, place: Place) => {
      if (!timeslots) return;

      // Find all timeslots that overlap with this location block
      const overlappingTimeslots = timeslots.filter((timeslot) => {
        return (
          timeslot.startTimestampMills <= locationTimeBlock.endMills &&
          timeslot.endTimestampMills >= locationTimeBlock.startMills
        );
      });

      if (overlappingTimeslots.length === 0) return;

      // Update each overlapping timeslot with the new place
      const timeslotChanges = overlappingTimeslots.map((timeslot) => ({
        action: "update" as const,
        timeslot: {
          ...timeslot,
          placeId: place.id,
        },
      }));

      const undoSteps = await applyTimeslotChanges(
        cloudSyncedDB,
        timeslotChanges,
        true
      );
      pushUndoSteps(undoSteps, true);
    },
    [timeslots, cloudSyncedDB, pushUndoSteps]
  );

  const isToday = useMemo(() => {
    return isDateToday(new Date(date));
  }, [date]);

  // Format date with day of week
  const formattedDate = useMemo(() => {
    const dateObj = new Date(date);
    return format(dateObj, dateFormat);
  }, [date, dateFormat]);

  const performUndo = useCallback(async () => {
    const undoSteps = popUndoSteps();
    if (!undoSteps) return;
    const redoSteps = await applyTimeslotChanges(
      cloudSyncedDB,
      undoSteps,
      true
    );
    pushRedoSteps(redoSteps);
  }, [popUndoSteps, pushRedoSteps, cloudSyncedDB]);

  const performRedo = useCallback(async () => {
    const redoSteps = popRedoSteps();
    if (!redoSteps) return;
    const undoSteps = await applyTimeslotChanges(
      cloudSyncedDB,
      redoSteps,
      true
    );
    pushUndoSteps(undoSteps, false);
  }, [popRedoSteps, pushUndoSteps, cloudSyncedDB]);

  // Update the date change handlers
  const goToPreviousDay = useCallback(() => {
    const currentDateObj = new Date(date);
    const oneDayBefore = addDays(currentDateObj, -1);
    setDate(format(oneDayBefore, "yyyy-MM-dd"));
    deselectAll();
  }, [date, setDate, deselectAll]);

  const goToToday = useCallback(() => {
    setDate(format(new Date(), "yyyy-MM-dd"));
    setShowCalendar(false);
    deselectAll();
  }, [setDate, deselectAll]);

  const goToNextDay = useCallback(() => {
    const currentDateObj = new Date(date);
    const oneDayAfter = addDays(currentDateObj, 1);
    setDate(format(oneDayAfter, "yyyy-MM-dd"));
    deselectAll();
  }, [date, setDate, deselectAll]);

  const cancelAddingTimeslot = useCallback(() => {
    setNewTimeslotParams(null);
  }, [setNewTimeslotParams]);

  const handleAddTimeslot = useCallback(
    (newTimeslot: NewTimeslotParams) => {
      if (newTimeslot.activityId === "") {
        return;
      }
      addTimeslot(newTimeslot);
      setNewTimeslotParams(null);
    },
    [setNewTimeslotParams, addTimeslot]
  );

  const handleUpdateActivity = useCallback(
    (activityId: string) => {
      if (!newTimeslotParams) return;
      if (newTimeslotParams.activityId !== activityId) {
        setNewTimeslotParams({ ...newTimeslotParams, activityId });
      }
    },
    [newTimeslotParams, setNewTimeslotParams]
  );

  const scrollToCurrentTime = useCallback(() => {
    const scrollContainer = document.querySelector(".scroll-container");
    if (!scrollContainer) return;
    const currentTimeElement = document.querySelector(".current-time-line");
    if (!currentTimeElement) return;
    const topBar = document.querySelector(".control-panel");
    if (!topBar) return;

    const containerRect = scrollContainer.getBoundingClientRect();
    const timelineRect = currentTimeElement.getBoundingClientRect();
    const relativeTop =
      timelineRect.top - containerRect.top + scrollContainer.scrollTop;

    scrollContainer.scrollTo({
      top: relativeTop - topBar.clientHeight - 20,
      behavior: "smooth",
    });
  }, []);

  const [showNotesMode, setShowNotesMode] = useState(false);

  const toggleNotesMode = useCallback(() => {
    setShowNotesMode((prev) => !prev);
  }, []);

  useEffect(() => {
    const scrollContainer = document.querySelector(
      ".scroll-container"
    ) as HTMLElement;
    if (!scrollContainer) return;

    const handleKeyDown = (event: KeyboardEvent) => {
      // Check if any text input or textarea is focused
      const activeElement = document.activeElement;
      const isInputFocused =
        activeElement instanceof HTMLInputElement ||
        activeElement instanceof HTMLTextAreaElement ||
        activeElement?.getAttribute("contenteditable") === "true";

      // Don't handle shortcuts if an input is focused
      if (isInputFocused) return;

      if (newTimeslotParams) {
        // Handle adding timeslot keyboard shortcuts
        if (event.key === "Escape") {
          cancelAddingTimeslot();
        }
        if (event.key === "Enter") {
          handleAddTimeslot(newTimeslotParams);
        }
        return;
      }
      if (event.key === "Escape") {
        if (showCalendar) setShowCalendar(false);
        else if (editingTimeslot) setEditingTimeslot(null);
        else if (selectedTimeslot) setSelectedTimeslot(null);
      } else if (event.key === "ArrowUp") {
        // Jump to the previous timeslot
        if (selectedTimeslot && timeslots) {
          const currentIndex = timeslots.findIndex(
            (t) => t.id === selectedTimeslot.id
          );
          if (currentIndex > 0) {
            setSelectedTimeslot(timeslots[currentIndex - 1]);
          }
        }
      } else if (event.key === "ArrowDown") {
        // Jump to the next timeslot
        if (selectedTimeslot && timeslots) {
          const currentIndex = timeslots.findIndex(
            (t) => t.id === selectedTimeslot.id
          );
          if (currentIndex < timeslots.length - 1) {
            setSelectedTimeslot(timeslots[currentIndex + 1]);
          }
        }
      } else if (event.key === "e") {
        if (selectedTimeslot) {
          setEditingTimeslot(selectedTimeslot.id);
        }
      } else if (event.key === "q") {
        // Switch to timeline view
        setDisplayMode("timeline");
      } else if (event.key === "w") {
        // Switch to timeline view
        setDisplayMode("timeslot");
      } else if (event.key === "t") {
        if (!isToday) {
          goToToday();
        } else {
          scrollToCurrentTime();
          showSnackbar("Already on today's date", "info", 2000);
        }
      } else if (event.key === "d") {
        toggleDisplayMode();
      } else if (event.key === "ArrowLeft") {
        // Go to the previous day
        goToPreviousDay();
      } else if (event.key === "ArrowRight") {
        // Go to the next day
        goToNextDay();
      } else if (event.key === "Delete" || event.key === "Backspace") {
        // Delete the selected timeslot
        if (selectedTimeslot) {
          deleteTimeslot(selectedTimeslot.id);
          deselectAll();
        }
      } else if (
        (event.metaKey || event.ctrlKey) &&
        event.shiftKey &&
        event.key === "z"
      ) {
        performRedo();
      } else if ((event.metaKey || event.ctrlKey) && event.key === "z") {
        performUndo();
      } else if (event.key === "n") {
        toggleNotesMode();
      }
    };

    const handleScroll = (event: WheelEvent) => {
      if (event.metaKey || event.ctrlKey) {
        event.preventDefault();
        const now = new Date().getTime();
        if (now > nextAllowedZoom.current) {
          // 100ms cooldown to prevent janky zooming on mac trackpads
          nextAllowedZoom.current = now + 100;
          if (event.deltaY > 0) {
            setPxPerHr(Math.min(pxPerHr - 20, nextAllowedZoom.current));
          } else {
            setPxPerHr(Math.max(pxPerHr + 20, 10));
          }
        }
        return;
      }
      const target = event.currentTarget as HTMLElement;
      const scrollTop = target.scrollTop;

      const currentMs = Math.max(scrollTop - 50, 0) / pxPerMs;
      currentScrollMs.current = currentMs;
    };

    window.addEventListener("keydown", handleKeyDown);
    scrollContainer.addEventListener("wheel", handleScroll);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      scrollContainer.removeEventListener("wheel", handleScroll);
    };
  }, [
    selectedTimeslot,
    editingTimeslot,
    timeslots,
    deleteTimeslot,
    deselectAll,
    showCalendar,
    goToPreviousDay,
    goToNextDay,
    performRedo,
    performUndo,
    isToday,
    goToToday,
    showSnackbar,
    newTimeslotParams,
    cancelAddingTimeslot,
    handleAddTimeslot,
    scrollToCurrentTime,
    toggleDisplayMode,
    dayBounds,
    pxPerMs,
    setPxPerHr,
    pxPerHr,
    showNotesMode,
    toggleNotesMode,
  ]);

  useEffect(() => {
    const saveScrollMs = currentScrollMs.current;
    const newScrollTop = saveScrollMs * pxPerMs + 50;
    const scrollContainer = document.querySelector(
      ".scroll-container"
    ) as HTMLElement;
    if (!scrollContainer) return;
    scrollContainer.scrollTo({
      top: newScrollTop,
      behavior: "instant",
    });
  }, [pxPerMs]);

  useEffect(() => {
    const setCurrentTime = () => {
      // Round to the nearest minute
      const nowMills = new Date().getTime();
      const nowRounded = Math.round(nowMills / 60000) * 60000;
      if (
        dayBounds &&
        (nowRounded < dayBounds[0] || nowRounded > dayBounds[1])
      ) {
        setCurrentTimeMills(0);
        return;
      }
      setCurrentTimeMills(nowRounded);
    };

    setCurrentTime();

    // Set up interval timer
    const timer = setInterval(() => {
      setCurrentTime();
    }, 30000); // Update every 30 seconds

    // Add focus event listener
    const handleFocus = () => {
      console.log("focus");
      setCurrentTime();
    };
    window.addEventListener("focus", handleFocus);

    // Cleanup
    return () => {
      clearInterval(timer);
      window.removeEventListener("focus", handleFocus);
    };
  }, [dayBounds]);

  return (
    <Container
      sx={{
        userSelect: "none",
      }}>
      <TimezoneSelectorModal
        open={timezoneSelectorOpen}
        onClose={() => setTimezoneSelectorOpen(false)}
        currentTimezone={currentTimezone}
        detectedTimezone={detectedTimezone}
        computerTimezone={computerTimezone}
        onTimezoneSelect={setCurrentTimezone}
      />
      <ControlPanel className="control-panel">
        <Stack
          direction="column"
          gap={2}
          width="100%"
          justifyContent="space-around">
          {/* Date selector */}
          <Stack
            direction="row"
            gap={3}
            width="100%"
            justifyContent="space-around">
            <ToggleButtonGroup
              value={displayMode}
              exclusive
              onChange={(_, newMode) => {
                if (newMode !== null) {
                  setDisplayMode(newMode);
                }
              }}
              sx={{ height: "36.5px" }}>
              <Tooltip
                title={
                  <Stack direction="row" gap={1}>
                    Timeline View
                    <KeyIcon keyName="Q" />
                  </Stack>
                }>
                <ToggleButton color="primary" value="timeline">
                  <TimelineIcon />
                </ToggleButton>
              </Tooltip>
              <Tooltip
                title={
                  <Stack direction="row" gap={1}>
                    Timeslot View
                    <KeyIcon keyName="W" />
                  </Stack>
                }>
                <ToggleButton color="primary" value="timeslot">
                  <TimeslotIcon />
                </ToggleButton>
              </Tooltip>
            </ToggleButtonGroup>
            <ButtonGroup variant="outlined" sx={{ width: "100%" }}>
              <Tooltip
                title={
                  <Stack direction="row" gap={1}>
                    Go to previous day
                    <KeyIcon keyName="ArrowLeft" />
                  </Stack>
                }>
                <Button onClick={goToPreviousDay} sx={{ padding: 0 }}>
                  <ChevronLeft />
                </Button>
              </Tooltip>
              <Tooltip
                title={
                  <Stack direction="row" gap={1}>
                    Go to today
                    <KeyIcon keyName="T" />
                  </Stack>
                }>
                <Button
                  onClick={goToToday}
                  sx={{ padding: 0 }}
                  disabled={isToday}>
                  <Today />
                </Button>
              </Tooltip>
              <Button
                onClick={() => setShowCalendar((c) => !c)}
                sx={{ width: "100%" }}>
                {formattedDate}
              </Button>
              <Tooltip
                title={
                  <Stack direction="row" gap={1}>
                    Go to next day
                    <KeyIcon keyName="ArrowRight" />
                  </Stack>
                }>
                <Button
                  onClick={goToNextDay}
                  sx={{ padding: 0, width: "10px" }}>
                  <ChevronRight />
                </Button>
              </Tooltip>
            </ButtonGroup>

            <Stack direction="row" gap={0}>
              <ButtonGroup variant="outlined">
                <Tooltip
                  title={
                    <Stack direction="row" gap={1}>
                      Undo
                      <Stack direction="row" gap={0}>
                        <KeyIcon keyName="Control" />
                        <KeyIcon keyName="Z" />
                      </Stack>
                    </Stack>
                  }>
                  <span>
                    <Button
                      disabled={totalUndoSteps === 0}
                      onClick={performUndo}>
                      <Undo sx={{ marginRight: 1 }} /> {totalUndoSteps}
                    </Button>
                  </span>
                </Tooltip>
                <Tooltip
                  title={
                    <Stack direction="row" gap={1}>
                      Redo
                      <Stack direction="row" gap={0}>
                        <KeyIcon keyName="Control" />
                        <KeyIcon keyName="Shift" />
                        <KeyIcon keyName="Z" />
                      </Stack>
                    </Stack>
                  }>
                  <span>
                    <Button
                      disabled={totalRedoSteps === 0}
                      onClick={performRedo}>
                      {totalRedoSteps}
                      <Redo sx={{ marginLeft: 1 }} />
                    </Button>
                  </span>
                </Tooltip>
              </ButtonGroup>
            </Stack>

            <Tooltip
              title={
                <Stack direction="row" gap={1}>
                  Toggle notes mode
                  <KeyIcon keyName="N" />
                </Stack>
              }>
              <ToggleButton
                value="check"
                selected={showNotesMode}
                color="primary"
                onClick={() => setShowNotesMode(!showNotesMode)}
                sx={{
                  padding: "4px 8px",
                  gap: 1,
                }}>
                <NoteAlt />
              </ToggleButton>
            </Tooltip>
          </Stack>
          {showCalendar && (
            <Stack direction="row" gap={2} justifyContent="center">
              <DatePickerCalendar
                $isSmallScreen={!isMediumScreen}
                showDoubleView={isMediumScreen}
                next2Label={null}
                prev2Label={null}
                onChange={(value) => {
                  if (value instanceof Date) {
                    setDate(format(value, "yyyy-MM-dd"));
                    setShowCalendar(false);
                  }
                }}
                value={new Date(date)}
              />
            </Stack>
          )}
          {/* Scale selector */}
          {displayMode === "timeline" && (
            <Stack direction="row" gap={3} alignItems="center">
              <ZoomIn color="primary" />
              <Slider
                marks
                size="medium"
                value={pxPerHr}
                onChange={(_, newValue) => {
                  setPxPerHr(newValue as number);
                }}
                min={10}
                max={400}
                step={10}
                valueLabelDisplay="auto"
                valueLabelFormat={(value) => `${value}px`}
              />

              <Tooltip title="Change timezone">
                <Button
                  variant="outlined"
                  onClick={() => setTimezoneSelectorOpen(true)}>
                  <img
                    src={timezone_icon}
                    alt="Timezone"
                    style={{ width: "25px", height: "25px", marginRight: 10 }}
                  />
                  {currentTimezone}
                </Button>
              </Tooltip>
              <Tooltip title="Show location history">
                <ToggleButton
                  value="check"
                  selected={showLocationHistory}
                  color="primary"
                  onClick={() => setShowLocationHistory(!showLocationHistory)}
                  sx={{
                    padding: "4px 8px",
                    gap: 1,
                  }}>
                  <LocationHistoryIcon
                    style={{
                      width: "25px",
                      height: "25px",
                      filter: showLocationHistory
                        ? "opacity(1)"
                        : "opacity(0.5)",
                    }}
                  />
                </ToggleButton>
              </Tooltip>
            </Stack>
          )}
        </Stack>
      </ControlPanel>

      {/* Timeslots */}
      <Stack
        className="scroll-container"
        sx={{
          height: "calc(100vh - 200px)",
          overflowY: "auto",
          overflowX: "hidden",
        }}>
        {dayBounds &&
          (displayMode === "timeline" ? (
            <TimelineView
              dayBounds={dayBounds}
              pxPerHr={pxPerHr}
              setPxPerHr={setPxPerHr}
              newTimeslotParams={newTimeslotParams}
              currentTimezone={currentTimezone}
              computerTimezone={computerTimezone}
              timeslots={timeslots}
              locationHistory={locationHistory}
              activityCategoryMap={activityCategoryMap}
              showLocationHistory={showLocationHistory}
              currentTimeMills={currentTimeMills}
              editingTimeslot={editingTimeslot}
              selectedTimeslot={selectedTimeslot}
              openAddTimeslot={openAddTimeslot}
              applyPlaceToTimeslots={applyPlaceToTimeslots}
              setEditingTimeslot={setEditingTimeslot}
              setNewTimeslotParams={setNewTimeslotParams}
              setSelectedTimeslot={setSelectedTimeslot}
              deselectAll={deselectAll}
              showNotesMode={showNotesMode}
              revertTimezone={() => {
                setCurrentTimezone(computerTimezone);
              }}
            />
          ) : (
            <TimeslotListView
              dayBounds={dayBounds}
              timeslots={timeslots || []}
              currentTimezone={currentTimezone}
              computerTimezone={computerTimezone}
              activityCategoryMap={activityCategoryMap}
              selectedTimeslot={selectedTimeslot}
              editingTimeslot={editingTimeslot}
              showNotesMode={showNotesMode}
              onTimeslotClick={(timeslot) => {
                setSelectedTimeslot(timeslot);
                setEditingTimeslot(null);
                setNewTimeslotParams(null);
              }}
              setEditingTimeslot={setEditingTimeslot}
              onBlankClick={(startMs, endMs) => {
                openAddTimeslot(startMs, endMs - startMs);
              }}
              revertTimezone={() => {
                setCurrentTimezone(computerTimezone);
              }}
              revertDisplayMode={() => {
                setDisplayMode("timeline");
              }}
            />
          ))}
      </Stack>

      {newTimeslotParams !== null ? (
        <NewTimeslotInfo
          newTimeslotParams={newTimeslotParams}
          cancel={cancelAddingTimeslot}
          addTimeslot={handleAddTimeslot}
          updateActivity={handleUpdateActivity}
        />
      ) : selectedTimeslot ? (
        <SelectedTimeslotInfo
          selectedTimeslot={selectedTimeslot}
          selectedTimeslotLotLon={selectedTimeslotLotLon}
          editingTimeslot={editingTimeslot}
          setEditingTimeslot={(editingTimeslot) => {
            setEditingTimeslot(editingTimeslot);
          }}
          onCancelEdit={() => {
            setEditingTimeslot(null);
          }}
          changeTimeslotDetails={(activityId, startMs, endMs, timezone) => {
            if (!selectedTimeslot) return;
            changeTimeslotActivityAndTime(
              selectedTimeslot,
              activityId,
              startMs,
              endMs,
              timezone
            );
          }}
          changeTimeslotPlace={(placeId) => {
            if (!selectedTimeslot) return;
            changeTimeslotPlace(selectedTimeslot, placeId);
          }}
          currentTimezone={currentTimezone}
        />
      ) : isToday ? (
        <SuggestedTimeslotInfo
          setAddingActivity={(activityId) => {
            setNewTimeslotParams({
              startMs: currentTimeMills,
              endMs: currentTimeMills + 60 * 60 * 1000,
              activityId,
            });
            setTimeout(() => {
              // Add scroll behavior here
              scrollToCurrentTime();
            }, 100);
          }}
        />
      ) : null}
    </Container>
  );
};
