import { Add, QuestionMark } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Fuse from "fuse.js";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { ICONS } from "../const/icons";
import { db, dbuuid } from "../database/db";
import { useActivityJoinCategory } from "../hooks/useActivityJoinCategory";
import { IconSelector } from "../page/data/components/IconSelector";
import { toTitleCase } from "../utils/string";

// Helper function to highlight matched text
const highlightMatch = (text: string, query: string) => {
  if (!query) return <span>{text}</span>;

  // Create a case-insensitive regex for the query
  const regex = new RegExp(
    `(${query.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")})`,
    "gi"
  );
  const parts = text.split(regex);

  return (
    <span>
      {parts.map((part, i) =>
        regex.test(part) ? <b key={i}>{part}</b> : <span key={i}>{part}</span>
      )}
    </span>
  );
};

interface ActivityOption {
  id: string;
  label: string;
  colour: string;
  icon?: string;
}

interface ActivitySelectorProps {
  preselectedActivityId?: string | null;
  onChange: (activityId: string) => void;
  showIcon?: boolean;
  autoFocus?: boolean;
  placeholder?: string;
  width?: number | string;
  disabled?: boolean;
}

export const ActivitySelector: React.FC<ActivitySelectorProps> = ({
  preselectedActivityId,
  onChange,
  showIcon = true,
  autoFocus = true,
  placeholder = "Select an activity",
  width = 300,
  disabled = false,
}) => {
  const { activityCategoryArray, activityCategoryMap } =
    useActivityJoinCategory();
  const [isLoading, setIsLoading] = useState(true);
  const [selectedActivity, setSelectedActivity] = useState<
    ActivityOption | undefined
  >(undefined);
  const [inputValue, setInputValue] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [newActivityName, setNewActivityName] = useState("");
  const [selectedCategoryId, setSelectedCategoryId] = useState<string>("");
  const [selectedIcon, setSelectedIcon] = useState<string>("");
  const [categories, setCategories] = useState<
    Array<{ id: string; name: string; colour: string }>
  >([]);
  const [formErrors, setFormErrors] = useState<{
    name?: string;
    category?: string;
    icon?: string;
  }>({});
  const inputRef = useRef<HTMLInputElement>(null);

  // Convert activityCategoryArray to options format
  const activityOptions = useMemo<ActivityOption[]>(() => {
    if (!activityCategoryArray) return [];
    return activityCategoryArray.map((a) => ({
      id: a.id,
      label: a.name,
      colour: a.colour,
      icon: a.icon,
    }));
  }, [activityCategoryArray]);

  // Set up fuzzy search with Fuse.js
  const fuse = useMemo(() => {
    return new Fuse(activityOptions, {
      keys: ["label"],
      threshold: 0.3,
      ignoreLocation: true,
    });
  }, [activityOptions]);

  // Custom filter function using Fuse.js
  const filterOptions = (
    options: ActivityOption[],
    { inputValue }: { inputValue: string }
  ) => {
    if (!inputValue) return options;

    const results = fuse.search(inputValue);
    const filteredOptions = results.map((result) => result.item);

    // Add a "Create new" option if there's input text
    if (inputValue.trim()) {
      // Always add the create option when there's input text
      return [
        ...filteredOptions,
        {
          id: "create-new-option",
          label: `Create "${inputValue}"`,
          colour: "#000000",
          isCreateOption: true,
        } as ActivityOption & { isCreateOption: boolean },
      ];
    }

    return filteredOptions;
  };

  // Load initial data
  useEffect(() => {
    if (activityOptions.length > 0) {
      setIsLoading(false);

      if (preselectedActivityId) {
        const preselected = activityOptions.find(
          (a) => a.id === preselectedActivityId
        );
        setSelectedActivity(preselected);
        if (preselected) {
          setInputValue(preselected.label);
        }
      }
    }
  }, [activityOptions, preselectedActivityId]);

  // Auto-focus and select text when component mounts
  useEffect(() => {
    if (autoFocus && inputRef.current) {
      // Small delay to ensure the input is ready
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
          inputRef.current.select();
        }
      }, 100);
    }
  }, [autoFocus]);

  // Load categories for the new activity dialog
  useEffect(() => {
    const loadCategories = async () => {
      const cats = await db.categories.toArray();
      setCategories(cats);
      if (cats.length > 0) {
        setSelectedCategoryId(cats[0].id);
      }
    };

    if (openDialog) {
      loadCategories();
    }
  }, [openDialog]);

  // Validate form and handle creating a new activity
  const handleCreateActivity = async () => {
    // Reset errors
    setFormErrors({});

    // Validate form
    const errors: { name?: string; category?: string; icon?: string } = {};

    if (!newActivityName.trim()) {
      errors.name = "Activity name is required";
    }

    if (!selectedCategoryId) {
      errors.category = "Category is required";
    }

    if (!selectedIcon) {
      errors.icon = "Please select an icon";
    }

    // Check if activity name already exists
    const existingActivity = activityOptions.find(
      (option) =>
        option.label.toLowerCase() === newActivityName.trim().toLowerCase()
    );

    if (existingActivity) {
      errors.name = "An activity with this name already exists";
    }

    // If there are errors, show them and stop
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    try {
      const newActivityId = dbuuid();
      await db.activities.add({
        id: newActivityId,
        name: newActivityName.trim(),
        categoryId: selectedCategoryId,
        icon: selectedIcon,
      });

      // Close dialog and select the new activity
      setOpenDialog(false);
      setFormErrors({});

      // Wait for the database to update and the hook to refresh
      setTimeout(() => {
        const newActivity = {
          id: newActivityId,
          label: newActivityName.trim(),
          colour:
            categories.find((c) => c.id === selectedCategoryId)?.colour ||
            "#000000",
          icon: selectedIcon,
        };
        setSelectedActivity(newActivity);
        setInputValue(newActivityName.trim());

        // Call onChange after setting local state
        onChange(newActivityId);
      }, 300);
    } catch (error) {
      console.error("Error creating activity:", error);
      setFormErrors({ name: "Error creating activity. Please try again." });
    }
  };

  // Reset form errors when dialog opens/closes
  useEffect(() => {
    if (!openDialog) {
      setFormErrors({});
    }
  }, [openDialog]);

  // Get the icon component for the selected activity
  const getSelectedIcon = () => {
    if (!showIcon) return null;

    if (!selectedActivity) return <QuestionMark color="disabled" />;
    const activityData = activityCategoryMap[selectedActivity.id];
    if (!activityData?.icon) return <QuestionMark color="disabled" />;

    const Icon = ICONS[activityData.icon];
    return Icon ? <Icon sx={{ color: selectedActivity.colour }} /> : null;
  };

  return (
    <>
      <Stack direction="row" spacing={1} alignItems="center" width={width}>
        {getSelectedIcon()}

        <Autocomplete
          options={activityOptions}
          size="small"
          sx={{ width: "100%" }}
          autoHighlight
          disableClearable
          loading={isLoading}
          disabled={disabled}
          value={selectedActivity}
          inputValue={inputValue}
          onInputChange={(_, newInputValue) => {
            setInputValue(newInputValue);
          }}
          filterOptions={filterOptions}
          noOptionsText={
            <Box
              sx={{ cursor: "pointer" }}
              onClick={() => {
                setNewActivityName(inputValue);
                setOpenDialog(true);
              }}>
              <Stack direction="row" spacing={1} alignItems="center">
                <Add fontSize="small" />
                <Typography>Create "{inputValue}"</Typography>
              </Stack>
            </Box>
          }
          isOptionEqualToValue={(option, value) => option?.id === value?.id}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              // Check if dropdown is open
              const textField = inputRef.current;
              const isDropdownOpen =
                textField && textField.getAttribute("aria-expanded") === "true";

              if (isDropdownOpen) {
                // If dropdown is open, let Autocomplete handle the selection
                event.stopPropagation();
                return;
              }
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              inputRef={inputRef}
              variant="standard"
              placeholder={placeholder}
              onFocus={(e) => {
                e.target.select();
              }}
            />
          )}
          renderOption={(props, option) => {
            // Special rendering for the "Create new" option
            if (option.id === "create-new-option") {
              return (
                <Box component="li" {...props} key={option.id}>
                  <Stack direction="row" gap={2} alignItems="center">
                    <Add fontSize="small" />
                    <Typography>{option.label}</Typography>
                  </Stack>
                </Box>
              );
            }

            if (!option?.id || !activityCategoryMap?.[option.id]) {
              return null;
            }

            const Icon = activityCategoryMap[option.id]?.icon
              ? ICONS[activityCategoryMap[option.id].icon]
              : null;

            return (
              <Box component="li" {...props} key={option.id}>
                <Stack direction="row" gap={2} alignItems="center">
                  {Icon && <Icon sx={{ color: option.colour }} />}
                  {highlightMatch(toTitleCase(option.label), inputValue)}
                </Stack>
              </Box>
            );
          }}
          onChange={(_, newValue) => {
            if (newValue?.id === "create-new-option") {
              // Handle the create new option
              setNewActivityName(inputValue);
              setOpenDialog(true);
            } else if (newValue?.id) {
              setSelectedActivity(newValue);
              onChange(newValue.id);
            }
          }}
        />
      </Stack>

      {/* Dialog for creating a new activity */}
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        maxWidth="sm"
        fullWidth
        onKeyDown={(event) => {
          if (event.key === "Enter" && !event.shiftKey) {
            // Enter should create the activity if the form is valid
            event.preventDefault();
            handleCreateActivity();
          } else if (event.key === "Escape") {
            // Escape should close the dialog
            setOpenDialog(false);
          }
        }}>
        <DialogTitle>Create New Activity</DialogTitle>
        <DialogContent>
          <Stack spacing={3} sx={{ mt: 1 }}>
            <TextField
              autoFocus
              label="Activity Name"
              fullWidth
              value={newActivityName}
              onChange={(e) => setNewActivityName(e.target.value)}
              onKeyDown={(e) => {
                // Prevent form submission on Enter in the text field
                if (e.key === "Enter") {
                  e.stopPropagation();
                }
              }}
              error={!!formErrors.name}
              helperText={formErrors.name}
            />

            <FormControl fullWidth error={!!formErrors.category}>
              <InputLabel>Category</InputLabel>
              <Select
                value={selectedCategoryId}
                label="Category"
                onChange={(e) => setSelectedCategoryId(e.target.value)}>
                {categories.map((category) => (
                  <MenuItem key={category.id} value={category.id}>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Box
                        sx={{
                          width: 16,
                          height: 16,
                          backgroundColor: category.colour,
                          borderRadius: "50%",
                        }}
                      />
                      {category.name}
                    </Stack>
                  </MenuItem>
                ))}
              </Select>
              {formErrors.category && (
                <Typography
                  color="error"
                  variant="caption"
                  sx={{ mt: 0.5, ml: 1.5 }}>
                  {formErrors.category}
                </Typography>
              )}
            </FormControl>

            <Box>
              <Typography variant="subtitle1" gutterBottom>
                Select Icon
              </Typography>
              <IconSelector
                selectedIcon={selectedIcon}
                onSelectIcon={setSelectedIcon}
                color={
                  categories.find((c) => c.id === selectedCategoryId)?.colour
                }
              />
              {formErrors.icon && (
                <Typography
                  color="error"
                  variant="caption"
                  sx={{ mt: 0.5, display: "block" }}>
                  {formErrors.icon}
                </Typography>
              )}
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={handleCreateActivity} variant="contained">
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ActivitySelector;
