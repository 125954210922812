import {
  Box,
  Button,
  Chip,
  Collapse,
  Container,
  FormControl,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useCloudSyncedDB } from "../hooks/useCloudSyncedDb";
import { useSettings } from "../hooks/useSettings";
import { useSnackbar } from "../hooks/useSnackbar";
import { useSyncing } from "../hooks/useSyncing";

import SyncIcon from "@mui/icons-material/Sync";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { CloudBackupChanges, TableType } from "../database/db";
import { JSONDisplay } from "./home/components/JsonDisplay";
import { isValidUrl } from "../utils/url";
import { setAppInitialised } from "../utils/db_helpers";
import { useModal } from "../hooks/useModal";
import { clearDatabase } from "../utils/db_helpers";
import { MATERIAL_COLOURS } from "../const/colours";
import { Warning } from "@mui/icons-material";
import {
  getLocationHistory,
  processAndInsertLocationHistory,
  updateLocationHistoryEndpoint,
} from "../utils/location_history_processing";
import { useNavigate } from "react-router-dom";

const errorMessage = (
  <>
    Invalid URL. Please check the endpoint and try again.
    <br />
    Endpoint was <strong>not</strong> saved.
  </>
);

const MODAL_CLEAR_DB_BODY = (
  <>
    <Typography variant="body1" gutterBottom>
      The server you are connecting to has existing data.
    </Typography>
    <Typography variant="body1" gutterBottom>
      Proceeding will clear your local database and import the data from the
      server.
    </Typography>
    <Typography variant="body1" gutterBottom fontWeight="bold" color="error">
      This action cannot be undone, make sure to back up your data before
      proceeding.
    </Typography>
    <Typography
      variant="body2"
      color="text.secondary"
      sx={{ fontStyle: "italic", mt: 2 }}>
      If you wish to keep your local data instead, please clear the data on the
      server's settings page before connecting.
    </Typography>
  </>
);

const MODAL_UPLOAD_DB_BODY = (
  <>
    <Typography variant="body1" gutterBottom>
      You are about to upload your local database to the server.
    </Typography>

    <Typography variant="body1" gutterBottom>
      This might take a while depending on the size of your database.
    </Typography>
  </>
);

// Add a component for expandable rows
const ExpandableTableRow = ({
  change,
  isExpanded,
  onToggleExpand,
}: {
  change: CloudBackupChanges;
  isExpanded: boolean;
  onToggleExpand: () => void;
}) => {
  return (
    <>
      <TableRow hover onClick={onToggleExpand} sx={{ cursor: "pointer" }}>
        <TableCell>
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              onToggleExpand();
            }}>
            {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{change.tableId}</TableCell>
        <TableCell>{change.table}</TableCell>
        <TableCell>{change.changeType}</TableCell>
        <TableCell>
          {new Date(change.timestampMills).toLocaleString()}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
          <Collapse in={isExpanded} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1, mb: 3 }}>
              <Typography variant="h6" gutterBottom component="div">
                Data
              </Typography>
              <JSONDisplay json={JSON.parse(change.data || "{}")} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export const CloudSettings = () => {
  const {
    showLocation,
    setShowLocation,
    locationHistoryEndpoint,
    setLocationHistoryEndpoint,

    cloudBackupEndpoint,
    cloudSyncEnabled,
    autoSyncLocationHistory,
    setAutoSyncLocationHistory,
    autoSyncBackups,
    syncBatchSize,
    setSyncBatchSize,
    setAutoSyncBackups,
    enableCloudSync,
    disableCloudSync,
    developerMode,
  } = useSettings();

  const { showSnackbar } = useSnackbar();
  const cloudSyncedDB = useCloudSyncedDB();
  const {
    processedChanges,
    totalChanges,
    getChanges,
    checkForChanges,
    getChangeCountForTable,
  } = cloudSyncedDB;

  const { isSyncing, isSyncingBackups, cloudBackupChanges, syncBackups } =
    useSyncing();

  const [tempCloudBackupEndpoint, setTempCloudBackupEndpoint] =
    useState(cloudBackupEndpoint);
  const [lastSyncTime, setLastSyncTime] = useState<string>("");
  const [pendingChanges, setPendingChanges] = useState<
    Record<TableType, number>
  >({
    timeslots: 0,
    activities: 0,
    categories: 0,
    places: 0,
    locationHistories: 0,
    journals: 0,
    journalFieldDefinitions: 0,
  });
  const [selectedTables, setSelectedTables] = useState<TableType[]>([]);
  const [pendingChangeDetails, setPendingChangeDetails] = useState<
    CloudBackupChanges[]
  >([]);
  const { showModal } = useModal();
  const [expandedRows, setExpandedRows] = useState<Record<number, boolean>>({});

  // Get the last sync time
  useEffect(() => {
    const getLastSyncTime = async () => {
      console.log("Getting last sync time");
      if (cloudSyncEnabled) {
        try {
          const result = await checkForChanges(cloudBackupEndpoint);
          if (result && result.latest_change_timestamp) {
            setLastSyncTime(
              new Date(result.latest_change_timestamp).toLocaleString()
            );
          }
          setLastSyncTime(new Date().toLocaleString());
        } catch (error) {
          console.error("Error getting last sync time:", error);
        }
      }
    };

    getLastSyncTime();
  }, [cloudSyncEnabled, cloudBackupEndpoint, checkForChanges]);

  // Get pending changes breakdown
  useEffect(() => {
    const getPendingChangesBreakdown = async () => {
      if (cloudSyncEnabled && cloudBackupChanges && cloudBackupChanges > 0) {
        try {
          const breakdown: Record<TableType, number> = {
            timeslots: await getChangeCountForTable("timeslots"),
            activities: await getChangeCountForTable("activities"),
            categories: await getChangeCountForTable("categories"),
            places: await getChangeCountForTable("places"),
            locationHistories: await getChangeCountForTable(
              "locationHistories"
            ),
            journals: await getChangeCountForTable("journals"),
            journalFieldDefinitions: await getChangeCountForTable(
              "journalFieldDefinitions"
            ),
          };

          setPendingChanges(breakdown);

          // If developer mode is enabled and tables are selected, get the details
          if (developerMode && selectedTables.length > 0) {
            // Still limited to 200 changes for performance reasons
            const changes = await getChanges(200);
            const filteredChanges = changes.filter((change) =>
              selectedTables.includes(change.table)
            );
            setPendingChangeDetails(filteredChanges);
          }
        } catch (error) {
          console.error("Error getting pending changes breakdown:", error);
        }
      } else {
        setPendingChanges({
          timeslots: 0,
          activities: 0,
          categories: 0,
          places: 0,
          locationHistories: 0,
          journals: 0,
          journalFieldDefinitions: 0,
        });
        setPendingChangeDetails([]);
      }
    };

    getPendingChangesBreakdown();
  }, [
    cloudSyncEnabled,
    cloudBackupChanges,
    developerMode,
    selectedTables,
    getChanges,
    getChangeCountForTable,
  ]);

  const [tempLocationHistoryEndpoint, setTempLocationHistoryEndpoint] =
    useState(locationHistoryEndpoint);

  const testAndSaveLocationHistoryEndpoint = async () => {
    const locationHistory = await getLocationHistory(
      tempLocationHistoryEndpoint
    );
    if (locationHistory) {
      const success = await processAndInsertLocationHistory(
        cloudSyncedDB,
        locationHistory
      );
      if (success) {
        updateLocationHistoryEndpoint(
          tempLocationHistoryEndpoint,
          locationHistory
        );
        setLocationHistoryEndpoint(tempLocationHistoryEndpoint);
        showSnackbar(
          <>
            Endpoint verified and saved.
            <br />
            {success.insertedCount} locations inserted, {success.skippedCount}{" "}
            locations skipped.
          </>,
          "success"
        );
      } else {
        showSnackbar(errorMessage, "error");
      }
    } else {
      showSnackbar(errorMessage, "error");
    }
  };

  const disconnectLocationHistory = () => {
    setLocationHistoryEndpoint("");
    setTempLocationHistoryEndpoint("");
    showSnackbar("Location history disconnected.", "success");
  };

  const toggleRowExpand = (id: number) => {
    setExpandedRows((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const handleTableFilterChange = (
    event: SelectChangeEvent<typeof selectedTables>
  ) => {
    const {
      target: { value },
    } = event;

    setSelectedTables(
      typeof value === "string"
        ? (value.split(",") as TableType[])
        : (value as TableType[])
    );
  };

  const handleDeleteTableFilter = (tableToDelete: TableType) => {
    setSelectedTables(
      selectedTables.filter((table) => table !== tableToDelete)
    );
  };

  const handleSyncNow = async () => {
    await syncBackups();
    showSnackbar("Sync initiated", "success");
  };

  const registerCloudBackup = async (endpoint: string): Promise<string> => {
    try {
      const response = await fetch(`${endpoint}/device`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        showSnackbar("Error registering cloud backup endpoint.", "error");
        return "";
      }

      const data = await response.json();
      return data.sync_key;
    } catch (error) {
      console.error("Error registering cloud backup endpoint:", error);
      return "";
    }
  };

  const testAndSaveCloudBackupEndpoint = async () => {
    if (isValidUrl(tempCloudBackupEndpoint)) {
      const syncKey = await registerCloudBackup(tempCloudBackupEndpoint);

      console.log("Sync key:", syncKey);
      if (syncKey) {
        const result = await cloudSyncedDB.checkForChanges(
          tempCloudBackupEndpoint,
          syncKey
        );
        if (result === false) {
          showSnackbar("Cloud backup endpoint not valid.", "error");
        }
        if (result && result.new_changes > 0) {
          const confirmed = await showModal(
            "Server has data",
            MODAL_CLEAR_DB_BODY,
            {
              icon: <Warning />,
              cancelText: "Do not import",
              okText: "Delete and import",
              colour: MATERIAL_COLOURS.red,
            }
          );
          if (confirmed) {
            await clearDatabase();
            setAppInitialised(true);
            // Download changes
            enableCloudSync(tempCloudBackupEndpoint, syncKey);
            showSnackbar("Downloading changes...", "info");
          }
        } else {
          const confirmed = await showModal(
            "Initialize cloud backup",
            MODAL_UPLOAD_DB_BODY,
            {
              icon: <Warning />,
              cancelText: "Do not connect",
              okText: "Connect",
              colour: MATERIAL_COLOURS.green,
            }
          );
          if (confirmed) {
            showSnackbar("Initializing cloud backup...", "info");
            enableCloudSync(tempCloudBackupEndpoint, syncKey);
            await cloudSyncedDB.initialiseCloudBackup();
            showSnackbar(
              "Cloud backup endpoint verified and saved.",
              "success"
            );
          }
        }
      } else {
        showSnackbar("Cloud backup endpoint not valid.", "error");
      }
    } else {
      showSnackbar(errorMessage, "error");
    }
  };

  const disconnectCloudBackup = async () => {
    disableCloudSync();
    cloudSyncedDB.emptyCloudBackupChanges();
    setTempCloudBackupEndpoint("");
    showSnackbar("Cloud backup disconnected.", "success");
  };

  const navigate = useNavigate();
  return (
    <Container>
      <Stack direction="row" spacing={2} alignItems="center" sx={{ mb: 2 }}>
        <Button
          variant="outlined"
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate("/settings")}>
          Back to Settings
        </Button>
        <Typography variant="h1">Cloud Settings</Typography>
      </Stack>

      <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="space-between"
          alignItems="center">
          <Typography variant="body1">Location Tracking</Typography>
          <Switch
            checked={showLocation}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setShowLocation(e.target.checked)
            }
          />
        </Stack>

        {showLocation &&
          (locationHistoryEndpoint ? (
            <>
              <hr />
              <Stack
                direction="row"
                spacing={2}
                justifyContent="space-between"
                alignItems="center">
                <Typography variant="body1">
                  Location History Endpoint
                </Typography>
                <TextField
                  sx={{ width: "100%" }}
                  placeholder="https://location-history.dumbertime.com/ABCDE"
                  variant="standard"
                  disabled
                  value={locationHistoryEndpoint}
                />
                <Button
                  variant="contained"
                  color="error"
                  onClick={disconnectLocationHistory}>
                  Disconnect
                </Button>
              </Stack>

              <Stack
                direction="row"
                spacing={2}
                sx={{ mt: 2 }}
                justifyContent="space-between"
                alignItems="center">
                <Typography variant="body1">Auto Sync</Typography>
                {autoSyncLocationHistory ? (
                  <Typography variant="body2" color="text.secondary">
                    Location history is synced every 5 minutes.
                  </Typography>
                ) : (
                  <Typography variant="body2" color="text.secondary">
                    To sync location history, please click on the cloud sync
                    option in the hamburger menu.
                  </Typography>
                )}
                <Switch
                  checked={autoSyncLocationHistory}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setAutoSyncLocationHistory(e.target.checked)
                  }
                />
              </Stack>
            </>
          ) : (
            <>
              <hr />
              <Stack
                direction="row"
                spacing={2}
                justifyContent="space-between"
                alignItems="center">
                <Typography variant="body1">
                  Location History Endpoint
                </Typography>
                <TextField
                  sx={{ width: "100%" }}
                  placeholder="https://location-history.dumbertime.com/ABCDE"
                  variant="standard"
                  value={tempLocationHistoryEndpoint}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setTempLocationHistoryEndpoint(e.target.value)
                  }
                />
                <Button
                  variant="contained"
                  onClick={testAndSaveLocationHistoryEndpoint}>
                  Test and Save
                </Button>
              </Stack>
            </>
          ))}
      </Paper>

      <Paper elevation={3} sx={{ p: 3, mt: 3, mb: 3 }}>
        <Typography variant="h5" gutterBottom>
          Cloud Backup Configuration
        </Typography>

        <Stack
          direction="row"
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
          sx={{ mb: 2 }}>
          <Typography variant="body1">Cloud Backup Endpoint</Typography>

          {cloudSyncEnabled ? (
            <>
              <TextField
                sx={{ width: "100%" }}
                placeholder="https://cloud-backup.dumbertime.com/ABCDE"
                variant="standard"
                disabled
                value={tempCloudBackupEndpoint}
              />
              <Button
                variant="contained"
                color="error"
                onClick={disconnectCloudBackup}>
                Disconnect
              </Button>
            </>
          ) : (
            <>
              <TextField
                sx={{ width: "100%" }}
                placeholder="https://cloud-backup.dumbertime.com/ABCDE"
                variant="standard"
                value={tempCloudBackupEndpoint}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setTempCloudBackupEndpoint(e.target.value)
                }
              />
              <Button
                variant="contained"
                onClick={testAndSaveCloudBackupEndpoint}>
                Test and Save
              </Button>
            </>
          )}
        </Stack>

        {cloudSyncEnabled && (
          <>
            <Stack
              direction="row"
              spacing={2}
              justifyContent="space-between"
              alignItems="center"
              sx={{ mb: 2 }}>
              <Typography variant="body1">Auto Sync</Typography>
              {autoSyncBackups ? (
                <Typography variant="body2" color="text.secondary">
                  Backup is checked every 5 minutes.
                </Typography>
              ) : (
                <Typography variant="body2" color="text.secondary">
                  To backup, please use the Sync Now button.
                </Typography>
              )}
              <Switch
                checked={autoSyncBackups}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setAutoSyncBackups(e.target.checked)
                }
              />
            </Stack>

            {developerMode && (
              <Stack
                direction="row"
                spacing={2}
                justifyContent="space-between"
                alignItems="center"
                sx={{ mb: 2 }}>
                <Typography variant="body1">Sync Batch Size</Typography>
                <TextField
                  sx={{ width: "100%" }}
                  value={syncBatchSize}
                  type="number"
                  inputProps={{
                    min: 2,
                    max: 100000,
                  }}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setSyncBatchSize(parseInt(e.target.value))
                  }
                />
              </Stack>
            )}
          </>
        )}
      </Paper>

      {cloudSyncEnabled && (
        <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
          <Typography variant="h5" gutterBottom>
            Sync Status
          </Typography>

          <Stack spacing={2}>
            <Box>
              <Typography variant="body1" fontWeight="bold">
                Last Sync:
              </Typography>
              <Typography variant="body2">
                {lastSyncTime || "Never synced"}
              </Typography>
            </Box>

            <Box>
              <Typography variant="body1" fontWeight="bold">
                Pending Changes: {cloudBackupChanges || 0}
              </Typography>

              {Object.values(pendingChanges).some((count) => count > 0) && (
                <TableContainer component={Paper} sx={{ mt: 2 }}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Table</TableCell>
                        <TableCell align="right">Changes</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.entries(pendingChanges).map(
                        ([table, count]) =>
                          count > 0 && (
                            <TableRow key={table}>
                              <TableCell component="th" scope="row">
                                {table}
                              </TableCell>
                              <TableCell align="right">{count}</TableCell>
                            </TableRow>
                          )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Box>

            {processedChanges !== totalChanges && totalChanges > 0 && (
              <Box>
                <Typography variant="body2" color="error">
                  Warning: Cloud backup initialisation in progress. Please wait
                  until it's complete.
                </Typography>
                <LinearProgress
                  variant="determinate"
                  value={(processedChanges / totalChanges) * 100}
                  sx={{ mt: 1 }}
                />
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Progress: {processedChanges} / {totalChanges}
                </Typography>
              </Box>
            )}

            <Button
              variant="contained"
              color="primary"
              startIcon={<SyncIcon />}
              onClick={handleSyncNow}
              disabled={isSyncing}
              sx={{ mt: 2 }}>
              {isSyncingBackups ? "Syncing..." : "Sync Now"}
            </Button>
          </Stack>
        </Paper>
      )}

      {cloudSyncEnabled && developerMode && (
        <Paper elevation={3} sx={{ p: 3 }}>
          <Typography variant="h5" gutterBottom>
            Developer Details
          </Typography>

          <FormControl sx={{ mb: 2, width: "100%" }}>
            <InputLabel id="table-filter-label">Filter Tables</InputLabel>
            <Select
              labelId="table-filter-label"
              id="table-filter"
              multiple
              value={selectedTables}
              onChange={handleTableFilterChange}
              input={<OutlinedInput label="Filter Tables" />}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip
                      key={value}
                      label={value}
                      onDelete={() => handleDeleteTableFilter(value)}
                      onMouseDown={(event) => {
                        event.stopPropagation();
                      }}
                    />
                  ))}
                </Box>
              )}>
              {[
                "timeslots",
                "activities",
                "categories",
                "places",
                "locationHistories",
                "journals",
              ].map((table) => (
                <MenuItem key={table} value={table}>
                  {table}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
            Note: For performance reasons, only up to 200 changes are displayed
            in the developer view. Click on a row to view the data.
          </Typography>

          {pendingChangeDetails.length > 0 ? (
            <TableContainer component={Paper} sx={{ maxHeight: 400 }}>
              <Table stickyHeader size="small">
                <TableHead>
                  <TableRow>
                    <TableCell width="50px"></TableCell>
                    <TableCell>ID</TableCell>
                    <TableCell>Table</TableCell>
                    <TableCell>Change Type</TableCell>
                    <TableCell>Timestamp</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pendingChangeDetails.map((change) => (
                    <ExpandableTableRow
                      key={change.id}
                      change={change}
                      isExpanded={!!expandedRows[change.id]}
                      onToggleExpand={() => toggleRowExpand(change.id)}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography variant="body2" color="text.secondary">
              No pending changes for the selected tables.
            </Typography>
          )}
        </Paper>
      )}
    </Container>
  );
};
