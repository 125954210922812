import styled from "@emotion/styled";
import { useMediaQuery, Tooltip, Typography, Button, Box } from "@mui/material";
import { format, parse, getDaysInMonth, addDays } from "date-fns";
import { useLiveQuery } from "dexie-react-hooks";
import React, { useEffect, useMemo, useState } from "react";
import Calendar, { OnArgs } from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useActivityJoinCategory } from "../../../hooks/useActivityJoinCategory";
import theme from "../../../theme/theme";
import {
  bucketTimeslotsByDay,
  CategorySegmentMap,
  getAllTimeslotsInAMonth,
  getDayCategorySegments,
} from "../../../utils/stats";
import StatsCalendarCell from "./StatsCalendarCell";
import { db } from "../../../database/db";
import timezone_icon from "../../../assets/icons/timezone.svg";
import { TimezoneSelectorModal } from "../../../components/TimezoneSelectorModal";
import { detectTimezone, getComputerTimezone } from "../../../utils/time";
import { TZDate } from "@date-fns/tz";
import { KeyIcon } from "../../../components/KeyIcon";
import { Stack } from "@mui/material";

const FullWidthCalendar = styled(Calendar)<{ isXS: boolean }>`
  width: 100%;
  max-width: none;

  .react-calendar__month-view__days__day {
    height: ${(props) => (props.isXS ? "180px" : "120px")};
    position: relative;
    padding: 0;
    border: 1px solid #e0e0e0;
  }

  .react-calendar__tile > abbr {
    position: absolute;
    top: 7px;
    left: 7px;
    font-size: 14px;
  }

  .react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
  }

  .react-calendar__tile {
    padding: 5px;
  }

  /* Disable hover and focus styles */
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background-color: inherit;
  }

  /* Remove the active style */
  .react-calendar__tile--active {
    background: inherit;
    color: inherit;
  }

  /* Fix for blank year view */
  .react-calendar__year-view .react-calendar__tile {
    height: auto;
    padding: 10px;
  }

  /* Ensure year view tiles are visible */
  .react-calendar__year-view .react-calendar__tile abbr {
    position: static;
  }
`;

const KeyboardShortcutInfo = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
`;

const StatsCalendar: React.FC = () => {
  const { activityCategoryMap } = useActivityJoinCategory();

  const [monthStats, setMonthStats] = useState<{
    [date: string]: CategorySegmentMap;
  }>({});
  const [daysWithNotes, setDaysWithNotes] = useState<Set<string>>(new Set());
  const [dayNotesMap, setDayNotesMap] = useState<{
    [date: string]: string[];
  }>({});
  const [daysWithJournals, setDaysWithJournals] = useState<Set<string>>(
    new Set()
  );
  const [dayJournalMap, setDayJournalMap] = useState<{
    [date: string]: string;
  }>({});
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  // Add timezone state
  const [currentTimezone, setCurrentTimezone] = useState(getComputerTimezone());
  const [timezoneSelectorOpen, setTimezoneSelectorOpen] = useState(false);
  const computerTimezone = useMemo(() => getComputerTimezone(), []);
  const [detectedTimezone, setDetectedTimezone] = useState(
    getComputerTimezone()
  );

  const viewDate = useMemo(() => {
    const yearMonth = searchParams.get("yearMonth");
    if (!yearMonth) {
      const currentDate = new Date();
      return currentDate;
    }
    return parse(yearMonth, "yyyy-MM", new Date());
  }, [searchParams]);

  useEffect(() => {
    let yearMonth = searchParams.get("yearMonth");
    if (!yearMonth) {
      // If yearMonth is not in the URL, add it
      const currentDate = new Date();
      yearMonth = format(currentDate, "yyyy-MM");
      setSearchParams({ yearMonth }, { replace: true });
    }
  }, [viewDate, searchParams, setSearchParams]);

  const timeslots = useLiveQuery(() => {
    return getAllTimeslotsInAMonth(
      viewDate.getMonth() + 1,
      viewDate.getFullYear(),
      currentTimezone
    );
  }, [viewDate, currentTimezone]);

  // Query journal entries for the current month
  const journalEntries = useLiveQuery(async () => {
    const year = viewDate.getFullYear();
    const month = viewDate.getMonth() + 1;

    // Create date objects with timezone consideration
    const startOfMonth = new TZDate(
      year,
      month - 1,
      1,
      0,
      0,
      0,
      0,
      currentTimezone
    );
    const lastDay = getDaysInMonth(
      new TZDate(year, month - 1, 1, 0, 0, 0, 0, currentTimezone)
    );

    // Format dates as strings for database query
    const startDateStr = format(startOfMonth, "yyyy-MM-dd");
    const endDateStr = format(
      new TZDate(year, month - 1, lastDay, 0, 0, 0, 0, currentTimezone),
      "yyyy-MM-dd"
    );

    return await db.journals
      .where("date")
      .between(startDateStr, endDateStr, true, true)
      .toArray();
  }, [viewDate, currentTimezone]);

  useEffect(() => {
    if (!journalEntries) return;

    const newDaysWithJournals = new Set<string>();
    const newDayJournalMap: { [date: string]: string } = {};

    journalEntries.forEach((entry) => {
      newDaysWithJournals.add(entry.date);
      newDayJournalMap[entry.date] = entry.entry;
    });

    setDaysWithJournals(newDaysWithJournals);
    setDayJournalMap(newDayJournalMap);
  }, [journalEntries]);

  useEffect(() => {
    if (!timeslots || !activityCategoryMap) return;

    // Detect timezone from timeslots
    if (timeslots.length > 0) {
      const detected = detectTimezone(timeslots);
      setDetectedTimezone(detected);
    }

    const bucketedTimeslots = bucketTimeslotsByDay(
      timeslots,
      viewDate.getMonth() + 1,
      viewDate.getFullYear(),
      currentTimezone
    );

    const newMonthStats: { [date: string]: CategorySegmentMap } = {};
    const newDaysWithNotes = new Set<string>();
    const newDayNotesMap: { [date: string]: string[] } = {};

    bucketedTimeslots.forEach((dayStats) => {
      const categorySegments = getDayCategorySegments(
        dayStats.timeslots,
        new Date(dayStats.date),
        activityCategoryMap,
        currentTimezone
      );
      newMonthStats[dayStats.date] = categorySegments;

      // Collect all notes for this day
      const dayNotes: string[] = [];
      dayStats.timeslots.forEach((timeslot) => {
        if (timeslot.notes && timeslot.notes.trim() !== "") {
          const activity = activityCategoryMap[timeslot.activityId];
          const activityName = activity ? activity.name : "Unknown";

          // Format time using the selected timezone
          const startTime = new TZDate(
            new Date(timeslot.startTimestampMills),
            currentTimezone
          );
          const formattedTime = format(startTime, "HH:mm");

          dayNotes.push(
            `${formattedTime} - ${activityName}: ${timeslot.notes.trim()}`
          );
        }
      });

      if (dayNotes.length > 0) {
        newDaysWithNotes.add(dayStats.date);
        newDayNotesMap[dayStats.date] = dayNotes;
      }
    });

    setMonthStats(newMonthStats);
    setDaysWithNotes(newDaysWithNotes);
    setDayNotesMap(newDayNotesMap);
  }, [timeslots, activityCategoryMap, viewDate, currentTimezone]);

  // Add keyboard navigation for left/right arrows
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "ArrowLeft") {
        // Navigate to previous month
        const newDate = new Date(viewDate);
        newDate.setMonth(newDate.getMonth() - 1);
        setSearchParams({ yearMonth: format(newDate, "yyyy-MM") });
      } else if (event.key === "ArrowRight") {
        // Navigate to next month
        const newDate = new Date(viewDate);
        newDate.setMonth(newDate.getMonth() + 1);
        setSearchParams({ yearMonth: format(newDate, "yyyy-MM") });
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [viewDate, setSearchParams]);

  const navigateToJournal = (dateKey: string, event: React.MouseEvent) => {
    event.stopPropagation(); // Prevent the calendar cell click from triggering
    navigate(`/journal/${dateKey}`);
  };

  const tileContent = ({ date, view }: { date: Date; view: string }) => {
    if (view !== "month") return null;

    const dateKey = format(date, "yyyy-MM-dd");
    const dailyStats = monthStats[dateKey];
    const hasNotes = daysWithNotes.has(dateKey);
    const dayNotes = dayNotesMap[dateKey] || [];
    const hasJournal = daysWithJournals.has(dateKey);
    const journalEntry = dayJournalMap[dateKey] || "";

    if (!dailyStats && !hasNotes && !hasJournal) return null;

    return (
      <StatsCalendarCell
        segments={dailyStats ? Object.values(dailyStats) : []}
        percentUnknown={
          dailyStats
            ? 100 -
              Object.values(dailyStats).reduce(
                (sum, segment) => sum + segment.percentage,
                0
              )
            : 100
        }
        hasNotes={hasNotes}
        dayNotes={dayNotes}
        hasJournal={hasJournal}
        journalEntry={journalEntry}
        dateKey={dateKey}
        onJournalClick={navigateToJournal}
      />
    );
  };

  const handleActiveStartDateChange = ({
    activeStartDate,
  }: {
    activeStartDate: Date;
  }) => {
    if (activeStartDate) {
      const newYearMonth = format(activeStartDate, "yyyy-MM");
      setSearchParams({ yearMonth: newYearMonth });
    }
  };

  const isXS = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mb: 2,
          alignItems: "center",
        }}>
        <KeyboardShortcutInfo>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="body2">Navigate months:</Typography>
            <KeyIcon keyName="ArrowLeft" />
            <KeyIcon keyName="ArrowRight" />
          </Stack>
        </KeyboardShortcutInfo>

        <Tooltip title="Change timezone">
          <Button
            variant="outlined"
            onClick={() => setTimezoneSelectorOpen(true)}>
            <Box
              component="img"
              src={timezone_icon}
              alt="Timezone"
              sx={{ width: 24, height: 24, mr: 1 }}
            />
            {currentTimezone}
          </Button>
        </Tooltip>
      </Box>

      <FullWidthCalendar
        isXS={isXS}
        next2Label={null}
        prev2Label={null}
        tileContent={tileContent}
        onActiveStartDateChange={({ activeStartDate }: OnArgs) => {
          if (activeStartDate) {
            handleActiveStartDateChange({ activeStartDate });
          }
        }}
        activeStartDate={viewDate}
        selectRange={false}
        onClickDay={(value: Date) => {
          const formattedDate = format(value, "yyyy-MM-dd");
          navigate(`/home/${formattedDate}/timeline`);
        }}
        minDate={new Date("2000-01-01")}
        maxDate={addDays(new Date(), 365)}
      />

      <TimezoneSelectorModal
        open={timezoneSelectorOpen}
        onClose={() => setTimezoneSelectorOpen(false)}
        currentTimezone={currentTimezone}
        detectedTimezone={detectedTimezone}
        computerTimezone={computerTimezone}
        onTimezoneSelect={(timezone) => setCurrentTimezone(timezone)}
      />
    </>
  );
};

export default StatsCalendar;
