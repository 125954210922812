import { Add, Archive, Delete } from "@mui/icons-material";
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useLiveQuery } from "dexie-react-hooks";
import { useState } from "react";
import { db, JournalFieldDefinition } from "../../../database/db";
import { useCloudSyncedDB } from "../../../hooks/useCloudSyncedDb";
import { useSnackbar } from "../../../hooks/useSnackbar";

export const JournalSettings = () => {
  const { showSnackbar } = useSnackbar();
  const cloudSyncedDB = useCloudSyncedDB();

  // State for the new field form
  const [newFieldIdentifier, setNewFieldIdentifier] = useState("");
  const [newFieldType, setNewFieldType] = useState<
    "text" | "checkbox" | "number"
  >("text");
  const [identifierError, setIdentifierError] = useState("");

  // State for the delete confirmation dialog
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [archiveDialogOpen, setArchiveDialogOpen] = useState(false);
  const [selectedField, setSelectedField] =
    useState<JournalFieldDefinition | null>(null);

  // Fetch all field definitions
  const fieldDefinitions = useLiveQuery(
    () => db.journalFieldDefinitions.orderBy("order").toArray(),
    []
  );

  // Validate the identifier
  const validateIdentifier = (identifier: string) => {
    if (!identifier) {
      setIdentifierError("Field identifier cannot be empty");
      return false;
    }

    if (!/^[a-z0-9_]+$/.test(identifier)) {
      setIdentifierError(
        "Field identifier can only contain lowercase letters, numbers, and underscores"
      );
      return false;
    }

    const isDuplicate = fieldDefinitions?.some(
      (field) => field.identifier === identifier
    );

    if (isDuplicate) {
      setIdentifierError("Field identifier already exists");
      return false;
    }

    setIdentifierError("");
    return true;
  };

  // Handle adding a new field
  const handleAddField = async () => {
    if (!validateIdentifier(newFieldIdentifier)) {
      return;
    }

    try {
      const nextOrder = fieldDefinitions?.length
        ? Math.max(...fieldDefinitions.map((f) => f.order)) + 1
        : 0;

      await cloudSyncedDB.addJournalFieldDefinition({
        identifier: newFieldIdentifier,
        type: newFieldType,
        order: nextOrder,
        archivedAt: 0,
      });

      setNewFieldIdentifier("");
      setNewFieldType("text");
      showSnackbar("Field added successfully", "success");
    } catch (error) {
      console.error("Error adding field:", error);
      showSnackbar("Failed to add field", "error");
    }
  };

  // Handle deleting a field
  const handleDeleteField = async () => {
    if (!selectedField) return;

    try {
      // First, find all journal entries that have this field
      const journalEntries = await db.journals.toArray();
      const entriesWithField = journalEntries.filter(
        (entry) =>
          entry.userData &&
          JSON.parse(entry.userData)[selectedField.identifier] !== undefined
      );

      // Update each journal entry to remove the field
      for (const entry of entriesWithField) {
        if (!entry.userData) continue;
        const updatedFields = { ...JSON.parse(entry.userData) };
        delete updatedFields[selectedField.identifier];

        await cloudSyncedDB.updateJournalEntry(entry.id, {
          userData: JSON.stringify(updatedFields),
        });
      }

      // Then delete the field definition
      await cloudSyncedDB.deleteJournalFieldDefinition(selectedField.id);

      setDeleteDialogOpen(false);
      setSelectedField(null);
      showSnackbar(
        `Field deleted successfully from ${entriesWithField.length} entries`,
        "success"
      );
    } catch (error) {
      console.error("Error deleting field:", error);
      showSnackbar("Failed to delete field", "error");
    }
  };

  // Handle archiving a field
  const handleArchiveField = async () => {
    if (!selectedField) return;

    try {
      await cloudSyncedDB.updateJournalFieldDefinition(selectedField.id, {
        archivedAt: Date.now(),
      });
      setArchiveDialogOpen(false);
      setSelectedField(null);
      showSnackbar("Field archived successfully", "success");
    } catch (error) {
      console.error("Error archiving field:", error);
      showSnackbar("Failed to archive field", "error");
    }
  };

  // Handle unarchiving a field
  const handleUnarchiveField = async (field: JournalFieldDefinition) => {
    try {
      await cloudSyncedDB.updateJournalFieldDefinition(field.id, {
        archivedAt: 0,
      });
      showSnackbar("Field unarchived successfully", "success");
    } catch (error) {
      console.error("Error unarchiving field:", error);
      showSnackbar("Failed to unarchive field", "error");
    }
  };

  // Format identifier to title case for display
  const formatIdentifier = (identifier: string) => {
    return identifier
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  return (
    <Container maxWidth="md">
      <Typography variant="h4" component="h1" gutterBottom>
        Journal Field Settings
      </Typography>

      <Paper elevation={2} sx={{ p: 3, mb: 4 }}>
        <Typography variant="h6" gutterBottom>
          Add New Field
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
          Fields can only contain lowercase letters, numbers, and underscores.
          They will be displayed in title case.
        </Typography>
        <Typography variant="body2" color="error" sx={{ mb: 2 }}>
          Warning: You cannot edit a field's identifier or type after creation.
        </Typography>

        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={2}
          alignItems="flex-start"
        >
          <TextField
            label="Field Identifier"
            value={newFieldIdentifier}
            onChange={(e) => {
              setNewFieldIdentifier(e.target.value);
              validateIdentifier(e.target.value);
            }}
            error={!!identifierError}
            helperText={
              identifierError || "Example: 'mood' will display as 'Mood'"
            }
            fullWidth
            sx={{ mb: 2 }}
          />

          <FormControl sx={{ minWidth: 150, mb: 2 }}>
            <Typography variant="body2" gutterBottom>
              Field Type
            </Typography>
            <RadioGroup
              row
              value={newFieldType}
              onChange={(e) =>
                setNewFieldType(
                  e.target.value as "text" | "checkbox" | "number"
                )
              }
            >
              <FormControlLabel value="text" control={<Radio />} label="Text" />
              <FormControlLabel
                value="checkbox"
                control={<Radio />}
                label="Checkbox"
              />
              <FormControlLabel
                value="number"
                control={<Radio />}
                label="Number"
              />
            </RadioGroup>
          </FormControl>

          <Button
            variant="contained"
            onClick={handleAddField}
            startIcon={<Add />}
            disabled={!newFieldIdentifier || !!identifierError}
            sx={{ mt: { xs: 0, sm: 3 } }}
          >
            Add Field
          </Button>
        </Stack>
      </Paper>

      <Paper elevation={2} sx={{ p: 3 }}>
        <Typography variant="h6" gutterBottom>
          Manage Fields
        </Typography>

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Field Name</TableCell>
                <TableCell>Identifier</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fieldDefinitions?.length === 0 && (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    No fields defined yet
                  </TableCell>
                </TableRow>
              )}
              {fieldDefinitions?.map((field) => (
                <TableRow key={field.id}>
                  <TableCell>{formatIdentifier(field.identifier)}</TableCell>
                  <TableCell>{field.identifier}</TableCell>
                  <TableCell>{field.type}</TableCell>
                  <TableCell>
                    {field.archivedAt > 0 ? "Archived" : "Active"}
                  </TableCell>
                  <TableCell>
                    {field.archivedAt > 0 ? (
                      <Button
                        size="small"
                        onClick={() => handleUnarchiveField(field)}
                      >
                        Unarchive
                      </Button>
                    ) : (
                      <>
                        <IconButton
                          color="warning"
                          onClick={() => {
                            setSelectedField(field);
                            setArchiveDialogOpen(true);
                          }}
                          size="small"
                        >
                          <Archive fontSize="small" />
                        </IconButton>
                        <IconButton
                          color="error"
                          onClick={() => {
                            setSelectedField(field);
                            setDeleteDialogOpen(true);
                          }}
                          size="small"
                        >
                          <Delete fontSize="small" />
                        </IconButton>
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>Delete Field</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Would you like to archive the field "
            {selectedField ? formatIdentifier(selectedField.identifier) : ""}"?
            instead of deleting it? Archiving will hide the field from new
            entries but preserve existing data. Deleting will permanently remove
            all data associated with this field from all journal entries.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button
            onClick={() => {
              setDeleteDialogOpen(false);
              setArchiveDialogOpen(true);
            }}
            color="primary"
          >
            Archive
          </Button>
          <Button onClick={handleDeleteField} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Archive Confirmation Dialog */}
      <Dialog
        open={archiveDialogOpen}
        onClose={() => setArchiveDialogOpen(false)}
      >
        <DialogTitle>Archive Field</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to archive the field "
            {selectedField ? formatIdentifier(selectedField.identifier) : ""}"
            This will hide the field from new entries but preserve existing
            data.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setArchiveDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleArchiveField} color="primary">
            Archive
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};
