import { addDays, addHours, format, getDaysInMonth, isSameDay } from "date-fns";

import { db, Timeslot } from "../database/db";
import { ActivityJoinCategory } from "../database/helpers";
import { TZDate } from "@date-fns/tz";

export const getAllTimeslotsInAMonth = async (
  month: number,
  year: number,
  timezone?: string
) => {
  // Ensure month is an integer within 1 - 12, and year is an integer after 2000
  if (month < 1 || month > 12 || year < 2000) {
    throw new Error("Invalid month or year");
  }

  // Create date objects with timezone consideration
  let startOfMonth, endOfMonth;

  if (timezone) {
    // First day of month at 00:00:00 in the specified timezone
    startOfMonth = new TZDate(year, month - 1, 1, 0, 0, 0, 0, timezone);
    // First day of next month at 00:00:00 in the specified timezone
    endOfMonth = new TZDate(year, month, 1, 0, 0, 0, 0, timezone);
  } else {
    // Use local timezone if none specified
    startOfMonth = new Date(year, month - 1, 1, 0, 0, 0, 0);
    endOfMonth = new Date(year, month, 1, 0, 0, 0, 0);
  }

  // Add buffer to ensure we get all timeslots that overlap with the month
  const startTime = addHours(startOfMonth, -24).getTime();
  const endTime = addHours(endOfMonth, 24).getTime();

  const timeslots = await db.timeslots
    .where("startTimestampMills")
    .between(startTime, endTime)
    .or("endTimestampMills")
    .between(startTime, endTime)
    .sortBy("startTimestampMills");

  return timeslots;
};

// Gets the timeslots for each day in a month, and the total duration of the timeslots for each day
export const bucketTimeslotsByDay = (
  timeslots: Timeslot[],
  month: number,
  year: number,
  timezone?: string
): {
  date: string;
  timeslots: Timeslot[];
  totalDuration: number;
  unknownDuration: number;
}[] => {
  const daysInMonth = getDaysInMonth(new Date(year, month - 1, 1));

  const daysStats = Array.from({ length: daysInMonth }, (_, i) => {
    const date = timezone
      ? new TZDate(year, month - 1, i + 1, 0, 0, 0, 0, timezone)
      : new Date(year, month - 1, i + 1);
    const dateKey = format(date, "yyyy-MM-dd");
    const timeslotsForDay = timeslots.filter((timeslot) => {
      const startDate = timezone
        ? new TZDate(new Date(timeslot.startTimestampMills), timezone)
        : new Date(timeslot.startTimestampMills);
      const endDate = timezone
        ? new TZDate(new Date(timeslot.endTimestampMills), timezone)
        : new Date(timeslot.endTimestampMills);
      return isSameDay(startDate, date) || isSameDay(endDate, date);
    });

    const totalDuration = timeslotsForDay.reduce((total, timeslot) => {
      const dayStart = timezone
        ? new TZDate(date, timezone).setHours(0, 0, 0, 0)
        : new Date(date).setHours(0, 0, 0, 0);
      const dayEnd = timezone
        ? new TZDate(addDays(dayStart, 1), timezone).setHours(0, 0, 0, 0)
        : addDays(dayStart, 1).setHours(0, 0, 0, 0);
      const start = Math.max(timeslot.startTimestampMills, dayStart);
      const end = Math.min(timeslot.endTimestampMills, dayEnd);
      return total + Math.max(0, end - start);
    }, 0);

    const unknownDuration = 24 * 60 * 60 * 1000 - totalDuration;

    return {
      date: dateKey,
      timeslots: timeslotsForDay,
      totalDuration: totalDuration,
      unknownDuration: unknownDuration,
    };
  });
  return daysStats;
};

export type CategorySegment = {
  category: {
    id: string;
    name: string;
    colour: string;
  };
  minutes: number;
  percentage: number;
};

export type CategorySegmentMap = Record<string, CategorySegment>;

// Meant to be used for a single day, after it's been processed by bucketTimeslotsByDay
export const getDayCategorySegments = (
  dayTimeslots: Timeslot[], // Timeslots for a single day
  date: Date, // The date of the timeslots
  activityMap: Record<string, ActivityJoinCategory>, // Map of activities to their join categories
  timezone?: string // Optional timezone parameter
): CategorySegmentMap => {
  const dayStart = timezone
    ? new TZDate(date, timezone).setHours(0, 0, 0, 0)
    : new Date(date).setHours(0, 0, 0, 0);
  const dayEnd = timezone
    ? new TZDate(addDays(dayStart, 1), timezone).setHours(0, 0, 0, 0)
    : addDays(dayStart, 1).setHours(0, 0, 0, 0);

  const categorySegments: CategorySegmentMap = {};
  // Calculate the total durations for each category for the day
  dayTimeslots.forEach((timeslot) => {
    const activity = activityMap[timeslot.activityId];
    const categoryId = activity?.categoryId;

    const clampedStart = Math.max(timeslot.startTimestampMills, dayStart);
    const clampedEnd = Math.min(timeslot.endTimestampMills, dayEnd);
    const duration = clampedEnd - clampedStart;
    const durationMinutes = duration / 60000;

    if (categoryId) {
      if (!categorySegments[categoryId]) {
        categorySegments[categoryId] = {
          category: {
            id: categoryId,
            name: activity?.category || "",
            colour: activity?.colour || "",
          },
          minutes: 0,
          percentage: 0,
        };
      }
      categorySegments[categoryId].minutes += durationMinutes;
    }
  });

  // Calculate the percentage of the day that each category occupies
  const totalMinutes = 24 * 60;
  Object.entries(categorySegments).forEach(([_, segment]) => {
    const minutes = segment.minutes;
    segment.percentage = (minutes / totalMinutes) * 100;
  });

  return categorySegments;
};
