import { DarkMode, LightMode, WbTwilightOutlined } from "@mui/icons-material";
import { Box, Stack, Tooltip, Typography } from "@mui/material";
import { useMemo } from "react";
import { Timeslot } from "../../../database/db";
import { useActivityJoinCategory } from "../../../hooks/useActivityJoinCategory";
import { DayTimeslotsRow } from "./DayTimeslotsRow";

interface DayTimeslotsSummaryProps {
  date: string;
  currentTimezone: string;
  sortedTimeslots: Timeslot[];
  handleSelectTimeslot?: (timeslot: Timeslot | null) => void;
  selectedTimeslot?: Timeslot | null;
}
export const DayTimeslotsSummary = ({
  date,
  currentTimezone,
  sortedTimeslots,
  handleSelectTimeslot,
  selectedTimeslot,
}: DayTimeslotsSummaryProps) => {
  const { activityCategoryMap } = useActivityJoinCategory();
  const isReadonly = !handleSelectTimeslot;
  // Split timeslots into before noon and after noon
  const { beforeNoonTimeslots, afterNoonTimeslots, eveningTimeslots } =
    useMemo(() => {
      // Get the noon timestamp for the current date
      const dateObj = new Date(date);
      dateObj.setHours(12, 0, 0, 0);
      const noonTimestamp = dateObj.getTime();
      const eveningTimestamp = dateObj.setHours(18, 0, 0, 0);

      const beforeNoon: Timeslot[] = [];
      const afterNoon: Timeslot[] = [];
      const evening: Timeslot[] = [];

      sortedTimeslots.forEach((timeslot) => {
        // If the timeslot starts before noon, put it in the before noon array
        // This includes timeslots that cross noon
        if (timeslot.startTimestampMills < noonTimestamp) {
          beforeNoon.push(timeslot);
        } else if (
          timeslot.startTimestampMills >= noonTimestamp &&
          timeslot.startTimestampMills < eveningTimestamp
        ) {
          afterNoon.push(timeslot);
        } else {
          evening.push(timeslot);
        }
      });

      return {
        beforeNoonTimeslots: beforeNoon,
        afterNoonTimeslots: afterNoon,
        eveningTimeslots: evening,
      };
    }, [sortedTimeslots, date]);

  return (
    <Stack direction="column" spacing={2} sx={{ p: 2, flexGrow: 1 }}>
      <Typography variant="h6" gutterBottom alignSelf="flex-start">
        Timeslots on {date} ({currentTimezone})
      </Typography>

      {sortedTimeslots.length === 0 ? (
        <Typography variant="body1" color="text.secondary">
          Nothing recorded for this day.
        </Typography>
      ) : (
        <>
          <DayTimeslotsRow
            rowTimeslots={beforeNoonTimeslots}
            label={
              <Tooltip title="Morning">
                <WbTwilightOutlined sx={{ fontSize: "1.5rem" }} />
              </Tooltip>
            }
            activityCategoryMap={activityCategoryMap}
            onSelectTimeslot={handleSelectTimeslot}
            selectedTimeslotId={selectedTimeslot?.id}
          />
          <DayTimeslotsRow
            rowTimeslots={afterNoonTimeslots}
            label={
              <Tooltip title="Afternoon">
                <LightMode sx={{ fontSize: "1.5rem" }} />
              </Tooltip>
            }
            activityCategoryMap={activityCategoryMap}
            onSelectTimeslot={handleSelectTimeslot}
            selectedTimeslotId={selectedTimeslot?.id}
          />
          <DayTimeslotsRow
            rowTimeslots={eveningTimeslots}
            label={
              <Tooltip title="Evening">
                <DarkMode sx={{ fontSize: "1.5rem" }} />
              </Tooltip>
            }
            activityCategoryMap={activityCategoryMap}
            onSelectTimeslot={handleSelectTimeslot}
            selectedTimeslotId={selectedTimeslot?.id}
          />

          {/* Legend for timeslot indicators */}
          {!isReadonly && (
            <Box sx={{ mt: 2, display: "flex", alignItems: "center", gap: 1 }}>
              <Box
                sx={{
                  width: 20,
                  height: 20,
                  border: "2px dashed black",
                  borderRadius: "4px",
                  display: "inline-block",
                }}
              />
              <Typography variant="caption" color="text.secondary">
                Activity has notes
              </Typography>
              <Box
                sx={{
                  width: 20,
                  height: 20,
                  outline: "2px solid rgba(25, 118, 210, 0.8)",
                  borderRadius: "4px",
                  display: "inline-block",
                  ml: 2,
                }}
              />
              <Typography variant="caption" color="text.secondary">
                Selected activity
              </Typography>
            </Box>
          )}
        </>
      )}
    </Stack>
  );
};
