import { Button, Container, Stack, Typography } from "@mui/material";
import { ActivityComponent } from "./components/ActivityComponent";
import { CategoryComponent } from "./components/CategoryComponent";
import { PlacesComponent } from "./components/PlacesComponent";
import { ImportExport as ImportExportIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { Category, db } from "../../database/db";
import { useState, useRef, useEffect } from "react";
import { fetchStorageInfo, getStorageInfoString } from "../../utils/storage";
import { useLiveQuery } from "dexie-react-hooks";

export const Data = () => {
  const [storageInfo, setStorageInfo] = useState<{
    quota: number;
    usage: number;
    usageDetails: { indexedDB: number };
  } | null>(null);

  const navigate = useNavigate();
  const activityComponentRef = useRef<HTMLDivElement>(null);

  const [selectedCategory, setSelectedCategory] = useState<Category | null>(
    null
  );

  const handleCategoryClick = (category: Category | null) => {
    setSelectedCategory(category);
  };

  useEffect(() => {
    if (selectedCategory && activityComponentRef.current) {
      activityComponentRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [selectedCategory]);

  // Get total timeslot count
  const totalTimeslotCount = useLiveQuery(async () => {
    return await db.timeslots.count();
  });

  // Get total location history count
  const totalLocationHistoryCount = useLiveQuery(async () => {
    return await db.locationHistories.count();
  });

  const totalCategoryCount = useLiveQuery(async () => {
    return await db.categories.count();
  });

  const totalActivityCount = useLiveQuery(async () => {
    return await db.activities.count();
  });

  const totalPlaceCount = useLiveQuery(async () => {
    return await db.places.count();
  });

  const totalJournalCount = useLiveQuery(async () => {
    return await db.journals.count();
  });

  useEffect(() => {
    const fetchData = async () => {
      const info = await fetchStorageInfo();
      if (info) {
        setStorageInfo(info);
      }
    };

    fetchData();
  }, []);

  return (
    <Container sx={{ paddingY: "50px" }}>
      <Typography variant="h1">Data Page</Typography>

      <CategoryComponent setSelectedCategory={handleCategoryClick} />
      <div ref={activityComponentRef}>
        <ActivityComponent selectedCategory={selectedCategory} />
      </div>
      <hr style={{ margin: "50px 0" }} />

      <a id="places" />
      <PlacesComponent />

      <hr style={{ margin: "50px 0" }} />

      <Stack direction="column" spacing={0} gap={0.5} marginTop={3}>
        {storageInfo && (
          <Typography>{getStorageInfoString(storageInfo)}</Typography>
        )}
        {totalCategoryCount !== undefined && (
          <Typography variant="body1">
            Total number of categories: {totalCategoryCount}
          </Typography>
        )}
        {totalActivityCount !== undefined && (
          <Typography variant="body1">
            Total number of activities: {totalActivityCount}
          </Typography>
        )}

        {totalPlaceCount !== undefined && (
          <Typography variant="body1">
            Total number of places: {totalPlaceCount}
          </Typography>
        )}

        {totalTimeslotCount !== undefined && (
          <Typography variant="body1">
            Total number of timeslots: {totalTimeslotCount}
          </Typography>
        )}
        {totalLocationHistoryCount !== undefined && (
          <Typography variant="body1">
            Total number of location histories: {totalLocationHistoryCount}
          </Typography>
        )}
        {totalJournalCount !== undefined && (
          <Typography variant="body1">
            Total number of journals: {totalJournalCount}
          </Typography>
        )}
      </Stack>

      <hr style={{ margin: "50px 0" }} />

      <Typography variant="h2">Import / Export</Typography>
      <Button
        startIcon={<ImportExportIcon />}
        variant="text"
        onClick={() => {
          navigate("/importexport");
        }}>
        Go to Import / Export
      </Button>
    </Container>
  );
};
