import React, { useCallback, useEffect, useState } from "react";
import { Activity, Category, db, Timeslot } from "../../../database/db";
import PercentageGraph from "./PercentageGraph";
import { format } from "date-fns";
import {
  Button,
  ButtonGroup,
  Paper,
  Stack,
  Tooltip,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { ChevronLeft, ChevronRight, Today } from "@mui/icons-material";
import { DatePickerCalendar } from "../../shared/DatePickerCalendar";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

type Tab = "today" | "last7days" | "last30days";

const TimeAnalysis: React.FC = () => {
  const [activeTab, setActiveTab] = useState<Tab>("today");

  const [categoryData, setCategoryData] = useState<
    { category: Category; minutes: number; totalTime: string }[]
  >([]);
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [dayRange, setDayRange] = useState<number>(1);
  const [customMode, setCustomMode] = useState<boolean>(false);
  const [showCalendar, setShowCalendar] = useState<boolean>(false);

  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.up("md"));

  const loadData = useCallback(async () => {
    const now = customMode ? new Date(endDate) : new Date();
    let startDate: Date;
    let totalPeriodMinutes: number;

    if (customMode) {
      // Custom date range calculation
      startDate = new Date(now);
      startDate.setDate(now.getDate() - (dayRange - 1));
      startDate.setHours(0, 0, 0, 0);
      now.setHours(23, 59, 59, 999);
      totalPeriodMinutes = dayRange * 24 * 60;
    } else {
      // Standard tab-based calculation
      switch (activeTab) {
        case "today":
          startDate = new Date(now);
          startDate.setHours(0, 0, 0, 0);
          totalPeriodMinutes = 24 * 60;
          break;
        case "last7days":
          startDate = new Date(now);
          startDate.setDate(now.getDate() - 6); // 7 days including today
          startDate.setHours(0, 0, 0, 0);
          totalPeriodMinutes = 7 * 24 * 60;
          break;
        case "last30days":
          startDate = new Date(now);
          startDate.setDate(now.getDate() - 29); // 30 days including today
          startDate.setHours(0, 0, 0, 0);
          totalPeriodMinutes = 30 * 24 * 60;
          break;
      }
      // Make sure end date is set to end of day
      now.setHours(23, 59, 59, 999);
    }

    // Debug logging
    console.log("Date range:", {
      startDate: startDate.toISOString(),
      endDate: now.toISOString(),
      customMode,
      activeTab,
      dayRange,
    });

    const loadedTimeslots = await db.timeslots
      .where("startTimestampMills")
      .aboveOrEqual(startDate.getTime())
      .and((timeslot) => timeslot.startTimestampMills <= now.getTime())
      .toArray();

    console.log("Loaded timeslots:", loadedTimeslots.length);

    const activities = await db.activities.toArray();
    const activityMapTemp = new Map(activities.map((a) => [a.id, a]));

    const categories = await db.categories.toArray();
    const categoryMapTemp = new Map(categories.map((c) => [c.id, c]));

    calculateCategoryData(
      loadedTimeslots,
      activityMapTemp,
      categoryMapTemp,
      totalPeriodMinutes
    );
  }, [activeTab, customMode, endDate, dayRange]);

  useEffect(() => {
    loadData();
  }, [activeTab, loadData, customMode, endDate, dayRange]);

  const calculateCategoryData = (
    timeslots: Timeslot[],
    activityMap: Map<string, Activity>,
    categoryMap: Map<string, Category>,
    totalPeriodMinutes: number
  ) => {
    const categoryMinutes = new Map<string, number>();
    let loggedMinutes = 0;

    timeslots.forEach((timeslot) => {
      const duration =
        (timeslot.endTimestampMills - timeslot.startTimestampMills) / 60000;
      loggedMinutes += duration;

      const activity = activityMap.get(timeslot.activityId);
      if (activity) {
        const categoryId = activity.categoryId;
        categoryMinutes.set(
          categoryId,
          (categoryMinutes.get(categoryId) || 0) + duration
        );
      }
    });

    const unknownMinutes = totalPeriodMinutes - loggedMinutes;

    const data = Array.from(categoryMinutes.entries()).map(
      ([categoryId, minutes]) => ({
        category: categoryMap.get(categoryId)!,
        minutes: Math.round(minutes),
        totalTime: "0m",
      })
    );

    data.push({
      category: { id: "", name: "Unknown", colour: "#CCCCCC" },
      minutes: Math.round(unknownMinutes),
      totalTime: "0m",
    });

    setCategoryData(data);
  };

  const handleTabClick = (tab: Tab) => {
    setCustomMode(false);
    setActiveTab(tab);

    // Update the end date to today
    setEndDate(new Date());

    // We don't need to set dayRange here as it's only used in custom mode
    // The loadData function will calculate the correct date range based on activeTab
  };

  const handleRangeChange = (
    _: React.MouseEvent<HTMLElement>,
    newRange: number | null
  ) => {
    if (newRange !== null) {
      setDayRange(newRange);
      setCustomMode(true);
    }
  };

  const handleDateChange = (date: Date | null) => {
    if (date) {
      setEndDate(date);
      setCustomMode(true);
      setShowCalendar(false);
    }
  };

  // Format date for display
  const formattedDate = format(endDate, "dd MMM yyyy");

  // Navigation functions
  const goToPreviousDay = () => {
    const newDate = new Date(endDate);
    newDate.setDate(newDate.getDate() - 1);
    setEndDate(newDate);
    setCustomMode(true);
  };

  const goToToday = () => {
    setEndDate(new Date());
    setCustomMode(true);
    setShowCalendar(false);
  };

  const goToNextDay = () => {
    const newDate = new Date(endDate);
    newDate.setDate(newDate.getDate() + 1);
    setEndDate(newDate);
    setCustomMode(true);
  };

  const isToday = endDate.toDateString() === new Date().toDateString();

  return (
    <div>
      <Paper sx={{ p: 2, mb: 2 }}>
        <Stack direction="column" spacing={2}>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={2}
            justifyContent="space-between"
            alignItems="center">
            <ButtonGroup variant="outlined" sx={{ flexGrow: 1 }}>
              <Tooltip title="Previous day">
                <Button onClick={goToPreviousDay} sx={{ padding: 0 }}>
                  <ChevronLeft />
                </Button>
              </Tooltip>
              <Tooltip title="Go to today">
                <Button
                  onClick={goToToday}
                  sx={{ padding: 0 }}
                  disabled={isToday}>
                  <Today />
                </Button>
              </Tooltip>
              <Button
                onClick={() => setShowCalendar((prev) => !prev)}
                sx={{ width: "100%" }}>
                {formattedDate}
              </Button>
              <Tooltip title="Next day">
                <Button onClick={goToNextDay} sx={{ padding: 0 }}>
                  <ChevronRight />
                </Button>
              </Tooltip>
            </ButtonGroup>

            <Stack direction="row" spacing={2} alignItems="center">
              <div>
                <Button
                  variant={
                    !customMode && activeTab === "today"
                      ? "contained"
                      : "outlined"
                  }
                  onClick={() => handleTabClick("today")}>
                  Today
                </Button>
                <Button
                  variant={
                    !customMode && activeTab === "last7days"
                      ? "contained"
                      : "outlined"
                  }
                  onClick={() => handleTabClick("last7days")}>
                  Last 7 Days
                </Button>
                <Button
                  variant={
                    !customMode && activeTab === "last30days"
                      ? "contained"
                      : "outlined"
                  }
                  onClick={() => handleTabClick("last30days")}>
                  Last 30 Days
                </Button>
              </div>
            </Stack>
          </Stack>

          {showCalendar && (
            <Stack direction="row" justifyContent="center">
              <DatePickerCalendar
                $isSmallScreen={!isMediumScreen}
                showDoubleView={isMediumScreen}
                next2Label={null}
                prev2Label={null}
                onChange={(value) => {
                  if (value instanceof Date) {
                    handleDateChange(value);
                  }
                }}
                value={endDate}
              />
            </Stack>
          )}

          <Stack direction="row" spacing={2} justifyContent="center">
            <ToggleButtonGroup
              value={dayRange}
              exclusive
              onChange={handleRangeChange}
              aria-label="date range">
              <ToggleButton value={1} aria-label="1 day">
                1 day
              </ToggleButton>
              <ToggleButton value={7} aria-label="7 days">
                7 days
              </ToggleButton>
              <ToggleButton value={14} aria-label="14 days">
                14 days
              </ToggleButton>
              <ToggleButton value={28} aria-label="28 days">
                28 days
              </ToggleButton>
              <ToggleButton value={90} aria-label="90 days">
                90 days
              </ToggleButton>
            </ToggleButtonGroup>
          </Stack>
        </Stack>
      </Paper>

      <h2>
        {customMode
          ? `${dayRange} ${
              dayRange === 1 ? "day" : "days"
            } ending ${endDate.toLocaleDateString()}`
          : activeTab === "today"
          ? "Today"
          : activeTab === "last7days"
          ? "Last 7 Days"
          : "Last 30 Days"}
      </h2>
      <PercentageGraph data={categoryData} />
    </div>
  );
};

export default TimeAnalysis;
