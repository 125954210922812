import {
  Stack,
  Typography,
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { Timeslot } from "../../../database/db";
import { format } from "date-fns";
import { useMemo } from "react";
import { timeDisplay } from "../../../utils/time";
import { useActivityJoinCategory } from "../../../hooks/useActivityJoinCategory";

export const DayTimeslotNotesSummary = ({
  sortedTimeslots,
}: {
  sortedTimeslots: Timeslot[];
}) => {
  // Get all notes from timeslots
  const { activityCategoryMap } = useActivityJoinCategory();
  const timeslotNotes = useMemo(() => {
    return sortedTimeslots
      .filter((timeslot) => timeslot.notes && timeslot.notes.trim() !== "")
      .map((timeslot) => {
        const startTime = format(
          new Date(timeslot.startTimestampMills),
          "HH:mm"
        );
        const activity = activityCategoryMap[timeslot.activityId];
        const minutes =
          (timeslot.endTimestampMills - timeslot.startTimestampMills) /
          1000 /
          60;

        return {
          time: `${startTime} - ${timeDisplay(minutes)}`,
          notes: timeslot.notes || "",
          activityName: activity ? activity.name : "Unknown Activity",
        };
      });
  }, [sortedTimeslots, activityCategoryMap]);
  return (
    <Stack
      direction="column"
      spacing={2}
      sx={{
        width: {
          xs: "100%",
          md: "50%",
        },
      }}>
      <Typography variant="h6" gutterBottom>
        Timeslot Notes {timeslotNotes.length > 0 && `(${timeslotNotes.length})`}
      </Typography>

      {timeslotNotes.length > 0 ? (
        <TableContainer
          component={Paper}
          variant="outlined"
          sx={{ bgcolor: "background.default", p: 2 }}>
          <Table size="small">
            <TableBody>
              {timeslotNotes.map((note, index) => (
                <TableRow key={index}>
                  <TableCell
                    sx={{
                      verticalAlign: "top",
                      border: "none",
                      p: 0,
                      pr: 2,
                      pb: 1,
                      whiteSpace: "nowrap",
                    }}>
                    {note.time}
                  </TableCell>
                  <TableCell
                    sx={{
                      width: "80%",
                      textAlign: "left",
                      whiteSpace: "pre-wrap",
                      border: "none",
                      p: 0,
                      pb: 1,
                    }}>
                    <Typography variant="body2" component="div">
                      {note.notes}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography variant="body2" color="text.secondary" fontStyle="italic">
          No notes for this day.
        </Typography>
      )}
    </Stack>
  );
};
