import { Edit, HelpOutlineOutlined, QuestionMark } from "@mui/icons-material";
import LocationOffIcon from "@mui/icons-material/LocationOff";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import HistoryIcon from "@mui/icons-material/History";
import {
  Button,
  Divider,
  getLuminance,
  Paper,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { darken, styled } from "@mui/material/styles";
import { format, formatDuration, intervalToDuration } from "date-fns";
import { useLiveQuery } from "dexie-react-hooks";
import React, { useEffect, useRef, useState } from "react";
import { ICONS } from "../../../const/icons";
import { db, Place, Timeslot } from "../../../database/db";
import { ActivityJoinCategory } from "../../../database/helpers";
import { useActivityJoinCategory } from "../../../hooks/useActivityJoinCategory";
import ChangePlace from "../../shared/ChangePlace";
import ChangeTimeslot from "../../shared/ChangeTimeslot";
import { useSettings } from "../../../hooks/useSettings";
import { JSONDisplay } from "./JsonDisplay";
import { DEV_COLOUR } from "../../../const/colours";
import { useRoundToSnap } from "../../../hooks/useRoundToSnap";
import { TZDate } from "@date-fns/tz";

const StyledPaper = styled(Paper)<{ small?: boolean }>`
  padding: 1rem;
  position: sticky;
  bottom: 10px;
  left: 0;
  width: 100%;
  z-index: 1000;

  background-color: #ffffffee;

  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
`;

interface EditButtonProps {
  children: React.ReactNode;
  onClick?: () => void;
  color?: string;
}

const EditButton: React.FC<EditButtonProps> = ({
  children,
  onClick,
  color,
}) => {
  const [isOverflowing, setIsOverflowing] = useState(false);
  const textRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    const checkOverflow = () => {
      if (textRef.current) {
        setIsOverflowing(
          textRef.current.scrollHeight > textRef.current.clientHeight ||
            textRef.current.scrollWidth > textRef.current.clientWidth
        );
      }
    };

    checkOverflow();
    window.addEventListener("resize", checkOverflow);
    return () => window.removeEventListener("resize", checkOverflow);
  }, [children]);

  const isLightColor = (color: string) => {
    const luminance = getLuminance(color);
    return luminance > 0.5;
  };

  const getContrastColor = (bgColor: string) => {
    return isLightColor(bgColor) ? darken(bgColor, 0.5) : darken(bgColor, 0.2);
  };

  const buttonContent = (
    <Button
      onClick={onClick}
      sx={{
        position: "relative",
        flexGrow: 1,
        height: "100%",
        justifyContent: "flex-start",
        textAlign: "left",
        textTransform: "none",
        color: color ? getContrastColor(color) : "inherit",
        "&:hover": {
          color: color
            ? isLightColor(color)
              ? darken(color, 0.4)
              : darken(color, 0.1)
            : "inherit",
        },
        "&:hover .edit-icon": {
          opacity: 1,
        },
      }}
      aria-label="Edit">
      <Typography
        variant="h6"
        component="span"
        ref={textRef}
        sx={{
          flexGrow: 1,
          display: "-webkit-box",
          WebkitLineClamp: 1,
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
          textOverflow: "ellipsis",
          textTransform: "capitalize",
        }}>
        {children}
      </Typography>
      <Edit
        className="edit-icon"
        sx={{
          opacity: 0,
          transition: "opacity 0.3s",
          marginLeft: 1,
        }}
      />
    </Button>
  );

  return isOverflowing ? (
    <Tooltip title={children} arrow placement="top">
      {buttonContent}
    </Tooltip>
  ) : (
    buttonContent
  );
};

interface ActivitySectionProps {
  timeslotId: string;
  activityId: string;
  activityCategoryMap: Record<string, ActivityJoinCategory>;
  startTimestampMills: number;
  endTimestampMills: number;
  editingTimeslot: string | null;
  currentTimezone: string;
  timeslotTimezone: string;
  setEditingTimeslot: (editingTimeslot: string | null) => void;
  onCancelEdit: () => void;
  onChangeTimeslotDetails: (
    activityId: string,
    startMs: number,
    endMs: number,
    timezone: string
  ) => void;
}

const ActivitySection: React.FC<ActivitySectionProps> = ({
  timeslotId,
  activityId,
  activityCategoryMap,
  currentTimezone,
  timeslotTimezone,
  startTimestampMills,
  endTimestampMills,
  editingTimeslot,
  setEditingTimeslot,
  onCancelEdit,
  onChangeTimeslotDetails,
}) => {
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (editingTimeslot) setIsEditing(true);
    else setIsEditing(false);
  }, [editingTimeslot]);

  const handleEditClick = () => {
    setEditingTimeslot(timeslotId);
  };

  const [startMs, setStartMs] = useState(startTimestampMills);
  const [endMs, setEndMs] = useState(endTimestampMills);
  const [timezone, setTimezone] = useState(timeslotTimezone);
  useEffect(() => {
    setStartMs(startTimestampMills);
    setEndMs(endTimestampMills);
    setTimezone(timeslotTimezone);
  }, [startTimestampMills, endTimestampMills, timeslotTimezone]);

  const roundToSnap = useRoundToSnap();

  const activity = activityCategoryMap[activityId];

  const colour = activity?.colour || "#000000";
  const IconComponent =
    activity && ICONS[activity.icon] ? ICONS[activity.icon] : QuestionMark;
  const name = activity?.name || "Unknown activity";

  const isSameTimezone = currentTimezone === timezone;
  const timezoneStartTime = new TZDate(startMs, timezone);
  const timezoneEndTime = new TZDate(endMs, timezone);
  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={3}
      width={{
        sm: "100%",
        md: "60%",
      }}>
      {isEditing ? (
        <Stack flexGrow={1}>
          <ChangeTimeslot
            activityId={activityId}
            changedTimeslot={(activityId) => {
              setIsEditing(false);
              onCancelEdit();
              setStartMs(roundToSnap(startMs));
              setEndMs(roundToSnap(endMs));
              onChangeTimeslotDetails(
                activityId,
                roundToSnap(startMs),
                roundToSnap(endMs),
                timezone
              );
            }}
            cancelled={() => {
              onCancelEdit();
              setIsEditing(false);
            }}
          />
        </Stack>
      ) : (
        <>
          <IconComponent
            sx={{
              width: 30,
              height: 30,
              color: colour,
            }}
          />
          <EditButton color={colour} onClick={handleEditClick}>
            {name}
          </EditButton>
        </>
      )}

      <Stack direction="column" gap={0}>
        {isEditing ? (
          <Stack direction="row" gap={1} alignItems="center">
            <input
              type="time"
              value={format(timezoneStartTime, "HH:mm")}
              onChange={(e) => {
                const [hours, minutes] = e.target.value.split(":");
                const date = new TZDate(startMs, timezone);
                date.setHours(parseInt(hours));
                date.setMinutes(parseInt(minutes));
                const newStartMs = date.getTime();
                if (newStartMs < endMs) {
                  setStartMs(newStartMs);
                }
              }}
            />
            <Typography>-</Typography>
            <input
              type="time"
              value={format(timezoneEndTime, "HH:mm")}
              onChange={(e) => {
                const [hours, minutes] = e.target.value.split(":");
                const date = new TZDate(endMs, timezone);
                date.setHours(parseInt(hours));
                date.setMinutes(parseInt(minutes));
                const newEndMs = date.getTime();
                if (newEndMs > startMs) {
                  setEndMs(newEndMs);
                }
              }}
            />
          </Stack>
        ) : (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            textAlign="center">
            {format(startMs, "HH:mm")} - {format(endMs, "HH:mm")}
          </Stack>
        )}

        <Typography>
          {formatDuration(
            intervalToDuration({
              start: startMs,
              end: endMs,
            }),
            {
              format: ["hours", "minutes"],
              zero: false,
            }
          )
            .replace("hours", "hr")
            .replace("hour", "hr")
            .replace("minutes", "min")
            .replace("minute", "min")}
        </Typography>
        {isEditing ? (
          isSameTimezone ? (
            <Typography sx={{ color: "#aaa", fontSize: "0.8em" }}>
              {timezone}
            </Typography>
          ) : (
            <Tooltip
              title={`This timeslot is timezone (${timeslotTimezone}), clicking this will change the timezone to ${currentTimezone}.`}
              arrow
              placement="top">
              <Button
                variant="text"
                size="small"
                onClick={() => {
                  setTimezone(currentTimezone);
                }}>
                Change to {currentTimezone}
              </Button>
            </Tooltip>
          )
        ) : (
          !isSameTimezone && (
            <Tooltip
              title={`This timeslot is in a different timezone from the current timezone (${currentTimezone}).`}
              arrow
              placement="top">
              <Stack
                direction="row"
                alignItems="center"
                gap={0.5}
                marginTop={0.5}
                sx={{ color: "#ff0000" }}>
                <Typography sx={{ fontSize: "0.8em" }}>
                  {format(timezoneStartTime, "HH:mm")} -{" "}
                  {format(timezoneEndTime, "HH:mm")} ({timeslotTimezone})
                </Typography>
                <HelpOutlineOutlined sx={{ fontSize: "0.85em" }} />
              </Stack>
            </Tooltip>
          )
        )}
        {/* {!isSameTimezone && !isEditing && (
          <Tooltip
            title={`This timeslot is in a different timezone from the current timezone (${currentTimezone}).`}
            arrow
            placement="top">
            <Stack
              direction="row"
              alignItems="center"
              gap={0.5}
              marginTop={0.5}
              sx={{ color: "#ff0000" }}>
              <Typography sx={{ fontSize: "0.8em" }}>
                {format(timezoneStartTime, "HH:mm")} -{" "}
                {format(timezoneEndTime, "HH:mm")} ({timeslotTimezone})
              </Typography>
              <HelpOutlineOutlined sx={{ fontSize: "0.85em" }} />
            </Stack>
          </Tooltip>
        )} */}
      </Stack>
    </Stack>
  );
};

interface LocationSectionProps {
  place: Place | null;
  selectedTimeslotLotLon: { latitude: number; longitude: number } | null;
  onChangeTimeslotPlace: (placeId: string | null) => void;
}

const LocationSection: React.FC<LocationSectionProps> = ({
  place,
  selectedTimeslotLotLon,
  onChangeTimeslotPlace,
}) => {
  const [isEditing, setIsEditing] = useState(false);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  return (
    <Stack
      direction="column"
      alignItems="flex-start"
      justifyContent="center"
      height="100%"
      width={{
        sm: "100%",
        md: "40%",
      }}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
        width={"100%"}>
        {place?.id ? (
          <LocationOnIcon />
        ) : (
          <LocationOffIcon sx={{ color: "#999999" }} />
        )}

        {isEditing ? (
          <ChangePlace
            placeId={place?.id || ""}
            changedPlace={(placeId) => {
              setIsEditing(false);
              onChangeTimeslotPlace(placeId);
            }}
            sortFromLatLon={selectedTimeslotLotLon || undefined}
            cancelled={() => setIsEditing(false)}
          />
        ) : (
          <EditButton
            onClick={handleEditClick}
            color={place ? "#000000" : "#999999"}>
            {place ? place.name : "No place selected"}
          </EditButton>
        )}
      </Stack>

      {selectedTimeslotLotLon && (
        <Tooltip
          title="Coordinates from location history"
          placement="top"
          arrow>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            sx={{
              color: "#999999",
              cursor: "help",
            }}
            gap={1}>
            <Stack direction="row" alignItems="center">
              <GpsFixedIcon sx={{ fontSize: 18 }} />
              <HistoryIcon sx={{ fontSize: 18 }} />
            </Stack>
            <Typography sx={{ fontSize: 14 }}>
              {`${selectedTimeslotLotLon.latitude.toFixed(3)}`},{" "}
              {`${selectedTimeslotLotLon.longitude.toFixed(3)}`}
            </Typography>
          </Stack>
        </Tooltip>
      )}
    </Stack>
  );
};

interface SelectedTimeslotInfoProps {
  selectedTimeslot: Timeslot | null;
  selectedTimeslotLotLon: { latitude: number; longitude: number } | null;
  editingTimeslot: string | null;
  setEditingTimeslot: (editingTimeslot: string | null) => void;
  changeTimeslotDetails: (
    activityId: string,
    startMs: number,
    endMs: number,
    timezone: string
  ) => void;
  changeTimeslotPlace: (placeId: string | null) => void;
  onCancelEdit: () => void;
  small?: boolean;
  currentTimezone: string;
}

export const SelectedTimeslotInfo = ({
  selectedTimeslot,
  selectedTimeslotLotLon,
  editingTimeslot,
  setEditingTimeslot,
  changeTimeslotDetails,
  changeTimeslotPlace,
  onCancelEdit,
  currentTimezone,
}: SelectedTimeslotInfoProps) => {
  // Query the db again so that we get updates even if the prop doesn't change
  const timeslot = useLiveQuery(
    () =>
      selectedTimeslot?.id ? db.timeslots.get(selectedTimeslot.id) : undefined,
    [selectedTimeslot]
  );

  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down("md"));
  const { developerMode } = useSettings();

  const { activityCategoryMap } = useActivityJoinCategory();

  const [place, setPlace] = useState<Place | null>(null);

  useEffect(() => {
    const fetchPlace = async () => {
      if (timeslot?.placeId) {
        const fetchedPlace = await db.places.get(timeslot.placeId);
        setPlace(fetchedPlace || null);
      } else {
        setPlace(null);
      }
    };

    fetchPlace();
  }, [timeslot?.placeId]);

  const [showJson, setShowJson] = useState(false);

  if (!timeslot) {
    return (
      <StyledPaper small={small}>
        <Typography variant="h6">Loading...</Typography>
      </StyledPaper>
    );
  }

  return (
    <StyledPaper small={small}>
      <Stack direction="column" width="100%">
        <Stack
          gap={1}
          padding={1}
          direction={{
            sm: "column",
            md: "row",
          }}
          flexGrow={1}
          height="100%">
          <ActivitySection
            timeslotId={timeslot.id}
            activityId={timeslot.activityId}
            activityCategoryMap={activityCategoryMap}
            onChangeTimeslotDetails={(activityId, startMs, endMs, timezone) =>
              changeTimeslotDetails(activityId, startMs, endMs, timezone)
            }
            startTimestampMills={timeslot.startTimestampMills}
            endTimestampMills={timeslot.endTimestampMills}
            editingTimeslot={editingTimeslot}
            currentTimezone={currentTimezone}
            timeslotTimezone={timeslot.timezone}
            setEditingTimeslot={setEditingTimeslot}
            onCancelEdit={onCancelEdit}
          />

          <Divider
            orientation="vertical"
            sx={{
              display: { xs: "none", md: "block" },
            }}
            flexItem
          />

          <LocationSection
            place={place}
            selectedTimeslotLotLon={selectedTimeslotLotLon}
            onChangeTimeslotPlace={changeTimeslotPlace}
          />
        </Stack>

        {developerMode && (
          <Stack
            direction="row"
            gap={1}
            alignItems="center"
            paddingX={1}
            sx={{
              position: "relative",
              fontSize: "0.8rem",
              color: DEV_COLOUR,
            }}>
            <Button
              size="small"
              onClick={() => setShowJson(!showJson)}
              variant="text"
              sx={{
                ml: "auto",
                position: "absolute",
                right: "15px",
                top: "0px",
                color: DEV_COLOUR,
              }}>
              {showJson ? "Hide JSON" : "Show JSON"}
            </Button>
            {showJson ? (
              <JSONDisplay
                json={timeslot}
                style={{
                  fontSize: "0.8rem",
                  width: "100%",
                  maxHeight: "200px",
                }}
              />
            ) : (
              <Typography>ID: {timeslot.id}</Typography>
            )}
          </Stack>
        )}
      </Stack>
    </StyledPaper>
  );
};

export default SelectedTimeslotInfo;
