import {
  Button,
  Container,
  Paper,
  Typography,
  Box,
  CircularProgress,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  LinearProgress,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useState, useCallback } from "react";
import { db } from "../database/db";
import { useNavigate } from "react-router-dom";
import { format, formatDistance } from "date-fns";
import * as GeoTZ from "browser-geo-tz";

// Type for timezone range
interface TimezoneRange {
  timezone: string;
  startMills: number;
  endMills: number;
  durationMills: number;
  formattedStart: string;
  formattedEnd: string;
  formattedDuration: string;
}

// Type for cached timezone data
interface TimezoneCache {
  [key: string]: string;
}

// Create a global in-memory cache that persists between component renders
const globalTimezoneCache: TimezoneCache = {};

export const TimezoneAnalysis = () => {
  const navigate = useNavigate();
  const [analysing, setAnalysing] = useState(false);
  const [timezoneRanges, setTimezoneRanges] = useState<TimezoneRange[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [progress, setProgress] = useState(0);
  const [totalLocations, setTotalLocations] = useState(0);
  const [processedLocations, setProcessedLocations] = useState(0);
  const [cacheHits, setCacheHits] = useState(0);

  // Function to get timezone with caching
  const getTimezone = useCallback(
    async (latitude: number, longitude: number) => {
      // Create a cache key from coordinates (rounded to 2 decimal places for better cache hits)
      const cacheKey = `${latitude.toFixed(2)},${longitude.toFixed(2)}`;

      // Check if we have this location in cache
      if (globalTimezoneCache[cacheKey]) {
        setCacheHits((prev) => prev + 1);
        return globalTimezoneCache[cacheKey];
      }

      // If not in cache, look it up
      const timezone = await GeoTZ.find(latitude, longitude);
      const timezoneStr = Array.isArray(timezone) ? timezone[0] : timezone;

      // Cache the result if valid
      if (timezoneStr) {
        globalTimezoneCache[cacheKey] = timezoneStr;
      }

      return timezoneStr;
    },
    []
  );

  const analyseTimezones = async () => {
    try {
      setAnalysing(true);
      setError(null);
      setTimezoneRanges([]);
      setProgress(0);
      setProcessedLocations(0);
      setCacheHits(0);

      // Get all location histories
      const locationHistories = await db.locationHistories
        .orderBy("timestampMills")
        .toArray();

      if (locationHistories.length === 0) {
        setError("No location history data found.");
        setAnalysing(false);
        return;
      }

      setTotalLocations(locationHistories.length);

      // Process location histories to determine timezone ranges
      const ranges: TimezoneRange[] = [];
      let currentTimezone: string | null = null;
      let rangeStart: number | null = null;

      // Process in batches to avoid UI freezing
      const batchSize = 200; // Increased batch size for better performance
      const totalBatches = Math.ceil(locationHistories.length / batchSize);

      for (let batchIndex = 0; batchIndex < totalBatches; batchIndex++) {
        const batchStart = batchIndex * batchSize;
        const batchEnd = Math.min(
          batchStart + batchSize,
          locationHistories.length
        );
        const batch = locationHistories.slice(batchStart, batchEnd);

        // Process each location in the batch
        for (let i = 0; i < batch.length; i++) {
          const location = batch[i];
          const globalIndex = batchStart + i;

          // Update progress
          setProcessedLocations(globalIndex + 1);
          setProgress(
            Math.floor(((globalIndex + 1) / locationHistories.length) * 100)
          );

          // Skip invalid locations
          if (!location.latitude || !location.longitude) continue;

          // Get timezone for current location (using cache)
          const timezoneStr = await getTimezone(
            location.latitude,
            location.longitude
          );

          if (!timezoneStr) continue;

          // If this is the first valid location or timezone has changed
          if (currentTimezone === null) {
            // Start a new range
            currentTimezone = timezoneStr;
            rangeStart = location.timestampMills;
          } else if (timezoneStr !== currentTimezone) {
            // Timezone has changed, end the current range
            if (rangeStart !== null) {
              const endMills = location.timestampMills;
              const durationMills = endMills - rangeStart;

              ranges.push({
                timezone: currentTimezone,
                startMills: rangeStart,
                endMills,
                durationMills,
                formattedStart: format(
                  new Date(rangeStart),
                  "dd MMM yyyy HH:mm"
                ),
                formattedEnd: format(new Date(endMills), "dd MMM yyyy HH:mm"),
                formattedDuration: formatDistance(0, durationMills, {
                  includeSeconds: true,
                }),
              });
            }

            // Start a new range with the new timezone
            currentTimezone = timezoneStr;
            rangeStart = location.timestampMills;
          }

          // If this is the last location, close the final range
          if (
            globalIndex === locationHistories.length - 1 &&
            rangeStart !== null &&
            currentTimezone
          ) {
            const endMills = location.timestampMills;
            const durationMills = endMills - rangeStart;

            ranges.push({
              timezone: currentTimezone,
              startMills: rangeStart,
              endMills,
              durationMills,
              formattedStart: format(new Date(rangeStart), "dd MMM yyyy HH:mm"),
              formattedEnd: format(new Date(endMills), "dd MMM yyyy HH:mm"),
              formattedDuration: formatDistance(0, durationMills, {
                includeSeconds: true,
              }),
            });
          }
        }

        // Allow UI to update between batches
        await new Promise((resolve) => setTimeout(resolve, 0));
      }

      // Sort ranges by start time (newest first)
      const sortedRanges = ranges.sort((a, b) => b.startMills - a.startMills);

      setTimezoneRanges(sortedRanges);
      setAnalysing(false);
    } catch (err) {
      setError(
        "Error analysing timezones: " +
          (err instanceof Error ? err.message : String(err))
      );
      setAnalysing(false);
    }
  };

  return (
    <Container>
      <Button
        variant="outlined"
        startIcon={<ArrowBackIcon />}
        onClick={() => navigate("/settings")}>
        Back to Settings
      </Button>
      <Typography variant="h1" gutterBottom>
        Timezone Analysis
      </Typography>

      <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
        <Typography variant="h2" gutterBottom>
          Location Timezone History
        </Typography>

        <Typography variant="body1" paragraph>
          This tool analyses your location history to identify time periods
          spent in different timezones. This can be useful for travel tracking
          and ensuring your time data is accurate.
        </Typography>

        <Box sx={{ mb: 2 }}>
          <Button
            variant="contained"
            onClick={analyseTimezones}
            disabled={analysing}
            sx={{ mr: 2 }}>
            {analysing ? <CircularProgress size={24} /> : "Analyse Timezones"}
          </Button>
        </Box>

        {analysing && (
          <Box sx={{ width: "100%", mb: 3 }}>
            <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
              <Box sx={{ width: "100%", mr: 1 }}>
                <LinearProgress variant="determinate" value={progress} />
              </Box>
              <Box sx={{ minWidth: 35 }}>
                <Typography
                  variant="body2"
                  color="text.secondary">{`${progress}%`}</Typography>
              </Box>
            </Box>
            <Typography variant="body2" color="text.secondary">
              Processed {processedLocations} of {totalLocations} locations
              {cacheHits > 0 && ` (${cacheHits} cache hits)`}
            </Typography>
          </Box>
        )}

        {timezoneRanges.length > 0 && (
          <Alert severity="info" sx={{ mb: 2 }}>
            Found {timezoneRanges.length} timezone period
            {timezoneRanges.length !== 1 ? "s" : ""}.
          </Alert>
        )}

        {timezoneRanges.length > 0 && (
          <TableContainer component={Paper} sx={{ mt: 3 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Timezone</TableCell>
                  <TableCell>From</TableCell>
                  <TableCell>To</TableCell>
                  <TableCell>Duration</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {timezoneRanges.map((range, index) => (
                  <TableRow key={index}>
                    <TableCell>{range.timezone}</TableCell>
                    <TableCell>{range.formattedStart}</TableCell>
                    <TableCell>{range.formattedEnd}</TableCell>
                    <TableCell>{range.formattedDuration}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        {error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
      </Paper>
    </Container>
  );
};
