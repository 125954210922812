import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useMemo } from "react";
import tz from "timezones-list";

interface TimezoneSelectorModalProps {
  open: boolean;
  onClose: () => void;
  currentTimezone: string;
  detectedTimezone: string;
  computerTimezone: string;
  onTimezoneSelect: (timezone: string) => void;
}

export const TimezoneSelectorModal = ({
  open,
  onClose,
  currentTimezone,
  detectedTimezone,
  computerTimezone,
  onTimezoneSelect,
}: TimezoneSelectorModalProps) => {
  const timezones = useMemo(() => {
    const result = [];
    let currentOffset = null;

    // Process each timezone
    for (let i = 0; i < tz.length; i++) {
      const zone = tz[i];

      // Add generic timezone entry when offset changes
      if (zone.utc !== currentOffset) {
        result.push({
          label: "GMT" + zone.utc,
          value: zone.utc,
        });
        currentOffset = zone.utc;
      }

      // Add named timezone
      result.push({
        label: zone.label,
        value: zone.utc,
      });
    }

    return result;
  }, []);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Select Timezone</DialogTitle>
      <DialogContent>
        <Stack spacing={2} sx={{ mt: 1 }}>
          <Typography
            variant="body1"
            color="text.secondary"
            sx={{ display: "flex", alignItems: "center" }}>
            Current timezone: {currentTimezone}
            <Tooltip title="Timezone that's currently set" placement="top">
              <HelpOutlineOutlinedIcon sx={{ ml: 1, cursor: "help" }} />
            </Tooltip>
          </Typography>
          <Stack direction="row" gap={1} justifyContent="space-between">
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{ display: "flex", alignItems: "center" }}>
              Detected timezone: {detectedTimezone}
              <Tooltip
                title="Timezone detected based on the timeslots or the location history"
                placement="top">
                <HelpOutlineOutlinedIcon sx={{ ml: 1, cursor: "help" }} />
              </Tooltip>
            </Typography>
            <Button
              variant="contained"
              color="primary"
              size="small"
              disabled={detectedTimezone === currentTimezone}
              sx={{
                cursor:
                  detectedTimezone === currentTimezone
                    ? "not-allowed"
                    : "pointer",
              }}
              onClick={() => {
                onTimezoneSelect(detectedTimezone);
                onClose();
              }}>
              Set to {detectedTimezone}
            </Button>
          </Stack>

          <Stack direction="row" gap={1} justifyContent="space-between">
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{ display: "flex", alignItems: "center" }}>
              Computer timezone: {computerTimezone}
              <Tooltip title="Timezone your computer is set to" placement="top">
                <HelpOutlineOutlinedIcon sx={{ ml: 1, cursor: "help" }} />
              </Tooltip>
            </Typography>
            <Button
              variant="contained"
              color="primary"
              size="small"
              disabled={computerTimezone === currentTimezone}
              sx={{
                cursor:
                  computerTimezone === currentTimezone
                    ? "not-allowed"
                    : "pointer",
              }}
              onClick={() => {
                onTimezoneSelect(computerTimezone);
                onClose();
              }}>
              Set to {computerTimezone}
            </Button>
          </Stack>
          <Autocomplete
            onKeyDown={(e) => {
              e.stopPropagation();
            }}
            options={timezones}
            value={timezones.find((zone) => zone.value === currentTimezone)}
            onChange={(_, newValue) => {
              if (newValue) {
                onTimezoneSelect(newValue.value);
                onClose();
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Timezone"
                variant="outlined"
                fullWidth
              />
            )}
            renderOption={(props, option) => (
              <li {...props}>
                <Typography noWrap>{option.label}</Typography>
              </li>
            )}
          />
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
