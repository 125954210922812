import { Timeslot } from "../../../../database/db";

export type BlankTimeslot = {
  id?: undefined; // This is here so that we can do timeblock.timeslot.id
  startTimestampMills: number;
  endTimestampMills: number;
};

export type TimeBlock = {
  start: number;
  end: number;
  startPx: number;
  endPx: number;
} & (
  | { type: "blank"; timeslot: BlankTimeslot }
  | { type: "timeslot"; timeslot: Timeslot }
);
// Represents the current state of a timeslot being resized/dragged
export type TimeslotDragState = {
  currentlyDragging: TimeBlock | null;
  dragDirection: "up" | "down" | null;
  currentTime: number | null;
  currentStartMs: number | null;
  currentEndMs: number | null;
};

// Contains all context needed for timeslot resize operations
export type TimeslotModificationContext = {
  startDragY: number;
} & (
  | {
      modificationType: "scale";
      above: TimeBlock | null;
      below: TimeBlock | null;
    }
  | {
      modificationType: "drag";
    }
) &
  TimeslotDragState;

export const convertYPositionToTimestamp = (
  yPos: number,
  pxPerMs: number,
  dayBounds: number[]
) => {
  if (dayBounds === null) return 0;
  const time = yPos / pxPerMs + dayBounds[0];
  return Math.max(dayBounds[0], Math.min(dayBounds[1], time));
};

export const updateTimeslotModification = (
  yPos: number,
  pxPerMs: number,
  dayBounds: number[],
  modificationContext: TimeslotModificationContext
): TimeslotModificationContext => {
  const currentTimeAtY = convertYPositionToTimestamp(yPos, pxPerMs, dayBounds);

  if (modificationContext.modificationType === "drag") {
    if (modificationContext.currentlyDragging === null) {
      console.error("No currently dragging timeslot");
      return modificationContext;
    }
    const startMs = convertYPositionToTimestamp(
      modificationContext.currentlyDragging.startPx +
        yPos -
        modificationContext.startDragY,
      pxPerMs,
      dayBounds
    );
    const endMs = convertYPositionToTimestamp(
      modificationContext.currentlyDragging.endPx +
        yPos -
        modificationContext.startDragY,
      pxPerMs,
      dayBounds
    );
    return {
      ...modificationContext,
      currentlyDragging: modificationContext.currentlyDragging,
      dragDirection: null,
      currentTime: currentTimeAtY,
      currentStartMs: startMs,
      currentEndMs: endMs,
    };
  }

  if (yPos < modificationContext.startDragY) {
    return {
      ...modificationContext,
      currentlyDragging: modificationContext.below,
      dragDirection: "up",
      currentTime: currentTimeAtY,
      currentStartMs: currentTimeAtY,
      currentEndMs: modificationContext.below?.end ?? null,
    };
  } else if (yPos > modificationContext.startDragY) {
    return {
      ...modificationContext,
      currentlyDragging: modificationContext.above,
      dragDirection: "down",
      currentTime: currentTimeAtY,
      currentStartMs: modificationContext.above?.start ?? null,
      currentEndMs: currentTimeAtY,
    };
  } else {
    return {
      ...modificationContext,
      currentlyDragging: null,
      dragDirection: null,
      currentTime: null,
      currentStartMs: null,
      currentEndMs: null,
    };
  }
};

export const SNAP_THRESHOLD_PX = 10; // Pixels within which to snap to a boundary
export const snapToPoint = (
  point: number,
  boundaries: number[],
  snapThreshold: number
) => {
  const snapPoint = boundaries.find(
    (boundary) => Math.abs(boundary - point) < snapThreshold
  );
  if (snapPoint) {
    return snapPoint;
  }
  return point;
};
