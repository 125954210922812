import { Settings, ViewDay } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  Paper,
  Popover,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { addDays, format, parseISO, subDays } from "date-fns";
import { useLiveQuery } from "dexie-react-hooks";
import { useEffect, useMemo, useRef, useState } from "react";
import { db, JournalEntry } from "../../../database/db";
import { useCloudSyncedDB } from "../../../hooks/useCloudSyncedDb";

interface JournalTableViewProps {
  entries: JournalEntry[];
  onSave: (id: string, changes: { entry: string }) => Promise<void>;
  selectedDate: string;
  onDateSelect: (date: Date | null) => void;
}

export const JournalTableView = ({
  entries,
  onSave,
  selectedDate,
  onDateSelect,
}: JournalTableViewProps) => {
  const [editingId, setEditingId] = useState<string | null>(null);
  const [editText, setEditText] = useState("");
  const [hoveredRow, setHoveredRow] = useState<string | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  const cloudSyncedDB = useCloudSyncedDB();
  const [tableHeight, setTableHeight] = useState<number>(500); // Default height
  const containerRef = useRef<HTMLDivElement>(null);

  // State for custom field editing
  const [editingField, setEditingField] = useState<{
    id: string;
    field: string;
  } | null>(null);
  const [editFieldValue, setEditFieldValue] = useState<
    string | number | boolean
  >("");

  // State for column settings
  const [settingsAnchorEl, setSettingsAnchorEl] =
    useState<HTMLButtonElement | null>(null);
  const [visibleFields, setVisibleFields] = useState<string[]>([]);
  const [canScrollHorizontally, setCanScrollHorizontally] = useState(false);

  // Get journal field definitions
  const fieldDefinitions = useLiveQuery(
    () =>
      db.journalFieldDefinitions.where("archivedAt").equals(0).sortBy("order"),
    []
  );

  // Initialize visible fields when field definitions load
  useEffect(() => {
    if (fieldDefinitions) {
      // Check if we have stored preferences
      const storedVisibleFields = localStorage.getItem(
        "journalTableVisibleFields"
      );
      if (storedVisibleFields) {
        try {
          const parsedFields = JSON.parse(storedVisibleFields);
          // Filter out any fields that no longer exist
          const validFields = parsedFields.filter((field: string) =>
            fieldDefinitions.some((def) => def.identifier === field)
          );
          setVisibleFields(validFields);
        } catch (e) {
          console.error("Error parsing stored visible fields:", e);
          setVisibleFields(fieldDefinitions.map((field) => field.identifier));
        }
      } else {
        // Default to showing all fields
        setVisibleFields(fieldDefinitions.map((field) => field.identifier));
      }
    }
  }, [fieldDefinitions]);

  // Save visible fields to localStorage when they change
  useEffect(() => {
    if (visibleFields.length > 0) {
      localStorage.setItem(
        "journalTableVisibleFields",
        JSON.stringify(visibleFields)
      );
    }
  }, [visibleFields]);

  // Format identifier to title case for display
  const formatIdentifier = (identifier: string) => {
    return identifier
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  // Generate all dates for the last 30 days starting from the selected date
  const allDates = useMemo(() => {
    const endDate = parseISO(selectedDate);

    const dates = [];
    for (let i = 0; i < 30; i++) {
      const date = subDays(endDate, i);
      const dateStr = format(date, "yyyy-MM-dd");
      const dayOfWeek = format(date, "EEE");
      const entry = entries.find((e) => e.date === dateStr) || null;

      // Parse userData if it exists
      let userData: Record<string, string | number | boolean> = {};
      if (entry?.userData) {
        try {
          userData = JSON.parse(entry.userData);
        } catch (error) {
          console.error("Error parsing userData:", error);
        }
      }

      dates.push({
        date: dateStr,
        dayOfWeek,
        entry,
        userData,
      });
    }

    return dates;
  }, [entries, selectedDate]);

  // Handle keyboard events at the component level
  useEffect(() => {
    const handleGlobalKeyDown = (event: KeyboardEvent) => {
      // Only handle if we're not already editing and not saving
      if (editingId || isSaving || editingField) return;

      // Start editing the first item if nothing is selected
      if (
        (event.key === "ArrowUp" ||
          event.key === "ArrowDown" ||
          event.key === "Tab") &&
        allDates.length > 0
      ) {
        event.preventDefault();
        const firstDate = allDates[0].date;
        const firstEntry = allDates[0].entry;
        setEditingId(firstEntry ? firstEntry.id : "new-" + firstDate);
        setEditText(firstEntry ? firstEntry.entry : "");
      }
    };

    window.addEventListener("keydown", handleGlobalKeyDown);
    return () => {
      window.removeEventListener("keydown", handleGlobalKeyDown);
    };
  }, [allDates, editingId, isSaving, editingField]);

  const handleRowClick = (date: string, entry: JournalEntry | null) => {
    if (isSaving || editingField) return; // Prevent clicking while saving or editing a field

    if (editingId) {
      // If already editing, save the current edit before switching
      handleBlur().then(() => {
        setEditingId(entry ? entry.id : "new-" + date);
        setEditText(entry ? entry.entry : "");
      });
    } else {
      setEditingId(entry ? entry.id : "new-" + date);
      setEditText(entry ? entry.entry : "");
    }
  };

  const handleBlur = async () => {
    if (!editingId || isSaving) return;

    setIsSaving(true);
    try {
      if (editText.trim() === "") {
        // If text is empty and this is an existing entry, save it
        if (!editingId.startsWith("new-")) {
          await onSave(editingId, { entry: editText });
        }
        setEditingId(null);
        setEditText("");
        return;
      }

      if (editingId.startsWith("new-")) {
        const date = editingId.replace("new-", "");
        const startDate = parseISO(date);
        const endDate = addDays(startDate, 1);
        await cloudSyncedDB.addJournalEntry({
          date,
          entry: editText,
          startMs: startDate.getTime(),
          endMs: endDate.getTime(),
        });
      } else {
        await onSave(editingId, { entry: editText });
      }
    } catch (error) {
      console.error("Error saving journal entry:", error);
    } finally {
      setEditingId(null);
      setEditText("");
      setIsSaving(false);
    }
  };

  // Handle custom field editing
  const handleFieldClick = (
    e: React.MouseEvent,
    date: string,
    entry: JournalEntry | null,
    fieldId: string
  ) => {
    e.stopPropagation();
    if (isSaving || editingId) return;

    if (entry) {
      // Get current value
      let currentValue = "";
      if (entry.userData) {
        try {
          const userData = JSON.parse(entry.userData);
          currentValue =
            userData[fieldId] !== undefined ? userData[fieldId] : "";
        } catch (error) {
          console.error("Error parsing userData:", error);
        }
      }

      setEditingField({ id: entry.id, field: fieldId });
      setEditFieldValue(currentValue);
    } else {
      // Create a new entry with this field
      setEditingField({ id: "new-" + date, field: fieldId });
      setEditFieldValue("");
    }
  };

  const handleFieldBlur = async () => {
    if (!editingField || isSaving) return;

    setIsSaving(true);
    try {
      const { id, field } = editingField;

      if (id.startsWith("new-")) {
        // Create a new entry
        const date = id.replace("new-", "");
        const startDate = parseISO(date);
        const endDate = addDays(startDate, 1);

        await cloudSyncedDB.addJournalEntry({
          date,
          entry: "",
          startMs: startDate.getTime(),
          endMs: endDate.getTime(),
          userData: JSON.stringify({ [field]: editFieldValue }),
        });
      } else {
        // Update existing entry
        const entry = entries.find((e) => e.id === id);
        if (entry) {
          let userData = {};
          if (entry.userData) {
            try {
              userData = JSON.parse(entry.userData);
            } catch (error) {
              console.error("Error parsing userData:", error);
            }
          }

          // Update the field
          userData = { ...userData, [field]: editFieldValue };

          await cloudSyncedDB.updateJournalEntry(id, {
            userData: JSON.stringify(userData),
          });
        }
      }
    } catch (error) {
      console.error("Error saving field value:", error);
    } finally {
      setEditingField(null);
      setEditFieldValue("");
      setIsSaving(false);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    // Stop propagation to prevent global shortcuts from triggering
    event.stopPropagation();

    if ((event.metaKey || event.ctrlKey) && event.key === "Enter") {
      event.preventDefault();
      handleBlur();
    } else if (event.key === "Escape") {
      event.preventDefault();
      handleBlur();
    } else if (
      (event.key === "Tab" && !event.shiftKey) ||
      event.key === "ArrowDown"
    ) {
      event.preventDefault(); // Prevent default tab behavior

      if (isSaving) return; // Don't navigate while saving

      // Find the current date being edited
      let currentDateString = "";
      if (editingId?.startsWith("new-")) {
        currentDateString = editingId.replace("new-", "");
      } else {
        const currentEntry = entries.find((entry) => entry.id === editingId);
        if (currentEntry) {
          currentDateString = currentEntry.date;
        }
      }

      if (!currentDateString) return;

      // Find the index of the current date in allDates
      const currentIndex = allDates.findIndex(
        ({ date }) => date === currentDateString
      );
      if (currentIndex === -1) return;

      // Save current entry before moving
      handleBlur().then(() => {
        // Move to the next day
        if (currentIndex < allDates.length - 1) {
          const nextDate = allDates[currentIndex + 1].date;
          const nextEntry = allDates[currentIndex + 1].entry;
          setEditingId(nextEntry ? nextEntry.id : "new-" + nextDate);
          setEditText(nextEntry ? nextEntry.entry : "");
        }
      });
    } else if (
      event.key === "ArrowUp" ||
      (event.key === "Tab" && event.shiftKey)
    ) {
      event.preventDefault();

      if (isSaving) return; // Don't navigate while saving

      // Find the current date being edited
      let currentDateString = "";
      if (editingId?.startsWith("new-")) {
        currentDateString = editingId.replace("new-", "");
      } else {
        const currentEntry = entries.find((entry) => entry.id === editingId);
        if (currentEntry) {
          currentDateString = currentEntry.date;
        }
      }

      if (!currentDateString) return;

      // Find the index of the current date in allDates
      const currentIndex = allDates.findIndex(
        ({ date }) => date === currentDateString
      );
      if (currentIndex === -1) return;

      // Save current entry before moving
      handleBlur().then(() => {
        // Move to the previous day
        if (currentIndex > 0) {
          const previousDate = allDates[currentIndex - 1].date;
          const previousEntry = allDates[currentIndex - 1].entry;
          setEditingId(
            previousEntry ? previousEntry.id : "new-" + previousDate
          );
          setEditText(previousEntry ? previousEntry.entry : "");
        }
      });
    }
  };

  const handleFieldKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    event.stopPropagation();

    if (event.key === "Enter" || event.key === "Escape") {
      event.preventDefault();
      handleFieldBlur();
    }
  };

  const handleToggleField = (fieldId: string) => {
    setVisibleFields((prev) => {
      if (prev.includes(fieldId)) {
        return prev.filter((id) => id !== fieldId);
      } else {
        return [...prev, fieldId];
      }
    });
  };

  const handleToggleAllFields = (checked: boolean) => {
    if (checked && fieldDefinitions) {
      setVisibleFields(fieldDefinitions.map((field) => field.identifier));
    } else {
      setVisibleFields([]);
    }
  };

  const tableContainerRef = useRef<HTMLDivElement>(null);

  // Calculate available height for the table
  useEffect(() => {
    const calculateAvailableHeight = () => {
      if (containerRef.current) {
        const containerRect = containerRef.current.getBoundingClientRect();
        const windowHeight = window.innerHeight;
        const containerTop = containerRect.top;
        // Leave some padding at the bottom (30px)
        const availableHeight = windowHeight - containerTop - 30;
        setTableHeight(Math.max(300, availableHeight)); // Minimum height of 300px
      }
    };

    // Calculate on mount and when window resizes
    calculateAvailableHeight();
    window.addEventListener("resize", calculateAvailableHeight);

    return () => {
      window.removeEventListener("resize", calculateAvailableHeight);
    };
  }, []);

  // Check if horizontal scrolling is available
  useEffect(() => {
    const checkForHorizontalScroll = () => {
      if (tableContainerRef.current) {
        const { scrollWidth, clientWidth } = tableContainerRef.current;
        setCanScrollHorizontally(scrollWidth > clientWidth);
      }
    };

    // Check initially and whenever the window resizes
    checkForHorizontalScroll();
    window.addEventListener("resize", checkForHorizontalScroll);

    // Also check when field visibility changes
    const timer = setTimeout(checkForHorizontalScroll, 100);

    return () => {
      window.removeEventListener("resize", checkForHorizontalScroll);
      clearTimeout(timer);
    };
  }, [visibleFields, fieldDefinitions]);

  return (
    <Box sx={{ mt: 2 }} ref={containerRef}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Typography variant="h6">Journal Entries (Last 30 Days)</Typography>

        <Tooltip title="Column Settings">
          <IconButton
            onClick={(e) => setSettingsAnchorEl(e.currentTarget)}
            color="primary"
          >
            <Settings />
          </IconButton>
        </Tooltip>

        <Popover
          open={Boolean(settingsAnchorEl)}
          anchorEl={settingsAnchorEl}
          onClose={() => setSettingsAnchorEl(null)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Box sx={{ p: 2, width: 250 }}>
            <Typography variant="subtitle1" gutterBottom>
              Visible Columns
            </Typography>

            <FormControlLabel
              control={
                <Switch
                  checked={fieldDefinitions?.length === visibleFields.length}
                  onChange={(e) => handleToggleAllFields(e.target.checked)}
                />
              }
              label="Show All Fields"
            />

            <Divider sx={{ my: 1 }} />

            {fieldDefinitions?.map((field) => (
              <FormControlLabel
                key={field.id}
                control={
                  <Checkbox
                    checked={visibleFields.includes(field.identifier)}
                    onChange={() => handleToggleField(field.identifier)}
                  />
                }
                label={formatIdentifier(field.identifier)}
              />
            ))}
          </Box>
        </Popover>
      </Box>

      {allDates.length === 0 ? (
        <Typography variant="body1" color="text.secondary">
          No journal entries found for the selected period.
        </Typography>
      ) : (
        <>
          {canScrollHorizontally && (
            <Typography
              variant="caption"
              color="text.secondary"
              sx={{
                display: "flex",
                alignItems: "center",
                mb: 1,
                justifyContent: "flex-end",
              }}
            >
              <Box
                component="span"
                sx={{
                  display: "inline-block",
                  mr: 1,
                  animation: "pulse 2s infinite",
                  "@keyframes pulse": {
                    "0%": { opacity: 0.6 },
                    "50%": { opacity: 1 },
                    "100%": { opacity: 0.6 },
                  },
                }}
              >
                ↔️
              </Box>
              Scroll horizontally to see more fields
            </Typography>
          )}
          <TableContainer
            component={Paper}
            ref={tableContainerRef}
            sx={{
              overflowX: "auto",
              height: `${tableHeight}px`,
            }}
          >
            <Table
              stickyHeader
              size="small"
              sx={{ "& .MuiTableCell-root": { py: 1 } }}
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    width="150px"
                    sx={{
                      minWidth: 150,
                      position: "sticky",
                      left: 0,
                      top: 0,
                      zIndex: 4, // Higher z-index for corner cell
                      backgroundColor: "background.paper",
                      px: 1.5, // Reduced horizontal padding
                    }}
                  >
                    Date
                  </TableCell>
                  <TableCell
                    sx={{
                      minWidth: 300,
                      backgroundColor: "background.paper",
                      position: "sticky",
                      top: 0,
                      zIndex: 3,
                      px: 1.5, // Reduced horizontal padding
                    }}
                  >
                    Journal Entry
                  </TableCell>

                  {fieldDefinitions &&
                    visibleFields.map((fieldId) => {
                      const field = fieldDefinitions.find(
                        (f) => f.identifier === fieldId
                      );
                      if (!field) return null;

                      return (
                        <TableCell
                          key={field.id}
                          sx={{
                            minWidth: 150,
                            whiteSpace: "nowrap",
                            position: "sticky",
                            top: 0,
                            zIndex: 3,
                            backgroundColor: "background.paper",
                            px: 1.5, // Reduced horizontal padding
                          }}
                        >
                          {formatIdentifier(field.identifier)}
                        </TableCell>
                      );
                    })}

                  <TableCell
                    width="100px"
                    align="center"
                    sx={{
                      position: "sticky",
                      right: 0,
                      top: 0,
                      zIndex: 4, // Higher z-index for corner cell
                      backgroundColor: "background.paper",
                      px: 1.5, // Reduced horizontal padding
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allDates.map(({ date, dayOfWeek, entry, userData }) => (
                  <TableRow
                    key={entry?.id || date}
                    onClick={() => handleRowClick(date, entry)}
                    onMouseEnter={() => setHoveredRow(date)}
                    onMouseLeave={() => setHoveredRow(null)}
                    sx={{
                      cursor: isSaving ? "wait" : "pointer",
                      "&:hover": { bgcolor: "action.hover" },
                      bgcolor:
                        editingId === entry?.id || editingId === "new-" + date
                          ? "action.selected"
                          : "inherit",
                      height: "auto", // Allow row to adjust to content
                    }}
                  >
                    <TableCell
                      sx={{
                        position: "sticky",
                        left: 0,
                        zIndex: 2,
                        backgroundColor:
                          editingId === entry?.id || editingId === "new-" + date
                            ? "action.selected"
                            : "background.paper",
                        "&:hover": { bgcolor: "action.hover" },
                        px: 1.5, // Reduced horizontal padding
                      }}
                    >
                      <Typography variant="body2" fontWeight="medium">
                        {dayOfWeek}, {date}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        backgroundColor:
                          editingId === entry?.id || editingId === "new-" + date
                            ? "action.selected"
                            : "background.paper",
                        "&:hover": { bgcolor: "action.hover" },
                        px: 1.5, // Reduced horizontal padding
                      }}
                    >
                      {editingId === entry?.id ||
                      editingId === "new-" + date ? (
                        <Box position="relative">
                          <TextField
                            fullWidth
                            multiline
                            minRows={3}
                            maxRows={10}
                            value={editText}
                            onChange={(e) => setEditText(e.target.value)}
                            onBlur={() => {
                              if (!isSaving) handleBlur();
                            }}
                            onKeyDown={handleKeyDown}
                            autoFocus
                            variant="outlined"
                            onClick={(e) => e.stopPropagation()}
                            placeholder="Write about your day..."
                            disabled={isSaving}
                          />
                          <Typography
                            variant="caption"
                            sx={{
                              position: "absolute",
                              bottom: 4,
                              right: 8,
                              color: "text.secondary",
                            }}
                          >
                            {isSaving
                              ? "Saving..."
                              : "Press Cmd/Ctrl + Enter or Esc to save, ↑/↓ or Tab/Shift+Tab to navigate"}
                          </Typography>
                        </Box>
                      ) : (
                        <Typography
                          variant="body2"
                          sx={{
                            whiteSpace: "pre-wrap",
                            maxHeight: hoveredRow === date ? "none" : "100px",
                            overflow:
                              hoveredRow === date ? "visible" : "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: hoveredRow === date ? "none" : 4,
                            WebkitBoxOrient: "vertical",
                            color: entry?.entry
                              ? "text.primary"
                              : "text.disabled",
                            fontStyle: entry?.entry ? "normal" : "italic",
                            transition:
                              "max-height 0.3s ease, overflow 0.3s ease",
                          }}
                        >
                          {entry?.entry || "No entry for this day"}
                        </Typography>
                      )}
                    </TableCell>

                    {fieldDefinitions &&
                      visibleFields.map((fieldId) => {
                        const field = fieldDefinitions.find(
                          (f) => f.identifier === fieldId
                        );
                        if (!field) return null;

                        const isEditing =
                          editingField &&
                          editingField.id === (entry?.id || "new-" + date) &&
                          editingField.field === field.identifier;

                        return (
                          <TableCell
                            key={field.id}
                            onClick={(e) =>
                              handleFieldClick(e, date, entry, field.identifier)
                            }
                            sx={{
                              cursor: "pointer",
                              backgroundColor: isEditing
                                ? "action.selected"
                                : "inherit",
                              "&:hover": { bgcolor: "action.hover" },
                              px: 1.5, // Reduced horizontal padding
                            }}
                          >
                            {isEditing ? (
                              field.type === "checkbox" ? (
                                <Checkbox
                                  checked={!!editFieldValue}
                                  onChange={(e) =>
                                    setEditFieldValue(e.target.checked)
                                  }
                                  onClick={(e) => e.stopPropagation()}
                                  onBlur={handleFieldBlur}
                                  autoFocus
                                />
                              ) : field.type === "number" ? (
                                <TextField
                                  type="number"
                                  fullWidth
                                  size="small"
                                  value={editFieldValue}
                                  onChange={(e) =>
                                    setEditFieldValue(
                                      e.target.value === ""
                                        ? ""
                                        : Number(e.target.value)
                                    )
                                  }
                                  onClick={(e) => e.stopPropagation()}
                                  onBlur={handleFieldBlur}
                                  onKeyDown={handleFieldKeyDown}
                                  autoFocus
                                  variant="outlined"
                                />
                              ) : (
                                <TextField
                                  fullWidth
                                  size="small"
                                  value={editFieldValue}
                                  onChange={(e) =>
                                    setEditFieldValue(e.target.value)
                                  }
                                  onClick={(e) => e.stopPropagation()}
                                  onBlur={handleFieldBlur}
                                  onKeyDown={handleFieldKeyDown}
                                  autoFocus
                                  variant="outlined"
                                />
                              )
                            ) : (
                              <Typography variant="body2">
                                {field.type === "checkbox" ? (
                                  userData[field.identifier] === true ? (
                                    "Yes"
                                  ) : userData[field.identifier] === false ? (
                                    "No"
                                  ) : (
                                    "-"
                                  )
                                ) : userData[field.identifier] !== undefined ? (
                                  userData[field.identifier]
                                ) : (
                                  <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    fontStyle="italic"
                                  >
                                    -
                                  </Typography>
                                )}
                              </Typography>
                            )}
                          </TableCell>
                        );
                      })}

                    <TableCell
                      width="100px"
                      align="center"
                      sx={{
                        position: "sticky",
                        right: 0,
                        zIndex: 2,
                        backgroundColor:
                          editingId === entry?.id || editingId === "new-" + date
                            ? "action.selected"
                            : "background.paper",
                        "&:hover": { bgcolor: "action.hover" },
                        px: 1.5, // Reduced horizontal padding
                      }}
                    >
                      <Button
                        variant="outlined"
                        onClick={(e) => {
                          e.stopPropagation();
                          onDateSelect(new Date(date));
                        }}
                        disabled={isSaving}
                      >
                        <ViewDay />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Box>
  );
};
