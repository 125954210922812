import { TZDate } from "@date-fns/tz";
import styled from "@emotion/styled";
import { Autocomplete, Box, Stack, TextField, Typography } from "@mui/material";
import {
  addDays,
  format,
  isWithinInterval,
  parseISO,
  startOfDay,
} from "date-fns";
import { useLiveQuery } from "dexie-react-hooks";
import { useCallback, useEffect, useMemo, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Activity as ActivityType, db, Timeslot } from "../../database/db";
import { useActivityJoinCategory } from "../../hooks/useActivityJoinCategory";
import { getComputerTimezone } from "../../utils/time";
import { TimeslotComponent } from "../home/components/timeslots/TimeslotComponent";
import { useSettings } from "../../hooks/useSettings";
import { ICONS } from "../../const/icons";
import { Edit } from "@mui/icons-material";

const Container = styled(Box)`
  padding: 20px;
`;

const DaySection = styled(Stack)`
  margin: 20px 0;
`;

const DateHeader = styled(Stack)`
  font-size: 1.2em;
  font-weight: bold;
  margin: 10px 0;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 4px;
`;

const HeaderContainer = styled(Stack)`
  gap: 16px;
  padding: 16px;
  cursor: pointer;
  transition: outline 0.1s linear;
  &:hover {
    outline: 1px solid #e0e0e0;
  }

  .edit-icon {
    color: #aaa;
    opacity: 0;
    transition: opacity 0.1s linear;
  }
  &:hover .edit-icon {
    opacity: 1;
  }
`;

export const Activity = () => {
  const { activityId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { activityCategoryMap } = useActivityJoinCategory();
  const { dateFormat } = useSettings();
  const [isEditingHeader, setIsEditingHeader] = useState(false);
  const [showCalendars, setShowCalendars] = useState(false);
  const [activeInput, setActiveInput] = useState<"start" | "end" | null>(null);

  const [selectedActivity, setSelectedActivity] = useState<ActivityType | null>(
    null
  );

  // Get dates from URL or set defaults
  const startDate = searchParams.get("start")
    ? parseISO(searchParams.get("start")!)
    : startOfDay(new Date());
  const endDate = searchParams.get("end")
    ? parseISO(searchParams.get("end")!)
    : addDays(startDate, 7);

  // Use live query to get timeslots
  const timeslots = useLiveQuery(
    async () => {
      if (!selectedActivity) return [];

      const fetchedTimeslots = await db.timeslots
        .where("activityId")
        .equals(selectedActivity.id)
        .toArray();

      return fetchedTimeslots.filter(
        (t) =>
          isWithinInterval(
            new TZDate(t.startTimestampMills, getComputerTimezone()),
            {
              start: startDate,
              end: endDate,
            }
          ) ||
          isWithinInterval(
            new TZDate(t.endTimestampMills, getComputerTimezone()),
            {
              start: startDate,
              end: endDate,
            }
          )
      );
    },
    [selectedActivity, startDate, endDate],
    []
  );

  const { activityCategoryArray } = useActivityJoinCategory();

  // Set selected activity when activityId changes
  useLiveQuery(async () => {
    if (activityId && activityCategoryArray) {
      const activity = activityCategoryArray.find((a) => a.id === activityId);
      setSelectedActivity(activity || null);
    }
  }, [activityId, activityCategoryArray]);

  // Group timeslots by day
  const timeslotsByDay = useMemo(() => {
    if (!timeslots) return new Map<string, Timeslot[]>();

    const grouped = new Map<string, Timeslot[]>();

    timeslots.forEach((timeslot) => {
      const startDate = new TZDate(
        timeslot.startTimestampMills,
        getComputerTimezone()
      );
      const endDate = new TZDate(
        timeslot.endTimestampMills,
        getComputerTimezone()
      );

      // If timeslot spans multiple days, split it
      let currentDate = startDate;
      while (currentDate <= endDate) {
        const dateKey = format(currentDate, "yyyy-MM-dd");

        if (!grouped.has(dateKey)) {
          grouped.set(dateKey, []);
        }

        const dayStart = startOfDay(currentDate);
        const nextDayStart = addDays(dayStart, 1);

        const slotForDay = {
          ...timeslot,
          startTimestampMills: Math.max(
            timeslot.startTimestampMills,
            dayStart.getTime()
          ),
          endTimestampMills: Math.min(
            timeslot.endTimestampMills,
            nextDayStart.getTime()
          ),
        };

        grouped.get(dateKey)!.push(slotForDay);
        currentDate = addDays(currentDate, 1);
      }
    });

    return grouped;
  }, [timeslots]);

  const handleStartDateChange = useCallback(
    (newStartDate: Date) => {
      const maxEndDate = addDays(newStartDate, 60);
      const newEndDate = endDate > maxEndDate ? maxEndDate : endDate;

      setSearchParams({
        start: format(newStartDate, "yyyy-MM-dd"),
        end: format(newEndDate, "yyyy-MM-dd"),
      });
    },
    [setSearchParams, endDate]
  );

  const handleEndDateChange = useCallback(
    (newEndDate: Date) => {
      const minStartDate = addDays(newEndDate, -60);
      const newStartDate = startDate < minStartDate ? minStartDate : startDate;

      setSearchParams({
        start: format(newStartDate, "yyyy-MM-dd"),
        end: format(newEndDate, "yyyy-MM-dd"),
      });
    },
    [setSearchParams, startDate]
  );

  const handleActivityChange = useCallback(
    (activity: ActivityType | null) => {
      setSelectedActivity(activity);
      if (activity) {
        navigate(
          `/activity/${activity.id}?start=${format(
            startDate,
            "yyyy-MM-dd"
          )}&end=${format(endDate, "yyyy-MM-dd")}`
        );
      }
    },
    [navigate, startDate, endDate]
  );

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "Escape" && isEditingHeader) {
        setIsEditingHeader(false);
      }
    },
    [isEditingHeader]
  );
  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown, isEditingHeader]);

  const iconName = selectedActivity
    ? activityCategoryMap[selectedActivity.id]?.icon
    : null;

  const Icon = iconName ? ICONS[iconName] : null;
  return (
    <Container>
      <Stack spacing={3}>
        {selectedActivity && !isEditingHeader ? (
          <HeaderContainer
            onClick={() => setIsEditingHeader(true)}
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={2}>
            {Icon && (
              <Icon
                sx={{
                  color: activityCategoryMap[selectedActivity.id]?.colour,
                }}
              />
            )}
            <Typography variant="h4">{selectedActivity.name}</Typography>
            <Edit className="edit-icon" />
          </HeaderContainer>
        ) : (
          <Box sx={{ p: 2 }}>
            <Autocomplete
              value={selectedActivity}
              onChange={(_, newValue) => {
                handleActivityChange(newValue);
                setIsEditingHeader(false);
              }}
              options={activityCategoryArray || []}
              getOptionLabel={(option) => option.name}
              sx={{ width: "100%" }}
              renderInput={(params) => (
                <TextField {...params} label="Select Activity" />
              )}
            />
          </Box>
        )}

        <Stack
          direction="row"
          spacing={2}
          justifyContent="center"
          sx={{ pX: 2 }}>
          <TextField
            label="Start Date"
            value={format(startDate, dateFormat)}
            onClick={() => {
              setShowCalendars((prev) => !prev);

              setActiveInput("start");
            }}
            slotProps={{
              input: { readOnly: true },
            }}
            size="small"
          />
          <TextField
            label="End Date"
            value={format(endDate, dateFormat)}
            onClick={() => {
              setShowCalendars((prev) => !prev);

              setActiveInput("end");
            }}
            slotProps={{
              input: { readOnly: true },
            }}
            size="small"
          />
        </Stack>

        {showCalendars && (
          <Stack
            direction="row"
            width="100%"
            justifyContent="center"
            spacing={2}
            sx={{ pX: 2 }}>
            <Calendar
              value={startDate}
              onChange={(value) => {
                handleStartDateChange(value as Date);
                if (activeInput === "start") {
                  setActiveInput("end");
                } else {
                  setShowCalendars(false);
                  setActiveInput(null);
                }
              }}
              showFixedNumberOfWeeks={true}
              maxDetail="month"
              maxDate={endDate}
            />

            <Calendar
              value={endDate}
              onChange={(value) => {
                handleEndDateChange(value as Date);
                if (activeInput === "end") {
                  setActiveInput("start");
                } else {
                  setShowCalendars(false);
                  setActiveInput(null);
                }
              }}
              showFixedNumberOfWeeks={true}
              maxDetail="month"
              minDate={startDate}
            />
          </Stack>
        )}

        {Array.from(timeslotsByDay.entries())
          .sort(([dateA], [dateB]) => dateA.localeCompare(dateB))
          .map(([date, dayTimeslots]) => (
            <DaySection key={date}>
              <DateHeader
                direction="row"
                alignContent="center"
                justifyContent="space-between">
                <Typography>{format(parseISO(date), dateFormat)}</Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ mb: 1 }}>
                  Total time:{" "}
                  {dayTimeslots
                    .reduce((acc, timeslot) => {
                      const duration =
                        (timeslot.endTimestampMills -
                          timeslot.startTimestampMills) /
                        (1000 * 60);
                      return acc + duration;
                    }, 0)
                    .toFixed(0)}{" "}
                  minutes
                </Typography>
              </DateHeader>

              <Stack spacing={1}>
                {dayTimeslots.map((timeslot) => (
                  <Box
                    key={timeslot.id + timeslot.startTimestampMills}
                    onDoubleClick={() => {
                      const date = format(
                        new TZDate(
                          timeslot.startTimestampMills,
                          getComputerTimezone()
                        ),
                        "yyyy-MM-dd"
                      );
                      navigate(`/home?date=${date}`);
                    }}>
                    <TimeslotComponent
                      timeslot={timeslot}
                      activityCategoryMap={activityCategoryMap}
                      isSelected={false}
                      isEditing={false}
                      isDraggingThis={false}
                      isDraggingOther={false}
                      isConflicting={false}
                      onClick={() => {}}
                      onDoubleClick={() => {}}
                    />
                  </Box>
                ))}
              </Stack>
            </DaySection>
          ))}
      </Stack>
    </Container>
  );
};
