import { db, LocationHistory } from "../database/db";
import { CloudSyncedDB } from "../hooks/useCloudSyncedDb";
import { haversineDistance } from "./location";
import { isValidUrl } from "./url";

type ServerLocationHistory = {
  id?: number | null;
  latitude?: number | null;
  longitude?: number | null;
  timestamp?: number | null;
  location_accuracy?: number | null;
  altitude?: number | null;
  activity?: string | null;
  activity_confidence?: number | null;
  metadata?: unknown | null;
  created_at?: string | null;
};

export const getLocationHistory = async (endpoint: string) => {
  try {
    if (!isValidUrl(endpoint)) return null;
    const response = await fetch(endpoint);
    const data = await response.json();
    return data as ServerLocationHistory[];
  } catch (error) {
    console.error("Error checking location history endpoint:", error);
    return null;
  }
};

export const updateLocationHistoryEndpoint = async (
  endpoint: string,
  serverLocationHistory: ServerLocationHistory[]
) => {
  // Get the latest synced id from the server location history
  const locationIds = serverLocationHistory
    .map((location) => location.id)
    .filter((id) => !!id) as number[];
  const latestSyncedId = locationIds.reduce(
    (max, id) => (id && id > max ? id : max),
    0
  );
  if (latestSyncedId === 0) return false;
  try {
    if (!isValidUrl(endpoint)) {
      console.error("Invalid URL provided for location history endpoint");
      return false;
    }

    const response = await fetch(endpoint, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        latest_synced_id: latestSyncedId,
      }),
    });

    if (!response.ok) {
      console.error(
        "Failed to update location history endpoint:",
        response.statusText
      );
      return false;
    }

    const data = await response.json();
    console.log("Location history endpoint updated successfully:", data);
    return true;
  } catch (error) {
    console.error("Error updating location history endpoint:", error);
    return false;
  }
};

export const mapServerDataToLocationHistory = (
  serverData: ServerLocationHistory
): Omit<LocationHistory, "id"> => {
  return {
    timestampMills: serverData.created_at
      ? new Date(serverData.created_at).getTime()
      : 0,
    latitude: serverData.latitude ?? 0,
    longitude: serverData.longitude ?? 0,
    accuracyMetres: serverData.location_accuracy ?? 0,
    guessedActivity: serverData.activity ?? undefined,
    guessedActivityConfidence: serverData.activity_confidence ?? undefined,
    metadata: JSON.stringify(serverData.metadata),
  };
};

export const processAndInsertLocationHistory = async (
  cloudSyncedDB: CloudSyncedDB,
  serverData: ServerLocationHistory[]
): Promise<false | { insertedCount: number; skippedCount: number }> => {
  // Check if serverData is an array
  if (!Array.isArray(serverData)) {
    console.error("Server data is not an array");
    return false;
  }

  const locationHistoryObjects = serverData.map(mapServerDataToLocationHistory);

  console.log(
    "Processing and inserting location history:",
    locationHistoryObjects
  );

  let insertedCount = 0;
  await db.transaction(
    "rw",
    db.locationHistories,
    db.cloudBackupChanges,
    async () => {
      for (const location of locationHistoryObjects) {
        await db.locationHistories
          .where("timestampMills")
          .equals(location.timestampMills)
          .first()
          .then((existingLocation) => {
            if (existingLocation) {
              // Skip existing record
              return;
            } else {
              // Insert new record
              insertedCount++;
              return cloudSyncedDB.addLocationHistory(location);
            }
          });
      }
    }
  );

  return {
    insertedCount,
    skippedCount: locationHistoryObjects.length - insertedCount,
  };
};

export type LocationTimeBlock = {
  type: "location" | "unknown";
  locationId: string;
  startMills: number;
  endMills: number;
  accurateMills: number;
  latitude: number;
  longitude: number;
};

export type LocationTimeBlockWithLocationHistory = LocationTimeBlock & {
  locationHistory: LocationHistory[];
};

export const createTimeBlocksFromLocationHistory = (
  dayBounds: [number, number],
  locationHistory: LocationHistory[]
): LocationTimeBlock[] => {
  let timeBlocks: LocationTimeBlockWithLocationHistory[] = [];

  // Add first block if it exists and is within/after day bounds
  const firstLocation = locationHistory[0];
  if (firstLocation) {
    timeBlocks.push({
      type: "location",
      locationId: firstLocation.id,
      startMills: firstLocation.timestampMills,
      endMills: firstLocation.timestampMills,
      accurateMills: firstLocation.timestampMills,
      latitude: firstLocation.latitude,
      longitude: firstLocation.longitude,
      locationHistory: [firstLocation],
    });
  }

  // Change threshold for sure location
  const LOCATION_THRESHOLD = 100; // metres
  const ACCURATE_TIME_THRESHOLD = 1000 * 30 * 60; // 30 minutes
  const MIN_BLOCK_TIME = 1000 * 15 * 60; // 10 minutes

  for (let i = 1; i < locationHistory.length; i++) {
    const currentLocation = locationHistory[i];
    const lastBlock = timeBlocks[timeBlocks.length - 1];

    const distance = haversineDistance(
      lastBlock.latitude,
      lastBlock.longitude,
      currentLocation.latitude,
      currentLocation.longitude
    );

    if (distance > LOCATION_THRESHOLD) {
      // Significant change in location, add new block
      timeBlocks.push({
        type: "location",
        locationId: currentLocation.id,
        startMills: currentLocation.timestampMills,
        endMills: currentLocation.timestampMills,
        accurateMills: currentLocation.timestampMills,
        latitude: currentLocation.latitude,
        longitude: currentLocation.longitude,
        locationHistory: [currentLocation],
      });
    } else {
      // Update the last block
      lastBlock.endMills = currentLocation.timestampMills;
      lastBlock.locationHistory.push(currentLocation);

      // Check if we need to update accurateMills
      if (
        currentLocation.timestampMills - lastBlock.accurateMills >
        ACCURATE_TIME_THRESHOLD
      ) {
        lastBlock.accurateMills =
          lastBlock.accurateMills + ACCURATE_TIME_THRESHOLD;
      } else {
        lastBlock.accurateMills = currentLocation.timestampMills;
      }
    }
  }

  // Filter out time blocks that are outside day bounds or too short
  timeBlocks = timeBlocks.filter((block) => {
    // Check if block overlaps with day bounds
    const blockStart = Math.max(block.startMills, dayBounds[0]);
    const blockEnd = Math.min(block.endMills, dayBounds[1]);

    if (blockEnd <= blockStart) {
      return false; // Block is completely outside day bounds
    }

    const blockDuration = blockEnd - blockStart;
    return blockDuration >= MIN_BLOCK_TIME;
  });

  // Clamp remaining blocks to day bounds
  timeBlocks = timeBlocks.map((block) => ({
    ...block,
    startMills: Math.max(block.startMills, dayBounds[0]),
    endMills: Math.min(block.endMills, dayBounds[1]),
    accurateMills: Math.min(block.accurateMills, dayBounds[1]),
  }));

  type AverageLocation = {
    locationId: string;
    latitude: number;
    longitude: number;
    timeBlocks: LocationTimeBlockWithLocationHistory[];
  };

  const averageLocations: AverageLocation[] = [];

  // For every location time block, create a new AverageLocation object and average all the location history
  for (const block of timeBlocks) {
    const averageLocation: AverageLocation = {
      locationId: block.locationId,
      latitude: 0,
      longitude: 0,
      timeBlocks: [block],
    };

    for (const location of block.locationHistory) {
      averageLocation.latitude += location.latitude;
      averageLocation.longitude += location.longitude;
    }

    averageLocation.latitude /= block.locationHistory.length;
    averageLocation.longitude /= block.locationHistory.length;
    averageLocations.push(averageLocation);
  }

  const mergedAverageLocations: AverageLocation[] = [];
  // For every average location check if the distance between the average location and the time block is less than the accuracy
  // If so, merge the time blocks by averaging the location and merging the timeblocks array
  for (const averageLocation of averageLocations) {
    if (mergedAverageLocations.length === 0) {
      mergedAverageLocations.push(averageLocation);
    } else {
      // Check against all merged average locations
      let merged = false;
      for (const mergedAverageLocation of mergedAverageLocations) {
        const distance = haversineDistance(
          averageLocation.latitude,
          averageLocation.longitude,
          mergedAverageLocation.latitude,
          mergedAverageLocation.longitude
        );
        if (distance < LOCATION_THRESHOLD) {
          mergedAverageLocation.timeBlocks.push(...averageLocation.timeBlocks);
          mergedAverageLocation.latitude =
            (mergedAverageLocation.latitude *
              mergedAverageLocation.timeBlocks.length +
              averageLocation.latitude) /
            (mergedAverageLocation.timeBlocks.length + 1);
          mergedAverageLocation.longitude =
            (mergedAverageLocation.longitude *
              mergedAverageLocation.timeBlocks.length +
              averageLocation.longitude) /
            (mergedAverageLocation.timeBlocks.length + 1);
          merged = true;
          break;
        }
      }
      if (!merged) {
        mergedAverageLocations.push(averageLocation);
      }
    }
  }

  // Set the location IDs of the merged average locations to be an auto incrementing ID
  for (let i = 0; i < mergedAverageLocations.length; i++) {
    mergedAverageLocations[i].locationId = i.toString();
    mergedAverageLocations[i].timeBlocks.forEach((block) => {
      block.locationId = mergedAverageLocations[i].locationId;
    });
  }

  console.log("Time blocks:", timeBlocks);

  // Return the time blocks without the location history
  return timeBlocks.map((block) => {
    const { locationHistory: _, ...rest } = block;
    return rest;
  });
};
