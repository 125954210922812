import {
  Button,
  Container,
  MenuItem,
  Select,
  Slider,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { StartAnalyticsScreen } from "../hooks/SettingsProvider";
import { useSettings } from "../hooks/useSettings";
import { JSONDisplay } from "./home/components/JsonDisplay";

const snapToMillsOptions = [
  { value: 0, label: "No snap" },
  { value: 60000, label: "1 minute" },
  { value: 300000, label: "5 minutes" },
  { value: 900000, label: "15 minutes" },
  { value: 1800000, label: "30 minutes" },
  { value: 3600000, label: "1 hour" },
];

const defaultTimeslotDurationOptions = [
  { value: 900000, label: "15 minutes" },
  { value: 1800000, label: "30 minutes" },
  { value: 3600000, label: "1 hour" },
  { value: 7200000, label: "2 hours" },
  { value: 14400000, label: "4 hours" },
  { value: 28800000, label: "8 hours" },
];

const startAnalyticsScreenOptions = [
  {
    label: "Recents",
    value: "recents",
  },
  {
    label: "Trends",
    value: "trends",
  },
  {
    label: "Calendar",
    value: "calendar",
  },
];

const dateFormatOptions = [
  { value: "dd/MM/yyyy", label: "DD/MM/YYYY" },
  { value: "MM/dd/yyyy", label: "MM/DD/YYYY" },
  { value: "yyyy-MM-dd", label: "YYYY-MM-DD" },
  { value: "dd-MM-yyyy", label: "DD-MM-YYYY" },
  { value: "MM-dd-yyyy", label: "MM-DD-YYYY" },
  { value: "EEEE, dd/MM/yyyy", label: "Monday, DD/MM/YYYY" },
  { value: "EEEE, MM/dd/yyyy", label: "Monday, MM/DD/YYYY" },
  { value: "EEEE, yyyy-MM-dd", label: "Monday, YYYY-MM-DD" },
  { value: "EEE, dd/MM/yyyy", label: "Mon, DD/MM/YYYY" },
  { value: "EEE, MM/dd/yyyy", label: "Mon, MM/DD/YYYY" },
  { value: "EEE, yyyy-MM-dd", label: "Mon, YYYY-MM-DD" },
];

export const Settings = () => {
  const {
    snapToMills,
    setSnapToMills,
    defaultTimeslotDuration,
    setDefaultTimeslotDuration,
    settingsAsJson,
    defaultZoom,
    setDefaultZoom,
    startAnalyticsScreen,
    setStartAnalyticsScreen,
    developerMode,
    setDeveloperMode,
    dateFormat,
    setDateFormat,
  } = useSettings();

  const navigate = useNavigate();

  const [toolsExpanded, setToolsExpanded] = useState(false);

  const [snapToMillsSliderValue, setSnapToMillsSliderValue] = useState(
    snapToMillsOptions.findIndex((option) => option.value === snapToMills)
  );

  const [
    defaultTimeslotDurationSliderValue,
    setDefaultTimeslotDurationSliderValue,
  ] = useState(
    defaultTimeslotDurationOptions.findIndex(
      (option) => option.value === defaultTimeslotDuration
    )
  );

  const getSnapToMillsSliderLabel = useCallback((number: number) => {
    return snapToMillsOptions[number].label;
  }, []);

  const getDefaultTimeslotDurationSliderLabel = useCallback(
    (number: number) => {
      return defaultTimeslotDurationOptions[number].label;
    },
    []
  );

  return (
    <Container>
      <Typography variant="h1">Settings Page</Typography>

      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        marginTop={2}
        marginBottom={5}
        alignItems="center"
        sx={{ mb: 2 }}>
        <Button
          fullWidth
          size="large"
          variant="contained"
          color="primary"
          onClick={() => navigate("/settings/cloud")}
          sx={{ mr: 2, mb: 2 }}>
          Cloud Settings
        </Button>

        <Button
          fullWidth
          size="large"
          variant="contained"
          color="primary"
          onClick={() => navigate("/settings/journal")}>
          Journal Fields
        </Button>
      </Stack>

      <hr />
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        alignItems="center">
        <Typography variant="body1" width="100%" align="left">
          Snap to time
        </Typography>
        <Typography variant="body1" width="100%" align="right">
          {getSnapToMillsSliderLabel(snapToMillsSliderValue)}
        </Typography>
        <Slider
          sx={{ width: "100%" }}
          value={snapToMillsSliderValue}
          onChange={(_: Event, newValue: number | number[]) => {
            const sliderIndex = newValue as number;
            setSnapToMillsSliderValue(sliderIndex);
            setSnapToMills(snapToMillsOptions[sliderIndex].value);
          }}
          marks
          min={0}
          max={snapToMillsOptions.length - 1}
        />
      </Stack>
      <hr />
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        alignItems="center">
        <Typography variant="body1" width="100%" align="left">
          Date Format
        </Typography>
        <Select
          value={dateFormat}
          onChange={(e) => setDateFormat(e.target.value)}
          sx={{ width: "100%" }}>
          {dateFormatOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </Stack>
      <hr />
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        alignItems="center">
        <Typography variant="body1" width="100%" align="left">
          Default Timeslot Duration
        </Typography>
        <Typography variant="body1" width="100%" align="right">
          {getDefaultTimeslotDurationSliderLabel(
            defaultTimeslotDurationSliderValue
          )}
        </Typography>
        <Slider
          sx={{ width: "100%" }}
          value={defaultTimeslotDurationSliderValue}
          onChange={(_: Event, newValue: number | number[]) => {
            const sliderIndex = newValue as number;
            setDefaultTimeslotDurationSliderValue(sliderIndex);
            setDefaultTimeslotDuration(
              defaultTimeslotDurationOptions[sliderIndex].value
            );
          }}
          marks
          min={0}
          max={defaultTimeslotDurationOptions.length - 1}
        />
      </Stack>
      <hr />
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        alignItems="center">
        <Typography variant="body1">Default Zoom</Typography>
        <Typography variant="body1" width="100%" align="right">
          {defaultZoom} px
        </Typography>
        <Slider
          sx={{ width: "100%" }}
          value={defaultZoom}
          onChange={(_: Event, newValue: number | number[]) => {
            const zoom = newValue as number;
            setDefaultZoom(zoom);
          }}
          marks
          step={20}
          min={10}
          max={200}
        />
      </Stack>

      <hr />
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        alignItems="center">
        <Typography variant="body1">Start Analytics Screen</Typography>
        <Select
          value={startAnalyticsScreen}
          onChange={(e) =>
            setStartAnalyticsScreen(e.target.value as StartAnalyticsScreen)
          }>
          {startAnalyticsScreenOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </Stack>

      <hr />
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        alignItems="center">
        <Typography variant="body1">Advanced tools and utilities</Typography>
        <Button onClick={() => setToolsExpanded(!toolsExpanded)} size="small">
          {toolsExpanded ? "Hide" : "Show"}
        </Button>
      </Stack>
      {toolsExpanded && (
        <Stack spacing={2} sx={{ mt: 2 }}>
          <Typography variant="body2" color="text.secondary">
            Advanced tools to help analyse and verify your data
          </Typography>

          <Stack
            direction="column"
            spacing={2}
            justifyContent="space-between"
            marginBottom={5}
            alignItems="stretch"
            sx={{ mb: 2 }}>
            <Stack spacing={1} direction="row" alignItems="center">
              <Button
                fullWidth
                size="large"
                variant="contained"
                color="primary"
                onClick={() => navigate("/dataintegrity")}>
                Data Integrity Check
              </Button>
              <Typography variant="body2" color="text.secondary" width="100%">
                Verify your local data against cloud backups to ensure
                everything is synchronised correctly
              </Typography>
            </Stack>

            <Stack spacing={1} direction="row" alignItems="center">
              <Button
                fullWidth
                size="large"
                variant="contained"
                color="primary"
                onClick={() => navigate("/timezoneanalysis")}>
                Timezone Analysis
              </Button>
              <Typography variant="body2" color="text.secondary" width="100%">
                Analyse timezone settings based on your current location to
                ensure accurate time tracking
              </Typography>
            </Stack>

            <Stack spacing={1} direction="row" alignItems="center">
              <Button
                fullWidth
                size="large"
                variant="contained"
                color="primary"
                onClick={() => navigate("/datacleanup")}
                sx={{ mb: 2 }}>
                Data Cleanup
              </Button>
              <Typography variant="body2" color="text.secondary" width="100%">
                Clean invalid timeslots
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      )}

      <hr />
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        alignItems="center">
        <Typography variant="body1">Developer Mode</Typography>
        <Switch
          checked={developerMode}
          onChange={(e) => {
            setDeveloperMode(e.target.checked);
          }}
        />
      </Stack>
      <hr />
      {developerMode && (
        <>
          <Typography variant="body1">Settings as JSON</Typography>
          <JSONDisplay
            style={{
              width: "100%",
              maxHeight: "500px",
              overflow: "auto",
            }}
            json={settingsAsJson}
          />
        </>
      )}
    </Container>
  );
};
