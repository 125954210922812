import styled from "@emotion/styled";
import { QuestionMark } from "@mui/icons-material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import NotesIcon from "@mui/icons-material/NoteAlt";
import {
  Box,
  Stack,
  SxProps,
  Theme,
  Typography,
  Tooltip,
  IconButton,
} from "@mui/material";
import React from "react";
import { ICONS } from "../../../../const/icons";
import { Timeslot } from "../../../../database/db";
import { ActivityJoinCategory } from "../../../../database/helpers";
import { timeDisplay } from "../../../../utils/time";
import { OUTLINE_COLORS } from "../../../../const/colours";

export const MID_TIMESLOT_HEIGHT = 40;
export const SHORT_TIMESLOT_HEIGHT = 20;

const TimeslotRow = styled(Box)<{
  isconflicting: string;
  isediting: string;
  isselected: string;
  isreduced: string;
}>`
  position: absolute;

  width: ${(props) =>
    props.isreduced === "true" ? "100%" : "calc(100% - 120px)"};
  z-index: 1;
  box-sizing: border-box;
  border: ${(props) =>
    props.isconflicting === "true"
      ? `2px solid ${OUTLINE_COLORS.conflicting}`
      : props.isediting === "true"
      ? `2px solid ${OUTLINE_COLORS.editing}`
      : props.isselected === "true"
      ? `1px solid ${OUTLINE_COLORS.selected}`
      : "1px solid #e0e0e0"};
  cursor: pointer;

  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
`;

interface TimeslotComponentProps {
  timeslot: Timeslot;
  dayBounds: number[];
  pxPerMs: number;
  currentTimezone: string;
  activityCategoryMap: Record<string, ActivityJoinCategory>;
  isSelected: boolean;
  isConflicting: boolean;
  isEditing: boolean;
  disableHover: boolean;
  isReduced?: boolean;
  onMouseEnter: () => void;
  onClick: () => void;
  onDoubleClick: () => void;
  onToggleNotesView?: () => void;
}

export const TimeslotComponent: React.FC<TimeslotComponentProps> = ({
  timeslot,
  dayBounds,
  pxPerMs,
  currentTimezone,
  activityCategoryMap,
  isSelected,
  isConflicting,
  isEditing,
  disableHover,
  isReduced = false,
  onMouseEnter,
  onClick,
  onDoubleClick,
  onToggleNotesView,
}) => {
  const activity = activityCategoryMap[timeslot.activityId];
  const IconComponent =
    activity && ICONS[activity.icon] ? ICONS[activity.icon] : QuestionMark;
  const colour = activity ? activity.colour : "#999999";
  const name = activity
    ? activity.name.charAt(0).toUpperCase() + activity.name.slice(1)
    : "Unknown";
  const top = (timeslot.startTimestampMills - dayBounds[0]) * pxPerMs;
  const height =
    (timeslot.endTimestampMills - timeslot.startTimestampMills) * pxPerMs;
  const minutes =
    (timeslot.endTimestampMills - timeslot.startTimestampMills) / 1000 / 60;

  const timezone = timeslot.timezone;
  const hasNotes = !!timeslot.notes && timeslot.notes.trim() !== "";

  let sx: SxProps<Theme> = {
    top: `${top}px`,
    height: `${height}px`,
    backgroundColor: isSelected ? `${colour}CC` : `${colour}33`,

    overflow: isEditing ? "visible" : "hidden",
  };
  if (!disableHover) {
    sx = {
      ...sx,
      "&:hover": {
        backgroundColor: isSelected ? `${colour}CC` : `${colour}55`,
      },
    };
  }
  const isWrongTimezone = timezone !== currentTimezone;

  const handleNotesIconClick = (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent the click from triggering the parent onClick
    if (onToggleNotesView) {
      onToggleNotesView();
    }
  };

  // Render reduced version when requested
  if (isReduced) {
    return (
      <TimeslotRow
        sx={sx}
        isreduced={isReduced.toString()}
        isselected={isSelected.toString()}
        isconflicting={isConflicting.toString()}
        isediting={isEditing.toString()}
        onMouseEnter={onMouseEnter}
        onClick={onClick}
        onDoubleClick={onDoubleClick}>
        {height >= SHORT_TIMESLOT_HEIGHT ? (
          <Stack direction="row" padding={0.5} gap={0.5} alignItems="center">
            <IconComponent sx={{ fontSize: "0.9rem" }} />
            <Typography
              textAlign="left"
              sx={{
                flexGrow: 1,
                fontSize: "0.9rem",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}>
              {name}
            </Typography>
          </Stack>
        ) : null}
      </TimeslotRow>
    );
  }

  // Render full version
  return (
    <TimeslotRow
      sx={sx}
      isreduced={isReduced.toString()}
      isselected={isSelected.toString()}
      isconflicting={isConflicting.toString()}
      isediting={isEditing.toString()}
      onMouseEnter={onMouseEnter}
      onClick={onClick}
      onDoubleClick={onDoubleClick}>
      {height >= MID_TIMESLOT_HEIGHT ? (
        <Stack direction="row" padding={1} gap={1} alignItems="center">
          <IconComponent />
          <Typography textAlign="left" sx={{ flexGrow: 1 }}>
            {name}
          </Typography>
          {timeslot.placeId && <LocationOnIcon />}
          {hasNotes && (
            <Tooltip title="Toggle notes view" arrow placement="top">
              <IconButton
                size="small"
                onClick={handleNotesIconClick}
                sx={{ padding: 0 }}>
                <NotesIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
          {isWrongTimezone && (
            <Typography sx={{ fontSize: "14px", color: "red" }}>
              {timezone}
            </Typography>
          )}
          <Typography sx={{ width: "70px", textAlign: "right" }}>
            {timeDisplay(minutes)}
          </Typography>
        </Stack>
      ) : height >= SHORT_TIMESLOT_HEIGHT ? (
        <Stack direction="row" alignItems="center" paddingX={1} gap={1}>
          <IconComponent sx={{ fontSize: "12px" }} />

          <Typography textAlign="left" sx={{ flexGrow: 1, fontSize: "12px" }}>
            {name}
          </Typography>

          {timeslot.placeId && <LocationOnIcon sx={{ fontSize: "12px" }} />}
          {hasNotes && (
            <Tooltip title="Toggle notes view" arrow placement="top">
              <IconButton
                size="small"
                onClick={handleNotesIconClick}
                sx={{ padding: 0 }}>
                <NotesIcon sx={{ fontSize: "12px" }} />
              </IconButton>
            </Tooltip>
          )}
          {isWrongTimezone && (
            <Typography sx={{ fontSize: "10px", color: "red" }}>
              {timezone}
            </Typography>
          )}
          <Typography
            sx={{ fontSize: "12px", width: "70px", textAlign: "right" }}>
            {timeDisplay(minutes)}
          </Typography>
        </Stack>
      ) : null}
    </TimeslotRow>
  );
};

export default TimeslotComponent;
