import { Add, Close, Edit, QuestionMark } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { format, formatDistanceToNow, intervalToDuration } from "date-fns";
import { useLiveQuery } from "dexie-react-hooks";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ICONS } from "../../../const/icons";
import { db, Timeslot } from "../../../database/db";
import { useActivityJoinCategory } from "../../../hooks/useActivityJoinCategory";
import { useCloudSyncedDB } from "../../../hooks/useCloudSyncedDb";
import { useSnackbar } from "../../../hooks/useSnackbar";
import { DayTimeslotNotesSummary } from "./DayTimeslotNotesSummary";
import { DayTimeslotsSummary } from "./DayTimeslotsSummary";

interface JournalDayViewProps {
  date: string;
  timeslots: Timeslot[];
  dayBounds: [number, number] | null;
  currentTimezone: string;
}

export const JournalDayView = ({
  date,
  timeslots,
  dayBounds,
  currentTimezone,
}: JournalDayViewProps) => {
  const { activityCategoryMap } = useActivityJoinCategory();
  const cloudSyncedDB = useCloudSyncedDB();
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  // State for timeslot notes
  const [selectedTimeslot, setSelectedTimeslot] = useState<Timeslot | null>(
    null
  );
  const [timeslotNotes, setTimeslotNotes] = useState("");
  const [isSavingTimeslotNotes, setIsSavingTimeslotNotes] = useState(false);
  const [notesSaved, setNotesSaved] = useState(false);
  const debounceTimerRef = useRef<NodeJS.Timeout | null>(null);

  // State for journal entry
  const [journalText, setJournalText] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [lastSaved, setLastSaved] = useState<Date | null>(null);

  // State for custom fields
  const [customFieldValues, setCustomFieldValues] = useState<
    Record<string, any>
  >({});
  const [isSavingCustomFields, setIsSavingCustomFields] = useState(false);

  // Get journal entry for the selected date
  const journalEntry = useLiveQuery(async () => {
    return await db.journals.where("date").equals(date).first();
  }, [date]);

  // Get journal field definitions
  const fieldDefinitions = useLiveQuery(
    () =>
      db.journalFieldDefinitions.where("archivedAt").equals(0).sortBy("order"),
    []
  );

  // Find the selected timeslot from the timeslots array
  useEffect(() => {
    if (timeslots && selectedTimeslot) {
      const timeslot = timeslots.find((t) => t.id === selectedTimeslot?.id);
      setSelectedTimeslot(timeslot || null);
    } else {
      setSelectedTimeslot(null);
    }
  }, [selectedTimeslot, timeslots]);

  // Update timeslot notes when selected timeslot changes
  useEffect(() => {
    if (selectedTimeslot) {
      setTimeslotNotes(selectedTimeslot.notes || "");
    } else {
      setTimeslotNotes("");
    }
  }, [selectedTimeslot]);

  // Update journal text when journal entry changes
  useEffect(() => {
    if (journalEntry) {
      setJournalText(journalEntry.entry);
    } else {
      setJournalText("");
    }
  }, [journalEntry]);

  // Format identifier to title case for display
  const formatIdentifier = (identifier: string) => {
    return identifier
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  // Parse userData when journal entry changes
  useEffect(() => {
    if (journalEntry?.userData) {
      try {
        const userData = JSON.parse(journalEntry.userData);
        setCustomFieldValues(userData);
      } catch (error) {
        console.error("Error parsing userData:", error);
        setCustomFieldValues({});
      }
    } else {
      setCustomFieldValues({});
    }
  }, [journalEntry]);

  // Handle custom field change
  const handleCustomFieldChange = async (identifier: string, value: any) => {
    const newValues = { ...customFieldValues, [identifier]: value };
    setCustomFieldValues(newValues);

    // Save the updated values
    if (!journalEntry) {
      // If no journal entry exists yet, create one with the custom field
      if (!date) return;

      setIsSavingCustomFields(true);
      try {
        const [startMs, endMs] = dayBounds || [0, 0];
        await cloudSyncedDB.addJournalEntry({
          date,
          entry: journalText,
          startMs,
          endMs,
          userData: JSON.stringify(newValues),
        });
      } catch (error) {
        console.error("Error saving custom fields:", error);
        showSnackbar("Failed to save custom fields", "error");
      } finally {
        setIsSavingCustomFields(false);
      }
    } else {
      // Update existing journal entry
      setIsSavingCustomFields(true);
      try {
        await cloudSyncedDB.updateJournalEntry(journalEntry.id, {
          userData: JSON.stringify(newValues),
        });
      } catch (error) {
        console.error("Error saving custom fields:", error);
        showSnackbar("Failed to save custom fields", "error");
      } finally {
        setIsSavingCustomFields(false);
      }
    }
  };

  // Save journal entry with debounce
  useEffect(() => {
    if (journalText === journalEntry?.entry) return;

    const timer = setTimeout(async () => {
      if (!date) return;

      setIsSaving(true);
      try {
        if (journalEntry) {
          if (journalText.trim() === "") {
            await cloudSyncedDB.deleteJournalEntry(journalEntry.id);
          } else {
            await cloudSyncedDB.updateJournalEntry(journalEntry.id, {
              entry: journalText,
            });
          }
        } else if (journalText.trim()) {
          const [startMs, endMs] = dayBounds || [0, 0];
          await cloudSyncedDB.addJournalEntry({
            date,
            entry: journalText,
            startMs,
            endMs,
          });
        }
        setLastSaved(new Date());
      } catch (error) {
        console.error("Error saving journal entry:", error);
        showSnackbar("Failed to save journal entry", "error");
      } finally {
        setIsSaving(false);
      }
    }, 1000); // 1 second debounce

    return () => clearTimeout(timer);
  }, [journalText, date, dayBounds, cloudSyncedDB, showSnackbar, journalEntry]);

  // Handle blur event to save immediately
  const handleNotesBlur = useCallback(() => {
    if (!selectedTimeslot) return;

    // Cancel any pending debounce
    if (debounceTimerRef.current) {
      clearTimeout(debounceTimerRef.current);
      debounceTimerRef.current = null;
    }

    // Only save if there are actual changes
    if (timeslotNotes !== selectedTimeslot.notes) {
      // Save immediately without debounce
      setIsSavingTimeslotNotes(true);
      cloudSyncedDB
        .updateTimeslot(selectedTimeslot.id, {
          notes: timeslotNotes,
        })
        .then(() => {
          // Update the selected timeslot with the new notes
          setSelectedTimeslot({
            ...selectedTimeslot,
            notes: timeslotNotes,
          });

          // Show saved notification
          setNotesSaved(true);
          setTimeout(() => {
            setNotesSaved(false);
          }, 2000);
        })
        .catch((error: Error) => {
          console.error("Error saving timeslot notes:", error);
          showSnackbar("Failed to save notes", "error");
        })
        .finally(() => {
          setIsSavingTimeslotNotes(false);
        });
    }
  }, [selectedTimeslot, timeslotNotes, cloudSyncedDB, showSnackbar]);

  // Debounced save for auto-saving while typing
  const debouncedSave = useCallback(() => {
    if (!selectedTimeslot) return;

    if (debounceTimerRef.current) {
      clearTimeout(debounceTimerRef.current);
    }

    debounceTimerRef.current = setTimeout(() => {
      // Only save if there are actual changes
      if (timeslotNotes !== selectedTimeslot.notes) {
        handleNotesBlur();
      }
    }, 1500); // 1.5 second debounce
  }, [selectedTimeslot, timeslotNotes, handleNotesBlur]);

  // Auto-save when notes change
  useEffect(() => {
    if (selectedTimeslot && timeslotNotes !== selectedTimeslot.notes) {
      debouncedSave();
    }

    return () => {
      // Clean up debounce timer on unmount
      if (debounceTimerRef.current) {
        clearTimeout(debounceTimerRef.current);
      }
    };
  }, [timeslotNotes, selectedTimeslot, debouncedSave]);

  // Handle timeslot selection
  const handleSelectTimeslot = useCallback((timeslot: Timeslot | null) => {
    // If clicking the same timeslot that's already selected, do nothing
    if (selectedTimeslot && timeslot && selectedTimeslot.id === timeslot.id) {
      return;
    }
    // Save current notes before changing selection
    if (selectedTimeslot && timeslotNotes !== selectedTimeslot.notes) {
      handleNotesBlur();
    }
    setSelectedTimeslot(timeslot);
    if (timeslot) {
      setTimeslotNotes(timeslot.notes || "");
    } else {
      setTimeslotNotes("");
    }
  }, []);

  // Handle ESC key to deselect timeslot
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Escape" && selectedTimeslot) {
        // Save notes before deselecting
        if (timeslotNotes !== selectedTimeslot.notes) {
          handleNotesBlur();
        }
        setSelectedTimeslot(null);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [selectedTimeslot, timeslotNotes, handleNotesBlur]);

  // Sort timeslots by start time
  const sortedTimeslots = useMemo(() => {
    return [...timeslots].sort(
      (a, b) => a.startTimestampMills - b.startTimestampMills
    );
  }, [timeslots]);

  return (
    <Stack direction="column" spacing={2}>
      <Paper sx={{ p: 2 }}>
        <Stack
          direction={{
            sm: "column",
            md: "row",
          }}
          spacing={2}>
          <DayTimeslotsSummary
            date={date}
            currentTimezone={currentTimezone}
            sortedTimeslots={sortedTimeslots}
            handleSelectTimeslot={handleSelectTimeslot}
            selectedTimeslot={selectedTimeslot}
          />
          {selectedTimeslot ? (
            <>
              <Divider orientation="vertical" flexItem />
              <Divider orientation="horizontal" flexItem />
              <Stack
                direction="column"
                spacing={2}
                sx={{ p: 2, minWidth: 350 }}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={1}>
                  {(() => {
                    // Get the activity details
                    const activityId = selectedTimeslot.activityId;
                    const activity = activityCategoryMap[activityId];
                    const category = activity
                      ? activityCategoryMap[activity.id]
                      : null;

                    return (
                      <>
                        <Box
                          sx={{
                            mr: 2,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: 40,
                            height: 40,
                            borderRadius: "50%",
                            bgcolor: category?.colour || "#ccc",
                            color: "white",
                          }}>
                          {(() => {
                            const Icon =
                              activity?.icon && ICONS[activity.icon]
                                ? ICONS[activity.icon]
                                : QuestionMark;
                            return <Icon fontSize="small" />;
                          })()}
                        </Box>

                        <Box sx={{ flexGrow: 1 }}>
                          <Typography variant="subtitle1" fontWeight="medium">
                            {activity?.name || "Unknown Activity"}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            {format(
                              new Date(selectedTimeslot.startTimestampMills),
                              "HH:mm"
                            )}{" "}
                            -
                            {format(
                              new Date(selectedTimeslot.endTimestampMills),
                              "HH:mm"
                            )}
                            {" • "}
                            {(() => {
                              const duration = intervalToDuration({
                                start: new Date(
                                  selectedTimeslot.startTimestampMills
                                ),
                                end: new Date(
                                  selectedTimeslot.endTimestampMills
                                ),
                              });

                              // Format duration in a readable way
                              const hours = duration.hours || 0;
                              const minutes = duration.minutes || 0;

                              if (hours > 0) {
                                return `${hours}h ${minutes}m`;
                              } else {
                                return `${minutes}m`;
                              }
                            })()}
                          </Typography>
                        </Box>
                      </>
                    );
                  })()}
                  <IconButton
                    onClick={() => setSelectedTimeslot(null)}
                    size="small"
                    aria-label="close">
                    <Close fontSize="small" />
                  </IconButton>
                </Stack>

                <Box position="relative">
                  <TextField
                    fullWidth
                    multiline
                    minRows={4}
                    maxRows={8}
                    value={timeslotNotes}
                    onChange={(e) => setTimeslotNotes(e.target.value)}
                    onBlur={handleNotesBlur}
                    placeholder="Add notes about this activity..."
                    variant="outlined"
                  />

                  <Box
                    sx={{
                      position: "absolute",
                      bottom: 8,
                      right: 8,
                      transition: "opacity 0.3s ease-in-out",
                      opacity: isSavingTimeslotNotes || notesSaved ? 1 : 0,
                    }}>
                    <Typography variant="caption" color="text.secondary">
                      {isSavingTimeslotNotes
                        ? "Saving..."
                        : notesSaved
                        ? "Saved"
                        : ""}
                    </Typography>
                  </Box>
                </Box>
              </Stack>
            </>
          ) : (
            <DayTimeslotNotesSummary sortedTimeslots={sortedTimeslots} />
          )}
        </Stack>
      </Paper>

      <Paper sx={{ p: 2, mt: 2 }}>
        <Typography variant="h5" gutterBottom>
          Journal
        </Typography>
        <TextField
          fullWidth
          multiline
          minRows={10}
          maxRows={20}
          value={journalText}
          onChange={(e) => setJournalText(e.target.value)}
          placeholder="Write about your day..."
          variant="outlined"
        />
        <Box sx={{ mt: 1, textAlign: "right" }}>
          {isSaving ? (
            <Typography variant="caption" color="text.secondary">
              Saving...
            </Typography>
          ) : lastSaved && journalEntry ? (
            <Typography variant="caption" color="text.secondary">
              Last saved at {format(lastSaved, "HH:mm:ss")} (
              {formatDistanceToNow(lastSaved, { addSuffix: true })})
            </Typography>
          ) : null}
        </Box>
        {fieldDefinitions && fieldDefinitions.length > 0 ? (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mt: 2,
                mb: 1,
              }}>
              <Typography variant="h6">Custom Fields</Typography>
              <Button
                size="small"
                variant="outlined"
                onClick={() => {
                  navigate("/settings/journal");
                }}>
                <Edit />
              </Button>
            </Box>
            <Table>
              <TableBody>
                {fieldDefinitions.map((field) => (
                  <TableRow key={field.id}>
                    <TableCell width="30%" sx={{ borderBottom: "none" }}>
                      <Typography variant="body1">
                        {formatIdentifier(field.identifier)}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ borderBottom: "none" }}>
                      {field.type === "text" && (
                        <TextField
                          fullWidth
                          value={customFieldValues[field.identifier] || ""}
                          onChange={(e) =>
                            handleCustomFieldChange(
                              field.identifier,
                              e.target.value
                            )
                          }
                          placeholder={`Enter ${formatIdentifier(
                            field.identifier
                          )}`}
                          variant="outlined"
                          size="small"
                        />
                      )}
                      {field.type === "checkbox" && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={!!customFieldValues[field.identifier]}
                              onChange={(e) =>
                                handleCustomFieldChange(
                                  field.identifier,
                                  e.target.checked
                                )
                              }
                            />
                          }
                          label=""
                        />
                      )}
                      {field.type === "number" && (
                        <TextField
                          fullWidth
                          type="number"
                          value={customFieldValues[field.identifier] || ""}
                          onChange={(e) =>
                            handleCustomFieldChange(
                              field.identifier,
                              e.target.value === ""
                                ? ""
                                : Number(e.target.value)
                            )
                          }
                          placeholder={`Enter ${formatIdentifier(
                            field.identifier
                          )}`}
                          variant="outlined"
                          size="small"
                        />
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {isSavingCustomFields && (
              <Box sx={{ mt: 1, textAlign: "right" }}>
                <Typography variant="caption" color="text.secondary">
                  Saving...
                </Typography>
              </Box>
            )}
          </>
        ) : (
          <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
            <Button
              variant="outlined"
              startIcon={<Add />}
              onClick={() => {
                navigate("/settings/journal");
              }}>
              Add Custom Journal Fields
            </Button>
          </Box>
        )}
      </Paper>
    </Stack>
  );
};
