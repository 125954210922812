import { Container, Typography } from "@mui/material";
import { DataExport } from "../data/components/DataExport";
import SmarterTimeImport from "../data/components/SmarterTimeImport";
import JournalImport from "../data/components/JournalImport";

export const ImportExport = () => {
  return (
    <Container sx={{ paddingY: "50px" }}>
      <Typography variant="h1">Import / Export</Typography>

      <DataExport />
      <JournalImport />
      <SmarterTimeImport />
    </Container>
  );
};
