import { useEffect, useMemo, useState } from "react";

import styled from "@emotion/styled";
import { SNAP_THRESHOLD_PX, snapToPoint } from "./utils";

const AddButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 130px;
  min-width: 0;
  right: 0px;
  font-size: 20px;
  background: #ffffffaa;
  z-index: 400;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid #00000022;

  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);

  &.clicked {
    border-top: 1px solid #ff000066;
    border-bottom: 1px solid #ff000066;
    transition: height 80ms linear, top 80ms linear;
    // overflow: hidden;

    .timeLabel {
      opacity: 1;
    }
  }

  &.topBar {
    border-top: 2px solid #ff000066;
  }

  &.bottomBar {
    border-bottom: 2px solid #ff000066;
  }

  .timeLabel {
    position: absolute;
    color: #666;
    left: -50px;
    font-size: 14px;
    background-color: #ffffffaa;
    padding: 2px 4px;
    border-radius: 2px;

    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);

    opacity: 1;
    transition: opacity 0.1s linear;
    transition: top 0.1s linear;
    &.top {
      top: -10px;
    }
    &.topOffset {
      top: 18px;
    }

    &.bottom {
      bottom: -10px;
    }
  }

  &:hover {
    .timeLabel {
      opacity: 1;
    }
  }
`;

const PlusIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  font-size: 18px;
  color: #666;
  border-radius: 50%;
  background: #ffffffcc;
  margin-top: -2px;
`;

// Add this new component before the TimelineView component
type TimelineAddButtonProps = {
  mouseYPosition: number;
  dayBounds: [number, number];
  allSnapPoints: number[];
  pxPerMs: number;
  onAddTimeslot: (addMs: number, lengthMs: number) => void;
};

export const TimelineAddButton = ({
  mouseYPosition,
  dayBounds,
  allSnapPoints,
  pxPerMs,
  onAddTimeslot,
}: TimelineAddButtonProps) => {
  const [dragging, setDragging] = useState(false);
  const [draggingStartY, setDraggingStartY] = useState(0);
  const [barType, setBarType] = useState<"topBar" | "bottomBar" | "none">(
    "none"
  );

  const allSnapPointsPx: number[] = useMemo(() => {
    return allSnapPoints.map((ms) => (ms - dayBounds[0]) * pxPerMs);
  }, [allSnapPoints, dayBounds, pxPerMs]);

  const snappedMouseYPosition = useMemo(() => {
    return snapToPoint(mouseYPosition, allSnapPointsPx, SNAP_THRESHOLD_PX);
  }, [mouseYPosition, allSnapPointsPx]);

  const addButtonPosition = useMemo(() => {
    let addPx = 0;
    let addHeightPx = 0;
    if (dragging) {
      // Calculate the difference in y position since the last mouse move
      const yDiff = snappedMouseYPosition - draggingStartY;
      if (Math.abs(yDiff) > 5) {
        if (yDiff > 0) {
          addPx = draggingStartY;
          addHeightPx = yDiff;
          setBarType("topBar");
        } else {
          addHeightPx = draggingStartY - snappedMouseYPosition;
          addPx = snappedMouseYPosition;
          setBarType("bottomBar");
        }
      }
    }

    if (addHeightPx === 0 && addPx === 0) {
      addHeightPx = 10;
      addPx = snappedMouseYPosition - addHeightPx / 2;
      setBarType("none");
    }

    const maxPx = (dayBounds[1] - dayBounds[0]) * pxPerMs;
    if (addPx < 0) addPx = 0;
    if (addPx > maxPx) addPx = maxPx;
    return {
      addPx,
      addHeightPx,
      addMs: addPx / pxPerMs,
      addHeightMs: addHeightPx / pxPerMs,
    };
  }, [dayBounds, dragging, draggingStartY, pxPerMs, snappedMouseYPosition]);

  useEffect(() => {
    const handleRelease = () => {
      console.log("handleRelease");
      if (dragging) {
        setDragging(false);
        if (barType === "none") {
          onAddTimeslot(
            dayBounds[0] + addButtonPosition.addMs,
            addButtonPosition.addHeightMs
          );
        } else {
          onAddTimeslot(
            dayBounds[0] + addButtonPosition.addMs,
            addButtonPosition.addHeightMs
          );
        }
      }
    };

    window.addEventListener("mouseup", handleRelease);
    window.addEventListener("touchend", handleRelease);
    return () => {
      window.removeEventListener("mouseup", handleRelease);
      window.removeEventListener("touchend", handleRelease);
    };
  }, [
    dragging,
    onAddTimeslot,
    addButtonPosition.addPx,
    addButtonPosition.addHeightPx,
    dayBounds,
    pxPerMs,
    barType,
    addButtonPosition.addMs,
    addButtonPosition.addHeightMs,
  ]);

  return (
    <AddButton
      style={{
        top: `${addButtonPosition.addPx}px`,
        height: `${addButtonPosition.addHeightPx}px`,
      }}
      className={`${barType} ${dragging ? "clicked" : ""}`}
      onClick={() => {
        console.log("clicked");
        onAddTimeslot(
          dayBounds[0] +
            addButtonPosition.addMs +
            addButtonPosition.addHeightMs / 2,
          addButtonPosition.addHeightMs
        );
      }}
      onMouseDown={() => {
        setDragging(true);
        setDraggingStartY(snappedMouseYPosition);
      }}
      onTouchStart={() => {
        setDragging(true);
        setDraggingStartY(snappedMouseYPosition);
      }}>
      <PlusIcon>+</PlusIcon>

      {dragging && barType === "none" ? (
        <div className="timeLabel top">
          {new Date(
            dayBounds[0] +
              addButtonPosition.addMs +
              addButtonPosition.addHeightMs / 2
          ).toLocaleTimeString("en-GB", {
            hour: "2-digit",
            minute: "2-digit",
          })}
        </div>
      ) : dragging && barType !== "none" ? (
        <>
          <div className="timeLabel top">
            {new Date(
              dayBounds[0] + addButtonPosition.addMs
            ).toLocaleTimeString("en-GB", {
              hour: "2-digit",
              minute: "2-digit",
            })}
          </div>

          <div
            className={`timeLabel ${
              addButtonPosition.addHeightPx > 40 ? "bottom" : "topOffset"
            }`}>
            {new Date(
              dayBounds[0] +
                addButtonPosition.addMs +
                addButtonPosition.addHeightMs
            ).toLocaleTimeString("en-GB", {
              hour: "2-digit",
              minute: "2-digit",
            })}
          </div>
        </>
      ) : (
        <div className="timeLabel top">
          {new Date(
            dayBounds[0] +
              addButtonPosition.addMs +
              addButtonPosition.addHeightMs / 2
          ).toLocaleTimeString("en-GB", {
            hour: "2-digit",
            minute: "2-digit",
          })}
        </div>
      )}
    </AddButton>
  );
};
