import {
  Button,
  Container,
  Paper,
  Typography,
  Box,
  CircularProgress,
  Alert,
  List,
  ListItem,
  ListItemText,
  Divider,
  Card,
  CardContent,
  Grid,
  Chip,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { useState } from "react";
import { db, Timeslot } from "../database/db";
import { useCloudSyncedDB } from "../hooks/useCloudSyncedDb";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import DeleteIcon from "@mui/icons-material/Delete";

// Type for zero-length timeslot with additional information
interface ZeroLengthTimeslot extends Timeslot {
  activityName?: string;
  categoryName?: string;
  formattedDate: string;
  dateString: string;
}

export const DataCleanup = () => {
  const cloudSyncedDB = useCloudSyncedDB();
  const navigate = useNavigate();
  const [analysing, setAnalysing] = useState(false);
  const [zeroLengthTimeslots, setZeroLengthTimeslots] = useState<
    ZeroLengthTimeslot[]
  >([]);
  const [deleting, setDeleting] = useState(false);
  const [deleteComplete, setDeleteComplete] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [deletingTimeslot, setDeletingTimeslot] = useState<string | null>(null);

  const analyseTimeslots = async () => {
    try {
      setAnalysing(true);
      setError(null);
      setDeleteComplete(false);

      // Get all timeslots
      const timeslots = await db.timeslots.toArray();

      // Find timeslots with zero duration
      const zeroLength = timeslots.filter(
        (timeslot) =>
          timeslot.endTimestampMills === timeslot.startTimestampMills
      );

      // Get all activities and categories for reference
      const activities = await db.activities.toArray();
      const categories = await db.categories.toArray();

      // Create a map of activity IDs to names
      const activityMap = new Map(activities.map((a) => [a.id, a]));
      const categoryMap = new Map(categories.map((c) => [c.id, c.name]));

      // Enhance timeslots with additional information
      const enhancedTimeslots = zeroLength.map((timeslot) => {
        const activity = activityMap.get(timeslot.activityId);
        const date = new Date(timeslot.startTimestampMills);
        const dateString = format(date, "yyyy-MM-dd");
        const formattedDate = format(date, "dd MMM yyyy");

        return {
          ...timeslot,
          activityName: activity?.name,
          categoryName: activity
            ? categoryMap.get(activity.categoryId)
            : undefined,
          formattedDate,
          dateString,
        };
      });

      // Group by date for better organization
      const sortedTimeslots = enhancedTimeslots.sort(
        (a, b) => b.startTimestampMills - a.startTimestampMills
      );

      setZeroLengthTimeslots(sortedTimeslots);
      setAnalysing(false);
    } catch (err) {
      setError(
        "Error analysing timeslots: " +
          (err instanceof Error ? err.message : String(err))
      );
      setAnalysing(false);
    }
  };

  const deleteZeroLengthTimeslots = async () => {
    try {
      setDeleting(true);
      setError(null);

      // Delete each zero-length timeslot
      for (const timeslot of zeroLengthTimeslots) {
        await cloudSyncedDB.deleteTimeslot(timeslot.id);
      }

      setDeleteComplete(true);
      setZeroLengthTimeslots([]);
      setDeleting(false);
    } catch (err) {
      setError(
        "Error deleting timeslots: " +
          (err instanceof Error ? err.message : String(err))
      );
      setDeleting(false);
    }
  };

  const deleteTimeslot = async (timeslotId: string) => {
    try {
      setDeletingTimeslot(timeslotId);
      await cloudSyncedDB.deleteTimeslot(timeslotId);
      setZeroLengthTimeslots((prev) => prev.filter((t) => t.id !== timeslotId));
      setDeletingTimeslot(null);
    } catch (err) {
      setError(
        "Error deleting timeslot: " +
          (err instanceof Error ? err.message : String(err))
      );
      setDeletingTimeslot(null);
    }
  };

  const navigateToDay = (dateString: string) => {
    navigate(`/home/${dateString}/timeline`);
  };

  // Group timeslots by date
  const timeslotsByDate = zeroLengthTimeslots.reduce((acc, timeslot) => {
    if (!acc[timeslot.dateString]) {
      acc[timeslot.dateString] = [];
    }
    acc[timeslot.dateString].push(timeslot);
    return acc;
  }, {} as Record<string, ZeroLengthTimeslot[]>);

  return (
    <Container>
      <Button
        variant="outlined"
        startIcon={<ArrowBackIcon />}
        onClick={() => navigate("/settings")}>
        Back to Settings
      </Button>
      <Typography variant="h1" gutterBottom>
        Data Cleanup
      </Typography>

      <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
        <Typography variant="h2" gutterBottom>
          Timeslot Cleanup
        </Typography>

        <Typography variant="body1" paragraph>
          This tool helps you find and remove timeslots with zero duration (0
          minutes long). These can sometimes be created accidentally and may
          clutter your timeline.
        </Typography>

        <Box sx={{ mb: 2 }}>
          <Button
            variant="contained"
            onClick={analyseTimeslots}
            disabled={analysing}
            sx={{ mr: 2 }}>
            {analysing ? <CircularProgress size={24} /> : "Analyse Timeslots"}
          </Button>

          {zeroLengthTimeslots.length > 0 && (
            <Button
              variant="contained"
              color="error"
              onClick={deleteZeroLengthTimeslots}
              disabled={deleting}
              startIcon={<DeleteIcon />}>
              {deleting ? (
                <CircularProgress size={24} />
              ) : (
                `Delete All ${zeroLengthTimeslots.length} Zero-Length Timeslots`
              )}
            </Button>
          )}
        </Box>

        {zeroLengthTimeslots.length > 0 && (
          <Alert severity="warning" sx={{ mb: 2 }}>
            Found {zeroLengthTimeslots.length} timeslot
            {zeroLengthTimeslots.length !== 1 ? "s" : ""} with zero duration.
          </Alert>
        )}

        {Object.entries(timeslotsByDate).length > 0 && (
          <Box sx={{ mt: 3 }}>
            <Typography variant="h3" gutterBottom>
              Zero-Length Timeslots by Date
            </Typography>

            {Object.entries(timeslotsByDate).map(([dateString, timeslots]) => (
              <Card key={dateString} sx={{ mb: 2 }}>
                <CardContent>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={6}>
                      <Typography variant="h6">
                        {timeslots[0].formattedDate}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {timeslots.length} zero-length timeslot
                        {timeslots.length !== 1 ? "s" : ""}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      sx={{ textAlign: { sm: "right" } }}>
                      <Button
                        variant="outlined"
                        startIcon={<CalendarTodayIcon />}
                        onClick={() => navigateToDay(dateString)}
                        sx={{ mr: 1 }}>
                        Go to Day
                      </Button>
                    </Grid>
                  </Grid>

                  <Divider sx={{ my: 2 }} />

                  <List dense>
                    {timeslots.map((timeslot) => (
                      <ListItem
                        key={timeslot.id}
                        secondaryAction={
                          <Button
                            size="small"
                            color="error"
                            onClick={() => deleteTimeslot(timeslot.id)}
                            disabled={deletingTimeslot === timeslot.id}
                            startIcon={
                              deletingTimeslot === timeslot.id ? (
                                <CircularProgress size={16} />
                              ) : (
                                <DeleteIcon />
                              )
                            }>
                            Delete
                          </Button>
                        }>
                        <ListItemText
                          primary={
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <Typography variant="body1">
                                {timeslot.activityName || "Unknown Activity"}
                              </Typography>
                              {timeslot.categoryName && (
                                <Chip
                                  label={timeslot.categoryName}
                                  size="small"
                                  sx={{ ml: 1 }}
                                />
                              )}
                            </Box>
                          }
                          secondary={
                            <Typography variant="body2">
                              {format(
                                new Date(timeslot.startTimestampMills),
                                "HH:mm"
                              )}{" "}
                              - Zero duration
                            </Typography>
                          }
                        />
                      </ListItem>
                    ))}
                  </List>
                </CardContent>
              </Card>
            ))}
          </Box>
        )}

        {deleteComplete && (
          <Alert severity="success" sx={{ mt: 2 }}>
            Successfully deleted all zero-length timeslots.
          </Alert>
        )}

        {error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
      </Paper>
    </Container>
  );
};
