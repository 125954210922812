import { TZDate } from "@date-fns/tz";
import styled from "@emotion/styled";
import {
  ArrowDownward,
  ArrowUpward,
  Close,
  DragHandle,
} from "@mui/icons-material";
import { Button, Stack, Typography } from "@mui/material";
import { format } from "date-fns";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { PRIMARY_COLOUR } from "../../../../const/colours";
import { LocationHistory, Place, Timeslot } from "../../../../database/db";
import { ActivityJoinCategory } from "../../../../database/helpers";
import { useCloudSyncedDB } from "../../../../hooks/useCloudSyncedDb";
import { useUndoRedo } from "../../../../hooks/useUndoRedo";
import {
  applyTimeslotChanges,
  getConflictingTimeslots,
  getTimeslotAdjustments,
  TimeslotChange,
  TimeslotLite,
} from "../../../../utils/conflict_management";
import {
  createTimeBlocksFromLocationHistory,
  LocationTimeBlock,
} from "../../../../utils/location_history_processing";
import {
  getTotalHours,
  hourLabelBackgroundColor,
  timeOfDay,
} from "../../../../utils/time";
import {
  generateTimeslotBoundaries,
  getBlankTimeRanges,
} from "../../../../utils/timeslot";
import {
  NewTimeslotDraggableIndicator,
  NewTimeslotParams,
} from "../AddTimeslotComponents";
import { LocationHistoryColumn } from "../LocationHistoryColumn";
import { TimeslotNotes } from "../TimeslotNotes";
import { TimelineAddButton } from "./AddButton";
import BlankTimeslotComponent from "./BlankTimeslotComponent";
import { CurrentTimeLine } from "./CurrentTimeLine";
import DraggingTimeslotComponent from "./DraggingTimeslotComponent";
import TimeslotComponent from "./TimeslotComponent";
import {
  BlankTimeslot,
  TimeBlock,
  TimeslotModificationContext,
  updateTimeslotModification,
} from "./utils";

const TimezoneHeader = styled.div<{
  width: number;
  highlighted: boolean;
}>`
  position: relative;
  height: 100%;
  background-color: #f7f7f7 
  padding: 5px;
  border-radius: 5px 5px 0 0;
  border: 1px solid #e0e0e0;
  border-top-color: ${(props) => (props.highlighted ? "#f00" : "inherited")};
  border-left-color: ${(props) => (props.highlighted ? "#f00" : "inherited")};
  border-right-color: ${(props) => (props.highlighted ? "#f00" : "inherited")};
  text-align: left;
  font-size: 13px;
  display: flex;
  align-items: start;
  justify-content: center;
  width: ${(props) => props.width}px;
    cursor: ${(props) => (props.highlighted ? "pointer" : "default")};

  transition: background-color 0.1s linear;
  svg {
    font-size: 16px;
    opacity: 0;
    color: #f00;
    transition: opacity 0.1s linear;
    position: absolute;
    top: -8px;
    right: -8px;
    background-color: #ffffffaa;
    padding: 2px;
    border-radius: 2px;
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
  }
  &:hover {
    svg {
      opacity: 1;
    }
    ${(props) => props.highlighted && "background-color: #ffeeee;"}
  }
`;
const Hour = styled.div<{
  height: number;
  backgroundColor: string;
  isMidnight: boolean;
}>`
  height: ${(props) => props.height - 1}px;
  position: relative;
  background-color: white;
  flex-grow: 1;
  background-color: ${(props) => props.backgroundColor};
  text-align: left;
  font-size: 13px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  outline: 1px solid rgba(176, 136, 136, 0.13);
  ${(props) =>
    props.isMidnight && `border-top: 2px solid rgba(200, 120, 120, 0.89);`}
  div {
    font-size: 12px;
    color: #666;
    font-weight: bold;
    margin-top: -9px;
    opacity: 0.5;
    filter: drop-shadow(1px 1px 1px #fff) drop-shadow(-1px -1px 1px #fff);
    transition: opacity 0.1s linear;
  }
  div:nth-of-type(2) {
    margin-bottom: -9px;
    margin-top: 0px;
  }
  &:hover {
    div {
      opacity: 1;
    }
  }
`;

const GridBackground = styled.div<{ height: number; pxPerHr: number }>`
  height: ${(props) => props.height - 1}px;
  position: relative;
  background-color: white;
  flex-grow: 1;
  text-align: left;
  font-size: 13px;
  display: flex;
  align-items: start;
  justify-content: center;
  background-image: linear-gradient(
    to bottom,
    rgba(176, 136, 136, 0.13) 1px,
    transparent 1px
  );
  background-size: 100% ${(props) => props.pxPerHr}px;
  background-position: 0 0;
  background-repeat: repeat-y;
`;

const DragButton = styled(Button)`
  position: absolute;
  background-color: #ffffffcc;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 20px;
  padding: 0;
  margin: 0;
  min-width: 0;
  z-index: 200;
  cursor: grab;
  opacity: 0.5;
  transition: opacity 0.1s linear;
  border: 1px solid ${PRIMARY_COLOUR}00;
  .handleIcon {
    font-size: 15px;
  }

  &:hover {
    border: 1px solid ${PRIMARY_COLOUR}22;
    opacity: 1;
  }
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
`;

const ScaleButton = styled(DragButton)`
  cursor: ns-resize;
  height: 10px;
  left: calc(10% + 100px);
  width: calc(80% - 300px);
`;

const MergeButton = styled(DragButton)`
  height: 20px;
  width: 130px;
  left: 40px;
  cursor: pointer;
`;

const TimeDisplay = styled(Typography)`
  position: absolute;
  top: ${(props) => `${props.top}px`};
  left: 10px;
  width: 40px;
  height: 25px;
  padding: 2px;
  background-color: ${(props) =>
    props.theme === "hover" ? "#EEEEEE" : "white"};
  text-align: left;
  border: 1px solid black;
  border-radius: 5px;
  font-size: 13px;
`;

const TimelineColumn = styled(Stack)`
  position: relative;
`;

const FixedTimeslotColumn = styled(Stack)<{
  width: number;
  highlighted: boolean;
}>`
  position: relative;
  width: ${(props) => props.width}px;
  min-width: ${(props) => props.width}px;
  flex-shrink: 0; // Add this to prevent column from shrinking
  border-left: ${(props) => (props.highlighted ? "1px solid #f00" : "none")};
  border-right: ${(props) => (props.highlighted ? "1px solid #f00" : "none")};
  border-bottom: ${(props) => (props.highlighted ? "1px solid #f00" : "none")};
`;

const HEADER_HEIGHT = 40;

type TimelineDisplayProps = {
  dayBounds: [number, number];
  pxPerHr: number;
  currentTimezone: string;
  computerTimezone: string;
  timeslots: Timeslot[] | undefined;
  locationHistory: LocationHistory[] | undefined;
  activityCategoryMap: Record<string, ActivityJoinCategory>;
  showLocationHistory: boolean;
  currentTimeMills: number;
  editingTimeslot: string | null;
  newTimeslotParams: NewTimeslotParams | null;
  selectedTimeslot: Timeslot | null;
  showNotesMode?: boolean;

  // Handlers that need to stay in parent
  setPxPerHr: (pxPerHr: number) => void;
  openAddTimeslot: (ms: number, length?: number) => void;
  applyPlaceToTimeslots: (
    locationTimeBlock: LocationTimeBlock,
    place: Place
  ) => void;
  setEditingTimeslot: (timeslotId: string | null) => void;
  setNewTimeslotParams: (params: NewTimeslotParams | null) => void;
  setSelectedTimeslot: (timeslot: Timeslot | null) => void;
  deselectAll: () => void;
  revertTimezone: () => void;
};

export const TimelineView = ({
  dayBounds,
  pxPerHr,
  currentTimezone,
  computerTimezone,
  timeslots,
  locationHistory,
  activityCategoryMap,
  showLocationHistory,
  currentTimeMills,
  editingTimeslot,
  newTimeslotParams,
  selectedTimeslot,
  showNotesMode = false,
  openAddTimeslot,
  applyPlaceToTimeslots,
  setEditingTimeslot,
  setNewTimeslotParams,
  setSelectedTimeslot,
  deselectAll,
  revertTimezone,
}: TimelineDisplayProps) => {
  const { pushUndoSteps } = useUndoRedo();
  const cloudSyncedDB = useCloudSyncedDB();

  const pxPerMs = pxPerHr / (60 * 60 * 1000);

  const totalHours: number = useMemo(() => {
    if (!dayBounds) return 0;
    return getTotalHours(dayBounds);
  }, [dayBounds]);

  // create a list of hours from 0 to 23
  // const hours = useState([...Array(totalHours).keys()])[0];
  // create a list of hours from start to end bounds
  const hourTimestamps = useMemo(() => {
    if (!dayBounds) return [];
    let startIterator = dayBounds[0];
    const endIterator = dayBounds[1];
    const hours = [];
    while (startIterator < endIterator) {
      hours.push(startIterator);
      startIterator += 1000 * 60 * 60;
    }
    console.log("hours", hours);
    return hours;
  }, [dayBounds]);

  const [mouseYPosition, setMouseYPosition] = useState<number | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  // State for highlighting conflicting timeslots, list of ids
  const [conflictingTimeslots, setConflictingTimeslots] = useState<string[]>(
    []
  );

  const [hoveredTimeslot, setHoveredTimeslot] = useState<Timeslot | null>(null);

  // State for scaling timeslots, if null means not scaling
  const [modificationContext, setModificationContext] =
    useState<TimeslotModificationContext | null>(null);

  const isModifyingTimeslot = useMemo(() => {
    return modificationContext !== null;
  }, [modificationContext]);

  const locationTimeBlocks: LocationTimeBlock[] = useMemo(() => {
    if (!dayBounds) return [];
    if (!locationHistory) return [];
    return createTimeBlocksFromLocationHistory(dayBounds, locationHistory);
  }, [dayBounds, locationHistory]);

  const blankTimeslots: BlankTimeslot[] = useMemo(() => {
    if (!dayBounds) return [];
    if (!timeslots) return [];
    // Calculate blank timeslots as the inverse of timeslots
    const blankTimeslots: BlankTimeslot[] = getBlankTimeRanges(
      timeslots,
      dayBounds
    ).map((range) => ({
      startTimestampMills: range[0],
      endTimestampMills: range[1],
    }));
    return blankTimeslots;
  }, [dayBounds, timeslots]);

  const timeBlocks: TimeBlock[] = useMemo(() => {
    if (!dayBounds) return [];
    if (!timeslots) return [];
    // Merge timeslots and blank timeslots
    const result: TimeBlock[] = [
      ...timeslots.map(
        (t) =>
          ({
            type: "timeslot",
            start: t.startTimestampMills,
            end: t.endTimestampMills,
            startPx: (t.startTimestampMills - dayBounds[0]) * pxPerMs,
            endPx: (t.endTimestampMills - dayBounds[0]) * pxPerMs,
            timeslot: t,
          } as TimeBlock)
      ),
      ...blankTimeslots.map(
        (t) =>
          ({
            type: "blank",
            start: t.startTimestampMills,
            end: t.endTimestampMills,
            startPx: (t.startTimestampMills - dayBounds[0]) * pxPerMs,
            endPx: (t.endTimestampMills - dayBounds[0]) * pxPerMs,
            timeslot: t,
          } as TimeBlock)
      ),
    ];
    result.sort((a, b) => a.startPx - b.startPx);
    return result;
  }, [dayBounds, timeslots, blankTimeslots, pxPerMs]);

  const modificationHandlePositions: number[] = useMemo(() => {
    const points: number[] = [0]; // Start of day

    timeBlocks.forEach((block) => {
      points.push(block.startPx);
      points.push(block.endPx);
    });

    // Remove duplicates
    return [...new Set(points)];
  }, [timeBlocks]);

  // Position of the handle closest to the user's cursor
  const modificationHandleYPosition: number | null = useMemo(() => {
    if (mouseYPosition === null) return null;
    if (modificationContext !== null) {
      // If dragging, return the cursor position
      return mouseYPosition;
    }

    // Otherwise, return the closest point
    const closestPoint = modificationHandlePositions.reduce(
      (closest, point) => {
        const currentDistance = Math.abs(point - mouseYPosition);
        const closestDistance = Math.abs(closest - mouseYPosition);
        return currentDistance < closestDistance ? point : closest;
      },
      modificationHandlePositions[0]
    );
    return closestPoint;
  }, [mouseYPosition, modificationHandlePositions, modificationContext]);

  const beginTimeslotModification = useCallback(
    (yPos: number, modificationType: "drag" | "scale") => {
      if (modificationHandleYPosition === null || mouseYPosition === null)
        return;

      if (modificationType === "scale") {
        let topTimeblock = timeBlocks.find(
          (block) => block.endPx === modificationHandleYPosition
        );
        let bottomTimeblock = timeBlocks.find(
          (block) => block.startPx === modificationHandleYPosition
        );

        if (!topTimeblock) {
          topTimeblock = {
            type: "blank",
            start: dayBounds[0],
            end: dayBounds[0],
            startPx: 0,
            endPx: 0,
            timeslot: {
              startTimestampMills: dayBounds[0],
              endTimestampMills: dayBounds[0],
            },
          };
        }

        if (!bottomTimeblock) {
          bottomTimeblock = {
            type: "blank",
            start: dayBounds[1],
            end: dayBounds[1],
            startPx: 0,
            endPx: 0,
            timeslot: {
              startTimestampMills: dayBounds[1],
              endTimestampMills: dayBounds[1],
            },
          };
        }

        const newDraggingTimeslots: TimeslotModificationContext = {
          modificationType: "scale",
          above: topTimeblock,
          below: bottomTimeblock,
          startDragY: yPos,
          currentlyDragging: null,
          dragDirection: null,
          currentTime: null,
          currentStartMs: null,
          currentEndMs: null,
        };
        setModificationContext(newDraggingTimeslots);
      } else {
        const timeblock =
          timeBlocks.find(
            (block) => block.timeslot.id === hoveredTimeslot?.id
          ) || null;
        const newDraggingTimeslots: TimeslotModificationContext = {
          modificationType: "drag",
          startDragY: yPos,
          currentlyDragging: timeblock,
          dragDirection: null,
          currentTime: null,
          currentStartMs: null,
          currentEndMs: null,
        };

        const updatedModificationContext = updateTimeslotModification(
          yPos,
          pxPerMs,
          dayBounds,
          newDraggingTimeslots
        );
        setModificationContext(updatedModificationContext);
      }
    },
    [
      modificationHandleYPosition,
      mouseYPosition,
      timeBlocks,
      dayBounds,
      pxPerMs,
      hoveredTimeslot?.id,
    ]
  );

  /**
   * Updates the cursor position and checks for conflicts.
   */
  const handleMouseMovement = useCallback(
    (yPos: number) => {
      setMouseYPosition(yPos);

      if (!timeslots) return;

      if (modificationContext === null) return;

      const updatedModificationContext = updateTimeslotModification(
        yPos,
        pxPerMs,
        dayBounds,
        modificationContext
      );

      setModificationContext(updatedModificationContext);

      const updatedTimeslot: TimeslotLite = {
        id: updatedModificationContext.currentlyDragging?.timeslot?.id || "",
        startTimestampMills: updatedModificationContext.currentStartMs ?? 0,
        endTimestampMills: updatedModificationContext.currentEndMs ?? 0,
      };

      // Check for conflicts
      const conflicts = getConflictingTimeslots(timeslots, updatedTimeslot);
      if (conflicts.length > 0) {
        // Highlight conflicting timeslots
        setConflictingTimeslots(conflicts.map((t) => t.id));
      } else {
        // No conflicts, clear any previously highlighted conflicts
        setConflictingTimeslots([]);
      }
    },
    [timeslots, modificationContext, pxPerMs, dayBounds]
  );

  const finishTimeslotModification = useCallback(async () => {
    const currentModificationContext = modificationContext;
    setModificationContext(null);
    setConflictingTimeslots([]);
    if (currentModificationContext === null) {
      return;
    }
    if (
      currentModificationContext?.currentStartMs === null ||
      currentModificationContext?.currentEndMs === null ||
      currentModificationContext?.currentTime === null
    ) {
      return;
    }
    // Check if all necessary data is available
    if (!dayBounds || !timeslots) {
      return;
    }

    const compareTimeslot = {
      id: currentModificationContext.currentlyDragging?.timeslot?.id || "",
      startTimestampMills: currentModificationContext.currentStartMs,
      endTimestampMills: currentModificationContext.currentEndMs,
    };

    const timeslotChanges = await getTimeslotAdjustments(
      timeslots,
      compareTimeslot
    );

    let undoSteps: TimeslotChange[] = [];

    if (currentModificationContext.currentlyDragging?.type !== "timeslot") {
      // No timeslot to update, just apply the changes
      undoSteps = await applyTimeslotChanges(
        cloudSyncedDB,
        [...timeslotChanges],
        true
      );
    } else {
      const updatedTimeslot = {
        ...currentModificationContext.currentlyDragging.timeslot,
        startTimestampMills: currentModificationContext.currentStartMs,
        endTimestampMills: currentModificationContext.currentEndMs,
      };
      undoSteps = await applyTimeslotChanges(
        cloudSyncedDB,
        [...timeslotChanges, { action: "update", timeslot: updatedTimeslot }],
        true
      );
    }

    // Push undo steps after a short delay
    setTimeout(() => {
      pushUndoSteps(undoSteps, true);
    }, 20);
  }, [modificationContext, dayBounds, timeslots, cloudSyncedDB, pushUndoSteps]);

  const timeslotBoundaries: number[] = useMemo(() => {
    if (!timeslots) return [];
    if (!dayBounds) return [];
    return generateTimeslotBoundaries(timeslots, dayBounds);
  }, [timeslots, dayBounds]);

  /**
   * Merges a timeslot up or down based on the specified direction to the previous or next timeslot boundary.
   */
  const processMerges = useCallback(
    async (timeslotId: string | null, direction: "up" | "down") => {
      if (!dayBounds || !timeslots || !timeslotId) return;

      const timeslot = timeslots.find((t) => t.id === timeslotId);
      if (!timeslot) return;

      // Determine new start and end times based on direction
      const [newStart, newEnd] =
        direction === "up"
          ? [
              // Get the last boundary that is less than the timeslot's start time
              timeslotBoundaries
                .filter((b) => b < timeslot.startTimestampMills)
                .pop() || timeslot.startTimestampMills,
              timeslot.endTimestampMills,
            ]
          : [
              timeslot.startTimestampMills,
              // Get the first boundary that is greater than the timeslot's end time
              timeslotBoundaries.find((b) => b > timeslot.endTimestampMills) ||
                timeslot.endTimestampMills,
            ];

      if (
        newStart === timeslot.startTimestampMills &&
        newEnd === timeslot.endTimestampMills
      )
        return;

      const updatedTimeslot = {
        ...timeslot,
        startTimestampMills: newStart,
        endTimestampMills: newEnd,
      };

      // Get and apply timeslot adjustments
      const timeslotChanges = await getTimeslotAdjustments(
        timeslots,
        updatedTimeslot
      );
      const undoSteps = await applyTimeslotChanges(
        cloudSyncedDB,
        [...timeslotChanges, { action: "update", timeslot: updatedTimeslot }],
        true
      );

      pushUndoSteps(undoSteps, true);
    },
    [dayBounds, timeslots, timeslotBoundaries, pushUndoSteps, cloudSyncedDB]
  );

  // Get the positions of the drag buttons for each timeslot
  const buttonPositions: number[] = useMemo(() => {
    if (!dayBounds) return [];
    if (!timeslots) return [];
    if (!timeslots.length) return [];
    if (selectedTimeslot === null) return [];

    const timeslot = timeslots.find((t) => t.id === selectedTimeslot?.id);

    if (!timeslot) return [];

    const top = (timeslot.startTimestampMills - dayBounds[0]) * pxPerMs;
    const bottom = (timeslot.endTimestampMills - dayBounds[0]) * pxPerMs;
    const middle = (top + bottom) / 2;
    return [top, middle, bottom];
  }, [dayBounds, timeslots, selectedTimeslot, pxPerMs]);

  const allSnapPoints: number[] = useMemo(() => {
    if (!dayBounds) return [];
    if (!timeslotBoundaries) return [];
    if (!timeslots) return [];
    const allPoints = new Set([
      ...dayBounds,
      ...timeslotBoundaries,
      currentTimeMills,
    ]);
    return Array.from(allPoints).sort((a, b) => a - b);
  }, [dayBounds, timeslotBoundaries, timeslots, currentTimeMills]);

  const shouldShowDragButtons: boolean = useMemo(() => {
    if (mouseYPosition === null) return false;
    if (editingTimeslot || newTimeslotParams !== null) return false;

    return buttonPositions.length > 0;
  }, [
    mouseYPosition,
    editingTimeslot,

    newTimeslotParams,
    buttonPositions.length,
  ]);

  const sameTimezone: boolean = useMemo(() => {
    return currentTimezone === computerTimezone;
  }, [currentTimezone, computerTimezone]);

  const shouldShowAddButton: boolean = useMemo(() => {
    if (editingTimeslot || newTimeslotParams !== null) return false;
    return true;
  }, [editingTimeslot, newTimeslotParams]);

  useEffect(() => {
    const containerRefCurrent = containerRef.current;
    const handleTouchMove = (e: TouchEvent) => {
      console.log("touchmove", isModifyingTimeslot);
      if (isModifyingTimeslot) {
        e.preventDefault();
      }
    };
    if (containerRefCurrent) {
      containerRefCurrent.addEventListener("touchmove", handleTouchMove, {
        passive: false,
      });
    }

    window.addEventListener("mouseup", finishTimeslotModification);
    window.addEventListener("touchend", finishTimeslotModification);
    return () => {
      window.removeEventListener("mouseup", finishTimeslotModification);
      window.removeEventListener("touchend", finishTimeslotModification);
      containerRefCurrent?.removeEventListener("touchmove", handleTouchMove);
    };
  }, [isModifyingTimeslot, finishTimeslotModification]);

  // Helper functions for timeslot dimensions
  const getTimeslotHeight = (timeslot: Timeslot) => {
    const durationMs =
      timeslot.endTimestampMills - timeslot.startTimestampMills;
    return durationMs * pxPerMs;
  };

  const getTimeslotTop = (timeslot: Timeslot) => {
    return (
      (timeslot.startTimestampMills - dayBounds[0]) * pxPerMs + HEADER_HEIGHT
    );
  };

  const TOP_MARGIN = 20;

  return (
    <Stack
      direction="column"
      gap={0}
      marginTop={`${TOP_MARGIN}px`}
      marginBottom="50px"
      paddingX="16px"
      ref={containerRef}
      onMouseMove={(e) => {
        handleMouseMovement(
          e.clientY -
            containerRef.current!.getBoundingClientRect().top -
            HEADER_HEIGHT
        );
      }}
      onTouchMove={(e) => {
        const touch = e.touches[0];
        handleMouseMovement(
          touch.clientY -
            containerRef.current!.getBoundingClientRect().top -
            HEADER_HEIGHT
        );
      }}
      onMouseLeave={() => {
        if (!modificationContext?.currentlyDragging) {
          setMouseYPosition(null);
          setHoveredTimeslot(null);
        }
      }}
      onTouchEnd={() => {
        setMouseYPosition(null);
        setHoveredTimeslot(null);
      }}>
      <Stack direction="row" gap={0} height={HEADER_HEIGHT + "px"}>
        <TimezoneHeader width={60} highlighted={false}>
          {computerTimezone}
        </TimezoneHeader>

        {!sameTimezone && (
          <TimezoneHeader
            width={60}
            highlighted={true}
            onClick={() => {
              revertTimezone();
            }}>
            {currentTimezone}
            <Close />
          </TimezoneHeader>
        )}
      </Stack>
      <Stack direction="row" gap={0}>
        {dayBounds && (
          <>
            <FixedTimeslotColumn
              direction="column"
              width={60}
              gap="1px"
              padding="1px"
              highlighted={false}>
              {hourTimestamps.map((hour, index) => {
                const time = new TZDate(hour, computerTimezone);
                const timeString = format(time, "HH:mm");
                const hourNumber = parseInt(timeString.split(":")[0]);
                const isLastHour = index === hourTimestamps.length - 1;
                const nextTime = new TZDate(
                  hour + 1000 * 60 * 60,
                  computerTimezone
                );
                const nextTimeString = format(nextTime, "HH:mm");
                const isMidnight = hourNumber === 0;
                return (
                  <Hour
                    height={pxPerHr}
                    backgroundColor={hourLabelBackgroundColor(hourNumber)}
                    isMidnight={isMidnight}
                    key={index}>
                    <div>{timeString}</div>
                    {isLastHour && <div>{nextTimeString}</div>}
                  </Hour>
                );
              })}
              {timeslotBoundaries.map((mills, index) => {
                // If this belongs to the highlighted timeslot, or dragged timeslot, don't show it
                if ([modificationContext?.currentTime].includes(mills)) {
                  return null;
                }
                // Convert mills to px
                const px = (mills - dayBounds[0]) * pxPerMs;
                // Convert mills to time using date-fns
                const time = new TZDate(mills, computerTimezone);
                const timeString = format(time, "HH:mm");
                return (
                  <TimeDisplay key={index + mills} top={px - 10}>
                    {timeString}
                  </TimeDisplay>
                );
              })}
            </FixedTimeslotColumn>
            {!sameTimezone && (
              <FixedTimeslotColumn
                direction="column"
                width={60}
                gap="1px"
                padding="1px"
                highlighted={true}>
                {hourTimestamps.map((hourTs, index) => {
                  const time = new TZDate(hourTs, currentTimezone);
                  const timeString = format(time, "HH:mm");
                  const hourNumber = parseInt(timeString.split(":")[0]);
                  const isLastHour = index === hourTimestamps.length - 1;
                  const nextTime = new TZDate(
                    hourTs + 1000 * 60 * 60,
                    currentTimezone
                  );
                  const nextTimeString = format(nextTime, "HH:mm");
                  const isMidnight = hourNumber === 0;

                  return (
                    <Hour
                      height={pxPerHr}
                      backgroundColor={hourLabelBackgroundColor(hourNumber)}
                      isMidnight={isMidnight}
                      key={index}>
                      <div>{timeString}</div>
                      {isLastHour && <div>{nextTimeString}</div>}
                    </Hour>
                  );
                })}
                {timeslotBoundaries.map((mills, index) => {
                  // If this belongs to the highlighted timeslot, or dragged timeslot, don't show it
                  if ([modificationContext?.currentTime].includes(mills)) {
                    return null;
                  }
                  // Convert mills to px
                  const px = (mills - dayBounds[0]) * pxPerMs;
                  // Convert mills to time using date-fns

                  const time = new TZDate(mills, currentTimezone);
                  const timeString = format(time, "HH:mm");
                  return (
                    <TimeDisplay key={index + mills} top={px - 10}>
                      {timeString}
                    </TimeDisplay>
                  );
                })}
              </FixedTimeslotColumn>
            )}
            <TimelineColumn
              direction="column"
              width="100%"
              gap="1px"
              padding="1px">
              <GridBackground
                height={pxPerHr * totalHours}
                pxPerHr={pxPerHr}
                onMouseEnter={() => {
                  if (!modificationContext?.currentlyDragging) {
                    setHoveredTimeslot(null);
                  }
                }}
                onClick={() => {
                  deselectAll();
                }}></GridBackground>
              {!modificationContext?.currentlyDragging &&
                !newTimeslotParams &&
                modificationHandleYPosition !== null && (
                  <ScaleButton
                    sx={{
                      top: `${
                        modificationHandleYPosition
                          ? modificationHandleYPosition - 5
                          : 0
                      }px`,
                    }}
                    className="handle top"
                    variant="text"
                    onMouseDown={() => {
                      // start scaling timeslot
                      beginTimeslotModification(mouseYPosition || 0, "scale");
                    }}
                    onTouchStart={() => {
                      beginTimeslotModification(mouseYPosition || 0, "scale");
                    }}>
                    <DragHandle className="handleIcon" />
                  </ScaleButton>
                )}
              {shouldShowDragButtons && (
                <>
                  <DragButton
                    sx={{
                      top: `${buttonPositions[1] - 15}px`,
                    }}
                    className="handle middle"
                    variant="text"
                    onMouseDown={() => {
                      beginTimeslotModification(mouseYPosition || 0, "drag");
                    }}
                    onTouchStart={() => {
                      beginTimeslotModification(mouseYPosition || 0, "drag");
                    }}>
                    <DragHandle className="handleIcon" />
                  </DragButton>
                  <MergeButton
                    sx={{
                      top: `${buttonPositions[0] - 10}px`,
                    }}
                    variant="text"
                    onClick={() => {
                      processMerges(hoveredTimeslot?.id || null, "up");
                    }}>
                    <ArrowUpward className="handleIcon" />
                  </MergeButton>
                  <MergeButton
                    sx={{
                      top: `${buttonPositions[2] - 10}px`,
                    }}
                    variant="text"
                    onClick={() => {
                      processMerges(hoveredTimeslot?.id || null, "down");
                    }}>
                    <ArrowDownward className="handleIcon" />
                  </MergeButton>
                </>
              )}
              {!modificationContext?.currentlyDragging &&
                blankTimeslots.map((timeslot) => (
                  <BlankTimeslotComponent
                    key={
                      timeslot.startTimestampMills + timeslot.endTimestampMills
                    }
                    startTimestampMills={timeslot.startTimestampMills}
                    endTimestampMills={timeslot.endTimestampMills}
                    dayBounds={dayBounds}
                    pxPerMs={pxPerMs}
                    isReduced={showNotesMode}
                    onMouseEnter={() => {
                      if (!modificationContext?.currentlyDragging) {
                        setHoveredTimeslot(null);
                      }
                    }}
                    onClick={() => {
                      deselectAll();
                    }}
                    onDoubleClick={() => {
                      openAddTimeslot(
                        timeslot.startTimestampMills,
                        timeslot.endTimestampMills -
                          timeslot.startTimestampMills
                      );
                    }}
                  />
                ))}
              {timeslots
                ?.filter((t) =>
                  modificationContext?.currentlyDragging?.type !== "timeslot"
                    ? true
                    : modificationContext?.currentlyDragging?.timeslot?.id !==
                      t.id
                )
                .map((timeslot) => (
                  <TimeslotComponent
                    key={timeslot.id + timeslot.startTimestampMills}
                    timeslot={timeslot}
                    dayBounds={dayBounds}
                    pxPerMs={pxPerMs}
                    currentTimezone={currentTimezone}
                    activityCategoryMap={activityCategoryMap}
                    isSelected={selectedTimeslot?.id === timeslot.id}
                    isConflicting={conflictingTimeslots.includes(timeslot.id)}
                    isEditing={editingTimeslot === timeslot.id}
                    isReduced={showNotesMode}
                    disableHover={
                      !modificationContext?.currentlyDragging ||
                      !!newTimeslotParams
                    }
                    onMouseEnter={() => {
                      if (!modificationContext?.currentlyDragging) {
                        setHoveredTimeslot(timeslot);
                      }
                    }}
                    onClick={() => {
                      deselectAll();
                      setSelectedTimeslot(timeslot);
                    }}
                    onDoubleClick={() => {
                      if (editingTimeslot !== timeslot.id) {
                        setEditingTimeslot(timeslot.id);
                      } else {
                        setEditingTimeslot(null);
                      }
                    }}
                  />
                ))}
              {modificationContext?.currentlyDragging && (
                <DraggingTimeslotComponent
                  modificationContext={modificationContext}
                  activityCategoryMap={activityCategoryMap}
                  currentYPos={mouseYPosition ?? 0}
                  pxPerMs={pxPerMs}
                  dayBounds={dayBounds}
                />
              )}
              {modificationContext?.currentTime && (
                <TimeDisplay
                  sx={{
                    position: "absolute",
                    top: (mouseYPosition ?? 0) - 10 + "px",
                    left: "15px",
                  }}
                  variant="subtitle1">
                  {timeOfDay(modificationContext.currentTime)}
                </TimeDisplay>
              )}
              {shouldShowAddButton && mouseYPosition && (
                <TimelineAddButton
                  mouseYPosition={mouseYPosition}
                  dayBounds={dayBounds}
                  allSnapPoints={allSnapPoints}
                  pxPerMs={pxPerMs}
                  onAddTimeslot={(addMs, lengthMs) => {
                    openAddTimeslot(addMs, lengthMs);
                  }}
                />
              )}
              {newTimeslotParams && (
                <NewTimeslotDraggableIndicator
                  newTimeslotParams={newTimeslotParams}
                  pxPerMs={pxPerMs}
                  dayBounds={dayBounds}
                  setNewTimeslotTimes={(times) => {
                    setNewTimeslotParams({
                      ...newTimeslotParams,
                      ...times,
                    });
                  }}
                />
              )}
              <CurrentTimeLine
                dayStartMills={dayBounds[0]}
                pxPerMs={pxPerMs}
                currentTimeMills={currentTimeMills}
                showTime={true}
              />
            </TimelineColumn>
            {/* Padding */}
            {showNotesMode && (
              <>
                <TimelineColumn width="10px"></TimelineColumn>
                <TimelineColumn width="100%" sx={{ backgroundColor: "#eee" }}>
                  {timeslots?.map((timeslot) => {
                    const height = getTimeslotHeight(timeslot);
                    const top = getTimeslotTop(timeslot) - TOP_MARGIN - 19;

                    return (
                      <TimeslotNotes
                        key={timeslot.id}
                        timeslot={timeslot}
                        height={height}
                        top={top}
                      />
                    );
                  })}
                </TimelineColumn>
              </>
            )}
            <TimelineColumn width="10px"></TimelineColumn>
            {showLocationHistory && (
              <LocationHistoryColumn
                dayBounds={dayBounds}
                pxPerMs={pxPerMs}
                currentTimeMills={currentTimeMills}
                locationHistory={locationHistory}
                locationTimeBlocks={locationTimeBlocks}
                applyPlaceToTimeslots={applyPlaceToTimeslots}
                setNewTimeslotParams={setNewTimeslotParams}
              />
            )}
          </>
        )}
      </Stack>
    </Stack>
  );
};
