import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  LinearProgress,
  Paper,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { db, TableType } from "../../database/db";
import { useSettings } from "../../hooks/useSettings";
import { useSnackbar } from "../../hooks/useSnackbar";
import { createContentHash } from "../../utils/contentHash";

type IntegrityStatus = {
  aheadOfServer: number;
  behindServer: number;
  deletedOnServer: number;
  missingLocally: number;
  localCount: number;
  serverCount: number;
  error?: string;
};

type TableStatus = Record<TableType, IntegrityStatus>;

const initialTableStatus: TableStatus = {
  timeslots: {
    aheadOfServer: 0,
    behindServer: 0,
    deletedOnServer: 0,
    missingLocally: 0,
    localCount: 0,
    serverCount: 0,
  },
  activities: {
    aheadOfServer: 0,
    behindServer: 0,
    deletedOnServer: 0,
    missingLocally: 0,
    localCount: 0,
    serverCount: 0,
  },
  categories: {
    aheadOfServer: 0,
    behindServer: 0,
    deletedOnServer: 0,
    missingLocally: 0,
    localCount: 0,
    serverCount: 0,
  },
  places: {
    aheadOfServer: 0,
    behindServer: 0,
    deletedOnServer: 0,
    missingLocally: 0,
    localCount: 0,
    serverCount: 0,
  },
  locationHistories: {
    aheadOfServer: 0,
    behindServer: 0,
    deletedOnServer: 0,
    missingLocally: 0,
    localCount: 0,
    serverCount: 0,
  },
  journals: {
    aheadOfServer: 0,
    behindServer: 0,
    deletedOnServer: 0,
    missingLocally: 0,
    localCount: 0,
    serverCount: 0,
  },
  journalFieldDefinitions: {
    aheadOfServer: 0,
    behindServer: 0,
    deletedOnServer: 0,
    missingLocally: 0,
    localCount: 0,
    serverCount: 0,
  },
};

const DataIntegrityCheck: React.FC = () => {
  const { cloudBackupEndpoint, cloudSyncKey } = useSettings();
  const { showSnackbar } = useSnackbar();

  const [selectedTables, setSelectedTables] = useState<
    Record<TableType, boolean>
  >({
    timeslots: true,
    activities: true,
    categories: true,
    places: true,
    locationHistories: true,
    journals: true,
    journalFieldDefinitions: true,
  });

  const [isChecking, setIsChecking] = useState(false);
  const [progress, setProgress] = useState(0);
  const [currentTable, setCurrentTable] = useState<TableType | null>(null);
  const [tableStatus, setTableStatus] =
    useState<TableStatus>(initialTableStatus);
  const [checkCompleted, setCheckCompleted] = useState(false);

  // Add more detailed progress tracking
  const [progressDetails, setProgressDetails] = useState({
    currentTableIndex: 0,
    totalTables: 0,
    currentTableProgress: 0,
    processingPhase: "initializing" as
      | "initializing"
      | "hashing"
      | "fetching"
      | "comparing",
  });

  const [tableCounts, setTableCounts] = useState<Record<TableType, number>>({
    timeslots: 0,
    activities: 0,
    categories: 0,
    places: 0,
    locationHistories: 0,
    journals: 0,
    journalFieldDefinitions: 0,
  });

  // Load table counts on component mount
  useEffect(() => {
    const loadTableCounts = async () => {
      const counts: Record<TableType, number> = {
        timeslots: 0,
        activities: 0,
        categories: 0,
        places: 0,
        locationHistories: 0,
        journals: 0,
        journalFieldDefinitions: 0,
      };

      for (const table of Object.keys(counts) as TableType[]) {
        counts[table] = await db[table].count();
      }

      setTableCounts(counts);
    };

    loadTableCounts();
  }, []);

  const handleTableSelect = (table: TableType) => {
    setSelectedTables((prev) => ({
      ...prev,
      [table]: !prev[table],
    }));
  };

  const resetStatus = () => {
    setTableStatus(initialTableStatus);
    setCheckCompleted(false);
    setProgress(0);
    setCurrentTable(null);
  };

  const startIntegrityCheck = async () => {
    if (!cloudBackupEndpoint || !cloudSyncKey) {
      showSnackbar("Cloud backup is not configured", "error");
      return;
    }

    resetStatus();
    setIsChecking(true);

    const tables = Object.entries(selectedTables)
      .filter(([_, selected]) => selected)
      .map(([table]) => table as TableType);

    // Initialize progress tracking
    setProgressDetails({
      currentTableIndex: 0,
      totalTables: tables.length,
      currentTableProgress: 0,
      processingPhase: "initializing",
    });

    let completedTables = 0;
    let hasErrors = false;

    for (let i = 0; i < tables.length; i++) {
      const table = tables[i];
      setCurrentTable(table);
      setProgressDetails((prev) => ({
        ...prev,
        currentTableIndex: i,
        currentTableProgress: 0,
        processingPhase: "initializing",
      }));

      try {
        await checkTableIntegrity(table);
      } catch (error) {
        console.error(`Error checking integrity for ${table}:`, error);
        showSnackbar(
          `Error checking ${table}: ${(error as Error).message}`,
          "error"
        );

        // Mark this table as having an error
        setTableStatus((prev) => ({
          ...prev,
          [table]: {
            ...prev[table],
            error: (error as Error).message,
          },
        }));

        hasErrors = true;
      }
      completedTables++;

      // Update overall progress based on completed tables
      const tableProgress = (completedTables / tables.length) * 100;
      setProgress(tableProgress);
    }

    setIsChecking(false);
    setCheckCompleted(true);

    if (hasErrors) {
      showSnackbar(
        "Integrity check completed with errors. Please check the results.",
        "warning"
      );
    } else {
      showSnackbar("Integrity check completed", "success");
    }
  };

  const checkTableIntegrity = async (table: TableType) => {
    try {
      // Get all local items for this table
      const localItems = await db[table].toArray();
      const localItemsMap = new Map(localItems.map((item) => [item.id, item]));

      // Create a map of item IDs to their content hashes
      const localHashMap = new Map<string, string>();

      // Update local count in status
      setTableStatus((prev) => ({
        ...prev,
        [table]: {
          ...prev[table],
          localCount: localItems.length,
        },
      }));

      // Track server items we've seen
      const serverItemsMap = new Map();

      // Update progress to hashing phase
      setProgressDetails((prev) => ({
        ...prev,
        processingPhase: "hashing",
        currentTableProgress: 5, // Start at 5%
      }));

      // Update overall progress
      updateOverallProgress();

      // Process items in batches to avoid UI freezing
      const BATCH_SIZE = 100;
      for (let i = 0; i < localItems.length; i += BATCH_SIZE) {
        const batch = localItems.slice(i, i + BATCH_SIZE);

        // Update hashing progress
        const hashingProgress = Math.min(30, (i / localItems.length) * 30);
        setProgressDetails((prev) => ({
          ...prev,
          currentTableProgress: 5 + hashingProgress,
        }));

        // Update overall progress
        updateOverallProgress();

        // Calculate content hashes for this batch
        for (const item of batch) {
          try {
            // Remove any timestamp fields that might cause false differences
            const itemForHashing = { ...item } as Record<string, unknown>;

            // Safely delete timestamp-related properties if they exist
            const timeProperties = [
              "timestampMills",
              "timestamp",
              "updatedAt",
              "createdAt",
            ];
            for (const prop of timeProperties) {
              if (prop in itemForHashing) {
                delete itemForHashing[prop];
              }
            }

            const hash = await createContentHash(itemForHashing);
            localHashMap.set(item.id, hash);
          } catch (error) {
            console.error(`Error hashing item ${item.id}:`, error);
          }
        }

        // Allow UI to update between batches
        await new Promise((resolve) => setTimeout(resolve, 0));
      }

      // Update progress to fetching phase
      setProgressDetails((prev) => ({
        ...prev,
        processingPhase: "fetching",
        currentTableProgress: 35, // Hashing complete at 35%
      }));

      // Update overall progress
      updateOverallProgress();

      let cursor = "0";
      let hasMore = true;
      let totalProcessed = 0;
      let totalServerItems = 0;
      let iterationCount = 0;
      const MAX_ITERATIONS = 100; // Safety limit to prevent infinite loops

      while (hasMore) {
        try {
          // Safety check: prevent infinite loops
          iterationCount++;
          if (iterationCount > MAX_ITERATIONS) {
            console.error(
              `[${table}] Exceeded maximum iterations (${MAX_ITERATIONS}), breaking loop`
            );
            hasMore = false;
            break;
          }

          console.log(
            `[${table}] Fetching data with cursor: ${cursor} (iteration ${iterationCount})`
          );
          const response = await fetch(
            `${cloudBackupEndpoint}/integrity?table=${table}&cursor=${encodeURIComponent(
              cursor
            )}`,
            {
              headers: {
                "X-Sync-Key": cloudSyncKey || "",
              },
            }
          );

          if (!response.ok) {
            const errorText = await response.text();
            throw new Error(`Server returned ${response.status}: ${errorText}`);
          }

          const data = await response.json();
          const { metadata, has_more, next_cursor, total_count } = data;

          console.log(
            `[${table}] Received ${metadata.length} items, has_more: ${has_more}, next_cursor: ${next_cursor}`
          );

          // If we received no metadata items, break the loop
          if (metadata.length === 0) {
            console.log(`[${table}] No items received, breaking loop`);
            hasMore = false;
            break;
          }

          // Safety check: if we get the same cursor back or no cursor, break the loop
          if (next_cursor === cursor || !next_cursor) {
            console.warn(
              `[${table}] Received same cursor or no cursor, breaking loop to prevent infinite requests`
            );
            hasMore = false;
            break;
          }

          // Update server count in status
          if (total_count !== undefined) {
            totalServerItems = total_count;
            setTableStatus((prev) => ({
              ...prev,
              [table]: {
                ...prev[table],
                serverCount: total_count,
              },
            }));
          }

          // Update progress for fetching phase
          const fetchingProgress =
            totalServerItems > 0
              ? Math.min(30, (totalProcessed / totalServerItems) * 30)
              : 15;

          setProgressDetails((prev) => ({
            ...prev,
            currentTableProgress: 35 + fetchingProgress,
          }));

          // Update overall progress
          updateOverallProgress();

          // Process server items in batches
          const SERVER_BATCH_SIZE = 100;
          for (let i = 0; i < metadata.length; i += SERVER_BATCH_SIZE) {
            const batch = metadata.slice(i, i + SERVER_BATCH_SIZE);

            // Process each item from the server
            for (const item of batch) {
              serverItemsMap.set(item.id, item);
              totalProcessed++;

              if (!localItemsMap.has(item.id)) {
                // Item exists on server but not locally
                setTableStatus((prev) => ({
                  ...prev,
                  [table]: {
                    ...prev[table],
                    missingLocally: prev[table].missingLocally + 1,
                  },
                }));
              } else {
                // Item exists both locally and on server
                if (item.type === "delete") {
                  // Item is deleted on server but exists locally
                  setTableStatus((prev) => ({
                    ...prev,
                    [table]: {
                      ...prev[table],
                      deletedOnServer: prev[table].deletedOnServer + 1,
                    },
                  }));
                } else if (item.contentHash) {
                  // Compare content hashes instead of timestamps
                  const localHash = localHashMap.get(item.id) || "";

                  if (
                    localHash &&
                    item.contentHash &&
                    localHash !== item.contentHash
                  ) {
                    // Content is different
                    setTableStatus((prev) => ({
                      ...prev,
                      [table]: {
                        ...prev[table],
                        aheadOfServer: prev[table].aheadOfServer + 1,
                      },
                    }));

                    console.log(`[${table}] Content mismatch:`, {
                      id: item.id,
                      localHash,
                      serverHash: item.contentHash,
                      serverTimestamp: item.timestamp,
                      localItem: localItemsMap.get(item.id),
                      serverItem: item,
                    });
                  }
                }
              }
            }

            // Allow UI to update between batches
            await new Promise((resolve) => setTimeout(resolve, 0));
          }

          hasMore = has_more;
          cursor = next_cursor || "0";

          // Safety check: if we've processed all items according to total_count, break the loop
          if (totalServerItems > 0 && totalProcessed >= totalServerItems) {
            console.log(
              `[${table}] Processed all ${totalProcessed} items, breaking loop`
            );
            hasMore = false;
            break;
          }

          // Add a small delay between requests to avoid overwhelming the server
          await new Promise((resolve) => setTimeout(resolve, 100));
        } catch (error) {
          console.error(`Error fetching integrity data for ${table}:`, error);
          throw error;
        }
      }

      // Update progress to comparing phase
      setProgressDetails((prev) => ({
        ...prev,
        processingPhase: "comparing",
        currentTableProgress: 65, // Fetching complete at 65%
      }));

      // Update overall progress
      updateOverallProgress();

      // Check for local items not on server
      // Process in batches to avoid UI freezing
      let itemsAheadOfServer = 0;
      const LOCAL_CHECK_BATCH_SIZE = 200;
      const localIds = Array.from(localItemsMap.keys());

      for (let i = 0; i < localIds.length; i += LOCAL_CHECK_BATCH_SIZE) {
        const batchIds = localIds.slice(i, i + LOCAL_CHECK_BATCH_SIZE);

        // Update progress for comparing phase
        const comparingProgress = Math.min(35, (i / localIds.length) * 35);
        setProgressDetails((prev) => ({
          ...prev,
          currentTableProgress: 65 + comparingProgress,
        }));

        // Update overall progress
        updateOverallProgress();

        for (const id of batchIds) {
          if (!serverItemsMap.has(id)) {
            itemsAheadOfServer++;
          }
        }

        // Update the count periodically
        if (itemsAheadOfServer > 0) {
          setTableStatus((prev) => ({
            ...prev,
            [table]: {
              ...prev[table],
              aheadOfServer: prev[table].aheadOfServer + itemsAheadOfServer,
            },
          }));
          itemsAheadOfServer = 0;
        }

        // Allow UI to update between batches
        await new Promise((resolve) => setTimeout(resolve, 0));
      }

      // Mark this table as complete
      setProgressDetails((prev) => ({
        ...prev,
        currentTableProgress: 100,
      }));

      // Update overall progress
      updateOverallProgress();
    } catch (error) {
      console.error(`Error checking integrity for ${table}:`, error);
      throw error;
    }
  };

  // Helper function to update the overall progress based on current table progress
  const updateOverallProgress = () => {
    const { currentTableIndex, totalTables, currentTableProgress } =
      progressDetails;

    if (totalTables === 0) return;

    // Calculate overall progress as a weighted average
    // Each table contributes (1/totalTables) to the overall progress
    // The current table's contribution is weighted by its own progress
    const completedTablesProgress = (currentTableIndex / totalTables) * 100;
    const currentTableContribution =
      (currentTableProgress / 100) * (1 / totalTables) * 100;

    const overallProgress = completedTablesProgress + currentTableContribution;
    setProgress(overallProgress);
  };

  // Function to check if any tables have errors
  const hasErrors = Object.entries(tableStatus)
    .filter(([table]) => selectedTables[table as TableType])
    .some(([_, status]) => status.error);

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Data Integrity Check
      </Typography>

      <Paper sx={{ p: 3, mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          Select tables to check
        </Typography>

        <FormGroup>
          {Object.keys(selectedTables).map((table) => (
            <FormControlLabel
              key={table}
              control={
                <Checkbox
                  checked={selectedTables[table as TableType]}
                  onChange={() => handleTableSelect(table as TableType)}
                  disabled={isChecking}
                />
              }
              label={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography sx={{ mr: 1 }}>{table}</Typography>
                  <Chip
                    size="small"
                    label={`${tableCounts[table as TableType]} items`}
                    color="primary"
                    variant="outlined"
                  />
                </Box>
              }
            />
          ))}
        </FormGroup>

        <Button
          variant="contained"
          color="primary"
          onClick={startIntegrityCheck}
          disabled={isChecking || !Object.values(selectedTables).some(Boolean)}
          sx={{ mt: 2 }}>
          {isChecking ? "Checking..." : "Start Integrity Check"}
        </Button>
      </Paper>

      {isChecking && (
        <Paper sx={{ p: 3, mb: 3 }}>
          <Typography variant="h6" gutterBottom>
            Checking {currentTable}...
          </Typography>
          <Box sx={{ mb: 1 }}>
            <Typography variant="body2" color="textSecondary">
              {progressDetails.processingPhase === "hashing" &&
                "Calculating content hashes..."}
              {progressDetails.processingPhase === "fetching" &&
                "Fetching data from server..."}
              {progressDetails.processingPhase === "comparing" &&
                "Comparing local and server data..."}
              {progressDetails.processingPhase === "initializing" &&
                "Initializing..."}
            </Typography>
          </Box>
          <LinearProgress
            variant="determinate"
            value={progress}
            sx={{ mb: 2 }}
          />
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <CircularProgress size={24} sx={{ mr: 1 }} />
            <Typography variant="body2">
              {Math.round(progress)}% Complete
              {progressDetails.totalTables > 0 &&
                ` (Table ${progressDetails.currentTableIndex + 1}/${
                  progressDetails.totalTables
                })`}
            </Typography>
          </Box>
        </Paper>
      )}

      {checkCompleted && (
        <Paper sx={{ p: 3 }}>
          <Typography variant="h6" gutterBottom>
            Integrity Check Results
          </Typography>

          {hasErrors && (
            <Alert severity="error" sx={{ mb: 3 }}>
              <AlertTitle>Errors Occurred</AlertTitle>
              Some tables could not be fully checked due to errors. The results
              shown may be incomplete.
            </Alert>
          )}

          <Box
            sx={{
              mb: 3,
              p: 2,
              bgcolor: "background.paper",
              borderRadius: 1,
              border: "1px solid",
              borderColor: "divider",
            }}>
            <Typography variant="subtitle2" gutterBottom>
              Summary
            </Typography>

            {Object.entries(tableStatus)
              .filter(([table]) => selectedTables[table as TableType])
              .reduce(
                (acc, [_, status]) => {
                  return {
                    localTotal: acc.localTotal + status.localCount,
                    serverTotal: acc.serverTotal + status.serverCount,
                    aheadTotal: acc.aheadTotal + status.aheadOfServer,
                    behindTotal: acc.behindTotal + status.behindServer,
                    deletedTotal: acc.deletedTotal + status.deletedOnServer,
                    missingTotal: acc.missingTotal + status.missingLocally,
                  };
                },
                {
                  localTotal: 0,
                  serverTotal: 0,
                  aheadTotal: 0,
                  behindTotal: 0,
                  deletedTotal: 0,
                  missingTotal: 0,
                }
              ).localTotal > 0 && (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                {Object.entries(tableStatus)
                  .filter(([table]) => selectedTables[table as TableType])
                  .reduce(
                    (acc, [_, status]) => {
                      return {
                        localTotal: acc.localTotal + status.localCount,
                        serverTotal: acc.serverTotal + status.serverCount,
                        aheadTotal: acc.aheadTotal + status.aheadOfServer,
                        behindTotal: acc.behindTotal + status.behindServer,
                        deletedTotal: acc.deletedTotal + status.deletedOnServer,
                        missingTotal: acc.missingTotal + status.missingLocally,
                      };
                    },
                    {
                      localTotal: 0,
                      serverTotal: 0,
                      aheadTotal: 0,
                      behindTotal: 0,
                      deletedTotal: 0,
                      missingTotal: 0,
                    }
                  ).localTotal > 0 && (
                  <>
                    <Chip
                      label={`Local: ${Object.entries(tableStatus)
                        .filter(([table]) => selectedTables[table as TableType])
                        .reduce(
                          (sum, [_, status]) => sum + status.localCount,
                          0
                        )} items`}
                      color="primary"
                    />
                    <Chip
                      label={`Server: ${Object.entries(tableStatus)
                        .filter(([table]) => selectedTables[table as TableType])
                        .reduce(
                          (sum, [_, status]) => sum + status.serverCount,
                          0
                        )} items`}
                      color="secondary"
                    />
                    {Object.entries(tableStatus)
                      .filter(([table]) => selectedTables[table as TableType])
                      .reduce(
                        (sum, [_, status]) => sum + status.aheadOfServer,
                        0
                      ) > 0 && (
                      <Chip
                        label={`Content differences: ${Object.entries(
                          tableStatus
                        )
                          .filter(
                            ([table]) => selectedTables[table as TableType]
                          )
                          .reduce(
                            (sum, [_, status]) => sum + status.aheadOfServer,
                            0
                          )}`}
                        color="warning"
                        variant="outlined"
                      />
                    )}
                    {Object.entries(tableStatus)
                      .filter(([table]) => selectedTables[table as TableType])
                      .reduce(
                        (sum, [_, status]) => sum + status.behindServer,
                        0
                      ) > 0 && (
                      <Chip
                        label={`Behind server: ${Object.entries(tableStatus)
                          .filter(
                            ([table]) => selectedTables[table as TableType]
                          )
                          .reduce(
                            (sum, [_, status]) => sum + status.behindServer,
                            0
                          )}`}
                        color="warning"
                        variant="outlined"
                      />
                    )}
                    {Object.entries(tableStatus)
                      .filter(([table]) => selectedTables[table as TableType])
                      .reduce(
                        (sum, [_, status]) => sum + status.deletedOnServer,
                        0
                      ) > 0 && (
                      <Chip
                        label={`Deleted on server: ${Object.entries(tableStatus)
                          .filter(
                            ([table]) => selectedTables[table as TableType]
                          )
                          .reduce(
                            (sum, [_, status]) => sum + status.deletedOnServer,
                            0
                          )}`}
                        color="error"
                        variant="outlined"
                      />
                    )}
                    {Object.entries(tableStatus)
                      .filter(([table]) => selectedTables[table as TableType])
                      .reduce(
                        (sum, [_, status]) => sum + status.missingLocally,
                        0
                      ) > 0 && (
                      <Chip
                        label={`Missing locally: ${Object.entries(tableStatus)
                          .filter(
                            ([table]) => selectedTables[table as TableType]
                          )
                          .reduce(
                            (sum, [_, status]) => sum + status.missingLocally,
                            0
                          )}`}
                        color="error"
                        variant="outlined"
                      />
                    )}
                  </>
                )}
              </Box>
            )}
          </Box>

          {Object.entries(tableStatus)
            .filter(([table]) => selectedTables[table as TableType])
            .map(([table, status]) => {
              // If there's an error for this table, show an error message
              if (status.error) {
                return (
                  <Box key={table} sx={{ mb: 3 }}>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                      <Typography
                        variant="subtitle1"
                        fontWeight="bold"
                        sx={{ mr: 2 }}>
                        {table}
                      </Typography>
                      <Chip
                        size="small"
                        label={`Local: ${status.localCount} items`}
                        color="primary"
                        variant="outlined"
                        sx={{ mr: 1 }}
                      />
                    </Box>

                    <Alert severity="error" sx={{ mt: 1 }}>
                      <AlertTitle>Error checking {table}</AlertTitle>
                      {status.error}
                    </Alert>
                  </Box>
                );
              }

              const total =
                status.aheadOfServer +
                status.behindServer +
                status.deletedOnServer +
                status.missingLocally;

              return (
                <Box key={table} sx={{ mb: 3 }}>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                    <Typography
                      variant="subtitle1"
                      fontWeight="bold"
                      sx={{ mr: 2 }}>
                      {table}
                    </Typography>
                    <Chip
                      size="small"
                      label={`Local: ${status.localCount} items`}
                      color="primary"
                      variant="outlined"
                      sx={{ mr: 1 }}
                    />
                    <Chip
                      size="small"
                      label={`Server: ${status.serverCount} items`}
                      color="secondary"
                      variant="outlined"
                    />
                  </Box>

                  {total === 0 ? (
                    <Typography
                      variant="body2"
                      color="success.main"
                      sx={{ display: "flex", alignItems: "center" }}>
                      <span style={{ fontSize: "1.2rem", marginRight: "4px" }}>
                        ✓
                      </span>{" "}
                      In sync
                    </Typography>
                  ) : (
                    <>
                      <Typography
                        variant="body2"
                        color={
                          status.aheadOfServer > 0
                            ? "warning.main"
                            : "text.secondary"
                        }>
                        • {status.aheadOfServer} items with content differences
                        (local content differs from server)
                      </Typography>

                      <Typography
                        variant="body2"
                        color={
                          status.behindServer > 0
                            ? "warning.main"
                            : "text.secondary"
                        }>
                        • {status.behindServer} items behind server (need
                        updating)
                      </Typography>

                      <Typography
                        variant="body2"
                        color={
                          status.deletedOnServer > 0
                            ? "error.main"
                            : "text.secondary"
                        }>
                        • {status.deletedOnServer} items deleted on server (but
                        exist locally)
                      </Typography>

                      <Typography
                        variant="body2"
                        color={
                          status.missingLocally > 0
                            ? "error.main"
                            : "text.secondary"
                        }>
                        • {status.missingLocally} items missing locally (exist
                        on server but not locally)
                      </Typography>
                    </>
                  )}
                </Box>
              );
            })}

          {Object.entries(tableStatus)
            .filter(([table]) => selectedTables[table as TableType])
            .every(
              ([_, status]) =>
                status.aheadOfServer === 0 &&
                status.behindServer === 0 &&
                status.deletedOnServer === 0 &&
                status.missingLocally === 0
            ) && (
            <Typography variant="body1" color="success.main">
              All selected tables are in sync with the server.
            </Typography>
          )}
        </Paper>
      )}
    </Box>
  );
};

export default DataIntegrityCheck;
